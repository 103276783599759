import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import { SingleSelect } from "../../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";
import { createAPIKey } from "../../services";

const apiSchema = yup.object({
  apiProvider: yup.string().required("AI Provider is required"),
  tier: yup.string().required("Tier is required"),
  apiKey: yup.string().required("API Key is required"),
});

const APIDialogCard = ({ open, setOpen, data }: any) => {
  const queryClient = useQueryClient();

  const { control, handleSubmit, formState, setValue, reset } = useForm({
    resolver: yupResolver(apiSchema),
    mode: "onChange",
    defaultValues: {
      apiProvider: data?.providerName || "",
      tier: "",
      apiKey: "",
    },
  });

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  useEffect(() => {
    if (data) {
      setValue("apiProvider", data?.providerName);
    }
  }, [data, setValue]);

  const { mutate, isLoading } = useMutation(createAPIKey, {
    onSuccess: () => {
      toast.success("API Key Added  Successfully");
      queryClient.invalidateQueries("projects");
      queryClient.invalidateQueries("all-models");

      setOpen(false);
      reset();
    },
    onError: (err) => handleError(err),
  });

  const Tier =
    data?.tier?.map((i: any) => ({
      label: i,
      value: i,
    })) || [];

  console.log(Tier, "API");

  const onSubmit = (value: any) => {
    const mutationData = {
      providerId: data?.providerId,
      tier: value?.tier,
      apiKey: value.apiKey,
    };
    mutate(mutationData);
  };

  const handleClose = useCallback(() => {
    setValue("tier", "");
    setValue("apiKey", "");
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <DialogWrapper
        open={open}
        setOpen={handleClose}
        title={"Add API Key"}
        width={isLargeScreen ? "sm" : isMediumScreen ? "xs" : "xs"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <TextField
            formateType={Formate.APIKey}
            control={control}
            name="apiProvider"
            label="API Provider"
            required
            placeholder="Enter API provider"
            backgroundColor="#FFFFFF"
            defaultValue={data?.providerName}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />

          <SingleSelect
            control={control}
            required
            name="tier"
            label="Tier"
            options={Tier}
            defaultData={Tier?.[0]?.value}
            placeholder="Tier"
            backgroundColor="#FFFFFF"
            // border="#F8F8F8"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />

          <TextField
            formateType={Formate.Anything}
            control={control}
            label="API Key"
            name="apiKey"
            required
            placeholder="API Key"
            backgroundColor="#FFFFFF"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              mt: 2,
              justifyContent: "space-between",
            }}
          >
            <MultiTypeButton
              buttonType={ButtonType.Cancel}
              // isLoading={isLoading}
              typeText={"Cancel"}
              actionOnClick={() => {
                setValue("tier", "");
                setValue("apiKey", "");
                setOpen(false);
              }}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              // isLoading={isLoading}
              typeText={"Save"}
              actionOnClick={handleSubmit(onSubmit)}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

export default APIDialogCard;
