import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { Control } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UIStore } from "../../../../store/general";

interface PromptViewTabProps {
  control?: Control;
  noOfVersions: string;
  onClick: (
    clickedStatus: boolean,
    index: number,
    prompt: any,
    id: any
  ) => void;
  index: number;
  prompt: any;
  selectedPromptId: string | null;
}

export default function PromptSmeViewTab({
  control,
  noOfVersions,
  onClick,
  index,
  prompt,
  selectedPromptId,
}: PromptViewTabProps) {
  console.log("Prompts:", prompt);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const theme = useTheme();
  const isDarkMode = UIStore.useState((s: any) => s.isDarkMode);
  const currentPath = window.location.pathname;

  const isSelected = prompt?.projectId === selectedPromptId;

  const handleClick = () => {
    const clickedStatus = !isSelected;
    onClick(clickedStatus, index, prompt, prompt?.projectId);

    navigate(
      `/app/sme/projects/${prompt?.projectId}/sme-prompts-detailed-view`,
      {
        replace: true,
      }
    );
  };

  return (
    <ButtonBase
      className="Datasets-Sme"
      sx={{
        width: "100%",
        padding: "1vh 1vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: `1px solid ${theme.palette.border.primary}`,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        cursor: "pointer",
        backgroundColor:
          isSelected || prompt?.projectId === projectId
            ? "#F5F5FA"
            : "transparent",
        "&:hover": {
          bgcolor: isDarkMode ? "rgba(41, 54, 64, 1)" : "#F5F5FA",
        },
      }}
      onClick={handleClick}
    >
      {/* <SimpleCheckBox
        control={control}
        isChecked={(clickedStatus: boolean) => {
          // console.log("checkbox");
          onClick(clickedStatus, index, dataset, dataset?._id);
        }}
        text={dataset?.name}
      /> */}

      <Typography
        variant="h5"
        sx={{
          fontWeight: prompt?.checked ? "bold" : "normal", // Visual feedback for selection
          textDecoration: prompt?.checked ? "underline" : "none",
        }}
      >
        {prompt?.title || "Dataset Placeholder"}
      </Typography>

      <Box
        sx={{
          width: "6vw",
          bgcolor: isDarkMode ? "rgba(75, 170, 190, 0.3)" : "#C75737",
          textAlign: "center",
          padding: "8px",
          borderRadius: "5px",
          "@media (max-width: 1650px)": {
            padding: "5px 10px",
          },
          "@media (max-width: 1450px)": {
            padding: "5px 10px",
          },
          "@media (max-width: 1280px)": {
            padding: "5px 10px",
          },
          "@media (max-width: 960px)": {
            padding: "5px 10px",
          },
          "@media (max-width: 768px)": {
            padding: "5px 10px",
          },
          "@media (max-width: 480px)": {
            padding: "5px 10px",
          },
        }}
      >
        <Typography
          color={isDarkMode ? "rgba(75, 170, 190, 1)" : "white"}
          variant="subtitle2"
        >
          {prompt?.prompts?.length
            ? `${prompt.prompts.length} ${
                prompt.prompts.length === 1 ? "Prompt" : "Prompts"
              }`
            : prompt?.promptCount
            ? `${prompt.promptCount} ${
                prompt.promptCount === 1 ? "Prompt" : "Prompts"
              }`
            : "NA"}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
