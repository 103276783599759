import { Box, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { UIStore } from "../../../../store/general";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import VersionParamsCard from "../../../dataset/components/UploadedCards/VersionParamsCard";
import { getAllDatasets } from "../../../dataset/services/DatasetService";
import { useConfirm } from "../../../project/components/confirmDialog";
import {
  getAllSmeProjects,
  getOneProject,
} from "../../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import Table from "../../../shared/components/Table";

export default function PromptVersionTable() {
  const { projectId, promptId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const Sme = Cookies.get("Sme");

  const isDarkMode = UIStore.useState((s: any) => s.isDarkMode);

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: Sme === "false" && !!projectId,
      staleTime: Infinity,
    }
  );

  const { data: FindOneSmeProject, isLoading: FindOneSmeLoading }: any =
    useQuery(
      ["sme-projects", projectId],
      () => getAllSmeProjects({ projectId }),
      {
        enabled: Sme === "true" && !!projectId,
        staleTime: Infinity,
      }
    );

  const GetAllPrompts =
    Sme === "true"
      ? FindOneSmeProject?.data || []
      : FindOneProject?.prompts || [];

  //   const { mutate: deleteVersions } = useMutation(deleteDataVersions, {
  //     onSuccess: (res) => {
  //       queryClient.invalidateQueries("GetAllVersions");
  //       displaySuccessMessage(res.data.message);
  //     },
  //     onError: (err) => handleError(err),
  //   });

  const handleDelete = (items: any) => {
    const idsToDelete =
      items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        // deleteVersions({ ids: idsToDelete, datasetId: datasetId });
      },
    });
  };

  const handleView = (data: any) => {
    Sme === "true"
      ? navigate(
          `/app/sme/projects/${projectId}/prompts/${data?.prompts?._id}/version/${data?.versions?._id}/grading-responses`,
          {
            replace: true,
          }
        )
      : navigate(
          `/app/projects/${projectId}/prompts/${promptId}/version/${data?._id}/responses`,
          {
            replace: true,
          }
        );
  };

  const renderButtons = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        {selectedRow.length >= 2 ? (
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Delete versions"}
            actionOnClick={() => handleDelete(selectedRow)}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        ) : (
          <>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              isLoading={isLoading}
              typeText={"Delete version"}
              actionOnClick={() => handleDelete(selectedRow)}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </>
        )}
      </Box>
    );
  };

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || [];

  const { data: dataSets, isLoading: dataSetsLoading }: any = useQuery(
    "datasets",
    () => getAllDatasets({ datasetIds: segregatedData?.datasetIds })
  );
  // console.log("Segregated Data:", segregatedData);

  // console.log("table Column1:", dataSets);

  const tableColumn = dataSets?.data?.[0]?.dataSetVersions;

  const FlatData = GetAllPrompts?.map(
    (prompt: any) => prompt?.prompts?.versions
  );

  const Fk = GetAllPrompts.flatMap((prompt: any) => {
    // Normalize versions into an array
    const versions = Array.isArray(prompt?.prompts?.versions)
      ? prompt?.prompts?.versions
      : [prompt?.prompts?.versions]; // Wrap single object in an array
    // console.log("Inside FKL:", versions);

    // Map each version to include the parent prompt data
    return versions.map((version: any) => ({
      ...prompt, // Spread main prompt data
      versions: version, // Attach version-specific data
    }));
  });
  // console.log("Filtered Data:", Fk);

  const GradeText = useMediaQuery("(max-width: 1420px)")
    ? "Grade"
    : "Start Grading";

  const columns = [
    { key: "number", title: "Version" },
    {
      key: "llm-models",
      title: "LLM Models",
      render: (e: any) => {
        // console.log("Row Data:", e);

        const modelCount =
          new Set(e?.models?.map((model: any) => model?.modelId)).size || 0;
        return modelCount < 10 ? `0${modelCount}` : modelCount.toString();
      },
    },
    {
      key: "params",
      title: "Dataset Versions",
      render: (e: any) => {
        return (
          <VersionParamsCard
            key={"params-card"}
            params={tableColumn?.map((p: any) => p?.tag)}
          />
        );
      },
    },
    {
      key: "datasetLength",
      title: "Dataset Records",
      render: (e: any) => {
        const modelCount = e?.datasets?.length || 0;
        return modelCount < 10 ? `0${modelCount}` : modelCount.toString();
      },
      // render: (e: any) => {
      //   const datasetCount = e?.datasets?.length || 0;
      //   const percentage = Math.min((datasetCount / 10) * 100, 100); // Cap percentage at 100%

      //   return (
      //     <Box display="flex" alignItems="center" gap={1}>
      //       {/* Progress bar */}
      //       <Box sx={{ width: "100%", mr: 1 }}>
      //         <LinearProgress
      //           variant="determinate"
      //           value={percentage}
      //           sx={{
      //             height: 6,
      //             borderRadius: 3,
      //             "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" }, // Green progress bar
      //           }}
      //         />
      //       </Box>
      //       {/* Percentage label */}
      //       <Typography variant="body2" color="textSecondary">{`${Math.round(
      //         percentage
      //       )}%`}</Typography>
      //     </Box>
      //   );
      // },
    },
    // {
    //   key: "sme",
    //   title: "Invited users",
    //   render: (e: any) => {},
    // },
  ];

  const columnsSme = [
    {
      key: "prompt-title",
      title: "Title",
      render: (e: any) => {
        // console.log("Row Data:", e);
        return e?.prompts?.title;
      },
    },
    // {
    //   key: "category",
    //   title: "Category",
    //   render: (e: any) => {
    //     // console.log("Row Data:", e);
    //     const category = e?.prompts?.category?.replace(/_/g, " ") || "NA";

    //     return (
    //       <Typography
    //         variant="subtitle1"
    //         sx={{
    //           fontWeight: 400,
    //         }}
    //       >
    //         {category}
    //       </Typography>
    //     );
    //   },
    // },
    {
      key: "number",
      title: "Version",
      render: (e: any) => {
        // console.log("Row Data:", e);
        // return e?.prompts?.versions?.number;
        return (
          <Box
            sx={{
              width: "6vw",
              bgcolor: isDarkMode ? "rgba(75, 170, 190, 0.3)" : "#C75737",
              textAlign: "center",
              padding: "8px",
              borderRadius: "5px",
              "@media (max-width: 1650px)": {
                padding: "5px 10px",
                width: "7vw",
              },
              "@media (max-width: 1450px)": {
                padding: "5px 10px",
                width: "7vw",
              },
              "@media (max-width: 1124)": {
                padding: "5px 0px",
                textWrap: "nowrap",
                width: "8vw",
              },
              "@media (max-width: 960px)": {
                padding: "5px 10px",
                textWrap: "nowrap",
                width: "8vw",
              },
              "@media (max-width: 768px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 480px)": {
                padding: "5px 10px",
              },
            }}
          >
            <Typography
              color={isDarkMode ? "rgba(75, 170, 190, 1)" : "white"}
              variant="subtitle2"
            >
              Version {e?.prompts?.versions?.number}
            </Typography>
          </Box>
        );
      },
    },
    {
      key: "llm-models",
      title: "Models",
      render: (e: any) => {
        const modelCount = e?.prompts?.modelsCount || 0;
        return modelCount < 10 ? `0${modelCount}` : modelCount.toString();
      },
    },
    {
      key: "responses",
      title: "Responses",
      render: (e: any) => {
        // console.log("Row Data:", e);
        return e?.responses?.totalResponsesCount;
      },
    },
    {
      key: "datasetLength",
      title: "Status",
      render: (e: any) => {
        // console.log("Row Data:", e);
        return `${e?.responses?.gradedResponsesCount} of ${e?.responses?.totalResponsesCount}`;
      },
    },

    {
      key: "grading",
      title: "Grading",

      render: (e: any) => {
        // Use gradedResponsesCount instead of totalResponsesCount for the progress calculation
        const gradedCount = e?.responses?.gradedResponsesCount || 0;
        const totalCount = e?.responses?.totalResponsesCount || 0;

        // Calculate the percentage of graded responses
        const percentage =
          totalCount > 0 ? Math.min((gradedCount / totalCount) * 100, 100) : 0;

        return (
          <Box display="flex" alignItems="center" gap={1}>
            {percentage === 0 ? (
              <Box
                sx={{
                  display: "inline-block",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  background:
                    "linear-gradient(90deg, #F07946 0%, #F4B537 100%)",
                  cursor: "pointer",
                  "@media (max-width: 1650px)": {
                    padding: "5px 10px",
                  },
                  "@media (max-width: 1450px)": {
                    padding: "5px 10px",
                  },
                  "@media (max-width: 1280px)": {
                    padding: "5px 10px",
                  },
                  "@media (max-width: 960px)": {
                    padding: "5px 10px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "5px 10px",
                  },
                  "@media (max-width: 480px)": {
                    padding: "5px 10px",
                  },
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "#FFFFFF",
                    fontFamily: "Figtree, sans-serif",
                    fontWeight: 500,
                    flexWrap: "nowrap",

                    "@media (max-width: 1650px)": {
                      flexWrap: "nowrap",
                    },
                    "@media (max-width: 1450px)": {
                      flexWrap: "nowrap",
                    },
                    "@media (max-width: 1280px)": {
                      flexWrap: "nowrap",
                    },
                    "@media (max-width: 960px)": {
                      flexWrap: "nowrap",
                    },
                    "@media (max-width: 768px)": {
                      flexWrap: "nowrap",
                    },
                    "@media (max-width: 480px)": {
                      flexWrap: "nowrap",
                    },
                  }}
                >
                  {GradeText}
                </Typography>
              </Box>
            ) : (
              <>
                <Box sx={{ width: "100%", mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={percentage}
                    sx={{
                      height: 6,
                      borderRadius: 3,
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#4caf50",
                      },
                    }}
                  />
                </Box>
                <Typography variant="body1" color="textSecondary">
                  {`${Math.round(percentage)}%`}
                </Typography>
              </>
            )}
          </Box>
        );
      },
    },
  ];

  return (
    // <Box sx={{ padding: "1.3vw 1.5vw 1.3vw 0.3vw" }}>
    <Box
      sx={{
        padding: Sme === "true" ? "4vh 1vw 1.5vh 1vw" : "1.5vh 2.2vw",
        "@media (max-width: 1650px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
        "@media (max-width: 1450px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
        "@media (max-width: 1280px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
        "@media (max-width: 960px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
        "@media (max-width: 768px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
        "@media (max-width: 480px)": {
          padding: Sme === "true" ? "3.2vh 0vw 1vh 0vw" : "1.5vh 2.6vw",
        },
      }}
    >
      {/* <Box display="flex" justifyContent="space-between">
        <Box display="flex" width="40%">
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
        </Box>
       

        {Sme === "false" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              mt: -1,
            }}
          >
             <Box>
            {selectedRow?.length >= 1 &&
              segregatedData?.versions?.length >= 1 &&
              renderButtons()}
          </Box> 
            <Box>
              <MultiTypeButton
                buttonType={ButtonType.Gradient}
                typeText={"Create new version"}
                actionOnClick={() => {
                  navigate(
                    `/app/projects/${projectId}/prompts/${promptId}/responses`
                  );
                }}
              />
            </Box>
          </Box>
        )}
      </Box> */}

      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          mt: Sme === "true" ? 0 : 3,
          "@media (max-width: 1650px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
          "@media (max-width: 1450px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
          "@media (max-width: 1280px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
          "@media (max-width: 960px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
          "@media (max-width: 768px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
          "@media (max-width: 480px)": {
            mt: Sme === "true" ? 0 : 0.5,
          },
        }}
      >
        {Sme === "true"
          ? // `${segregatedData?.prompts?.title || ""} Prompt`
            "Prompts"
          : `${segregatedData?.title || ""} Prompt versions`}
      </Typography>

      <Box
        sx={{
          mt: Sme === "true" ? 3 : 6,
          "@media (max-width: 1650px)": {
            mt: Sme === "true" ? 2 : 5,
          },
          "@media (max-width: 1450px)": {
            mt: Sme === "true" ? 2 : 5,
          },
          "@media (max-width: 1280px)": {
            mt: Sme === "true" ? 2 : 5,
          },
          "@media (max-width: 960px)": {
            mt: Sme === "true" ? 2 : 5,
          },
          "@media (max-width: 768px)": {
            mt: Sme === "true" ? 2 : 5,
          },
          "@media (max-width: 480px)": {
            mt: Sme === "true" ? 2 : 5,
          },
        }}
      >
        <Table
          data={
            Sme === "true"
              ? GetAllPrompts.flatMap((prompt: any) => {
                  const versions = Array.isArray(prompt?.prompts?.versions)
                    ? prompt?.prompts?.versions
                    : [prompt?.prompts?.versions];

                  return versions.map((version: any) => ({
                    ...prompt,
                    versions: version,
                  }));
                })
              : segregatedData?.versions
          }
          columns={Sme === "true" ? columnsSme : columns}
          loading={
            Sme === "true" ? FindOneSmeLoading : dataSetsLoading || isLoading
          }
          is_Id={true}
          renderButtons={renderButtons}
          onRowSelectionChange={(selected: any) => {
            setSelectedRow(selected || null);
          }}
          onViewClick={(item: any) => {
            localStorage.clear();
            handleView(item);
          }}
          pagination={{
            totalCount:
              Sme === "true"
                ? GetAllPrompts.reduce((count: number, prompt: any) => {
                    const versions = Array.isArray(prompt?.versions)
                      ? prompt?.versions
                      : [prompt?.versions];
                    return count + (versions?.length || 0);
                  }, 0)
                : segregatedData?.versions?.length,
            pageCount: limit,
            setPageCount: setLimit,
            page: offset / limit,
            setPage: (page) => {
              const newOffset = page * limit;
              setOffset(newOffset);
            },
          }}
          onPageChangeCallback={(paginationData) => {
            setOffset(paginationData.offset);
            setLimit(paginationData.limit);
          }}
        />
      </Box>
    </Box>
  );
}
