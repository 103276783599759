import { Box, Radio, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CardSelector from "./cardSelector";

const PresetsSection = ({
  presets,
  cardSelected,
  presetCreation,
  includedPreset,
  preSetDelete,
  clearSelection,
  dialog,
}: {
  presets: any[];
  cardSelected: any[];
  presetCreation?: any;
  preSetDelete: any;
  includedPreset: any;
  clearSelection?: any;
  dialog?: boolean;
}) => {
  const [selectedPreset, setSelectedPreset] = useState<any | null>(null);

  const [selectedPresetIndex, setSelectedPresetIndex] = useState<number | null>(
    null
  );
  const [selectedName, setSelectedName] = useState<any>();

  const handleNameSelect = (name: any) => {
    setSelectedName(name);
    presetCreation(name);
  };

  useEffect(() => {
    if (!clearSelection) {
      setSelectedPreset(null);
    }
  }, [clearSelection]);

  const selectedKeys = selectedName?.versionName;

  const matchingPresets = presets?.filter((preset: any) => {
    const presetKeys = preset.selectedCards
      .map((card: any) => card?.versionName)
      .sort();

    if (selectedKeys?.length === 1) {
      return presetKeys.includes(selectedKeys[0]);
    }

    return presetKeys.includes(selectedKeys);
  });

  const namesMapped = cardSelected.map(
    (card) => `${card?.providerName} ${card?.versionName}`
  );

  const handlePresetClick = (preset: any, index: number) => {
    if (selectedPreset?.name === preset.name) {
      setSelectedPreset(null);
      setSelectedPresetIndex(null);
      includedPreset(null);
      preSetDelete(false);
    } else {
      setSelectedPreset(preset);
      setSelectedPresetIndex(index);
      includedPreset(preset);
      preSetDelete(true);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
      }}
    >
      {/* <Box
          sx={{
            bgcolor: "#F8F8F8",
            borderRadius: "8px",
            padding: "10px",
          }}
        ></Box> */}
      {/* {!dialog ? (
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
          }}
        >
          Selected Model
        </Typography>
      ) : null} */}

      {cardSelected?.length >= 1 && (
        <CardSelector
          names={[...namesMapped].reverse()}
          onNameSelect={handleNameSelect}
          cards={cardSelected}
          setDelete={preSetDelete}
          emptyPreset={setSelectedPreset}
        />
      )}

      <Box
        sx={{
          border: "1px solid #1212121A",
          // height: "18vh",
          // borderTop: "none",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#FDF7F4",
          color: "#121212B2",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid #1212121A",
            gap: "10px",
            p: "0.5vw 0.5vw",
          }}
        >
          <img
            src={Icons.Memo}
            alt="Memo"
            width={"20px"}
            height={"20px"}
            style={{ marginTop: 2 }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
              }}
            >
              Selected presets for the model
            </Typography>
          </Box>
        </Box> */}

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: matchingPresets.length === 0 ? "center" : "left",
            justifyContent:
              matchingPresets.length === 0 ? "center" : "flex-start",
            alignItems: matchingPresets.length === 0 ? "center" : "none",
          }}
        >
          {matchingPresets.length > 0 ? (
            <Stack>
              <Box sx={{ width: "100%", overflowY: "auto" }}>
                {matchingPresets.map((preset, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // borderBottom:
                      //   selectedPreset?.name === preset?.name
                      //     ? "4px solid #F07946"
                      //     : "none",
                      p: dialog ? "0.2vh 0.3vw" : "0.2vh 0.5vw",
                      mb: "5px",
                      cursor:
                        selectedPreset?.name === preset?.name
                          ? "null"
                          : "pointer",

                      // color:
                      //   selectedPreset?.name === preset?.name
                      //     ? "#F07946"
                      //     : "inherit",

                      // "&:hover": {
                      //   bgcolor: "#E8E8E8",
                      // },
                    }}
                    onClick={() => handlePresetClick(preset, index)}
                  >
                    <Radio
                      checked={selectedPreset?.name === preset?.name}
                      sx={{
                        color: "#F07946",
                        "&.Mui-checked": {
                          color: "#F07946",
                        },
                        padding: "4px",
                        marginRight: "8px",
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                        },
                        "@media (max-width: 1650px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "13.5px",
                          },
                        },
                        "@media (max-width: 1450px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "13.5px",
                          },
                        },
                        "@media (max-width: 1280px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        },
                        "@media (max-width: 960px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        },
                        "@media (max-width: 768px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        },
                        "@media (max-width: 480px)": {
                          "& .MuiSvgIcon-root": {
                            fontSize: "20px",
                          },
                        },
                      }}
                    />
                    <Box
                      sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color:
                          selectedPreset?.name === preset?.name
                            ? "#F07946"
                            : "inherit",
                        "@media (max-width: 1650px)": {
                          mt: 0.2,
                        },
                        "@media (max-width: 1450px)": {
                          mt: 0.2,
                        },
                        "@media (max-width: 1280px)": {
                          mt: 0.5,
                        },
                        "@media (max-width: 960px)": {
                          mt: 0.5,
                        },
                        "@media (max-width: 768px)": {
                          mt: 0.5,
                        },
                        "@media (max-width: 480px)": {
                          mt: 0.2,
                        },
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 400, textAlign: "left", ml: -1 }}
                      >
                        {preset.name || `Preset ${index + 1}`}
                      </Typography>
                      <Typography variant="body1" sx={{ fontWeight: 300 }}>
                        tokens: {preset.maxTokens} | t: {preset.temperature}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          ) : (
            <Box
              sx={{
                p: "1vw 0.5vw",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                No presets added yet. Add presets using the configuration panel.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PresetsSection;
