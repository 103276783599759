import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { SettingsIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UIStore } from "../../../store/general";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { getOneProject } from "../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import { getAllUsers, getOneDatasetVersions } from "../services/LayoutService";
import DatasetsDialog from "./TableUIComponents/DatasetsDialog";
import EditPromptDialog from "./TableUIComponents/EditPromptDialog";
import SmeForm from "./TableUIComponents/SmeForm";

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "transparent",
  color: "#121212CC", // Set text color to white
  padding: 0,
});

const StyledAvatarBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledSelect = styled(Box)(({ theme }) => ({
  backgroundColor: "#F8F8F8",
  borderRadius: "8px",
  boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.05)",
  // height: "56px",

  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #12121226",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #12121226",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #12121226",
  },
  "& .MuiSelect-icon": {
    right: theme.spacing(2),
  },
}));

interface ResponsiveAppBarProps {
  isSidebarOpen: boolean;
  sme?: boolean;
}
export default function ResponsiveAppBar({
  isSidebarOpen,
  sme,
}: ResponsiveAppBarProps) {
  const url = useLocation();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, promptId, versionId } = useParams();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState<
    string | null
  >(null);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      datasetVersion: "",
      promptVersions: "",
    },
  });

  const datasetVersion = watch("datasetVersion");
  const promptVersion = watch("promptVersions");

  // const { data: FindAllUsers } = useQuery("users-all", getAllUsers);

  const { data: FindAllUsers } = useQuery(
    ["users-all", search, limit, offset],
    () => getAllUsers({ search, limit, offset })
  );

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || {};

  const version = segregatedData?.versions?.find(
    (v: any) => v?._id == versionId
  );
  // console.log("Title:", version);

  const { data: FindOneDatasetVersions, isLoading }: any = useQuery(
    ["datasets", version?.datasets?.[0]?.datasetId, projectId],
    getOneDatasetVersions,

    {
      enabled: !!version?.datasets?.[0]?.datasetId,
    }
  );
  const excludedUserIds =
    segregatedData?.versions?.flatMap((version: any) =>
      version?.sme?.map((j: any) => j?.userId)
    ) || [];

  const FilteredSme = Array.isArray(FindAllUsers?.data)
    ? FindAllUsers.data.filter(
        (user: any) =>
          // user?.role?.name === "Sme" && !excludedUserIds.includes(user?._id)
          user?.role?.name === "Sme"
      )
    : [];

  const versionOptions = useMemo(() => {
    return (
      segregatedData?.versions?.map(
        (i: { number: any; _id: any; datasets?: any }) => ({
          label: `Version ${i?.number}`,
          value: i?._id,
          dataSetVersions: i?.datasets?.[0]?.datasetVersionIds || [],
        })
      ) || []
    );
  }, [segregatedData]);

  const dataSetVersionsOptions = FindOneDatasetVersions?.data?.map(
    (v: any) => ({
      label: v?.tag,
      value: v?._id,
    })
  );
  // console.log(
  //   "Immediate:",
  //   versionOptions.find((v: any) => v?.value === versionId)?.value
  // );

  useEffect(() => {
    if (versionOptions?.length > 0 && !promptVersion) {
      setValue(
        "promptVersions",
        versionOptions.find((v: any) => v?.value === versionId)?.value
      );
    }
  }, [versionOptions, promptVersion, setValue]);

  // useEffect(() => {
  //   if (dataSetVersionsOptions?.length > 0 && !datasetVersion) {
  //     setValue("datasetVersion", dataSetVersionsOptions[0].value);
  //   }
  // }, [dataSetVersionsOptions, datasetVersion, setValue]);

  useEffect(() => {
    if (promptVersion) {
      localStorage.setItem("Prompt-Version", promptVersion);
      navigate(
        `/app/projects/${projectId}/prompts/${promptId}/version/${promptVersion}/responses`,
        {
          replace: true,
        }
      );
    }
  }, [promptVersion, projectId, promptId]);

  const handleOpenNavMenu = (event: any) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event: any) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleNavigate = (path: any) => {
    navigate(`/${path.toLowerCase()}`);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  const currentPath = window.location.pathname;

  const getRouteTitle = () => {
    switch (currentPath) {
      case "/app/dashboard":
        return "Dashboard";
      case "/app/projects":
        return "Your Projects";
      case "/app/projects/all":
        return "Your Projects";
      case "/app/datasets":
        return "Datasets";
      case "/app/datasets/create":
        return "Datasets";
      case "/app/configuration":
        return "Configuration";

      default:
        return FindOneProject?.title || "Project Name";
    }
  };

  return (
    <Box
      sx={{
        // borderBottom: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "0px",
        padding: "0.5vh clamp(10px, 2vw, 20px)",
        bgcolor: theme.palette.surface.AppBarBackground,
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: isSidebarOpen ? "86vw" : "96vw",
        left: isSidebarOpen ? "14vw" : "4vw",
        transition: "width 0.3s ease, left 0.3s ease",
      }}
    >
      <StyledToolbar disableGutters>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            onClick={() =>
              navigate(
                `/app/projects/${projectId}/prompts/${promptId}/prompt-detailed-view`
              )
            }
            sx={{ color: theme.palette.text.primary, fontSize: "20px" }}
          >
            <ArrowBackIcon
              sx={{
                color: "black",
                width: "2vw",
                height: "2vh",
              }}
            />
          </IconButton>
          <Typography variant="h3">Prompt Responses View</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1vw",
            padding: "0 0vw",
            alignItems: "center",
          }}
        >
          {/* <SingleSelect
            control={control}
            name="datasetVersion"
            options={dataSetVersionsOptions}
            placeholder="Select Dataset Version"
            title="Select Dataset Version"
            backgroundColor="#F8F8F8"
            border="1px solid #12121226"
            borderRadius={"8px"}
            width="10vw"
            textColor="#121212B2"
            padding={"1.1vw 0vw"}
          /> */}
          {/* <Box
            sx={{
              backgroundColor: "#F8F8F8",
              borderRadius: "8px",
              border: "1px solid #12121226",
              width: "10vw",
              height: "5vh",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "0 0.5vw",
              cursor: "pointer",
            }}
            onClick={() => setOpen2(true)}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 400,
                color: "#121212B2",
              }}
            >
              Your Datasets
            </Typography>
            <KeyboardArrowDownIcon
              sx={{
                color: "121212B2",
                fontSize: "1.2rem",
              }}
            />
          </Box> */}
          <SingleSelect
            control={control}
            name="promptVersions"
            options={versionOptions}
            backgroundColor="#F8F8F8"
            border="1px solid #12121226"
            borderRadius={"8px"}
            textColor="#121212B2"
            padding="1vw 0vw"
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            // isLoading={isLoading}
            typeText={"Invite experts"}
            actionOnClick={() => setOpen(true)}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />

          <IconButton onClick={() => {}}>
            <SettingsIcon />
          </IconButton>
        </Box>
      </StyledToolbar>
      <SmeForm
        open={open}
        setOpen={setOpen}
        data={{ projectId, promptId, versionId }}
        sme={FilteredSme}
      />
      <DatasetsDialog
        open={open2}
        setOpen={setOpen2}
        datasetId={version?.datasets?.[0]?.datasetId}
        mainId={version?.datasets?.[0]?._id}
        datasetVersionIds={version?.datasets?.[0]?.datasetVersionIds}
      />
      <EditPromptDialog
        open={open3}
        setOpen={setOpen3}
        prompt={segregatedData}
        version={version}
      />
    </Box>
  );
}
