import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getResponses } from "../../project/services/ProjectService";
import { handleError } from "../../shared/components/Errors/HandleError";
import Spinner from "../../shared/components/Loaders/Spinner";
import { postGrading } from "../services";
import LeftPanel from "./components/left-panel/LeftPanel";
import RightPanel from "./components/right-panel/RightPanel";
import { Response } from "./types/sme-grade";

const SmeGradeOverview = () => {
  const { projectId, promptId, versionId } = useParams();
  const queryClient = useQueryClient();
  const [search, setSearch] = useState();
  const [selectedResponse, setSelectedResponse] = useState<any | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [submittedGrades, setSubmittedGrades] = useState({});
  const [comments, setComments] = useState({});
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const { data: FindAllResponses, isLoading: responseLoading }: any = useQuery(
    ["prompt-responses", search, projectId, promptId, versionId],
    () =>
      getResponses({
        search: search,
        projectId: projectId,
        promptId: promptId,
        versionId: versionId,
      })
  );
  console.log("Responses:", FindAllResponses);

  useEffect(() => {
    if (FindAllResponses?.responses?.length > 0 && selectedResponse === null) {
      setSelectedResponse({
        ...FindAllResponses.responses[0],
        selectedModelIndex: 0,
        selectedPresetIndex: 0,
      });
      setCurrentPage(1);
    }
  }, [FindAllResponses, selectedResponse]);

  useEffect(() => {
    if (FindAllResponses?.responses) {
      setIsDataLoaded(true);
    }
  }, [FindAllResponses]);

  const onSelectResponse = (
    response: Response,
    modelIndex: number,
    presetIndex: number
  ) => {
    setSelectedResponse({
      ...response,
      selectedModelIndex: modelIndex,
      selectedPresetIndex: presetIndex,
    });
    setCurrentPage(calculateCurrentPage(response, modelIndex, presetIndex));
  };

  const calculateCurrentPage = (
    response: any,
    modelIndex: number,
    presetIndex: number
  ) => {
    let totalIndex = 0;
    for (const resp of FindAllResponses.responses) {
      if (resp._id === response._id) {
        for (let i = 0; i < modelIndex; i++) {
          totalIndex += resp.models[i].responses.length;
        }
        return totalIndex + presetIndex + 1;
      }
      totalIndex += resp.models.reduce(
        (sum: number, model: any) => sum + model.responses.length,
        0
      );
    }
    return totalIndex + 1;
  };

  const handlePageChange = (newPage: number) => {
    let totalIndex = 0;
    for (const response of FindAllResponses.responses) {
      for (const model of response.models) {
        if (totalIndex + model.responses.length >= newPage) {
          const presetIndex = newPage - totalIndex - 1;
          setSelectedResponse({
            ...response,
            selectedModelIndex: response.models.indexOf(model),
            selectedPresetIndex: presetIndex,
          });
          setCurrentPage(newPage);
          return;
        }
        totalIndex += model.responses.length;
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalResponses = FindAllResponses.responses.reduce(
      (total: number, response: any) =>
        total +
        response.models.reduce(
          (sum: number, model: any) => sum + model.responses.length,
          0
        ),
      0
    );
    if (currentPage < totalResponses) {
      handlePageChange(currentPage + 1);
    }
  };

  const { mutate, isLoading } = useMutation(postGrading, {
    onSuccess: () => {
      toast.success("Grading submitted successfully");

      queryClient.invalidateQueries("prompt-responses");
      queryClient.invalidateQueries("one-responses");
      queryClient.invalidateQueries("grading-params");
    },
    onError: (err) => handleError(err),
  });

  const handleSubmit = (
    grading: any,
    comment: string,
    responseId: any,
    modelId: any,
    presetId: any
  ) => {
    // console.log("Submitting grade for:", selectedResponse?._id);
    // console.log("Grading:", grading);
    // console.log("Comment:", comment);

    const mutationData = {
      projectId,
      promptId,
      versionId,
      responseId,
      modelId,
      presetId,
      gradingParams: grading?.gradingParams,
      comments: grading?.comments,
    };

    if (selectedResponse) {
      mutate(mutationData);
    }
  };

  if (responseLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // padding: "0.8vw 1.2vw",
      }}
    >
      <Box
        sx={{
          width: "95vw",
          height: "90vh",
          backgroundColor: "#FFFFFF",
          // border: "1px solid #1212121A",
          // borderRadius: "1rem",
          overflow: "hidden",
          display: "flex",
          marginLeft: -2,
          visibility: isDataLoaded ? "visible" : "hidden",
          "@media (max-width: 1650px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
          "@media (max-width: 1450px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
          "@media (max-width: 1280px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
          "@media (max-width: 960px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
          "@media (max-width: 768px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
          "@media (max-width: 480px)": {
            height: "90vh",
            width: "94vw",
            marginLeft: -1,
          },
        }}
      >
        <LeftPanel
          // responses={DUMMY_RESPONSES}
          search={setSearch}
          responses={FindAllResponses?.responses}
          selectedResponse={selectedResponse}
          onSelectResponse={onSelectResponse}
          submittedGrades={submittedGrades}
        />
        <Box sx={{ flex: 1, overflow: "hidden" }}>
          <RightPanel
            analytics={FindAllResponses?.analytics}
            response={selectedResponse}
            main={FindAllResponses?.responses}
            currentPage={currentPage}
            totalResponses={FindAllResponses?.responses?.reduce(
              (total: number, response: any) =>
                total +
                response.models.reduce(
                  (sum: number, model: any) => sum + model.responses.length,
                  0
                ),
              0
            )}
            onPrevious={handlePrevious}
            onNext={handleNext}
            onPageChange={handlePageChange}
            onSubmit={handleSubmit}
            submittedGrades={submittedGrades}
            comments={comments}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SmeGradeOverview;
