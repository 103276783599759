import { http } from "../../../../../app/services";

const postPromptNewVersion = (data: any) => {
  return http.post("/prompt/new-version", data);
};
const postPromptReRun = (data: any) => {
  return http.post("/prompt-responses/rerun", data);
};

export { postPromptNewVersion, postPromptReRun };
