import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { googleLogout } from "@react-oauth/google";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../../assets";
import { UIStore } from "../../../store/general";
import { getOneProject } from "../../project/services/ProjectService";
import { getOneUser } from "../services/LayoutService";
import { capitalizeFirstLetter } from "../utils/LayoutUtils";
import ThemeToggle from "./ThemeToggle";

const StyledToolbar = styled(Toolbar)({
  justifyContent: "space-between",
  display: "flex",
  alignItems: "center",
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: "transparent",
  color: "#121212CC", // Set text color to white
  padding: 0,
});

const StyledAvatarBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const pages = ["Dashboard", "Projects", "Datasets", "Configuration"];

interface ResponsiveAppBarProps {
  isSidebarOpen: boolean;
  sme?: boolean;
}
export default function ResponsiveAppBar({
  isSidebarOpen,
  sme,
}: ResponsiveAppBarProps) {
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const theme = useTheme();
  const navigate = useNavigate();
  const { projectId, promptId } = useParams();

  const Sme = Cookies.get("Sme");

  // console.log("Now DatasetId:", datasetId);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [search, setSearch] = useState("");
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState<
    string | null
  >(null);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });
  // console.log("OrgUser:", FindOneUser);

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: Sme === "false" && !!projectId,
    }
  );
  const FilteredPrompt = FindOneProject?.prompts?.find(
    (p: any) => p?._id === promptId
  );
  console.log("Project User:", FilteredPrompt);

  const handleOpenNavMenu = (event: any) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event: any) =>
    setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleNavigate = (path: any) => {
    navigate(`/${path.toLowerCase()}`);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  const currentPath = String(window.location.pathname || "");

  const renderRouteTitle = useCallback(() => {
    const organizationName = capitalizeFirstLetter(
      FindOneUser?.data?.organization?.name || ""
    );

    if (
      currentPath.startsWith("/app/datasets/") &&
      currentPath.endsWith("/detailed-view")
    ) {
      return <></>;
    }

    // if (sme && currentPath === "/app/dashboard") {
    //   return (
    //     <Typography
    //       variant="h2"
    //       sx={{
    //         ml: "1vw",
    //         fontWeight: 600,
    //       }}
    //     >
    //       Dashboard
    //     </Typography>
    //   );
    // }

    // if (sme && currentPath === "/app/projects/all") {
    //   return (
    //     <Typography
    //       variant="h2"
    //       sx={{
    //         ml: "1vw",
    //         fontWeight: 600,
    //       }}
    //     >
    //       Projects
    //     </Typography>
    //   );
    // }

    if (
      currentPath.startsWith("/app/datasets/") &&
      currentPath.endsWith("/add-dataset-version")
    ) {
      return (
        <Box sx={{ display: "flex", gap: "0.5vw", alignItems: "center" }}>
          <Typography
            onClick={() => navigate("/app/dashboard")}
            variant="h3"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Home
          </Typography>
          <Typography variant="h3">/</Typography>
          <Typography
            onClick={() => navigate(-1)}
            variant="h3"
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Datasets
          </Typography>
          <Typography variant="h3" sx={{ fontSize: "1.5vw", lineHeight: "1" }}>
            /
          </Typography>
          <Typography variant="h3">Add Dataset Version</Typography>
        </Box>
      );
    }

    switch (currentPath) {
      case "/app/dashboard":
      case "/app/projects":
      case "/app/sme/dashboard":
      case "/app/sme/projects":
      case "/app/projects/all":
      case "/app/datasets":
      case "/app/datasets/all":
      case "/app/datasets/create":
      case "/app/configuration":
      case "/app/configuration/profile":
      case "/app/configuration/users":
      case "/app/configuration/billing":
      case "/app/configuration/apiKeys":
        return null;
      default:
        return (
          <Box sx={{ display: "flex", gap: "0.5vw", alignItems: "center" }}>
            {(() => {
              if (
                currentPath.endsWith("/response") ||
                currentPath.endsWith("/grading-responses")
              ) {
                return (
                  <Typography
                    onClick={() => navigate(-1)}
                    variant="h3"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Projects
                  </Typography>
                );
              } else if (currentPath.endsWith("/sme-prompts-detailed-view")) {
                return (
                  <Typography
                    variant="h3"
                    sx={{
                      ml: -0.5,
                    }}
                  >
                    Projects
                  </Typography>
                );
              } else if (currentPath.endsWith("/prompt-detailed-view")) {
                return (
                  <>
                    <Typography
                      onClick={() => navigate("/app/projects/all")}
                      variant="h3"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Projects
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{ fontSize: "1.5vw", lineHeight: "1" }}
                    >
                      /
                    </Typography>
                    <Typography
                      variant="h3"
                      onClick={() =>
                        navigate(`/app/projects/${projectId}/prompts/all`)
                      }
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Prompts
                    </Typography>
                  </>
                );
              } else if (currentPath.includes("versions-comparison")) {
                return (
                  <Typography
                    onClick={() => navigate(-1)}
                    variant="h3"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Comparing Prompt Versions
                  </Typography>
                );
              } else {
                return (
                  <Typography
                    onClick={() => navigate("/app/projects/all")}
                    variant="h3"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      ml: 3,
                    }}
                  >
                    Projects
                  </Typography>
                );
              }
            })()}
            {(currentPath.startsWith("/app/projects/") ||
              currentPath.startsWith("/app/sme/projects/")) &&
              renderProjectSubPath()}
          </Box>
        );
    }
  }, [currentPath, navigate, FindOneUser]);

  const renderProjectSubPath = useCallback(() => {
    const subPaths = {
      "/prompts": "Prompts",
      "/prompts/create": "Prompts",
      "/prompts/all": "Prompts",
      "/response": "Prompt View",
      "/datasets": "Datasets",
      "/datasets/all": "Datasets",
      "/datasets/create": "Datasets",
      "/detailed-view": "Datasets",
      "/prompt-detailed-view": `${FilteredPrompt?.title} Prompt `,
      "/configuration": "Configuration",
      "/dashboard": "Dashboard",
      "/add-dataset-version": "Add Dataset Version",
      "/grading-responses": "Responses",
    };

    for (const [path, label] of Object.entries(subPaths)) {
      if (currentPath.endsWith(path)) {
        return (
          <>
            <Typography
              variant="h3"
              sx={{ fontSize: "1.5vw", lineHeight: "1" }}
            >
              /
            </Typography>
            <Typography
              variant="h3"
              sx={{
                cursor: path === "/datasets" ? "pointer" : "default",
                textDecoration: path === "/datasets" ? "underline" : "none",
              }}
              onClick={path === "/datasets" ? () => navigate(-1) : undefined}
            >
              {label}
            </Typography>
          </>
        );
      }
    }
    return null;
  }, [currentPath, navigate]);

  const handleClickLogout = () => {
    Cookies.remove("g_state");
    Cookies.remove("access_token");
    Cookies.remove("ACCOUNT_CHOOSER");
    Cookies.remove("Sme");
    localStorage.clear();
    googleLogout();
    navigate("/login", { replace: true });
    handleCloseUserMenu();
  };

  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        borderRadius: "0px",
        padding:
          Sme === "true"
            ? "0.5vh clamp(10px, 4vw, 30px) 0.5vh 0vw"
            : "0.5vh clamp(10px, 4vw, 30px) 0.5vh 2vw",
        bgcolor: theme.palette.surface.AppBarBackground,
        position: "fixed",
        zIndex: theme.zIndex.appBar,
        width: isSidebarOpen ? "86vw" : "96vw",
        left: isSidebarOpen ? "14vw" : "4vw",
        transition: "width 0.3s ease, left 0.3s ease",
        "@media (max-width: 1650px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
        "@media (max-width: 1450px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
        "@media (max-width: 1280px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
        "@media (max-width: 960px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
        "@media (max-width: 768px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
        "@media (max-width: 480px)": {
          padding: "0.45vh clamp(10px, 2vw, 30px) 0.40vh 5vw",
        },
      }}
    >
      <StyledToolbar disableGutters>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", md: "none" },
          }}
        >
          <IconButton
            size="large"
            aria-label="app navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map((page) => (
              <StyledMenuItem key={page} onClick={() => handleNavigate(page)}>
                <Typography>{page}</Typography>
              </StyledMenuItem>
            ))}
          </Menu>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: -1,
            ml: currentPath.includes("/versions-comparison") ? -4 : 0,
            "@media (max-width: 1650px)": {
              ml:
                Sme === "true"
                  ? -6.5
                  : currentPath.includes("/versions-comparison")
                  ? -7
                  : 0,
            },
            "@media (max-width: 1450px)": {
              ml:
                Sme === "true"
                  ? -6.5
                  : currentPath.includes("/versions-comparison")
                  ? -7
                  : 0,
            },
            "@media (max-width: 1280px)": {
              ml:
                Sme === "true"
                  ? -5.5
                  : currentPath.includes("/versions-comparison")
                  ? -6
                  : 0,
            },
            "@media (max-width: 960px)": {
              ml:
                Sme === "true"
                  ? -5.5
                  : currentPath.includes("/versions-comparison")
                  ? -6
                  : 0,
            },
            "@media (max-width: 768px)": {
              ml:
                Sme === "true"
                  ? -5.5
                  : currentPath.includes("/versions-comparison")
                  ? -6
                  : 0,
            },
            "@media (max-width: 480px)": {
              ml:
                Sme === "true"
                  ? -5.5
                  : currentPath.includes("/versions-comparison")
                  ? -6
                  : 0,
            },
          }}
        >
          {(currentPath.startsWith("/app/projects/") ||
            currentPath.startsWith("/app/sme/projects/")) &&
            (currentPath.endsWith("/dashboard") ||
              currentPath.endsWith("/prompts") ||
              currentPath.endsWith("/prompts/all") ||
              currentPath.endsWith("/datasets") ||
              currentPath.endsWith("/datasets/all") ||
              currentPath.endsWith("/configuration") ||
              currentPath.endsWith("/detailed-view") ||
              currentPath.endsWith("/prompts/create") ||
              currentPath.endsWith("/datasets/create") ||
              currentPath.endsWith("/add-dataset-version") ||
              currentPath.endsWith("/prompt-detailed-view") ||
              currentPath.endsWith("/response") ||
              currentPath.endsWith("/grading-responses") ||
              currentPath.includes("versions-comparison")) && (
              <IconButton
                onClick={() => {
                  window.location.pathname.endsWith("/add-dataset-version") ||
                  window.location.pathname.endsWith("/grading-responses") ||
                  window.location.pathname.includes("versions-comparison")
                    ? navigate(-1)
                    : navigate("/app/projects/all");
                }}
                sx={{ color: theme.palette.text.primary, fontSize: "1.2rem" }}
              >
                <ArrowBackIcon
                  sx={{
                    color: "black",
                    width: "2vw",
                    height: "2vh",
                  }}
                />
              </IconButton>
            )}
          {currentPath.startsWith("/app/datasets") &&
            currentPath.endsWith("/add-dataset-version") && (
              <IconButton
                onClick={() => {
                  navigate(-1);
                }}
                sx={{ color: theme.palette.text.primary }}
              >
                <ArrowBackIcon
                  sx={{
                    color: "black",
                    width: "2vw",
                    height: "2vh",
                    "@media (max-width: 1650px)": {
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1450px)": {
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1280px)": {
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 960px)": {
                      width: "15px",
                      height: "15px",
                    },
                    "@media (max-width: 768px)": {
                      width: "15px",
                      height: "15px",
                    },
                    "@media (max-width: 480px)": {
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              </IconButton>
            )}

          <Typography
            variant="h1"
            sx={{
              fontWeight: 600,
              ml:
                Sme === "true" && !currentPath.endsWith("/grading-responses")
                  ? 2
                  : Sme === "true" && currentPath.endsWith("/grading-responses")
                  ? -1
                  : 0,
              "@media (max-width: 1650px)": {
                ml: Sme === "true" ? 0 : -1.5,
              },
              "@media (max-width: 1450px)": {
                ml: Sme === "true" ? 0 : -1.5,
              },
              "@media (max-width: 1280px)": {
                ml: Sme === "true" ? 0 : -1.5,
              },
              "@media (max-width: 960px)": {
                ml: Sme === "true" ? 0 : -1,
              },
              "@media (max-width: 768px)": {
                ml: Sme === "true" ? 0 : -1,
              },
              "@media (max-width: 480px)": {
                ml: Sme === "true" ? 0 : -1,
              },
            }}
          >
            {renderRouteTitle()}
          </Typography>
        </Box>
        {/* <Box
          sx={{
            width: "25%",
          }}
        >
          <SearchContainer
            debounced={true}
            onChange={setSearch}
            value={search}
          />
        </Box> */}

        <Stack
          direction={"row"}
          sx={{
            gap: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ThemeToggle />

          <StyledAvatarBox>
            <Avatar
              alt={FindOneUser?.data?.name || "BM"}
              src="/static/images/avatar/2.jpg"
              sx={{
                marginLeft: "10px",
                cursor: "pointer",
                marginRight: { sm: "10px", md: "2px", mt: 1 },
                backgroundColor: "#F07946",
                color: "#FFFFFF",
                gap: "5px",
              }}
              onClick={handleOpenUserMenu}
            >
              {FindOneUser?.data?.name ? (
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {FindOneUser?.data?.name
                    .split(" ")
                    .map((word: any) => word.charAt(0))
                    .join("")
                    .toUpperCase()
                    .substring(0, 2)}
                </Typography>
              ) : (
                <CircularProgress size={20} sx={{ color: "white" }} />
              )}
            </Avatar>

            {/* <Box sx={{ ml: 2 }}>
              <Typography variant="h4" fontSize={"20px"} fontWeight={500}>
                {FindOneUser?.data?.name
                  ?.split(" ")
                  .filter(
                    (_: any, index: any, arr: any) =>
                      index === 0 || index === arr.length - 1
                  )
                  .map((word: any) => word[0])
                  .join(" ")}
              </Typography> 
              <Typography variant="body2" fontSize={"15px"}>
                {FindOneUser?.data?.emailAddress}
              </Typography> 
            </Box>*/}

            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              sx={{
                mt: "10px",
                "@media (max-width: 1650px)": {
                  mt: "5px",
                },
                "@media (max-width: 1450px)": {
                  mt: "5px",
                },
                "@media (max-width: 1280px)": {
                  mt: "5px",
                },
                "@media (max-width: 960px)": {
                  mt: "5px",
                },
                "@media (max-width: 768px)": {
                  mt: "5px",
                },
                "@media (max-width: 480px)": {
                  mt: "5px",
                },
              }}
            >
              <Box
                sx={{
                  padding: 3,
                  "@media (max-width: 1650px)": {
                    padding: 2,
                  },
                  "@media (max-width: 1450px)": {
                    padding: 2,
                  },
                  "@media (max-width: 1280px)": {
                    padding: 1.5,
                  },
                  "@media (max-width: 960px)": {
                    padding: 1.5,
                  },
                  "@media (max-width: 768px)": {
                    padding: 1.5,
                  },
                  "@media (max-width: 480px)": {
                    padding: 1.5,
                  },
                }}
              >
                {/* <Typography variant="h2" fontWeight={600} marginBottom={2}>
                  Organizations
                </Typography>
                {settings.map((setting) => (
                  <StyledMenuItem
                    key={setting}
                    onClick={() => handleNavigate(setting)}
                    selected={setting === selectedOrganization}
                  >
                    <Box sx={{ display: "flex", gap: "10px" }}>
                      <img src="" alt="" style={{ width: 24, height: 24 }} />
                      <Typography
                        variant="h4"
                        color={isDarkMode ? "white" : "black"}
                      >
                        {setting}
                      </Typography>
                    </Box>
                    {selectedOrganization === setting && (
                      <CheckIcon color="primary" />
                    )}
                  </StyledMenuItem>
                ))} */}
                {/* <ActionButton label="Create New Organization" /> */}

                {/* <Box
                  sx={{
                    borderBottom: `1px solid ${theme.palette.border}`,
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                /> */}

                <Box
                  sx={{
                    padding: "10px",
                    "@media (max-width: 1650px)": {
                      padding: "5px",
                    },
                    "@media (max-width: 1450px)": {
                      padding: "5px",
                    },
                    "@media (max-width: 1280px)": {
                      padding: "5px",
                    },
                    "@media (max-width: 960px)": {
                      padding: "5px",
                    },
                    "@media (max-width: 768px)": {
                      padding: "5px",
                    },
                    "@media (max-width: 480px)": {
                      padding: "5px",
                    },
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
                    <Avatar
                      alt="Profile Picture"
                      src="https://cdn.pixabay.com/photo/2023/02/18/11/00/icon-7797704_640.png"
                      onClick={handleOpenUserMenu}
                    />
                    <Box>
                      <Typography variant="h4" fontWeight={500}>
                        {FindOneUser?.data?.name}
                      </Typography>
                      <Typography variant="h6">
                        {FindOneUser?.data?.emailAddress}
                      </Typography>
                    </Box>
                  </Stack>

                  <Divider
                    sx={{
                      mt: 1,
                      mb: 1,
                    }}
                  />

                  <Button
                    startIcon={
                      <img
                        src={Icons.SignOut}
                        alt="upload-icon"
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    sx={{
                      ml: 2,
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    onClick={handleClickLogout}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#F07946", marginTop: 0, cursor: "pointer" }}
                      // onClick={() => {
                      //   Cookies.remove("g_state");
                      //   Cookies.remove("access_token");
                      //   Cookies.remove("ACCOUNT_CHOOSER");
                      //   localStorage.clear();
                      //   googleLogout();
                      //   navigate("/login", { replace: true });
                      //   handleCloseUserMenu();
                      // }}
                    >
                      Sign Out
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Menu>
          </StyledAvatarBox>
        </Stack>
      </StyledToolbar>
    </Box>
  );
}
