import { useMediaQuery } from "@mui/material";

const useScreenSizes = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1750px)"
  );
  const isSmallScreen = useMediaQuery(
    "(min-width: 768px) and (max-width: 1320px)"
  );
  const isExtraSmallScreen = useMediaQuery("(max-width: 768px)");

  return {
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
    isExtraSmallScreen,
  };
};

export default useScreenSizes;
