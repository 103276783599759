import { Search } from "@mui/icons-material";
import { TextField } from "@mui/material";
import _ from "lodash";
import useScreenSizes from "../../../utils/CustomSizesHook";

interface SearchContainerProps {
  placeHolder?: string;
  onChange: (v: string) => void;
  onFocus?: (e: any) => void;
  width?: string;
  maxWidth?: string;
  height?: string;
  debounced?: boolean;
  minWidth?: string;
  value?: string;
  defaultValue?: string;
  autoFocus?: boolean;
}

function SearchContainer(props: SearchContainerProps) {
  const {
    placeHolder = "Search",
    onChange,
    maxWidth = "40vw",
    width = "100%",
    height,
    debounced,
    minWidth = "20vw",
    onFocus,
    defaultValue = "",
    autoFocus = false,
  } = props;

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const handleChange = _.debounce(function (e: any) {
    onChange(e.target.value);
  }, 1000);

  return (
    <TextField
      sx={{
        maxWidth,
        width,
        minWidth,
        borderRadius: "8px",
        backgroundColor: "transparent",
        height: isLargeScreen
          ? "42px"
          : isMediumScreen
          ? "40px"
          : isSmallScreen
          ? "38px"
          : "35px",
        "&:hover": {},
        // "@media (max-width: 1900px)": {
        //   maxWidth: "38vw",
        //   minWidth: "18vw",
        //   height: "42px",
        // },
        // "@media (max-width: 1450px)": {
        //   maxWidth: "36vw",
        //   minWidth: "16vw",
        //   height: "4.5vh",
        // },
        // "@media (max-width: 1280px)": {
        //   maxWidth: "34vw",
        //   minWidth: "14vw",
        //   height: "38px",
        // },
        // "@media (max-width: 960px)": {
        //   maxWidth: "32vw",
        //   minWidth: "12vw",
        //   height: "36px",
        //   padding: "2.5vh 3.5vw",
        //   marginLeft: -7,
        // },
        // "@media (max-width: 768px)": {
        //   maxWidth: "30vw",
        //   minWidth: "10vw",
        //   height: "34px",
        // },
        // "@media (max-width: 480px)": {
        //   maxWidth: "28vw",
        //   minWidth: "8vw",
        //   height: "32px",
        // },
        "& .MuiOutlinedInput-root": {
          background: "F8F8F9",
          borderRadius: "8px",
          height: "42px",
          "& .MuiOutlinedInput-input": {
            backgroundColor: "none",
            marginTop: 0,
          },
        },
        "& .MuiInputBase-root": {
          boxShadow: "none",
        },
        "& .MuiInputAdornment-root": {
          color: "grey",
          backgroundColor: "#FBFBFB",
        },
        "& .css-3m43fk-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused": {
          boxShadow: "none",
        },
        "& .MuiInputBase-input::placeholder": {
          fontSize: "16px",
          fontWeight: 300,
          fontFamily: "Figtree",
        },
      }}
      fullWidth
      color="primary"
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      onChange={(e) => {
        debounced ? handleChange(e) : onChange(e.target.value);
      }}
      size="small"
      placeholder={placeHolder}
      InputProps={{
        startAdornment: <Search sx={{ color: "grey", marginRight: 1 }} />,
      }}
      onFocus={onFocus}
    />
  );
}

export default SearchContainer;
