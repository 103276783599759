import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Divider,
  TextField as MuiTextField,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import useScreenSizes from "../../../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../../../shared/components/Button/MultiTypeButton";
import Spinner from "../../../../shared/components/Loaders/Spinner";

interface GradingSectionProps {
  data: any[];
  onGradingChange: any;
  onCommentChange: (comment: string) => void;
  onSubmit: any;
  initialGrading?: any;
  initialComment?: string;
  buttonLogic?: any;
  showButton?: any;
}

export default function GradingSection({
  onGradingChange,
  data,
  onCommentChange,
  onSubmit,
  initialGrading,
  initialComment,
  buttonLogic,
  showButton,
}: GradingSectionProps) {
  const [grading, setGrading] = useState<Record<string, number>>({});
  const theme = useTheme();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  useEffect(() => {
    if (!buttonLogic?.grading) {
      console.error("No grading data found in buttonLogic");
      return;
    }
    const gradingData = buttonLogic?.grading.find(
      (grading: any) => grading.userId === userId
    );
    // console.log("Grading Boolean:", gradingData);

    if (gradingData) {
      const prefilledGrading = gradingData?.parameters?.reduce(
        (acc: any, param: any) => ({
          ...acc,
          [param.paramId]: param.score,
        }),
        {}
      );
      // console.log("Grading values:", prefilledGrading);

      setGrading(prefilledGrading);
      setComment(gradingData.comments || "");
      setIsSubmitted(true);
    } else {
      const initialGradingState = data?.reduce(
        (acc: Record<string, number>, param: any) => ({
          ...acc,
          [param._id]: initialGrading?.[param._id] || 0,
        }),
        {}
      );
      setGrading(initialGradingState || "");
      setComment(initialComment || "");
      setIsSubmitted(false);
    }
  }, [buttonLogic, userId, data, initialGrading, initialComment]);

  const findMatchingResponse = (showButton: any, buttonLogic: any) => {
    for (const model of showButton.models) {
      for (const response of model.responses) {
        if (response._id === buttonLogic?._id) {
          return response;
        }
      }
    }
    return null;
  };

  const matchingResponse = findMatchingResponse(showButton, buttonLogic);

  const handleRatingChange = (parameter: any, value: number) => {
    const newGrading = { ...grading, [parameter]: value };
    setGrading(newGrading);
    onGradingChange(newGrading);
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
    onCommentChange(event.target.value);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const gradingParams = Object.entries(grading).map(([paramId, score]) => ({
      paramId,
      score,
    }));

    const postData = {
      gradingParams,
      comments: comment,
    };

    // console.log("Submitting Data:", postData);
    onSubmit(postData);
  };

  const isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(max-width: 1290px)");

  return (
    <Box sx={{ width: "100%", position: "relative", minHeight: "100%" }}>
      <Typography variant="h3" sx={{ mb: 2, fontWeight: 500 }}>
        Grading parameters
      </Typography>

      <Box
        sx={{
          maxHeight: "13.3vh",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          scrollbarWidth: "thin",
          scrollbarColor: "#F07946 #E8E8E8",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#F07946",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#E8E8E8",
            margin: "10px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
          "@media (max-width: 1650px)": {
            maxHeight: "13.3vh",
          },
          "@media (max-width: 1450px)": {
            maxHeight: "13.3vh",
          },
          "@media (max-width: 1280px)": {
            maxHeight: "13.3vh",
          },
          "@media (max-width: 960px)": {
            maxHeight: "13.3vh",
          },
          "@media (max-width: 768px)": {
            maxHeight: "13.3vh",
          },
          "@media (max-width: 480px)": {
            maxHeight: "13.3vh",
          },
        }}
      >
        {data ? (
          data.map((param: any) => (
            <Box
              key={param._id}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 500 }}>
                {param?.name || "Unnamed Parameter"}
              </Typography>
              <Rating
                value={grading?.[param?._id] || 0}
                onChange={(_, newValue) =>
                  handleRatingChange(param?._id, newValue || 0)
                }
                icon={<StarIcon style={{ color: "#ECB43C" }} />}
                emptyIcon={<StarIcon style={{ color: "#E0E0E0" }} />}
              />
            </Box>
          ))
        ) : (
          <Spinner />
        )}
      </Box>
      <Divider
        sx={{
          mt: 0.5,
          mb: 0.5,
        }}
      />

      <Box sx={{ mt: 0 }}>
        <Typography variant="h6" sx={{ mb: 1, fontWeight: 500 }}>
          Comments
        </Typography>
        <MuiTextField
          multiline
          rows={isMediumLaptop ? 6 : isMediumLaptop2 ? 4 : 10}
          fullWidth
          label="Write your comments here..."
          value={comment}
          onChange={handleCommentChange}
          sx={{
            ".css-1uhka4x-MuiInputBase-root-MuiOutlinedInput-root": {
              maxHeight: "none",
              padding: "0px 5px",
              border: "1px solid #12121226 !important",
            },
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#FFFFFF",
              // border: "1px solid #12121226",
              border: "none !important",
            },
            ".MuiInputBase-root": {
              maxHeight: "none",
              padding: "0px 5px",
            },
          }}
        />
      </Box>

      {matchingResponse?.grading
        ?.filter((grade: any) => grade.type === "Sme")
        ?.some((grade: any) => grade.userId === userId) !== true && (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
            // typeText={isSubmitted ? "Re-submit" : "Submit"}
            typeText="Submit"
            actionOnClick={handleSubmit}
          />
        </Box>
      )}
    </Box>
  );
}
