import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import { getAllRoles } from "../../../configuration/services/roleService";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import { MultiTypeTextField } from "../../../shared/components/TextField/multiTypeTextField";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import { addUser, inviteSme } from "../../services/LayoutService";

const usersSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  expertise: yup.array().required("Expertise is required"),
});

const SmeCreation = ({
  reset: Restart,
  search,
  setSelectedUsers,
  setOpen,
  Ids,
}: any) => {
  const queryClient = useQueryClient();

  const { projectId, promptId, versionId } = Ids;
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    resolver: yupResolver(usersSchema),
    defaultValues: {
      name: "",
      email: "",
      expertise: [],
    },
  });

  const { data: rolesData, isLoading: rolesLoading }: any = useQuery(
    "users",
    getAllRoles
  );

  const roleOptions =
    rolesData?.data?.roles?.map((i: { name: any; _id: any }) => ({
      label: i?.name,
      value: i?._id,
    })) || [];

  const typeRefetch = roleOptions?.find((f: any) => f?.label === "Sme")?.value;

  const mutationInvitationData = {
    projectId: projectId,
    promptId: promptId,
    versionId: versionId,
    // smeIds: [userId],
  };

  const { mutate: inviteMutation, isLoading: inviteLoading } = useMutation(
    inviteSme,
    {
      onSuccess: () => {
        toast.success("Sme Invited  Successfully");
        queryClient.invalidateQueries("users-all");
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries("sme-invite");
        queryClient.invalidateQueries("users-all");
        queryClient.invalidateQueries("projects");
        setSelectedUsers([]);
        search("");
        reset();
        Restart(false);
        setOpen(false);
        setLoading(false);
      },
      onError: (err) => {
        handleError(err);
        setLoading(false);
      },
    }
  );

  const { mutate, isLoading } = useMutation(addUser, {
    onSuccess: (res) => {
      const userId = res?.data?.user?._id;
      setUserId(userId);

      inviteMutation({ ...mutationInvitationData, smeIds: [userId] });
    },
    onError: (err) => {
      handleError(err);
      setLoading(false);
    },
  });

  const onSubmit = (value: any) => {
    setLoading(true);
    const mutationData = {
      name: value.name,
      email: value.email,
      roleId: typeRefetch,
      expertise: value.expertise,
    };

    mutate(mutationData);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <TextField
        formateType={Formate.Alphanumeric}
        control={control}
        label="Name"
        name="name"
        required={true}
        placeholder="Enter name"
        backgroundColor="#FFFFFF"
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />

      <TextField
        formateType={Formate.EmailAddress}
        control={control}
        label="Email"
        name="email"
        required={true}
        placeholder="Enter email"
        backgroundColor="#FFFFFF"
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />

      <MultiTypeTextField
        control={control}
        name="expertise"
        label="Areas of Expertise"
        placeholder="Enter your expertise and press enter"
        backgroundColor="#FFFFFF"
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />

      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          isLoading={isLoading}
          typeText={"Send Invitation"}
          actionOnClick={() => {
            handleSubmit(onSubmit)();
          }}
          width={
            isLargeScreen
              ? "192px"
              : isMediumScreen
              ? "115px"
              : isSmallScreen
              ? "61px"
              : "192px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Box>
    </Box>
  );
};

export default SmeCreation;
