import Cookies from "js-cookie";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: "sme" | "user";
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredRole,
}) => {
  const location = useLocation();
  const isAuthenticated = !!Cookies.get("access_token");
  const isSme = Cookies.get("Sme") === "true";

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const currentPath = location.pathname;
  const isInSmeRoute = currentPath.startsWith("/app/sme");
  const isInHomeRoute = currentPath.startsWith("/app") && !isInSmeRoute;

  if (isSme && isInHomeRoute) {
    return <Navigate to="/app/sme/dashboard" replace />;
  }

  if (!isSme && isInSmeRoute) {
    return <Navigate to="/app/dashboard" replace />;
  }

  if (requiredRole) {
    const userRole = isSme ? "sme" : "user";
    if (userRole !== requiredRole) {
      const redirectPath = isSme ? "/app/sme/dashboard" : "/app/dashboard";
      return <Navigate to={redirectPath} replace />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;
