import { http } from "../../../app/services";

const addUser = (data: any) => {
  return http.post("/users", data);
};

const getAllUsers = (queryKey: any) => {
  return http
    .get(`/users`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOneUser = ({ queryKey }: any) => {
  return http.get(`/users/${queryKey[1]}`).then((res) => res.data);
};

const updateUser = ({ data, id }: any) => {
  return http.put(`/users/${id}`, data).then((res) => res.data);
};

const inviteSme = (data: any) => {
  return http.patch("/sme/invite", data);
};

const getOneDatasetVersions = ({ queryKey }: any) => {
  const [_, id, projectId] = queryKey;

  const queryString = new URLSearchParams({ projectId }).toString();

  return http
    .get(`/datasets/${id}/versions?${queryString}`)
    .then((res) => res.data);
};

export {
  addUser,
  getAllUsers,
  getOneDatasetVersions,
  getOneUser,
  inviteSme,
  updateUser,
};
