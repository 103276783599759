// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { useConfirm } from "../../project/components/confirmDialog";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import Spinner from "../../shared/components/Loaders/Spinner";
import { deleteDataset, getDatasetDetails } from "../services/DatasetService";
import DatasetViewTab from "./UploadedCards/DatasetViewTab";

export default function DatasetsView() {
  const theme = useTheme();
  const { projectId, datasetId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { control } = useForm();
  const [selectedDatasetId, setSelectedDatasetId] = useState<string | null>(
    null
  );

  const { data: GetAllDatasets, isLoading } = useQuery(
    ["get-Datasets-Details", { projectId: projectId }],
    getDatasetDetails
  );

  const { mutate: deleteDatasets } = useMutation(deleteDataset, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("get-Datasets-Details");
    },
    onError: (err) => handleError(err),
  });

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const handleCheckboxClick = (clickedStatus: boolean, id: string) => {
    setSelectedIds((prevIds) => {
      if (clickedStatus) {
        // Add the ID if the checkbox is selected
        return [...prevIds, id];
      } else {
        // Remove the ID if the checkbox is deselected
        return prevIds.filter((prevId) => prevId !== id);
      }
    });
  };

  const handleDelete = (idsToDelete: any) => {
    // const idsToDelete =
    //   items.length >= 2 ? items.map((item: any) => item?._id) : [items[0]?._id];

    confirm({
      msg: "Are you sure you want to delete this?",
      action: () => {
        deleteDatasets({ ids: idsToDelete });
      },
    });
  };

  const handleDatasetClick = (
    clickedStatus: boolean,
    index: number,
    dataset: any,
    id: string
  ) => {
    // If clickedStatus is true, set the dataset as selected
    setSelectedDatasetId(clickedStatus ? id : null);
  };

  if (isLoading) {
    return (
      <Stack height={"90vh"} alignItems={"center"} justifyContent={"center"}>
        <Spinner />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        // "@media (max-width: 1450px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 1280px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 960px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 768px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 480px)": {
        //   ml: -2,
        // },
      }}
    >
      <Grid container spacing={2} sx={{ width: "100%", height: "92vh" }}>
        <Grid
          size={3.5}
          sx={{
            borderRight: `1px solid ${theme.palette.border.primary}`,
            mt: 1,
            "@media (max-width: 1650px)": {
              mt: -0.5,
            },
            "@media (max-width: 1450px)": {
              mt: -0.5,
            },
            "@media (max-width: 1280px)": {
              mt: 0,
            },
            "@media (max-width: 960px)": {
              mt: 0,
            },
            "@media (max-width: 768px)": {
              mt: 0,
            },
            "@media (max-width: 480px)": {
              mt: 0,
            },
          }}
        >
          <Box
            className="Heading"
            display="flex"
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
            padding={2}
          >
            <Typography variant="h3" sx={{ fontWeight: "600" }}>
              All Datasets
            </Typography>
            <Stack direction="row" alignItems="center" gap={"5px"}>
              {/* {selectedIds.length > 0 && (
                <>
                  {selectedIds.length >= 1 && (
                    <IconButton
                      color="primary"
                      disableRipple={false}
                      onClick={() => handleDelete(selectedIds)}
                      sx={{
                        ":hover": {
                          backgroundColor:
                            selectedIds.length > 1
                              ? "rgba(255, 0, 0, 0.1)"
                              : "rgba(0, 0, 255, 0.1)",
                        },
                        transition: "background-color 0.3s",
                      }}
                    >
                      <DeleteForeverOutlinedIcon />
                    </IconButton>
                  )}
                </>
              )} */}

              <Box width="6vw">
                <MultiTypeButton
                  actionOnClick={() => {
                    if (projectId) {
                      navigate(`/app/projects/${projectId}/datasets/create`);
                    } else {
                      navigate("/app/datasets/create");
                    }
                  }}
                  buttonType={ButtonType.Gradient}
                  isLoading={false}
                  typeText="Add New"
                  key="dataset-view"
                  height={
                    isLargeScreen
                      ? "42px"
                      : isMediumScreen
                      ? "40px"
                      : isSmallScreen
                      ? "38px"
                      : "35px"
                  }
                />
              </Box>
            </Stack>
          </Box>
          {GetAllDatasets?.data?.map((dataset: any) => {
            return (
              <>
                <DatasetViewTab
                  index={1}
                  noOfVersions="2"
                  // onClick={(clickedStatus, index, dataset, id) =>
                  //   handleCheckboxClick(clickedStatus, id)
                  // }
                  onClick={handleDatasetClick}
                  key={"dataSetView-checkbox"}
                  control={control}
                  dataset={dataset}
                  selectedDatasetId={selectedDatasetId}
                />
              </>
            );
          })}
        </Grid>
        <Grid size={8.5}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
}
