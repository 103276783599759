import { Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UIStore } from "../../../store/general";

export const StyledTableContainer = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "8px",
  overflow: "hidden",
  overflowX: "auto",
  maxHeight: "90vh",
});

export const StyledTable = styled("table")(({ theme }) => {
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  return {
    width: "100%",
    borderCollapse: "collapse",
    // background: "#FFFFFF",
    paddingBottom: "30px",
    tableLayout: "fixed",
    "& thead": {
      position: "sticky",
      top: 0,
      zIndex: 2,
      backgroundColor: "#FDF7F4",
      color: "#121212",
      border: "#1111110D",
    },
    "& tbody": {
      display: "block",
      maxHeight: "58vh",
      overflowY: "auto",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    },
    "& tr": {
      cursor: "pointer",
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    "& th": {
      backgroundColor: isDarkMode ? "rgba(41, 54, 64, 1)" : "#FDF7F4",
      fontSize: "18px",
      fontWeight: 400,
      textAlign: "left",
      " @media (max-width: 1450px)": {
        fontSize: "16px",
      },
      " @media (max-width: 1280px)": {
        fontSize: "16px",
      },
      " @media (max-width: 960px)": {
        fontSize: "16px",
      },
      " @media (max-width: 768px)": {
        fontSize: "16px",
      },
      " @media (max-width: 480px)": {
        fontSize: "16px",
      },
    },
    "& td": {
      textAlign: "left",
      // " @media (max-width: 1450px)": {
      //   width: "50px",
      //   padding: "5px 5px",
      // },
      // " @media (max-width: 1280px)": {
      //   width: "50px",
      //   padding: "5px 5px",
      // },
      // " @media (max-width: 960px)": {
      //   width: "50px",
      //   padding: "6px 6px",
      // },
      // " @media (max-width: 768px)": {
      //   width: "50px",
      //   padding: "4px 4px",
      // },
      // " @media (max-width: 480px)": {
      //   width: "50px",
      //   padding: "2px 2px",
      // },
    },
    "& tbody tr:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  };
});
export const StyledPaginationContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  p: {
    margin: 0,
  },
});

export const StyledCheckbox = styled(Checkbox)({
  color: "#1212121A",
  // backgroundColor: "#FFFFFF",
  // border: "1px solid #1212121A",
  borderRadius: "4px",
});
