import { ArrowBack, ArrowForward } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import { getOneResponse } from "../../../project/services/ProjectService";
import { getAllModels } from "../../../prompt/services";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";
import { SingleSelect } from "../Select/SingleSelect";
import DialogWrapper from "../Wrapper/DialogWrapper";

type ViewType = "initial" | "models" | "prompts" | "comparison";

const CompareModelDialog = ({
  open,
  setOpen,
  data,
  rowsData,
  prompt,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  data: any;
  rowsData?: any;
  prompt?: any;
}) => {
  const { projectId, promptId, versionId: currentVersionId } = useParams();
  const navigate = useNavigate();

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      version1: null,
      version2: null,
    },
  });

  const [view, setView] = useState<ViewType>("initial");
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [versionId, setVersionId] = useState<string[]>([]);
  const [apiData, setApiData] = useState<any[]>([]);

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const Title = [
    {
      main: "Compare Prompt Versions",
      sub: "Compare different versions of this prompt",
      onClick: () => setView("prompts"),
    },
    {
      main: "Compare Model Responses",
      sub: "Compare different model responses of this version",
      onClick: () => setView("models"),
    },
  ];

  const selectedVersion1 = watch("version1");
  const selectedVersion2 = watch("version2");

  const availableVersions = prompt?.versions || [];

  const getOptionsForSelect = (excludedId: any) => {
    // console.log("Excluded ID:", excludedId);

    const options = availableVersions
      .filter((version: any) => version._id !== excludedId)
      .map((version: any) => ({
        label: `Version ${
          availableVersions.findIndex((v: any) => v._id === version._id) + 1
        }`,
        value: version._id,
      }));

    // console.log("Generated options:", options);
    return options;
  };

  const { data: GetModels, isLoading: modelsLoading } = useQuery(
    "all-models",
    getAllModels
  );

  // const { data: FindResponse, isLoading: responseLoading }: any = useQuery(
  //   [
  //     "prompt-responses",
  //     {
  //       projectId: projectId,
  //       promptId: promptId,
  //       versionId: versionId,
  //     },
  //   ],
  //   getAllResponses,
  //   {
  //     enabled: !!versionId,
  //   }
  // );

  const modelIds = data?.models.flatMap((model: any) => model?.modelId);

  const allResponses = useMemo(() => {
    if (Array.isArray(rowsData)) {
      return rowsData.flatMap((entry) =>
        Object.entries(entry)
          .filter(([key]) => key !== "DatasetRecord")
          .flatMap(
            ([_, source]: [string, any]) =>
              source.response?.map((r: any) => ({
                presetId: r.presetId,
                response: r,
              })) || []
          )
      );
    }

    return Object.entries(rowsData)
      .filter(([key]) => key !== "DatasetRecord")
      .flatMap(
        ([_, source]: [string, any]) =>
          source.response?.map((r: any) => ({
            presetId: r.presetId,
            response: r,
          })) || []
      );
  }, [rowsData]);
  // console.log("Sld:", apiData, FindResponse, allResponses, selectedVersions);

  const filterModel = useMemo(() => {
    return (
      GetModels?.models
        ?.filter((m: any) => modelIds?.includes(m?.versionId))
        ?.flatMap((fm: any) => {
          const matchingModel = data?.models?.find(
            (m: any) => m?.modelId === fm?.versionId
          );

          return (
            matchingModel?.presets.map((preset: any) => ({
              ...fm,
              presets: [
                {
                  ...preset,
                  responses: allResponses
                    .filter((res) => res.presetId === preset._id)
                    .map((res) => res.response),
                },
              ],
            })) || []
          );
        }) || []
    );
  }, [GetModels?.models, modelIds, data?.models, allResponses]);

  // useEffect(() => {
  //   if (FindResponse?.responses) {
  //     setApiData((prevData) => {
  //       const existingEntry = prevData.find(
  //         (entry) => entry.versionId === versionId
  //       );

  //       if (existingEntry) {
  //         return prevData.map((entry) =>
  //           entry.versionId === versionId
  //             ? { versionId, data: FindResponse?.responses }
  //             : entry
  //         );
  //       }

  //       const updatedData = [
  //         ...prevData,
  //         { versionId, data: FindResponse?.responses },
  //       ];

  //       const uniqueData = updatedData.filter(
  //         (value, index, self) =>
  //           index === self.findIndex((t) => t.versionId === value.versionId)
  //       );

  //       return uniqueData.slice(-2);
  //     });
  //   }
  // }, [FindResponse?.responses, versionId]);

  const handleModelSelect = (model: any) => {
    setSelectedModels((prev) => {
      if (
        prev.some((m: any) => m?.presets?.[0]?._id === model?.presets?.[0]?._id)
      ) {
        return prev.filter(
          (m: any) => m?.presets?.[0]?._id !== model?.presets?.[0]?._id
        );
      }
      if (prev.length >= 3) {
        return prev;
      }
      return [...prev, model];
    });
  };

  // const handleModelSelectVersions = (version: any) => {
  //   setSelectedVersions((prev) => {
  //     const isSelected = prev.some((m: any) => m?._id === version?._id);

  //     const updatedVersions = isSelected
  //       ? prev.filter((m: any) => m?._id !== version?._id)
  //       : prev.length < 2
  //       ? [...prev, version]
  //       : prev;

  //     const filteredVersions = updatedVersions.filter(
  //       (v: any) => v?._id !== currentVersionId
  //     );
  //     const latestVersionId = filteredVersions[0]?._id || currentVersionId;

  //     setVersionId(latestVersionId);

  //     return updatedVersions;
  //   });
  // };

  const maxResponseLength = useMemo(() => {
    return Math.max(
      ...selectedModels.map(
        (model: any) => model?.presets[0]?.responses?.length || 0
      )
    );
  }, [selectedModels]);

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, maxResponseLength - 1));
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const onSubmit = (data: any) => {
    // console.log("Finals:", data);

    const { version1, version2 } = data;

    const versionIds = [version1, version2].join(",");

    navigate(
      `/app/projects/${projectId}/prompts/${promptId}/versions-comparison?versionIds=${versionIds}`
    );
  };

  const ModelResponseView = ({ model, data }: { model: any; data: any }) => {
    const responses = model.presets?.[0]?.responses || [];
    const currentResponse = responses[currentIndex] as any;
    const datasets = model?.presets[0]?.responses[0];

    const { data: FindOneResponses, isLoading }: any = useQuery(
      ["one-responses", datasets?._id, datasets?.modelId, datasets?.presetId],
      getOneResponse,
      {
        enabled: !!datasets?._id,
      }
    );

    // console.log("Lats:", model, FindOneResponses);

    if (!currentResponse) {
      return (
        <Box sx={{ padding: "16px", textAlign: "center" }}>
          <Typography>No response available</Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding:
            selectedModels?.length &&
            selectedModels.indexOf(model) === selectedModels.length - 1
              ? "10px 0px 5px 2px"
              : "10px 10px 5px 2px",
          borderRight:
            selectedModels?.length &&
            selectedModels.indexOf(model) === selectedModels.length - 1
              ? "none"
              : "1px solid #E5E7EB",
          width: "100%",
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              color: "#1F2937",
              marginBottom: "8px",
            }}
          >
            {model?.providerName}: {model?.modelName} ({model?.versionName})
          </Typography>
          <Chip
            label={`t: ${model?.presets?.[0]?.temperature || "N/A"}`}
            size="small"
            sx={{
              backgroundColor: "#FBE9D7",
              border: "1px solid #736AEC1A",
              color: "#121212B2",
              mb: 2,
              height: "3vh",
              fontSize: "0.8rem",
              fontWeight: 300,
              ml: 0,
            }}
          />

          <Divider sx={{ marginBottom: "8px" }} />
        </Box>

        {FindOneResponses?.data?.[0]?.datasetsDetails?.[0]?.dataSetVersions?.[0]
          ?.storageDetails?.url && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              p: 2,
              backgroundColor: "#FFFFFF",
              border: "1px solid #1212121A",
              borderRadius: "0.5rem",
              mb: 2,
              // cursor: "pointer",
            }}
            onClick={() =>
              window.open(
                FindOneResponses?.data?.[0]?.datasetsDetails[0]
                  .dataSetVersions[0]?.storageDetails?.url,
                "_blank"
              )
            }
          >
            <img
              alt="csv-panel"
              src={Icons.csv || "/placeholder.svg"}
              height={"35vh"}
              width={"35vw"}
            />
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {
                FindOneResponses?.data?.[0]?.datasetsDetails[0]
                  .dataSetVersions[0]?.tag
              }
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            flex: 1,
            borderRadius: "5px",
            maxHeight: "70vh",
            overflowY: "auto",
            padding: "0px 5px 0px 0px",
            "&::-webkit-scrollbar": {
              width: "0.3rem",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "0.25rem",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },
            "@media (max-width: 1650px)": {
              maxHeight: "55vh",
              padding: "0px 5px 0px 0px",
            },
            "@media (max-width: 1450px)": {
              maxHeight: "55vh",
              padding: "0px 5px 0px 0px",
            },
            "@media (max-width: 1280px)": {
              maxHeight: "50vh",
              padding: "0px 5px 0px 0px",
            },
            "@media (max-width: 960px)": {
              maxHeight: "45vh",
              padding: "0px 5px 0px 0px",
            },
            "@media (max-width: 768px)": {
              maxHeight: "45vh",
              padding: "0px 5px 0px 0px",
            },
            "@media (max-width: 480px)": {
              maxHeight: "45vh",
              padding: "0px 5px 0px 0px",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: 500, marginBottom: "8px" }}
          >
            Response
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: currentResponse?.response !== null ? "#4B5563" : "red",
              marginBottom: "24px",
              minHeight: "200px",
              fontSize: "14px",
              lineHeight: "24px",
              textAlign: "justify",
            }}
          >
            {currentResponse?.response ||
              currentResponse?.failure ||
              "No response available"}
          </Typography>
        </Box>
      </Box>
    );
  };

  const initialView = (
    <>
      <Typography variant="h3" sx={{ fontWeight: 500, mb: 4 }}>
        Compare Prompt Versions or Model Responses
      </Typography>

      {Title.map((item: any, index: any) => (
        <Box
          key={index}
          onClick={item.onClick}
          border="1px solid #1212121A"
          bgcolor="#FFFFFF"
          padding="16px"
          width="100%"
          borderRadius="12px"
          sx={{
            cursor: "pointer",
            mt: index === 0 ? 0 : 3,
            transition: "all 0.2s ease",
            "&:hover": {
              bgcolor: "#F5F5F5",
              transform: "translateY(-2px)",
            },
            "@media (max-width: 1650px)": {
              padding: "5px",
            },
            "@media (max-width: 1450px)": {
              padding: "5px",
            },
            "@media (max-width: 1280px)": {
              padding: "5px",
            },
            "@media (max-width: 960px)": {
              padding: "5px",
            },
            "@media (max-width: 768px)": {
              padding: "5px",
            },
            "@media (max-width: 480px)": {
              padding: "5px",
            },
          }}
        >
          <Box
            gap={2}
            p="8px"
            sx={{
              display: "flex",
              flexDirection: "column",
              "@media (max-width: 1650px)": {
                gap: 0.5,
              },
              "@media (max-width: 1450px)": {
                gap: 0.5,
              },
              "@media (max-width: 1280px)": {
                gap: 0.5,
              },
              "@media (max-width: 960px)": {
                gap: 0.5,
              },
              "@media (max-width: 768px)": {
                gap: 0.5,
              },
              "@media (max-width: 480px)": {
                gap: 0.5,
              },
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "#121212CC", fontWeight: 500 }}
            >
              {item.main}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: "#121212CC", fontWeight: 300, opacity: 0.5 }}
            >
              {item.sub}
            </Typography>
          </Box>
        </Box>
      ))}
    </>
  );

  const modelsView = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 500 }}>
          Compare models by selecting up to 3 Model responses
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#F07946",
            cursor: "pointer",
            fontWeight: 500,
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() => {
            setView("initial");
            setSelectedModels([]);
          }}
        >
          Back
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {filterModel?.map((model: any, index: number) => (
          <Box
            key={index}
            border="1px solid #1212121A"
            bgcolor="#FFFFFF"
            padding="16px"
            borderRadius="12px"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
              "@media (max-width: 1650px)": {
                padding: "10px 5px",
              },
              "@media (max-width: 1450px)": {
                padding: "10px 5px",
              },
              "@media (max-width: 1280px)": {
                padding: "5px",
              },
              "@media (max-width: 960px)": {
                padding: "5px",
              },
              "@media (max-width: 768px)": {
                padding: "5px",
              },
              "@media (max-width: 480px)": {
                padding: "5px",
              },
            }}
          >
            <Checkbox
              checked={selectedModels.some(
                (m: any) => m?.presets?.[0]?._id === model?.presets?.[0]?._id
              )}
              onChange={() => handleModelSelect(model)}
              disabled={
                selectedModels.length >= 3 &&
                !selectedModels.some(
                  (m: any) => m?.presets?.[0]?._id === model?.presets?.[0]?._id
                )
              }
              sx={{
                mt: -2,
                "@media (max-width: 1650px)": {
                  mt: -2,
                },

                "@media (max-width: 1450px)": {
                  mt: -2,
                },
                "@media (max-width: 1280px)": {
                  mt: -2,
                },
                "@media (max-width: 960px)": {
                  mt: -2,
                },
                "@media (max-width: 768px)": {
                  mt: -2,
                },
                "@media (max-width: 480px)": {
                  mt: -2,
                },
              }}
            />

            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {model.providerName}:{model.modelName} ({model.versionName})
              </Typography>
              <Chip
                label={`t: ${model?.presets?.[0]?.temperature}`}
                size="small"
                sx={{
                  backgroundColor: "#FBE9D7",
                  border: "1px solid #736AEC1A",
                  color: "#121212B2",
                  mb: 2,
                  // height: "3vh",
                  fontSize: "0.8rem",
                  fontWeight: 300,
                  ml: 0,
                  "@media (max-width: 1650px)": {
                    mb: 0,
                  },
                  "@media (max-width: 1450px)": {
                    mb: 0,
                  },
                  "@media (max-width: 1280px)": {
                    mb: 0,
                  },
                  "@media (max-width: 960px)": {
                    mb: 0,
                  },
                  "@media (max-width: 768px)": {
                    mb: 0,
                  },
                  "@media (max-width: 480px)": {
                    mb: 0,
                  },
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <MultiTypeButton
          actionOnClick={() => setView("comparison")}
          typeText="Compare"
          buttonType={ButtonType.Gradient}
          disabled={selectedModels.length < 2 || selectedModels.length > 3}
          width={
            isLargeScreen
              ? "192px"
              : isMediumScreen
              ? "115px"
              : isSmallScreen
              ? "61px"
              : "192px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Box>
    </>
  );

  const comparisonView = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 500 }}>
          Model response comparison
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#F07946",
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() => setView("models")}
        >
          Back to Selection
        </Typography>
      </Box>

      <Grid container spacing={2} sx={{ height: "calc(100vh - 200px)" }}>
        {selectedModels.map((model: any) => {
          // console.log(
          //   "Compare Data:",
          //   model,
          //   selectedModels,
          //   data,
          //   filterModel,
          //   prompt
          // );

          return (
            <Grid
              item
              xs={12}
              md={selectedModels.length === 2 ? 6 : 4}
              key={model.presets[0]._id}
              sx={{
                // height: isSmallScreen ? "500px" : "100%",
                overflowY: "auto",
              }}
            >
              <ModelResponseView model={model} data={data} />
            </Grid>
          );
        })}
      </Grid>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <IconButton
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          sx={{
            color: currentIndex === 0 ? "#E5E7EB" : "#F97316",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ArrowBack sx={{ fontSize: 20 }} />
          <Typography
            sx={{
              ml: 1,
              color: "inherit",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Previous
          </Typography>
        </IconButton>

        <Typography variant="body1" sx={{ color: "#6B7280" }}>
          Response {currentIndex + 1} of {maxResponseLength}
        </Typography>

        <IconButton
          onClick={handleNext}
          disabled={currentIndex === maxResponseLength - 1}
          sx={{
            color:
              currentIndex === maxResponseLength - 1 ? "#E5E7EB" : "#F97316",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Typography
            sx={{
              mr: 1,
              color: "inherit",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            Next
          </Typography>
          <ArrowForward sx={{ fontSize: 20 }} />
        </IconButton>
      </Box>
    </>
  );

  const promptsView = (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: 500 }}>
          Compare Prompt Versions
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#F07946",
            cursor: "pointer",
            fontWeight: 500,
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={() => {
            setView("initial");
            setSelectedVersions([]);
          }}
        >
          Back
        </Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <SingleSelect
          control={control}
          required
          name="version1"
          label="Comparison 1"
          options={getOptionsForSelect(selectedVersion2)}
          placeholder="select version"
          defaultData="Version 1"
          width={
            isLargeScreen
              ? "235px"
              : isMediumScreen
              ? "200px"
              : isSmallScreen
              ? "100px"
              : "235px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
        <SingleSelect
          control={control}
          required
          name="version2"
          label="Comparison 2"
          options={getOptionsForSelect(selectedVersion1)}
          placeholder="Choose the second version"
          disabled={!selectedVersion1}
          width={
            isLargeScreen
              ? "235px"
              : isMediumScreen
              ? "200px"
              : isSmallScreen
              ? "100px"
              : "235px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
        <MultiTypeButton
          actionOnClick={() => {
            handleSubmit(onSubmit)();
          }}
          typeText="Compare"
          buttonType={ButtonType.Gradient}
          disabled={!selectedVersion1 || !selectedVersion2}
          width={
            isLargeScreen
              ? "192px"
              : isMediumScreen
              ? "115px"
              : isSmallScreen
              ? "61px"
              : "192px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Box>
    </Box>
  );

  const viewContent = {
    initial: initialView,
    models: modelsView,
    prompts: promptsView,
    comparison: comparisonView,
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
        setView("initial");
        setSelectedModels([]);
        setSelectedVersions([]);
        setCurrentIndex(0);
        setVersionId([]);
        setApiData([]);
        setValue("version1", null);
        setValue("version2", null);
      }}
      title="Compare"
      width={
        view === "comparison" && isMediumScreen
          ? "md"
          : view === "comparison" && isLargeScreen
          ? "lg"
          : "sm"
      }
    >
      <Box
        sx={{
          padding: "15px 0px",
          "@media (max-width: 1650px)": {
            padding: "10px 0px",
          },
          "@media (max-width: 1450px)": {
            padding: "10px 0px",
          },
          "@media (max-width: 1280px)": {
            padding: "10px 0px",
          },
          "@media (max-width: 960px)": {
            padding: "10px 0px",
          },
          "@media (max-width: 768px)": {
            padding: "10px 0px",
          },
          "@media (max-width: 480px)": {
            padding: "10px 0px",
          },
        }}
      >
        {viewContent[view]}
      </Box>
    </DialogWrapper>
  );
};

export default CompareModelDialog;
