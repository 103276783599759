import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Link, Stack, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { displayErrorMessage } from "../../../app/services";
import { AuthStore } from "../../../store/general";
import { ColorPalette } from "../../../themes/colorTokens/colorPalette";
import useScreenSizes from "../../../utils/CustomSizesHook";
import AutocompleteCountries from "../../shared/components/Autocomplete/AutocompleteCountries";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import SimpleCheckBox from "../../shared/components/Checkbox/Checkbox";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { signUp } from "../services/AuthService";
import { countries, employeeCountOptions } from "../utils/AuthUtils";

const validationSchema = yup.object({
  fullName: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("Full Name is required"),
  organizationName: yup
    .string()
    // .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("Organization Name is required"),
  organizationIndustry: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed")
    .required("Organization Industry is required"),
  // Add additional validations for other TextFields as needed
});

export default function RegistrationDetails() {
  const navigate = useNavigate();
  const [isTermsAccepted, setTermsAccepted] = useState(false);
  const refresh_Token = Cookies.get("refresh_Token");
  const userName = AuthStore.useState((s) => s.userName);
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  useEffect(() => {
    if (userName) {
      setValue("fullName", userName);
    }
  }, [userName]);

  const {
    mutate: postSignupDetails,
    isLoading: PostDetailsLoader,
    isSuccess: postSignupSuccess,
  } = useMutation(signUp, {
    onSuccess: (res: any) => {
      // console.log("SignUp Details:", res);

      Cookies.set("access_token", res.access_token);
      Cookies.set("Sme", res?.sme);
    },
    onError: (error: any) => {
      displayErrorMessage(error);
    },
  });

  useEffect(() => {
    if (postSignupSuccess) {
      const checkSmeAndNavigate = () => {
        const smeValue = Cookies.get("Sme");
        if (smeValue !== undefined) {
          if (smeValue === "true") {
            navigate("/app/sme", { replace: true });
          } else {
            navigate("/app", { replace: true });
          }
        } else {
          // If cookie is not set yet, try again after a short delay
          setTimeout(checkSmeAndNavigate, 100);
        }
      };

      checkSmeAndNavigate();
    }
  }, [postSignupSuccess, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data: any) => {
    postSignupDetails({
      name: data.fullName,
      orgName: data.organizationName,
      orgIndustry: data.organizationIndustry,
      orgSize: data.organizationEmployeeCount,
      country: data.SelectCountry,
      token: refresh_Token,
    });
  };

  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      navigate(`/app`, { replace: true });
    }
  }, [Cookies.get("access_token")]);

  return (
    <Box
      padding={5}
      sx={{
        "@media (max-width: 1440px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 1280px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 960px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 768px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 480px)": {
          padding: "20px 25px",
        },
      }}
    >
      <Stack>
        <Typography variant="h3" textAlign={"center"}>
          Let’s get you started by setting up your organization.
        </Typography>
      </Stack>

      <Stack marginTop={"2.5vh"} gap={"2vh"}>
        <TextField
          control={control}
          formateType={Formate.StrictUpperCase}
          name="fullName"
          label="Full Name"
          required={true}
          placeholder="Enter your Full Name"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          name="organizationName"
          label="Organization Name"
          required={true}
          placeholder="Enter your Organization Name "
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <TextField
          control={control}
          formateType={Formate.AlphabetsOnly}
          name="organizationIndustry"
          label="Organization Industry"
          required={true}
          placeholder="Enter your Organization Industry"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <SingleSelect
          control={control}
          required
          name="organizationEmployeeCount"
          label="Organization Employee Count"
          options={employeeCountOptions}
          defaultValue="1-10"
          placeholder="Enter employee count"
          defaultData={"1-10"}
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <AutocompleteCountries
          required
          name="SelectCountry"
          control={control}
          options={countries}
          label="Select Country"
          placeholder="Select Option"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Stack>
      <Stack direction={"row"} alignItems={"center"} marginTop={"10px"}>
        <SimpleCheckBox
          key={"checkbox"}
          text={""}
          control={control}
          isChecked={(e: any) => {
            setTermsAccepted(e);
          }}
        />
        <Typography variant="subtitle2">
          I agree to the{" "}
          <Link
            href="/terms"
            underline="hover"
            color={ColorPalette.BrandColors.OrangeLight}
          >
            Terms and Conditions
          </Link>{" "}
          and{" "}
          <Link
            href="/privacy"
            underline="hover"
            color={ColorPalette.BrandColors.OrangeLight}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Stack>

      <Stack my={"2vh"}>
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          isLoading={PostDetailsLoader}
          typeText={"Submit"}
          disabled={!isTermsAccepted}
          actionOnClick={() => {
            handleSubmit(onSubmit)();
          }}
          key={"registration"}
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Stack>

      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Typography variant="subtitle2">Have an account?</Typography>
        <Button
          variant="text"
          onClick={() => {
            navigate("/login");
          }}
        >
          <Typography
            variant="subtitle2"
            color={ColorPalette.BrandColors.OrangeLight}
            fontWeight="bold"
          >
            Login
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
