import { Box, LinearProgress } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import EmptyPage from "../../shared/components/Errors/EmptyPage";
import { getAllProjects, getAllSmeProjects } from "../services/ProjectService";

export default function ProjectSetup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const [projectId, setProjectId] = useState<any>();
  const Sme = Cookies.get("Sme");

  const { data: GetAllProjects, isLoading: allProjectsLoading }: any = useQuery(
    ["projects", search, limit, offset],
    () => getAllProjects({ search, limit, offset }),
    {
      enabled: Sme === "false",
      onSuccess: (res: any) => {
        if (res?.total > 0) {
          navigate("/app/projects/all");
        }
      },
    }
  );

  const { data: getSmeProjects, isLoading: smeLoading }: any = useQuery(
    "sme-projects",
    getAllSmeProjects,
    {
      enabled: Sme === "true",
      onSuccess: (res: any) => {
        setProjectId(res?.data?.[0]?.projectId);
      },
    }
  );

  const { data: projectDetails, isLoading: projectDetailsLoading }: any =
    useQuery(
      ["sme-project-details", projectId],
      () => getAllSmeProjects({ projectId }),
      {
        enabled: Sme === "true" && !!projectId,
        onSuccess: (res: any) => {
          if (res?.data?.length >= 1) {
            navigate(
              `/app/sme/projects/${projectId}/sme-prompts-detailed-view`
            );
          }
        },
      }
    );

  console.log("main Details:", projectDetails);

  const isLoading = allProjectsLoading || smeLoading || projectDetailsLoading;

  if (isLoading || !!projectId) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box>
      {isLoading ? (
        <LinearProgress />
      ) : Sme === "true" && !projectDetails ? (
        <EmptyPage
          buttonTitle="Add Project"
          tagLine="No Projects Assigned yet"
          Sme={Sme}
        />
      ) : GetAllProjects?.total === 0 ? (
        <EmptyPage
          buttonTitle="Add Project"
          tagLine="Create your first project"
          Sme={Sme}
        />
      ) : (
        <LinearProgress />
      )}
    </Box>
  );
}
