import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, Collapse, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface NestedOption {
  _id: string;
  label: string;
  children?: NestedOption[];
  versions?: NestedOption[];
}

interface Props {
  options: NestedOption[];
  onChange?: (parentIds: any, childrenIds: any, versionIds: any) => void;
}

const CustomDropdown = ({ options, onChange }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [expanded2, setExpanded2] = useState<string | null>(null);
  const [selected, setSelected] = useState<string[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelectionUpdate = (updatedSelected: string[]) => {
    setSelected(updatedSelected);

    const parentIds: any = [];
    const childrenIds: any = [];
    const versionIds: any = [];

    options.forEach((parent) => {
      if (updatedSelected.includes(parent.label)) {
        parentIds.push(parent._id);
      }

      parent.children?.forEach((child) => {
        if (updatedSelected.includes(child.label)) {
          childrenIds.push(child._id);
        }

        child.versions?.forEach((version) => {
          if (updatedSelected.includes(version.label)) {
            versionIds.push(version._id);
          }
        });
      });
    });

    const parentString = parentIds.length > 0 ? parentIds.join(",") : parentIds;
    const childString =
      childrenIds.length > 0 ? childrenIds.join(",") : childrenIds;
    const versionString =
      versionIds.length > 0 ? versionIds.join(",") : versionIds;

    if (
      parentIds.length === 0 &&
      childrenIds.length === 0 &&
      versionIds.length === 0
    ) {
      if (onChange) {
        onChange(null, null, null);
      }
    } else {
      if (onChange) {
        onChange(parentString, childString, versionString);
      }
    }
  };

  const handleParentToggle = (parent: NestedOption, isChecked: boolean) => {
    const childLabels = parent.children?.map((c) => c.label) || [];
    const versionLabels =
      parent.children?.flatMap(
        (child) => child.versions?.map((v) => v.label) || []
      ) || [];

    const allSelectedLabels = [parent.label, ...childLabels, ...versionLabels];

    const updatedSelected = isChecked
      ? Array.from(new Set([...selected, ...allSelectedLabels]))
      : selected?.filter((label) => !allSelectedLabels.includes(label));

    handleSelectionUpdate(updatedSelected);
  };

  const handleChildToggle = (childLabel: string, isChecked: boolean) => {
    const updatedSelected = isChecked
      ? [...selected, childLabel]
      : selected?.filter((label) => label !== childLabel);

    const child = options
      .flatMap((parent) => parent.children || [])
      .find((c) => c.label === childLabel);
    if (child && child.versions) {
      const versionLabels = child.versions.map((v) => v.label);
      const updatedSelectedWithVersions = isChecked
        ? Array.from(new Set([...updatedSelected, ...versionLabels]))
        : updatedSelected?.filter((label) => !versionLabels.includes(label));

      handleSelectionUpdate(updatedSelectedWithVersions);
    } else {
      handleSelectionUpdate(updatedSelected);
    }
  };

  const handleVersionToggle = (versionLabel: string, isChecked: boolean) => {
    const updatedSelected = isChecked
      ? [...selected, versionLabel]
      : selected?.filter((label) => label !== versionLabel);

    handleSelectionUpdate(updatedSelected);
  };

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: "8px",
        padding: "0 2vw",
      }}
      ref={dropdownRef}
    >
      <Box
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          border: "1px solid #12121226",
          backgroundColor: "#F8F8F8",
          padding: "0.42vw 1vw",
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontWeight: "400",
          textAlign: "left",
          borderRadius: "8px",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
          }}
        >
          Select LLM Series
        </Typography>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Box>

      {isOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "white",
            border: "1px solid #ddd",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            maxHeight: "53vh",
            overflowY: "auto",
            zIndex: 10,
            ml: 0,
            mt: 1,
            borderRadius: "8px",
          }}
        >
          {options.map((parent) => (
            <Box key={parent._id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.625vw",
                  cursor: "pointer",
                  backgroundColor: selected.includes(parent.label)
                    ? "#f0f0f0"
                    : "white",
                }}
                onClick={() =>
                  setExpanded((prev) =>
                    prev === parent._id ? null : parent._id
                  )
                }
              >
                {expanded === parent._id ? (
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(360deg)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      transform: "rotate(270deg)",
                      transition: "transform 0.3s ease-in-out",
                    }}
                  />
                )}
                <Checkbox
                  checked={selected.includes(parent.label)}
                  onChange={(e) => handleParentToggle(parent, e.target.checked)}
                />

                <Typography variant="h5" sx={{ fontWeight: 600 }}>
                  {parent.label}
                </Typography>
              </Box>

              <Collapse
                in={expanded === parent._id}
                timeout="auto"
                unmountOnExit
              >
                {parent.children?.map((child) => (
                  <Box key={child._id}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        // padding: "0px 10px 0px 50px",
                        padding: "0 0 0 1.8vw",
                        backgroundColor: selected.includes(child.label)
                          ? "#f9f9f9"
                          : "white",
                      }}
                      onClick={() =>
                        setExpanded2((prev) =>
                          prev === child._id ? null : child._id
                        )
                      }
                    >
                      {expanded2 === child._id ? (
                        <ExpandMoreIcon
                          sx={{
                            transform: "rotate(360deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      ) : (
                        <ExpandMoreIcon
                          sx={{
                            transform: "rotate(270deg)",
                            transition: "transform 0.3s ease-in-out",
                          }}
                        />
                      )}
                      <Checkbox
                        checked={selected.includes(child.label)}
                        onChange={(e) =>
                          handleChildToggle(child.label, e.target.checked)
                        }
                      />
                      <Typography variant="h6" sx={{ fontWeight: 400 }}>
                        {child.label}
                      </Typography>
                    </Box>

                    <Collapse
                      in={expanded2 === child._id}
                      timeout="auto"
                      unmountOnExit
                    >
                      {child?.versions?.map((version) => (
                        <Box
                          key={version._id}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // padding: "0px 10px 0px 90px",
                            padding: "0 0vw 0 3.625vw",
                            backgroundColor: selected.includes(version.label)
                              ? "#e8e8e8"
                              : "white",
                          }}
                        >
                          <Checkbox
                            checked={selected.includes(version.label)}
                            onChange={(e) =>
                              handleVersionToggle(
                                version.label,
                                e.target.checked
                              )
                            }
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {version.label}
                          </Typography>
                        </Box>
                      ))}
                    </Collapse>
                  </Box>
                ))}
              </Collapse>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CustomDropdown;
