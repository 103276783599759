import { Box, styled } from "@mui/material";

export const StyledContainer = styled(Box)(() => ({
  border: "1px solid #1212121A",
  backgroundColor: "#FFFFFF",
  borderRadius: "15px",
  width: "90%",
  padding: "35px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  minHeight: "65vh",
  maxHeight: "75vh",
  "@media (max-width: 1650px)": {
    padding: "10px 25px",
  },
  "@media (max-width: 1450px)": {
    padding: "10px 25px",
  },
  "@media (max-width: 1280px)": {
    padding: "20px 25px",
  },
  "@media (max-width: 960px)": {
    padding: "20px 25px",
  },
  "@media (max-width: 768px)": {
    padding: "20px 25px",
  },
  "@media (max-width: 480px)": {
    padding: "20px 25px",
  },
}));

export const StyledContainerSub = styled(Box)(() => ({
  border: "1px solid #1212121A",
  backgroundColor: "#FFFFFF",
  borderRadius: "15px",
  padding: "20px 30px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  "@media (max-width: 1650px)": {
    padding: "10px 20px",
  },
  "@media (max-width: 1450px)": {
    padding: "10px 20px",
  },
  "@media (max-width: 1280px)": {
    padding: "10px 20px",
  },
  "@media (max-width: 960px)": {
    padding: "10px 20px",
  },
  "@media (max-width: 768px)": {
    padding: "10px 20px",
  },
  "@media (max-width: 480px)": {
    padding: "10px 20px",
  },
}));
