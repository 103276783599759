import { Button, Stack, Typography, useTheme } from "@mui/material";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { AuthStore } from "../../../store/general";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import OtpInput from "../../shared/components/TextField/OtpInput/OtpInput";
import { verifyOtp } from "../services/AuthService";

export default function OtpVerificationDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();
  const [otp, setOtp] = useState(0);
  const refresh_Token = Cookies.get("refresh_Token");
  const EmailAddress = AuthStore.useState((s) => s.signupEmailAddress);
  const {
    mutate: verifyOTP,
    isLoading: loginLoader,
    isSuccess: otpSuccess,
  } = useMutation(verifyOtp, {
    onSuccess: (res: any) => {
      Cookies.set("access_token", res.access_token);
      Cookies.set("Sme", res?.sme);
      Cookies.remove("refresh_Token");
      displaySuccessMessage(res.message);
      // navigate(`/app`, { replace: true });
    },
    onError: (error: any) => {
      displayErrorMessage(error);
    },
  });

  useEffect(() => {
    if (otpSuccess) {
      const checkSmeAndNavigate = () => {
        const smeValue = Cookies.get("Sme");
        if (smeValue !== undefined) {
          if (smeValue === "true") {
            navigate("/app/sme", { replace: true });
          } else {
            navigate("/app", { replace: true });
          }
        } else {
          // If cookie is not set yet, try again after a short delay
          setTimeout(checkSmeAndNavigate, 100);
        }
      };

      checkSmeAndNavigate();
    }
  }, [otpSuccess, navigate]);

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      gap={"20px"}
      sx={{
        padding: 5,
        "@media (max-width: 1650px)": {
          padding: "20px 15px",
          gap: "12px",
        },
        "@media (max-width: 1450px)": {
          padding: "20px 15px",
          gap: "12px",
        },
        "@media (max-width: 1280px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 960px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 768px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 480px)": {
          padding: "20px 25px",
        },
      }}
    >
      <Typography variant="h3"> OTP Verification</Typography>
      <Typography variant="subtitle2">
        {" "}
        Please enter the code, we sent to your Email Address
      </Typography>

      <OtpInput
        length={6}
        onChangeOtp={(e: any) => {
          setOtp(e);
        }}
      />

      <MultiTypeButton
        isLoading={loginLoader}
        buttonType={ButtonType.Gradient}
        actionOnClick={() => {
          verifyOTP({
            otp: otp.toString(),
            token: refresh_Token,
            type: "LOGIN",
            value: EmailAddress,
          });
        }}
        key={"Verify-otp"}
        typeText={"Verify OTP"}
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />
      <Button variant="text">Resend OTP</Button>
    </Stack>
  );
}
