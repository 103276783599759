import { Box, Typography, useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { AuthStore } from "../../../store/general";
import OnboardingPageWrapper from "../../shared/components/Wrapper/PageWrapper";
import { githubLogin } from "../services/AuthService";

function GitHubCallback() {
  const navigate = useNavigate();

  // Extract code from URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  console.log("Code:", urlParams);

  const code = urlParams.get("code");
  console.log("Code2:", code);

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const { isSuccess } = useQuery(
    ["gitHubCallback", { token: code }],
    githubLogin,
    {
      enabled: !!code,
      onSuccess: (data) => {
        if (data.isAccountVerified) {
          Cookies.set("access_token", data.access_token);
          Cookies.set("Sme", data?.sme);
          navigate("/app", { replace: true });
        } else {
          Cookies.set("refresh_Token", data.access_token);
          AuthStore.update((s) => {
            s.userName = data.userName;
          });
          navigate("/register", { replace: true });
        }
      },
    }
  );

  useEffect(() => {
    if (isSuccess) {
      const checkSmeAndNavigate = () => {
        const smeValue = Cookies.get("Sme");
        if (smeValue !== undefined) {
          if (smeValue === "true") {
            navigate("/app/sme", { replace: true });
          } else {
            navigate("/app", { replace: true });
          }
        } else {
          // If cookie is not set yet, try again after a short delay
          setTimeout(checkSmeAndNavigate, 100);
        }
      };

      checkSmeAndNavigate();
    }
  }, [isSuccess, navigate]);

  return (
    <OnboardingPageWrapper>
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          padding: 5,
          "@media (max-width: 1440px)": {
            padding: "20px 25px",
          },
          "@media (max-width: 1280px)": {
            padding: "20px 25px",
          },
          "@media (max-width: 960px)": {
            padding: "20px 25px",
          },
          "@media (max-width: 768px)": {
            padding: "20px 25px",
          },
          "@media (max-width: 480px)": {
            padding: "20px 25px",
          },
        }}
      >
        <Typography variant="subtitle2">Please Wait....</Typography>
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{
            width: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "8px",
            height: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
          }} // Fixed size for better presentation
        />
      </Box>
    </OnboardingPageWrapper>
  );
}

export default GitHubCallback;
