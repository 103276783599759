import { ArrowBack, ArrowForward } from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Rating,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons, Images } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import { getOneResponse } from "../../../project/services/ProjectService";
import { getGradingParams, postGrading } from "../../../prompt/services";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";
import { handleError } from "../Errors/HandleError";
import Spinner from "../Loaders/Spinner";
import DialogWrapper from "../Wrapper/DialogWrapper";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: "1vw 0" }}>{children}</Box>}
    </div>
  );
}

const TEMP_GRADING_KEY = "temp-grading-data";

const GradientDialog = ({
  open,
  setOpen,
  data,
  onClose,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
  onClose?: any;
}) => {
  const queryClient = useQueryClient();
  const { projectId, promptId, versionId } = useParams();
  const { rowIndex, colId: initialColId, colIndex, data: rowData } = data || {};

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [tabValue, setTabValue] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [userGrading, setUserGrading] = useState<Record<string, number>>({});
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userId, setUserId] = useState("");
  const [hasUserRated, setHasUserRated] = useState(false);
  const [ratedColumns, setRatedColumns] = useState<Record<string, boolean>>({});
  const [currentColId, setCurrentColId] = useState(initialColId);

  const [currentPresetIndex, setCurrentPresetIndex] = useState(0);

  const getGradingFromTemp = useCallback(
    (responseId: string, presetId: string) => {
      const stored = JSON.parse(localStorage.getItem(TEMP_GRADING_KEY) || "{}");
      return stored[`${responseId}-${presetId}`];
    },
    []
  );

  const saveGradingToTemp = useCallback((responseId: string, data: any) => {
    const stored = JSON.parse(localStorage.getItem(TEMP_GRADING_KEY) || "{}");
    stored[`${responseId}-${data.presetId}`] = data;
    localStorage.setItem(TEMP_GRADING_KEY, JSON.stringify(stored));
  }, []);

  const clearTempGrading = () => {
    localStorage.removeItem(TEMP_GRADING_KEY);
  };

  const columnIds = useMemo(() => {
    if (!rowData) return [];
    return Object.keys(rowData).filter((key) => key !== "DatasetRecord");
  }, [rowData]);

  const currentColumnIndex = useMemo(() => {
    return columnIds.findIndex((id) => id === currentColId);
  }, [columnIds, currentColId]);

  useEffect(() => {
    if (initialColId && currentColId === undefined) {
      setCurrentColId(initialColId);
    }
  }, [initialColId, currentColId]);

  const responses = useMemo(() => {
    if (!rowData) return [];

    const allResponses: any[] = [];
    columnIds.forEach((colId) => {
      if (rowData[colId]?.response) {
        rowData[colId].response.forEach((resp: any) => {
          allResponses.push({
            ...resp,
            columnId: colId,
          });
        });
      }
    });
    return allResponses;
  }, [rowData, columnIds]);

  // const currentResponse = useMemo(() => {
  //   if (!responses.length) return null;
  //   const matchedResponses = responses.filter(
  //     (r) => r.columnId === currentColId
  //   );

  //   const uniqueResponses = matchedResponses.filter(
  //     (item, index, self) => index === self.findIndex((r) => r._id === item._id)
  //   );

  //   return uniqueResponses.length === 1 ? uniqueResponses[0] : uniqueResponses;
  // }, [responses, currentColId]);

  const currentResponse = useMemo(() => {
    if (!responses.length) return null;
    const matchedResponses = responses.filter(
      (r) => r.columnId === currentColId
    );
    return matchedResponses.length ? matchedResponses : null;
  }, [responses, currentColId]);

  // console.log("Data3:", responses, currentResponse);

  const { data: FindGradingParams, isLoading: gradingLoading } = useQuery(
    ["grading-params", projectId, promptId, versionId],
    getGradingParams,
    {
      enabled: !!versionId,
    }
  );

  const { data: FindOneResponses, isLoading }: any = useQuery(
    [
      "prompt-responses",
      currentResponse?.[currentPresetIndex]?.responseId,
      // currentResponse?.[currentPresetIndex]?._id,
      currentResponse?.[currentPresetIndex]?.modelId,
      currentResponse?.[currentPresetIndex]?.presetId,
    ],
    getOneResponse,

    {
      enabled: !!currentResponse?.[currentPresetIndex]?._id,
    }
  );

  const combinedParams = useMemo(() => {
    if (
      !currentResponse?.[currentPresetIndex] ||
      !FindGradingParams?.gradingParams
    )
      return [];

    if (currentResponse?.[currentPresetIndex]?.grading?.length > 0) {
      return FindGradingParams?.gradingParams?.map((gParam: any) => {
        const matchingGradings = currentResponse?.[
          currentPresetIndex
        ]?.grading?.filter((gradingItem: any) =>
          gradingItem?.parameters.some((p: any) => p?.paramId === gParam._id)
        );

        const smes = matchingGradings
          .filter((gradingItem: any) => gradingItem?.type === "Sme")
          .map((gradingItem: any) => {
            const smeParam = gradingItem?.parameters.find(
              (p: any) => p?.paramId === gParam._id
            );
            return {
              SmeScore: smeParam?.score || null,
              SmeScale: smeParam?.scale || null,
              SmeComment: gradingItem?.comments || null,
            };
          });

        const userGradings = matchingGradings
          .filter(
            (gradingItem: any) =>
              gradingItem?.type === "Self" || gradingItem?.type === "User"
          )
          .map((gradingItem: any) => {
            const userParam = gradingItem?.parameters.find(
              (p: any) => p?.paramId === gParam._id
            );
            return {
              score: userParam?.score || null,
              scale: userParam?.scale || null,
              comment: gradingItem?.comments || null,
              userId: gradingItem?.userId,
            };
          });

        const currentUserGrading = userGradings.find(
          (g: any) => g.userId === userId
        );

        return {
          ...gParam,
          Smes: smes,
          UserGradings: userGradings,
          UserScore: currentUserGrading?.score || null,
          UserScale: currentUserGrading?.scale || null,
          UserComment: currentUserGrading?.comment || null,
          hasUserGraded: !!currentUserGrading,
        };
      });
    }

    return FindGradingParams?.gradingParams;
  }, [currentResponse, FindGradingParams, userId, currentPresetIndex]);
  // console.log("currentResponse34:", combinedParams);

  const { mutate, isLoading: isSubmitting } = useMutation(postGrading, {
    onSuccess: () => {
      toast.success("User grading submitted successfully");

      const tempData = {
        columnId: currentColId,
        presetId: currentResponse?.[currentPresetIndex]?.presetId,
        scores: userGrading,
        comment: comment,
      };
      console.log("Saving to temp storage in mutate success:", {
        responseId: currentResponse?.[currentPresetIndex]?._id,
        tempData,
      });
      saveGradingToTemp(currentResponse?.[currentPresetIndex]?._id, tempData);

      setRatedColumns((prev) => ({
        ...prev,
        [`${currentColId}-${currentResponse?.[currentPresetIndex].presetId}`]:
          true,
      }));

      // setHasUserRated(true);

      // Clear temporary state if needed
      // setUserGrading({});
      // setComment("");

      queryClient.invalidateQueries("prompt-responses");
      queryClient.invalidateQueries("one-responses");
      queryClient.invalidateQueries("grading-params");
      queryClient.invalidateQueries("prompt-responses");
    },
    onError: (err) => handleError(err),
  });

  const Resp = useMemo(() => FindOneResponses?.data[0], [FindOneResponses]);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded?.userId) {
        setUserId(decoded.userId);
      }
    }
  }, []);

  const groupedBySme = useMemo(() => {
    const smeGrading = currentResponse?.[currentPresetIndex]?.grading?.filter(
      (gradingItem: any) => gradingItem?.type === "Sme"
    );

    return smeGrading?.map((sme: any, index?: any) => ({
      SmeName: sme?.name || `SME ${index + 1}`,
      SmeComment: sme?.comments,
      parameters: FindGradingParams?.gradingParams.map((param: any) => {
        const matchingParam = sme.parameters.find(
          (p: any) => p.paramId === param?._id
        );
        return {
          name: param.name,
          description: param.description,
          SmeScore: matchingParam?.score || null,
          SmeScale: matchingParam?.scale || null,
        };
      }),
    }));
  }, [currentResponse, FindGradingParams]);

  // console.log("Main Resp:", groupedBySme);

  useEffect(() => {
    if (currentResponse?.[currentPresetIndex]?._id) {
      const currentPreset = currentResponse[currentPresetIndex];

      const hasApiGrading = combinedParams?.some(
        (param: any) => param.hasUserGraded
      );

      if (hasApiGrading) {
        const newGrading: Record<string, number> = {};
        combinedParams.forEach((param: any) => {
          if (param.UserScore !== null) {
            newGrading[param._id] = param.UserScore;
          }
        });
        setUserGrading(newGrading);
        setComment(combinedParams[0]?.UserComment || "");
        setRatedColumns((prev) => ({
          ...prev,
          [`${currentColId}-${currentPreset.presetId}`]: true,
        }));
      } else {
        const tempData = getGradingFromTemp(
          currentPreset._id,
          currentPreset.presetId
        );

        if (tempData && tempData.columnId === currentColId) {
          setUserGrading(tempData.scores);
          setComment(tempData.comment);
          setRatedColumns((prev) => ({
            ...prev,
            [`${currentColId}-${currentPreset.presetId}`]: true,
          }));
        } else {
          setUserGrading({});
          setComment("");
          setRatedColumns((prev) => ({
            ...prev,
            [`${currentColId}-${currentPreset.presetId}`]: false,
          }));
        }
      }
    }
  }, [
    currentResponse,
    currentPresetIndex,
    combinedParams,
    currentColId,
    getGradingFromTemp,
  ]);

  const handleNextPage = useCallback(() => {
    if (!currentResponse) return;

    if (currentPresetIndex < currentResponse.length - 1) {
      setCurrentPresetIndex((prev) => prev + 1);
    } else {
      const nextColumnIndex = currentColumnIndex + 1;
      if (nextColumnIndex < columnIds.length) {
        if (
          !ratedColumns[
            `${currentColId}-${currentResponse[currentPresetIndex].presetId}`
          ] &&
          (Object.keys(userGrading).length > 0 || comment.trim())
        ) {
          const tempData = {
            columnId: currentColId,
            presetId: currentResponse[currentPresetIndex].presetId,
            scores: userGrading,
            comment: comment,
          };
          saveGradingToTemp(currentResponse[currentPresetIndex]._id, tempData);
        }
        setCurrentColId(columnIds[nextColumnIndex]);
        setCurrentPresetIndex(0);
      }
    }
  }, [
    currentResponse,
    currentPresetIndex,
    currentColumnIndex,
    columnIds,
    ratedColumns,
    currentColId,
    userGrading,
    comment,
    saveGradingToTemp,
  ]);

  const handlePreviousPage = useCallback(() => {
    if (!currentResponse) return;

    if (currentPresetIndex > 0) {
      setCurrentPresetIndex((prev) => prev - 1);
    } else {
      const prevColumnIndex = currentColumnIndex - 1;
      if (prevColumnIndex >= 0) {
        if (
          !ratedColumns[
            `${currentColId}-${currentResponse[currentPresetIndex].presetId}`
          ] &&
          (Object.keys(userGrading).length > 0 || comment.trim())
        ) {
          const tempData = {
            columnId: currentColId,
            presetId: currentResponse[currentPresetIndex].presetId,
            scores: userGrading,
            comment: comment,
          };
          saveGradingToTemp(currentResponse[currentPresetIndex]._id, tempData);
        }
        setCurrentColId(columnIds[prevColumnIndex]);
        const prevColumnResponses = responses.filter(
          (r) => r.columnId === columnIds[prevColumnIndex]
        );
        setCurrentPresetIndex(prevColumnResponses.length - 1);
      }
    }
  }, [
    currentResponse,
    currentPresetIndex,
    currentColumnIndex,
    columnIds,
    ratedColumns,
    currentColId,
    userGrading,
    comment,
    responses,
    saveGradingToTemp,
  ]);

  // console.log(
  //   "Combined Params:",
  //   combinedParams,
  //   currentResponse,
  //   FindGradingParams?.gradingParams,
  //   data
  // );

  const isColumnRated = useCallback(
    (columnId: string, presetId: string) => {
      return ratedColumns[`${columnId}-${presetId}`] || false;
    },
    [ratedColumns]
  );

  const handleRatingChange = useCallback(
    (paramId: string, value: number) => {
      if (!currentResponse) return;

      if (
        isColumnRated(
          currentColId,
          currentResponse[currentPresetIndex].presetId
        )
      )
        return;
      setUserGrading((prev) => ({
        ...prev,
        [paramId]: value,
      }));
    },
    [isColumnRated, currentColId, currentResponse, currentPresetIndex]
  );

  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!currentResponse) return;

      if (
        isColumnRated(
          currentColId,
          currentResponse[currentPresetIndex].presetId
        )
      )
        return;
      const newComment = event.target.value;

      setComment(newComment);
    },
    [isColumnRated, currentColId]
  );

  const handleSubmit = useCallback(() => {
    if (!currentResponse) return;

    setIsSubmitted(true);

    const hasAllRatings = combinedParams?.every(
      (param: any) => userGrading[param._id] !== undefined
    );

    if (!hasAllRatings) {
      toast.error("Please rate all parameters");
      return;
    }

    if (!comment.trim()) {
      toast.error("Please add a comment");
      return;
    }

    const gradingParams = Object.entries(userGrading).map(
      ([paramId, score]) => ({
        paramId,
        score,
        scale: combinedParams.find((p: any) => p._id === paramId)?.scale || 5,
      })
    );

    const postData = {
      projectId,
      promptId,
      versionId,
      responseId: currentResponse?.[currentPresetIndex]?.responseId,
      modelId: currentResponse?.[currentPresetIndex]?.modelId,
      presetId: currentResponse?.[currentPresetIndex]?.presetId,
      gradingParams: gradingParams,
      comments: comment,
    };

    console.log("Submitting grading:", postData);
    mutate(postData);

    setRatedColumns((prev) => ({
      ...prev,
      [`${currentColId}-${currentResponse[currentPresetIndex].presetId}`]: true,
    }));

    const stored = JSON.parse(localStorage.getItem(TEMP_GRADING_KEY) || "{}");
    delete stored[
      `${currentResponse[currentPresetIndex]._id}-${currentResponse[currentPresetIndex].presetId}`
    ];
    localStorage.setItem(TEMP_GRADING_KEY, JSON.stringify(stored));
  }, [
    userGrading,
    comment,
    combinedParams,
    currentResponse,
    currentColId,
    mutate,
    currentPresetIndex,
  ]);

  const handleClose = useCallback(() => {
    console.log("Clearing all temp storage");
    clearTempGrading();
    onClose?.();
    setOpen(false);
  }, [onClose, setOpen]);

  // console.log("Temp Storage Data2:", isColumnRated(currentColId), ratedColumns);

  const isMediumLaptop = useMediaQuery(
    "(min-width: 420px) and (max-width: 1450px)"
  );

  return (
    <DialogWrapper
      open={open}
      setOpen={handleClose}
      title="Table Strip"
      width="lg"
    >
      <Box sx={{ flexGrow: 1, height: "70vh", overflow: "hidden" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ height: "100%", position: "relative" }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                p: "0.5vw",
                borderRight: "1px solid #E5E7EB",
              }}
            >
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#1F2937",
                    mb: 1,
                  }}
                >
                  {currentColId}
                </Typography>
                <Chip
                  label={`t: ${currentResponse?.[currentPresetIndex]?.preset?.temperature}`}
                  size="small"
                  sx={{
                    backgroundColor: "#FBE9D7",
                    border: "1px solid #736AEC1A",
                    color: "#121212B2",
                    mb: 2,
                    height: "3vh",
                    fontSize: "0.8rem",
                    fontWeight: 300,
                    ml: 0,
                  }}
                />
                <Divider
                  sx={{
                    mb: 1,
                  }}
                />
              </Box>
              {Resp?.datasetsDetails?.[0]?.dataSetVersions?.[0]?.storageDetails
                ?.url && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    p: 2,
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #1212121A",
                    borderRadius: "0.5rem",
                    mb: 3,
                    cursor: "pointer",
                    // width: "39.5vw",
                  }}
                  onClick={() =>
                    window.open(
                      Resp?.datasetsDetails?.[0]?.dataSetVersions?.[0]
                        ?.storageDetails?.url,
                      "_blank"
                    )
                  }
                >
                  <img
                    alt="csv-panel"
                    src={Icons.csv}
                    height={"35vh"}
                    width={"35vw"}
                  />
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    {Resp?.datasetsDetails?.[0]?.dataSetVersions?.[0]?.tag}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  mb: 2,
                  pr: 2,
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#E5E7EB",
                    borderRadius: "4px",
                  },
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: 500, mb: 1 }}>
                  Response
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#4B5563",
                    mb: 3,
                    minHeight: "200px",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {currentResponse?.[currentPresetIndex]?.response ||
                    "No response available"}
                </Typography>
              </Box>

              <Box>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <IconButton
                    onClick={handlePreviousPage}
                    disabled={
                      currentColumnIndex === 0 && currentPresetIndex === 0
                    }
                    sx={{
                      color:
                        currentColumnIndex === 0 && currentPresetIndex === 0
                          ? "#E5E7EB"
                          : "#F97316",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <ArrowBack sx={{ fontSize: 20 }} />
                    <Typography
                      sx={{
                        ml: 1,
                        color: "inherit",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Previous
                    </Typography>
                  </IconButton>
                  <IconButton
                    onClick={handleNextPage}
                    disabled={
                      currentColumnIndex === columnIds.length - 1 &&
                      currentPresetIndex === (currentResponse?.length ?? 0) - 1
                    }
                    sx={{
                      color:
                        currentColumnIndex === columnIds.length - 1 &&
                        currentPresetIndex ===
                          (currentResponse?.length ?? 0) - 1
                          ? "#E5E7EB"
                          : "#F97316",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 1,
                        color: "inherit",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Next
                    </Typography>
                    <ArrowForward sx={{ fontSize: 20 }} />
                  </IconButton>
                </Stack>
              </Box>
            </Box>
          </Grid>

          {/* Right Panel */}
          <Grid item xs={12} md={6} sx={{ position: "relative" }}>
            <Box
              sx={{
                borderLeft: "1px solid #E5E7EB",
                height: "100%",
                padding: "0 1vw",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={(e, newValue) => setTabValue(newValue)}
                aria-label="rating tabs"
                sx={{
                  borderBottom: "1px solid #E5E7EB",
                  "& .MuiTab-root": {
                    textTransform: "none",
                    minWidth: "7.5vw",
                    padding: "1vh 1.5vw",
                    marginBottom: "1vh",
                  },
                  "& .Mui-selected": {
                    color: "#FFFFFF !important",
                    backgroundColor: "#F07946",
                    borderRadius: "8px",
                    padding: "0.5vw",
                  },
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              >
                <Tab
                  label={
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: tabValue === 0 ? "#FFFFFF" : "#6B7280",
                      }}
                    >
                      Moderation
                    </Typography>
                  }
                />
                <Tab
                  label={
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: tabValue === 1 ? "#FFFFFF" : "#6B7280",
                      }}
                    >
                      My Rating
                    </Typography>
                  }
                />
                <Tab
                  label={
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: tabValue === 2 ? "#FFFFFF" : "#6B7280",
                      }}
                    >
                      Experts Rating
                    </Typography>
                  }
                />
              </Tabs>

              <TabPanel value={tabValue} index={0}>
                <Typography sx={{ color: "#4B5563", fontSize: "14px" }}>
                  {!currentResponse?.[currentPresetIndex]?.moderation
                    ?.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "12vh",
                      }}
                    >
                      <img
                        alt="grade-no-image"
                        src={Images.PageUnderCon}
                        width={"80%"}
                      />
                      <Typography variant="body1" sx={{ color: "#4B5563" }}>
                        No moderation data available
                      </Typography>
                    </Box>
                  ) : (
                    "No moderation data available"
                  )}
                </Typography>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {gradingLoading ? (
                  <Spinner />
                ) : combinedParams?.length <= 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "12vh",
                    }}
                  >
                    <img
                      alt="grade-no-image"
                      src={Images.PageUnderCon}
                      width={"80%"}
                    />
                    <Typography variant="body1" sx={{ color: "#4B5563" }}>
                      No data available
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        position: "relative",
                        minHeight: "100%",
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 2, fontWeight: 500 }}>
                        Grading parameters
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                          height: "20vh",
                          overflowY: "auto",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": {
                            width: "0.5rem",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#888",
                            borderRadius: "0.25rem",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#555",
                          },
                        }}
                      >
                        {combinedParams?.map((param: any) => (
                          <Box
                            key={param?._id}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 500 }}
                            >
                              {param?.name || "Unnamed Parameter"}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              <Rating
                                value={
                                  userGrading[param._id] || param.UserScore || 0
                                }
                                onChange={(_, newValue) =>
                                  handleRatingChange(param._id, newValue || 0)
                                }
                                readOnly={isColumnRated(
                                  currentColId,
                                  currentResponse?.[currentPresetIndex]
                                    ?.presetId
                                )}
                                icon={<StarIcon style={{ color: "#ECB43C" }} />}
                                emptyIcon={
                                  <StarIcon style={{ color: "#E0E0E0" }} />
                                }
                              />
                            </Box>
                          </Box>
                        ))}
                      </Box>

                      <>
                        <Divider
                          sx={{
                            mt: 1,
                            mb: 1,
                          }}
                        />

                        <Box sx={{ mt: 0 }}>
                          <Typography
                            variant="h6"
                            sx={{ mb: 1, fontWeight: 500 }}
                          >
                            Comments
                          </Typography>
                          <TextField
                            multiline
                            rows={isMediumLaptop ? 6.5 : 10}
                            fullWidth
                            label="Write your comments here..."
                            value={comment}
                            onChange={handleCommentChange}
                            disabled={isColumnRated(
                              currentColId,
                              currentResponse?.[currentPresetIndex]?.presetId
                            )}
                            sx={{
                              ".css-1uhka4x-MuiInputBase-root-MuiOutlinedInput-root":
                                {
                                  maxHeight: "none",
                                  padding: "0.5vw 0.8vw",
                                  borderRadius: "8px",
                                  color: "#12121299",
                                },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#FFFFFF",
                                border: "none !important",
                                color: "#12121299",
                              },
                              ".MuiInputBase-root": {
                                maxHeight: "none",
                                padding: "0.5vw 0.8vw",
                                borderRadius: "8px",
                                color: "#12121299",
                              },
                            }}
                          />
                        </Box>
                      </>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        paddingRight: "1vw",
                        position: "absolute",
                        right: 0,
                      }}
                    >
                      <MultiTypeButton
                        buttonType={ButtonType.Gradient}
                        typeText={
                          isColumnRated(
                            currentColId,
                            currentResponse?.[currentPresetIndex]?.presetId
                          )
                            ? "Already Rated"
                            : "Submit"
                        }
                        actionOnClick={handleSubmit}
                        disabled={
                          isColumnRated(
                            currentColId,
                            currentResponse?.[currentPresetIndex]?.presetId
                          ) || isSubmitting
                        }
                        height={
                          isLargeScreen
                            ? "42px"
                            : isMediumScreen
                            ? "40px"
                            : isSmallScreen
                            ? "38px"
                            : "35px"
                        }
                      />
                    </Box>
                  </>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Box
                  sx={{
                    width: "100%",
                    position: "relative",
                    minHeight: "100%",
                  }}
                >
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                  >
                    {gradingLoading ? (
                      <Spinner />
                    ) : currentResponse?.[currentPresetIndex]?.grading?.filter(
                        (g: any) => g?.type === "Sme"
                      )?.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginTop: "12vh",
                        }}
                      >
                        <img
                          alt="grade-no-image"
                          src={Images.PageUnderCon}
                          width={"80%"}
                        />
                        <Typography variant="body1" sx={{ color: "#4B5563" }}>
                          No grading data available
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          position: "relative",
                          minHeight: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            height: "60vh",
                            overflowY: "auto",
                            scrollbarWidth: "thin",
                            "&::-webkit-scrollbar": {
                              width: "0.5rem",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              backgroundColor: "#888",
                              borderRadius: "0.25rem",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                              backgroundColor: "#555",
                            },
                          }}
                        >
                          {groupedBySme?.map((sme: any, index: number) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="h5"
                                sx={{ mb: 2, fontWeight: 500 }}
                              >
                                {sme?.SmeName}
                              </Typography>
                              <Typography
                                variant="h5"
                                sx={{ mb: 1, fontWeight: 500 }}
                              >
                                Grading parameters
                              </Typography>
                              {sme?.parameters.map(
                                (param: any, idx: number) => (
                                  <Box
                                    key={`param-${idx}`}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      marginBottom: "0.5rem",
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      {param.name || "Unnamed Parameter"}
                                    </Typography>
                                    <Rating
                                      value={param.SmeScore || 0}
                                      max={param.SmeScale || 5}
                                      readOnly
                                      icon={
                                        <StarIcon
                                          style={{ color: "#ECB43C" }}
                                        />
                                      }
                                      emptyIcon={
                                        <StarIcon
                                          style={{ color: "#E0E0E0" }}
                                        />
                                      }
                                    />
                                  </Box>
                                )
                              )}
                              <Divider
                                sx={{
                                  mt: 1,
                                  mb: 1,
                                }}
                              />
                              <Box sx={{ mt: 0 }}>
                                <Typography
                                  variant="h6"
                                  sx={{ mb: 1, fontWeight: 500 }}
                                >
                                  Comments
                                </Typography>
                                <Box
                                  sx={{
                                    backgroundColor: "#FFFFFF",
                                    color: "#12121299",
                                    border: "1px solid #12121226",
                                    minHeight: "24vh",
                                    padding: "0.5vw 0.8vw",
                                    borderRadius: "8px",
                                    mb: 2,
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                  >
                                    {sme?.SmeComment}
                                  </Typography>
                                </Box>
                              </Box>
                              {groupedBySme?.length === index + 1 ? null : (
                                <Divider
                                  sx={{
                                    mt: 3,
                                  }}
                                />
                              )}
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>

                  {/* {currentResponse?.grading?.length === 0 ||
                  combinedParams?.length <= 0 ? null : (
                    <>
                      <Divider
                        sx={{
                          mt: 0,
                          mb: 0,
                        }}
                      />

                      <Box sx={{ mt: 3 }}>
                        <Typography
                          variant="h6"
                          sx={{ mb: 1, fontWeight: 500 }}
                        >
                          Comments
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: "#FFFFFF",
                            color: "#12121299",
                            border: "1px solid #12121226",
                            minHeight: "24vh",
                            padding: "0.5vw 0.8vw",
                            borderRadius: "8px",
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight={400}>
                            {combinedParams?.[0]?.SmeComment}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  )} */}
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </DialogWrapper>
  );
};

export default GradientDialog;
