// Services for DataGrid

import { http } from "../../../app/services";

export const fetchDataGridData = async () => {
  // Write your API call here
};

export const getAllResponses = ({ queryKey }: any) => {
  return http
    .get(`/prompt-responses`, {
      params: queryKey[1],
    })
    .then((res) => res.data);
};

export const getAllStatus = ({ queryKey }: any) => {
  return http.get(`/prompt/status/${queryKey[1]}`).then((res) => res.data);
};

export const getOneJob = ({ queryKey }: any) => {
  const [_, projectId, promptId, versionId] = queryKey;

  const queryString = new URLSearchParams({
    projectId,
    promptId,
    versionId,
  }).toString();

  return http.get(`/jobs?${queryString}`).then((res) => res.data);
};
