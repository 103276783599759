import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Icons } from "../../../assets";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { deleteGradingParameter, getGradingData } from "../services/utility";
import GradingDialogCard from "./gradinfComponents/gradingDialogCard";

const GradingParams = ({
  onValid,
  finalData,
}: {
  onValid: (isValid: boolean) => void;
  finalData: any;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [gradingData, setGradingData] = useState(getGradingData());
  const [showBanner, setShowBanner] = useState(true);
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { control, watch, formState, setValue } = useForm({
    mode: "onChange",
  });

  const handleDelete = (index: number) => {
    deleteGradingParameter(index);
    setGradingData(getGradingData());
  };

  const storedData = localStorage.getItem("GradingParams");
  useEffect(() => {
    if (storedData) {
      const parsedData = JSON.parse(storedData);

      const updatedGradingData = parsedData.map((item: any) => ({
        ...item,
      }));

      setGradingData(updatedGradingData);
      onValid(true);
      finalData(updatedGradingData);
    }
  }, [storedData]);

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        mt: 4,
        "@media (max-width: 1650px)": {
          mt: 2,
        },
        "@media (max-width: 1450px)": {
          mt: 2,
        },
        "@media (max-width: 1280px)": {
          mt: 2,
        },
        "@media (max-width: 960px)": {
          mt: 2,
        },
        "@media (max-width: 768px)": {
          mt: 2,
        },
        "@media (max-width: 480px)": {
          mt: 2,
        },
      }}
    >
      {showBanner && (
        <Box
          sx={{
            backgroundColor: "#FBE9D7",
            color: theme.palette.text.primary,
            position: "fixed",
            top: "30px",
            left: "50%",
            transform: "translateX(-50%)",
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: "10px",
            p: 2,
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: theme.shadows[3],
            width: "40vw",
            zIndex: 1100,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              width: "0.5vw",
              backgroundColor: "#ED9035",
              borderRadius: "5px 0 0 5px",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              paddingLeft: "16px",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
              }}
            >
              Well-defined grading parameters help analyze LLM performance
              across different aspects of responses. This enables better model
              selection and prompt refinement.
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={() => setShowBanner(false)}
            sx={{
              position: "absolute",
              top: "4px",
              right: "4px",
              backgroundColor: "transparent",
              color: theme.palette.text.primary,
              // "&:hover": {
              //   backgroundColor: "#FF8A65",
              //   color: "#fff",
              // },
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      <Box
        width={"93%"}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "20px",
          "@media (max-width: 1650px)": {
            width: "97%",
          },
          "@media (max-width: 1450px)": {
            width: "97%",
          },
          "@media (max-width: 1280px)": {
            width: "97%",
          },
          "@media (max-width: 960px)": {
            width: "97%",
          },
          "@media (max-width: 768px)": {
            width: "97%",
          },
          "@media (max-width: 480px)": {
            width: "97%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            Grading Parameters
          </Typography>
        </Box>
      </Box>

      <Box
        border={`2px solid ${theme.palette.border.primary}`}
        sx={{
          width: "93%",
          p: "10px 0px",
          display: "flex",
          flexDirection: "row",
          borderRadius: "10px",
          mt: 1,
          "@media (max-width: 1650px)": {
            width: "97%",
            p: "0px",
          },
          "@media (max-width: 1450px)": {
            width: "97%",
            p: "0px",
          },
          "@media (max-width: 1280px)": {
            width: "97%",
            p: "0px",
          },
          "@media (max-width: 960px)": {
            width: "97%",
            p: "0px",
          },
          "@media (max-width: 768px)": {
            width: "97%",
            p: "0px",
          },
          "@media (max-width: 480px)": {
            width: "97%",
            p: "0px",
          },
        }}
      >
        <Box
          sx={{
            width: "60%",
            p: "20px 20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "25px",
              mb: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
              }}
            >
              Customize the parameters on which Subject Matter Experts (SMEs)
              will evaluate the LLM responses
            </Typography>
          </Box>
          <Box>
            <LLMCard
              data={gradingData}
              onDelete={(index: number) => handleDelete(index)}
            />
          </Box>
          <Box
            sx={{
              mt: 3,
              width: "280px",
              "@media (max-width: 1650px)": {
                width: "230px",
              },
              "@media (max-width: 1450px)": {
                width: "230px",
              },
              "@media (max-width: 1280px)": {
                width: "230px",
              },
              "@media (max-width: 960px)": {
                width: "200px",
              },
              "@media (max-width: 768px)": {
                width: "190px",
              },
              "@media (max-width: 480px)": {
                width: "190px",
              },
            }}
          >
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              // isLoading={isLoading}

              typeText={"+ Add Custom Parameter"}
              actionOnClick={() => setOpen(true)}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
          <GradingDialogCard open={open} setOpen={setOpen} data={null} />
          <Typography sx={{ fontStyle: "italic", my: "10px" }}>
            Note : All the parameters are evaluated on a scale of 1 (Poor) to 5
            (Excellent)
          </Typography>
        </Box>

        <Box
          sx={{
            width: "40%",
            p: "20px 0px",
            borderLeft: "1px solid #1212121A",
            alignItems: "center",
            justifyContent: "center",
            "@media (max-width: 1650px)": {
              p: "20px 0px",
            },
            "@media (max-width: 1450px)": {
              p: "20px 0px",
            },
            "@media (max-width: 1280px)": {
              p: "20px 0px",
            },
            "@media (max-width: 960px)": {
              p: "20px 0px",
            },
            "@media (max-width: 768px)": {
              p: "20px 0px",
            },
            "@media (max-width: 480px)": {
              p: "20px 0px",
            },
          }}
        >
          <Box
            sx={{
              paddingBottom: "20px",
              paddingLeft: "40px",
              "@media (max-width: 1650px)": {
                paddingBottom: "10px",
                paddingLeft: "20px",
              },
              "@media (max-width: 1450px)": {
                paddingBottom: "10px",
                paddingLeft: "20px",
              },
              "@media (max-width: 1280px)": {
                paddingBottom: "20px",
                paddingLeft: "20px",
              },
              "@media (max-width: 960px)": {
                paddingBottom: "20px",
                paddingLeft: "20px",
              },
              "@media (max-width: 768px)": {
                paddingBottom: "20px",
                paddingLeft: "20px",
              },
              "@media (max-width: 480px)": {
                paddingBottom: "20px",
                paddingLeft: "20px",
              },
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
              }}
            >
              Why Custom Parameters ?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: "20px 40px",
              "@media (max-width: 1650px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "@media (max-width: 1450px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "@media (max-width: 1280px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "@media (max-width: 960px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "@media (max-width: 768px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
              "@media (max-width: 480px)": {
                gap: "10px",
                padding: "10px 20px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              },
            }}
          >
            <Box>
              <img
                alt="expert"
                src={Icons.Grades1}
                width={isMediumScreen ? "40vw" : "50vw"}
                height={isMediumScreen ? "40vw" : "50vw"}
                style={{
                  marginBottom: "5px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                }}
              >
                Expert Grading by SMEs
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 400,
                  marginTop: 1,
                  opacity: 0.5,
                }}
              >
                Let Subject Matter Experts (SME) grade responses based on these
                custom grading params, capturing their expertise.
              </Typography>
            </Box>

            <Box>
              <img
                alt="expert"
                src={Icons.Grades2}
                width={isMediumScreen ? "40vw" : "50vw"}
                height={isMediumScreen ? "40vw" : "50vw"}
                style={{
                  marginBottom: "5px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                }}
              >
                AI-Driven Evaluation with PromptKey
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 400,
                  marginTop: 1,
                  opacity: 0.5,
                }}
              >
                PromptKey's Al Evaluator learns from SME's grading to
                automatically evaluate thousands of responses, saving time and
                effort
              </Typography>
            </Box>

            <Box>
              <img
                alt="expert"
                src={Icons.Grades3}
                width={isMediumScreen ? "40vw" : "50vw"}
                height={isMediumScreen ? "40vw" : "50vw"}
                style={{
                  marginBottom: "5px",
                }}
              />
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 600,
                }}
              >
                Your LLM Performance
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 400,
                  marginTop: 1,
                  opacity: 0.5,
                }}
              >
                Discover which LLMs perform best at specific aspects, helping
                you choose the right model and fine-tune your prompt
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default GradingParams;

export const LLMCard = ({
  index,
  checked,
  onChange,
  value,
  data,
  onDelete,
}: any) => {
  const theme = useTheme();
  const [isChecked, setIsChecked] = useState(checked);
  const [openAPI, setOpenAPI] = useState(false);

  const handleAPI = () => {
    setOpenAPI(true);
  };

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );

  return (
    <Box
      sx={{
        height: "40vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        p: "10px 0px",
        scrollbarWidth: "thin",
        scrollbarColor: "#F07946 #E8E8E8",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#F07946",
          borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#E8E8E8",
          margin: "10px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      }}
    >
      {data.map((param: any, index: number) => (
        <Box
          key={index}
          border="1px solid #12121226"
          bgcolor="#FFFFFF00"
          px="20px"
          py="10px"
          width="100%"
          borderRadius="12px"
          sx={{
            cursor: "pointer",
            gap: "10px",
            "@media (max-width: 1650px)": {
              px: "5px",
              py: "5px",
            },
            "@media (max-width: 1450px)": {
              px: "5px",
              py: "5px",
            },
            "@media (max-width: 1280px)": {
              px: "5px",
              py: "5px",
            },
            "@media (max-width: 960px)": {
              px: "5px",
              py: "5px",
            },
            "@media (max-width: 768px)": {
              px: "5px",
              py: "5px",
            },
            "@media (max-width: 480px)": {
              px: "5px",
              py: "5px",
            },
          }}
        >
          <Box
            gap={2}
            alignItems="center"
            p={"15px 5px"}
            sx={{
              "@media (max-width: 1650px)": {
                p: "5px",
              },
              "@media (max-width: 1450px)": {
                p: "5px",
              },
              "@media (max-width: 1280px)": {
                p: "5px",
              },
              "@media (max-width: 960px)": {
                p: "5px",
              },
              "@media (max-width: 768px)": {
                p: "5px",
              },
              "@media (max-width: 480px)": {
                p: "5px",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Typography variant="h3" sx={{ fontWeight: 600 }}>
                  {param.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  onClick={handleAPI}
                  sx={{
                    bgcolor: "#C75737",
                    p: 2,
                    textAlign: "center",
                    borderRadius: "4px",
                    height: "2vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // border: "1px solid #4BAABE66",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "white",
                      fontWeight: 600,
                    }}
                  >
                    {param.type || "Custom"}
                  </Typography>
                </Box>
                {param.type !== "Default" ? (
                  <img
                    alt="delete"
                    width={
                      isLargeScreen ? "30px" : isMediumScreen ? "20px" : "15px"
                    }
                    height={
                      isLargeScreen ? "30px" : isMediumScreen ? "20px" : "15px"
                    }
                    src={Icons.TrashBin}
                    onClick={() => onDelete(index)}
                  />
                ) : (
                  // Placeholder Box for alignment
                  <Box
                    sx={{
                      width: "30px",
                      height: "30px",
                      "@media (max-width: 1650px)": {
                        width: "22px",
                        height: "22px",
                      },
                      "@media (max-width: 1450px)": {
                        width: "22px",
                        height: "22px",
                      },
                      "@media (max-width: 1280px)": {
                        width: "20px",
                        height: "20px",
                      },
                      "@media (max-width: 960px)": {
                        width: "18px",
                        height: "18px",
                      },
                      "@media (max-width: 768px)": {
                        width: "15px",
                        height: "15px",
                      },
                      "@media (max-width: 480px)": {
                        width: "15px",
                        height: "15px",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                {param.description || "No description available"}
              </Typography>
            </Box>
            {/* <Box
              sx={{
                width: "100%",
                height: "1px",
                bgcolor: "#1212121A",
                my: 1,
                mt: 1,
              }}
            /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  p: "0px 10px",
                  gap: "20px",
                  bgcolor: "#E8E8E8",
                  color: "#22222299",
                  border: "1px solid #1212121A",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 400,
                    lineHeight: "30px",
                    textAlign: "center",
                    textUnderlinePosition: "from-font",
                    textDecorationSkipInk: "none",
                  }}
                >
                  Score : 1 - 5
                </Typography>
              </Box> */}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
