import { Box, Slider, Typography } from "@mui/material";

const SliderSection = ({
  label,
  value,
  onChange,
  max,
  min,
  color,
  step,
}: any) => {
  return (
    <Box
      sx={{
        my: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: -1,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 400,
          }}
        >
          {label}
        </Typography>
        {/* <Box
          sx={{
            bgcolor: "#F8F8F899",
            border: "1px solid #12121226",
            textAlign: "center",
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#12121280",
            height: "22px",
            lineHeight: "22px",
            padding: "0.5px 20px 0px 20px",
            mt: -1.75,
            "@media (max-width: 1450px)": {
              height: "18px",
              lineHeight: "18px",
              mt: -1,
            },
            "@media (max-width: 1280px)": {
              height: "18px",
              lineHeight: "18px",
              mt: -1,
            },
            "@media (max-width: 960px)": {
              height: "18px",
              lineHeight: "18px",
              mt: -1,
            },
            "@media (max-width: 768px)": {
              height: "18px",
              lineHeight: "18px",
              mt: -1,
            },
            "@media (max-width: 480px)": {
              height: "18px",
              lineHeight: "18px",
              mt: -1,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 400,
              
            }}
          >
            {label === "Max Tokens" ? value : value?.toFixed(1)}
          </Typography>
        </Box> */}
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 400,
            color: "#12121280",
            height: "22px",
            lineHeight: "22px",
            padding: "0.5px 20px 0px 20px",
            "@media (max-width: 1650px)": {
              height: "18px",
              lineHeight: "18px",
            },
            "@media (max-width: 1450px)": {
              height: "18px",
              lineHeight: "18px",
            },
            "@media (max-width: 1280px)": {
              height: "18px",
              lineHeight: "18px",
            },
            "@media (max-width: 960px)": {
              height: "18px",
              lineHeight: "18px",
            },
            "@media (max-width: 768px)": {
              height: "18px",
              lineHeight: "18px",
            },
            "@media (max-width: 480px)": {
              height: "18px",
              lineHeight: "18px",
            },
          }}
        >
          {label === "Max tokens" ? value : value?.toFixed(1)}
        </Typography>
      </Box>

      <Slider
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        sx={{
          color: { color },
          "& .MuiSlider-thumb": {
            width: 20,
            height: 20,
          },
          "@media (max-width: 1650px)": {
            mt: -3,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 14,
              height: 14,
            },
          },
          "@media (max-width: 1450px)": {
            mt: -3,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 14,
              height: 14,
            },
          },
          "@media (max-width: 1280px)": {
            mt: -2,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
            },
          },
          "@media (max-width: 960px)": {
            mt: -2,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
            },
          },
          "@media (max-width: 768px)": {
            mt: -2,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
            },
          },
          "@media (max-width: 480px)": {
            mt: -2,
            mb: -2,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
            },
          },
        }}
      />
    </Box>
  );
};

export default SliderSection;
