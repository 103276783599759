import {
  Box,
  Checkbox,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Images } from "../../../../assets";
import { formatNumber, getCurrencySymbol } from "../../services/utility";
import APIDialogCard from "./APIDialogCard";

const LLMCard = ({
  load,
  onCardSelection,
  data,
  selectedCard,
  showSelectedOnly,
  loading,
  nextButton,
  sliderMaxTokens,
  maxTemp,
  maxTokens,
}: any) => {
  console.log("Home:", data);

  const theme = useTheme();
  const [openAPI, setOpenAPI] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);

  const [presets, setPresets] = useState<any[]>([]);
  const [mappedPresets, setMappedPresets] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [localSelectedCards, setLocalSelectedCards] = useState<any[]>([]);

  //   const storedPresets = JSON.parse(localStorage.getItem("presets") || "[]");
  //   useEffect(() => {
  //     if (Array.isArray(storedPresets)) {
  //       setPresets(storedPresets);
  //       setMappedPresets(storedPresets);
  //     }
  //   }, [storedPresets]); use this if needed

  // Function to update presets manually
  const updatePresets = () => {
    const storedPresets = JSON.parse(localStorage.getItem("presets") || "[]");
    setPresets(storedPresets);
    setMappedPresets(storedPresets);
  };

  useEffect(() => {
    updatePresets();
  }, [load]);

  useEffect(() => {
    const storedSelectedCards = JSON.parse(
      localStorage.getItem("ModelSelection") || "[]"
    );

    console.log("Cards:", storedSelectedCards, data);

    if (storedSelectedCards.length > 0) {
      setLocalSelectedCards(storedSelectedCards);

      storedSelectedCards.forEach((storedCard: any) => {
        const matchingModel = data?.find((model: any) => {
          const isMatch = model.versionId === storedCard.versionId;
          return isMatch;
        });
        if (matchingModel) {
          onCardSelection(matchingModel, true);
        }
      });
    }
  }, [data]);

  const handleAPI = (model: any) => {
    setSelectedModel(model);
    setOpenAPI(true);
  };

  const handleCheckboxChange = (checked: boolean, model: any) => {
    if (checked && !model?.isKeyAvailable) {
      const error = `Please add API key for ${model?.providerName} to proceed.`;
      toast.error(error);
      return;
    }

    if (checked && model?.isKeyAvailable && !model?.isModelSupported) {
      const error = `The api key doesn't support the Model.`;
      toast.error(error);
      return;
    }

    if (checked && model?.isModelSupported) {
      // if (checked) {
      const lastSelectedCard = selectedCard[selectedCard.length - 1];

      if (
        lastSelectedCard &&
        !mappedPresets[lastSelectedCard.versionName]?.length
      ) {
        const error = `Please add at least one preset for ${lastSelectedCard?.modelName} (${lastSelectedCard?.versionName}) to continue.`;

        setErrorMessage(error);
        toast.error(error);
        nextButton(error);
        return;
      }
    }

    setErrorMessage(null);
    nextButton(model, checked);
    onCardSelection(model, checked);

    if (checked && model?.maxOutputTokens && model?.temperatureRange) {
      sliderMaxTokens(model?.maxOutputTokens);
      maxTokens(model?.maxOutputTokens);
      maxTemp(model?.temperatureRange);
    }

    const updatedSelectedCards = checked
      ? [...localSelectedCards, model]
      : localSelectedCards?.filter(
          (card) => card.versionName !== model.versionName
        );
    setLocalSelectedCards(updatedSelectedCards);
    localStorage.setItem("selectedCards", JSON.stringify(updatedSelectedCards));
  };

  const filteredData = showSelectedOnly
    ? data?.filter((model: any) =>
        selectedCard.some(
          (selected: any) => selected?.versionName === model?.versionName
        )
      )
    : data;

  const transformedPresets = Array.isArray(presets)
    ? presets.reduce((acc: any, preset: any) => {
        if (preset?.selectedCards) {
          preset.selectedCards.forEach((card: any) => {
            const versionName = card.versionName;
            if (!acc[versionName]) {
              acc[versionName] = [];
            }
            acc[versionName].push(preset);
          });
        }
        return acc;
      }, {})
    : {};

  return (
    <Box
      sx={{
        height: "62vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        p: "0px 0px",
        // scrollbarWidth: "thin",
        // scrollbarColor: "#F07946 #E8E8E8",
        // "&::-webkit-scrollbar": {
        //   width: "5px",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   backgroundColor: "#F07946",
        //   borderRadius: "10px",
        // },
        // "&::-webkit-scrollbar-track": {
        //   backgroundColor: "#E8E8E8",
        //   margin: "10px",
        // },
        // "&::-webkit-scrollbar-thumb:hover": {
        //   backgroundColor: theme.palette.primary.dark,
        // },
      }}
    >
      {loading && <LinearProgress sx={{ marginBottom: "20px" }} />}

      {data?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img src={Images.emptyScreen} alt="emptyModels" width={"50%"} />
        </Box>
      ) : (
        !loading &&
        filteredData?.map((model: any, index: number) => {
          const modelPresets = transformedPresets[model?.versionName] || [];

          return (
            <Box
              key={index}
              border="1px solid #12121226"
              bgcolor="#FFFFFF00"
              px="20px"
              py="10px"
              width="100%"
              borderRadius="12px"
              sx={{
                cursor: "pointer",
                "@media (max-width: 1650px)": {
                  px: "10px",
                  py: "0px",
                },
                "@media (max-width: 1450px)": {
                  px: "10px",
                  py: "0px",
                },
                "@media (max-width: 1280px)": {
                  px: "10px",
                  py: "0px",
                },
                "@media (max-width: 960px)": {
                  px: "10px",
                  py: "0px",
                },
                "@media (max-width: 768px)": {
                  px: "10px",
                  py: "0px",
                },
                "@media (max-width: 480px)": {
                  px: "10px",
                  py: "0px",
                },
              }}
            >
              <Box gap={2} alignItems="center" p={"10px 5px"}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                    }}
                  >
                    <Typography variant="h4" sx={{ fontWeight: 600 }}>
                      {model?.providerName}: {model?.modelName} (
                      {model?.versionName})
                    </Typography>
                    <Box
                      onClick={() =>
                        model?.isKeyAvailable ? null : handleAPI(model)
                      }
                      sx={{
                        bgcolor: model?.isKeyAvailable ? "#88B053" : "#C75737",
                        p: "0px 8px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        textWrap: "nowrap",
                        height: "3vh",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: model?.isKeyAvailable ? "white" : "white",
                          // fontFamily: "Usual",
                        }}
                      >
                        {model?.isKeyAvailable
                          ? "API Key Available"
                          : "API Key Unavailable"}
                      </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Checkbox
                      sx={{
                        ml: 2,
                        "@media (max-width: 900px)": {
                          ml: 1,
                        },
                        "@media (max-width: 1650px)": {
                          mt: -1,
                        },
                        "@media (max-width: 1450px)": {
                          mt: -1,
                        },
                        "@media (max-width: 1280px)": {
                          mt: -1,
                        },
                        "@media (max-width: 960px)": {
                          mt: -1,
                        },
                        "@media (max-width: 768px)": {
                          mt: -1,
                        },
                        "@media (max-width: 480px)": {
                          mt: -1,
                        },
                      }}
                      checked={
                        selectedCard?.some(
                          (card: any) =>
                            card?.versionName === model?.versionName
                        ) ||
                        localSelectedCards.some(
                          (card: any) =>
                            card?.versionName === model?.versionName
                        )
                      }
                      onChange={(e) =>
                        handleCheckboxChange(e.target.checked, model)
                      }
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                    mt: 1,
                    "@media (max-width: 1650px)": {
                      mt: -0.5,
                    },
                    "@media (max-width: 1450px)": {
                      mt: -0.5,
                    },
                    "@media (max-width: 1280px)": {
                      mt: 0,
                    },
                    "@media (max-width: 960px)": {
                      mt: 0,
                    },
                    "@media (max-width: 768px)": {
                      mt: 0,
                    },
                    "@media (max-width: 480px)": {
                      mt: 0,
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,

                      textAlign: "center",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    {formatNumber(model?.contextLength)} Context
                  </Typography>
                  <Box
                    sx={{
                      width: "1px",
                      height: "20px",
                      bgcolor: "#1212121A",
                      my: 1,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      // lineHeight: "30px",
                      textAlign: "center",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    {getCurrencySymbol(model?.currency)}
                    {model?.inputTokenCost}/M input tokens
                  </Typography>
                  <Box
                    sx={{
                      width: "1px",
                      height: "20px",
                      bgcolor: "#1212121A",
                      my: 1,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      // lineHeight: "30px",
                      textAlign: "center",
                      textUnderlinePosition: "from-font",
                      textDecorationSkipInk: "none",
                    }}
                  >
                    {getCurrencySymbol(model?.currency)}
                    {model?.outputTokenCost}/M output tokens
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  {modelPresets?.map((p: any) => (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          height: "1px",
                          bgcolor: "#1212121A",
                          my: 1,
                          mt: 1,
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          p: "0px 10px",
                          gap: "20px",
                          bgcolor: "#F8F8F8",
                          color: "#22222299",
                          border: "1px solid #1212120D",
                          borderRadius: "10px",
                          width: "fit-content",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: 400,
                            textAlign: "center",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                          }}
                        >
                          {p?.temperature} Temperature
                        </Typography>
                        <Box
                          sx={{
                            width: "1px",
                            height: "20px",
                            bgcolor: "#1212121A",
                            my: 1,
                          }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            // lineHeight: "30px",
                            textAlign: "center",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                          }}
                        >
                          {formatNumber(p?.maxTokens)} Output Tokens
                        </Typography>
                      </Box>
                    </>
                  ))}
                </Box>
              </Box>
            </Box>
          );
        })
      )}
      <APIDialogCard open={openAPI} setOpen={setOpenAPI} data={selectedModel} />
    </Box>
  );
};

export default LLMCard;
