// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Cookies from "js-cookie";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useConfirm } from "../../project/components/confirmDialog";
import {
  getAllSmeProjects,
  getOneProject,
} from "../../project/services/ProjectService";
import Spinner from "../../shared/components/Loaders/Spinner";
import PromptSmeViewTab from "./promptComponents/PromptSmeViewTab";
import PromptVersionTable from "./promptComponents/PromptViewVersionTable";

export default function PromptsView() {
  const theme = useTheme();
  const { projectId, datasetId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { control } = useForm();
  const [selectedPromptId, setSelectedPromptId] = useState<string | null>(null);

  const Sme = Cookies.get("Sme");

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: Sme === "false" && !!projectId,
      staleTime: Infinity,
    }
  );

  // const { data: FindOneSmeProject, isLoading: FindOneSmeLoading }: any =
  //   useQuery(
  //     ["sme-projects", projectId],
  //     () => getAllSmeProjects({ projectId }),
  //     {
  //       enabled: Sme === "true" && !!projectId,
  //     }
  //   );

  const { data: FindSmeProject, isLoading: FindSmeLoading }: any = useQuery(
    "sme-projects",
    getAllSmeProjects,
    {
      enabled: Sme === "true",
      staleTime: Infinity,
    }
  );

  const GetAllPrompts =
    Sme === "true" ? FindSmeProject?.data || [] : FindOneProject?.prompts || [];

  const handleDatasetClick = (
    clickedStatus: boolean,
    index: number,
    dataset: any,
    id: string
  ) => {
    // If clickedStatus is true, set the dataset as selected
    setSelectedPromptId(clickedStatus ? id : null);
  };

  if (isLoading || FindSmeLoading) {
    return (
      <Stack height={"90vh"} alignItems={"center"} justifyContent={"center"}>
        <Spinner />
      </Stack>
    );
  }

  return Sme === "true" ? (
    <Box
      sx={{
        flexGrow: 1,
        // "@media (max-width: 1450px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 1280px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 960px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 768px)": {
        //   ml: -2,
        // },
        // "@media (max-width: 480px)": {
        //   ml: -2,
        // },
      }}
    >
      <Grid container spacing={2} sx={{ width: "100%", height: "92vh" }}>
        <Grid
          item
          xs={3}
          sx={{
            borderRight: `1px solid ${theme.palette.border.primary}`,
            mt: 2.5,
          }}
        >
          {Sme === "true" ? (
            <Box
              className="Heading"
              display="flex"
              justifyContent="flex-start"
              alignItems={"center"}
              width="100%"
              padding={2}
              mb={1.5}
              sx={{
                "@media (max-width: 1650px)": {
                  padding: "5px 15px",
                },
                "@media (max-width: 1450px)": {
                  padding: "5px 15px",
                },
                "@media (max-width: 1280px)": {
                  padding: "10px 15px",
                },
                "@media (max-width: 960px)": {
                  padding: "10px 15px",
                },
                "@media (max-width: 768px)": {
                  padding: "10px 15px",
                },
                "@media (max-width: 480px)": {
                  padding: "10px 15px",
                },
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "600" }}>
                Projects
              </Typography>
            </Box>
          ) : null}

          {GetAllPrompts?.map((p: any) => {
            return (
              <>
                <PromptSmeViewTab
                  index={1}
                  noOfVersions="2"
                  // onClick={(clickedStatus, index, dataset, id) =>
                  //   handleCheckboxClick(clickedStatus, id)
                  // }
                  onClick={handleDatasetClick}
                  key={"dataSetView-Sme-checkbox"}
                  control={control}
                  prompt={p}
                  selectedPromptId={selectedPromptId}
                />
              </>
            );
          })}
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box>
      <PromptVersionTable />
    </Box>
  );
}
