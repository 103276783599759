import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Icons } from "../../../assets";
import EditPromptDialog from "../../layout/components/TableUIComponents/EditPromptDialog";
import { getOneProject } from "../../project/services/ProjectService";
import QuillEditorEdit from "../../prompt/components/promptcreationComponents/QuillEdit";
import DataGrid from "../../shared/components/Grid/DataGrid";
import { getAllResponses, getOneJob } from "../services/DataGridService";

export default function ResponseViewSetup() {
  const ref = useRef("");
  const { projectId, promptId, versionId } = useParams();
  // const [versionId, setVersion] = useState("");
  const [loading, setLoading] = useState(false);
  // const [status, SetStatus] = useState("inprogress");
  const [data, setData] = useState<any>();
  const [limit, setLimit] = useState<number>(5);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open3, setOpen3] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  // const { data: statusDetails, isLoading: statusLoading }: any = useQuery(
  //   ["prompt-responses", projectId, promptId],
  //   getAllStatus
  // );

  const { data: jobDetails, isLoading: jobLoading }: any = useQuery(
    ["jobs", projectId, promptId, versionId],
    getOneJob
  );

  const filteredJobData = useMemo(() => {
    if (!jobDetails?.data) return null;

    const filteredByPrompt = jobDetails?.data?.filter(
      (p: any) => p?.promptId === promptId
    );

    const filteredByVersion = filteredByPrompt?.filter(
      (p: any) => p?.versionId === versionId
    );

    if (!filteredByVersion || filteredByVersion.length === 0) return null;

    const sortedJobs = filteredByVersion.sort(
      (a: any, b: any) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );

    return sortedJobs[0];
  }, [jobDetails?.data, promptId, versionId]);

  const { data: FindResponse, isLoading: responseLoading }: any = useQuery(
    [
      "prompt-responses",
      {
        projectId: projectId,
        promptId: promptId,
        versionId: versionId,
        limit: limit,
        offset: offset,
      },
    ],
    getAllResponses,
    {
      enabled: !!versionId && filteredJobData?.state === "Done",
      onSuccess: (res: any) => {
        console.log("totals:", res);

        setLoading(false);
        // setTotalCount(res?.total || 0);
        setTotalCount(res?.analytics?.recordsCount || 0);
      },
    }
  );

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  /**
   * this is dummy
   */

  useEffect(() => {
    setOpen3(isEditable);
  }, [isEditable]);

  const prompt = FindOneProject?.prompts.find((p: any) => p?._id === promptId);
  const version = prompt?.versions?.find((v: any) => v?._id === versionId);
  // console.log("Versions Check:", version);

  // const dataSetVersionsOptions =
  //   version?.datasets?.[0]?.datasetVersionIds.flatMap((v: any) => v) || [];

  const paramsOptions =
    version?.datasets?.[0]?.params?.map((param: any) => ({
      label: param,
      value: param,
    })) || [];

  const transformedArray = FindResponse?.responses?.map((item: any) => ({
    DatasetRecord: item?.DatasetRecord,
    models: item?.models?.map((m: any) => {
      console.log("midon", m);
      return {
        model: m?.model,
        response: m?.responses,
      };
    }),
  }));

  const outputArray = transformedArray?.map((input: any) => {
    const output: any = {
      DatasetRecord: input?.DatasetRecord,
    };

    input?.models?.forEach((modelObj: any) => {
      const providerName = modelObj?.model?.providerName;
      const versionName = modelObj?.model?.versionName;
      const key = `${providerName}/${versionName}`;
      output[key] = { response: modelObj?.response };
    });

    return output;
  });

  const convertToCSV = (data: any) => {
    const headers = Object.keys(data[0]).join(",") + "\n";

    const rows = data
      ?.map((row: any) => {
        return Object.values(row)
          ?.map((value) => {
            if (typeof value === "object" && value !== null) {
              return `"${JSON.stringify(value)}"`;
            }
            return `"${value}"`;
          })
          .join(",");
      })
      .join("\n");

    return headers + rows;
  };

  const downloadCSV = () => {
    if (!outputArray || outputArray.length === 0) {
      alert("No data to export!");
      return;
    }

    const csvData = convertToCSV(outputArray);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `responses.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (filteredJobData) {
      setLoading(filteredJobData.state === "inprogress");
    }
  }, [filteredJobData?.state]);

  const handleChange = useCallback((quillText: string) => {
    localStorage.setItem("Modified Description", quillText);
  }, []);

  const modifiedData = outputArray?.map((obj: any) => {
    const newObj: any = {};
    for (const [key, value] of Object?.entries(obj)) {
      const newKey = key?.replace(/\./g, "_");
      newObj[newKey] = value;
    }
    return newObj;
  });

  // console.log("outputArray", modifiedData?.length);
  // console.log("outputArray :", modifiedData);
  // console.log("outputArrayssss :", outputArray);

  // Pass setIsEditable as a prop to QuillEditorEdit
  // const handleQuillEditorChange = useCallback((quillText: string) => {
  //   localStorage.setItem("Modified Description", quillText);
  // }, []);

  const handlePaginationChange = useCallback(
    (newLimit: number, newOffset: number) => {
      setLimit(newLimit);
      setOffset(newOffset);
    },
    []
  );

  if (jobLoading) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Stack>
        <Box sx={{ mx: "0px", my: "0px" }}>
          <>
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Stack direction={"row"}>
                  <Typography variant="h3">Prompt Title:</Typography>
                  <Typography variant="h3">{data?.title}</Typography>
                </Stack>
                <img src={Icons.llmLoader} width={"20%"} alt="" />

                {/* {status === "Done" ? (
                  <>
                    {" "}
                    <Typography variant="h4">
                      Response Generation Status: {status}, Please Wait Response
                      is Loading...........
                    </Typography>
                  </>
                ) : (
                  <> */}
                <Typography variant="h4">
                  Response Generation Status: In Progress, Please come after
                  some time...........
                </Typography>
                {/* </>
                )} */}
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: "0 2.5vw",
                    mt: 0
                  }}
                >
                  <QuillEditorEdit
                    border={false}
                    height="13vh"
                    defaultHtml={version?.datasets?.[0]?.description}
                    placeholder="Enter user message"
                    onChange={(quillText: string) => handleChange(quillText)}
                    mentions={[
                      {
                        mentionChar: "{",
                        values: paramsOptions,
                      },
                    ]}
                    setIsEditable={setIsEditable}
                  />
                </Box>

                <DataGrid
                  rowsData={modifiedData}
                  loading={loading || responseLoading}
                  setLimit={setLimit}
                  setOffset={setOffset}
                  totalCount={totalCount}
                  currentLimit={limit}
                  currentOffset={offset}
                  onPaginationChange={handlePaginationChange}
                />

                <EditPromptDialog
                  open={open3}
                  setOpen={setOpen3}
                  prompt={prompt}
                  version={version}
                />
              </Box>
            )}
          </>

          {/* <QuillEditorWithMentions
            defaultHtml={ref.current}
            onChange={(quillText: string) => {
              ref.current = quillText;
            }}
            placeholder="Please enter text"
            key={"Quill-mentions"}
            mentions={[
              {
                mentionChar: "@",
                values: [{ id: 1, value: "varma" }],
              },
            ]}
          /> */}

          {/* {true ? (
            <>
              {" "}
              <Button
                sx={{
                  height: "40px",
                  width: "100px",
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#F07946",
                  marginTop: "10px",
                }}
                variant="outlined"
              >
                Re-run
              </Button>
            </>
          ) : (
            <>
              {" "}
              <Button
                sx={{
                  width: "100px",
                  height: "40px",
                  fontSize: "14px",
                  color: "white",
                  backgroundColor: "#F07946",
                }}
                variant="outlined"
              >
                Run
              </Button>
            </>
          )} */}
        </Box>
        {/* {loading || responseLoading || isLoading ? (
          <>
            <Box>
              <Stack
                direction={"row"}
                py={"10px"}
                mx={"20px"}
                gap={"20px"}
                height={"200px"}
              >
                <Typography variant="h3">Prompt Title:</Typography>
                <Typography variant="h3">{data?.title}</Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner />
            </Box>
          </>
        ) : (
         
        )} */}
      </Stack>
    </Box>

    // <>
    //   <Stack
    //     direction={"row"}
    //     py={"10px"}
    //     mx={"20px"}
    //     gap={"20px"}
    //     height={"200px"}
    //   >
    //     <Typography variant="h3">Prompt Title:</Typography>
    //     <Typography variant="h3">{data?.title}</Typography>
    //   </Stack>
    //   {loading && (
    //     <Typography sx={{ mx: "20px" }} variant="h2">
    //       Data Generation In Progress.......
    //     </Typography>
    //   )}
    //   <Box
    //     sx={{
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)",
    //       textAlign: "center",
    //     }}
    //   >
    //     {loading ? (
    //       <CircularProgress color="primary" />
    //     ) : (
    //       <>
    //         <MultiTypeButton
    //           buttonType={ButtonType.Gradient}
    //           typeText={"Download CSV"}
    //           actionOnClick={downloadCSV}
    //           key={"Download CSV"}
    //         />

    //         <Box>
    //           <Typography></Typography>
    //           <Typography>
    //             Total Number of Rows {outputArray?.length}
    //           </Typography>
    //         </Box>
    //       </>
    //     )}
    //   </Box>
    // </>
  );
}
