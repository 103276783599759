import { Box, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";

import { yupResolver } from "@hookform/resolvers/yup";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { getOneUser } from "../../layout/services/LayoutService";
import { createAPIKey, getAllModels } from "../../prompt/services";
import { handleError } from "../../shared/components/Errors/HandleError";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { StyledContainer, StyledContainerSub } from "../styles/apiKeysStyles";
import { decryptAPIKey } from "../utils/utils";
import ApiKeysCard from "./ApiKeysCard";

const validationSchema = yup.object({
  apiProvider: yup.string().required("AI Provider is required"),
  apiKey: yup.string().required("API Key is required"),
  tier: yup.string().required("Tier is required"),
});

interface FormData {
  aiProvider: string;
  apiKey: string;
}
const APIKeys = () => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [savedItems, setSavedItems] = useState<FormData[]>([]);
  const [userId, setUserId] = useState("");

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      apiProvider: "",
      apiKey: "",
      tier: "",
    },
  });
  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });

  const { data: GetModels } = useQuery("all-models", getAllModels);
  // console.log("All Models:", GetModels);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  const { mutate, isLoading } = useMutation(createAPIKey, {
    onSuccess: () => {
      toast.success("API Key Added  Successfully");
      queryClient.invalidateQueries("users");
    },
    onError: (err) => handleError(err),
  });

  const Options = useMemo(() => {
    return (
      GetModels?.dropdowns?.map((i: any) => ({
        label: i?.provider,
        value: i?._id,
        tier: i?.models?.[0]?.versions?.[0]?.tier,
      })) || []
    );
  }, [GetModels?.dropdowns]);

  const API = watch("apiProvider");

  const FilteredTier = Options.filter((f: any) => f?.value == API)?.[0]?.tier;
  console.log("Option:", FilteredTier);

  const Tier =
    FilteredTier?.map((i: any) => ({
      label: i,
      value: i,
    })) || [];

  useEffect(() => {
    if (Options) {
      setValue("apiProvider", Options?.[0]?.value);
      setValue("tier", Options?.[0]?.tier?.[0]);
    }
  }, [Options, setValue]);

  const onSubmit = (data: any) => {
    const mutationData = {
      providerId: data?.apiProvider,
      apiKey: data?.apiKey,
      tier: data?.tier,
    };
    mutate(mutationData);
    reset();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 2,
      }}
    >
      {/* <Typography
        variant="h3"
        fontWeight="600"
        sx={{
          width: "90%",
          textAlign: "left",
          mb: 2,
        }}
      >
        API Keys
      </Typography> */}
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box display="flex" flexDirection={"row"} justifyContent="flex-end">
            {/* <SearchContainer
              debounced={true}
              onChange={setSearch}
              value={search}
            /> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <MultiTypeButton
                buttonType={ButtonType.Gradient}
                typeText={"Add custom model"}
                actionOnClick={() => true}
                height={
                  isLargeScreen ? "5vh" : isMediumScreen ? "4vh" : "3.5vh"
                }
              />
              {/* <AddProjectForm open={open} setOpen={setOpen} data={null} /> */}
            </Box>
          </Box>

          <StyledContainerSub>
            <Stack direction={"row"} gap={"15px"}>
              <SingleSelect
                control={control}
                required
                name="apiProvider"
                label="AI Provider"
                options={Options}
                defaultValue=""
                placeholder="Select a provider"
                defaultData={Options?.[0]?.value}
                width="40vw"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
              <SingleSelect
                control={control}
                required
                name="tier"
                label="Tier"
                options={Tier}
                defaultData={Tier?.[0]?.value}
                placeholder="Tier"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </Stack>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <TextField
                formateType={Formate.EmailAddress}
                control={control}
                label="API Key"
                name="apiKey"
                required
                placeholder="Enter api key"
                backgroundColor="#F8F8F8"
                width="40vw"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  mt: 3,
                }}
              >
                <MultiTypeButton
                  buttonType={ButtonType.Gradient}
                  // isLoading={isLoading}
                  typeText={"Save"}
                  width={
                    isLargeScreen
                      ? "192px"
                      : isMediumScreen
                      ? "115px"
                      : isSmallScreen
                      ? "61px"
                      : "192px"
                  }
                  height={
                    isLargeScreen
                      ? "42px"
                      : isMediumScreen
                      ? "40px"
                      : isSmallScreen
                      ? "38px"
                      : "35px"
                  }
                  actionOnClick={handleSubmit(onSubmit)}
                />
              </Box>
            </Box>
          </StyledContainerSub>
          <Box
            sx={{
              height: "40vh",
              overflowY: "auto",
              mt: 1.5,
            }}
          >
            {FindOneUser?.data?.organization?.apiConfiguration.map(
              (item: any, index: any) => {
                const decryptedApiKey = decryptAPIKey(
                  item.apiKey,
                  process.env.API_KEYS_ENCRYPTION_KEY
                );

                return (
                  <StyledContainerSub key={index} sx={{ mb: 2 }}>
                    {/* <Typography variant="h6">
                    Saved API Key {index + 1}
                  </Typography> */}
                    <ApiKeysCard
                      index={index}
                      item={item}
                      key={item.providerId}
                      providerId={item.providerId}
                      options={Options}
                    />
                  </StyledContainerSub>
                );
              }
            )}
          </Box>
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default APIKeys;
