import {
  Box,
  TablePagination,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Icons, Images } from "../../../../assets";
import { UIStore } from "../../../../store/general";
import "../../styles/Shared.module.css";
import {
  StyledCheckbox,
  StyledPaginationContainer,
  StyledTable,
  StyledTableContainer,
} from "../../styles/Table.styles";
import SkeletonTable from "../Loaders/SkeltonLoading";

const ITEMS_PER_PAGE_OPTIONS = [5, 10, 20, 25, 50, 100, 500];

export type ColumnType = {
  key: string;
  title: string;
  render?: (item: any) => React.ReactElement | string | null;
  hide?: boolean;
  default?: boolean;
  width?: string;
  sortable?: boolean;
  sortKey?: string;
};

type PaginationType = {
  totalCount: number;
  page: number;
  setPage: (page: number) => void;
  pageCount: number;
  setPageCount?: (pageCount: number) => void;
};

type SelectionType = {
  selected: any[];
  setSelected: (selected: any[]) => void;
};

interface TableProps {
  columns: Array<ColumnType>;
  sx?: any;
  data: any[];
  setSortHeader?: any;
  loading?: boolean;
  is_Id?: boolean;
  rank?: boolean;
  onRowClick?: (v: any) => void;
  pagination?: PaginationType;
  selection?: SelectionType;
  onPageChangeCallback?: (paginationData: {
    offset: number;
    limit: number;
  }) => void;
  menuItems?: { label: string; action: string }[];
  onMenuItemClick?: (action: string) => void;
  onRowSelectionChange?: any;
  renderButtons?: any;
  onViewClick?: any;
  preSelectedRows?: any[];
}

function Table(props: TableProps) {
  const isDarkMode = UIStore.useState((s) => {
    return s.isDarkMode;
  });
  const theme = useTheme();
  const {
    columns,
    data: initialData,
    sx,
    pagination,
    is_Id = false,
    rank = false,
    loading = false,
    setSortHeader,
    onRowClick,
    onPageChangeCallback,
    menuItems,
    onMenuItemClick,
    selection,
    onRowSelectionChange,
    renderButtons,
    onViewClick,
    preSelectedRows = [],
  } = props;

  const [selected, setSelected] = useState<any[]>([]);
  const [data, setData] = useState<any[]>(initialData);
  const [sortBy, setSortBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    if (preSelectedRows?.length > 0) {
      setSelected(preSelectedRows);
      selection?.setSelected(preSelectedRows);
    }
  }, [preSelectedRows]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelected(data);
      selection?.setSelected(data);
      onRowSelectionChange?.(data);
    } else {
      setSelected([]);
      selection?.setSelected([]);
      onRowSelectionChange?.([]);
    }
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    e.stopPropagation();

    const isSelected = selected.some((selectedItem) => {
      return is_Id
        ? selectedItem?._id === item?._id
        : selectedItem?.id === item?.id;
    });

    if (e.target.checked && !isSelected) {
      // Add the item to the selection
      const newSelection = [...selected, item];
      setSelected(newSelection);
      selection?.setSelected(newSelection);
      onRowSelectionChange?.(newSelection);
    } else if (!e.target.checked && isSelected) {
      // Remove the item from the selection
      const updatedSelected = selected.filter((selectedItem) =>
        is_Id ? selectedItem?._id !== item?._id : selectedItem?.id !== item?.id
      );
      setSelected(updatedSelected);
      selection?.setSelected(updatedSelected);
      onRowSelectionChange(updatedSelected);
    }
  };

  const handleSort = (sortKey: string | any) => {
    if (sortBy === sortKey) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortBy(sortKey);
      setSortOrder("asc");
    }
    setSortHeader({ sort: sortKey, sortBy: sortOrder });
  };

  const handleRowClick = (item: any) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    if (pagination) {
      const newOffset = page * pagination?.pageCount;
      pagination.setPage(page);
      selection?.setSelected([]);
      if (onPageChangeCallback) {
        onPageChangeCallback({
          offset: newOffset,
          limit: pagination.pageCount,
        });
      }
    }
  };

  const handleRowsPerPageChange = (e: any) => {
    if (pagination?.setPageCount) {
      pagination?.setPageCount(+e.target.value);
      setSelected([]);
    }
  };

  const isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(max-width: 1290px)");

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");

  return (
    <>
      <StyledTableContainer sx={sx}>
        <StyledTable>
          <thead>
            <tr>
              <th
                style={{
                  width: "50px",
                  padding: isMediumLaptop
                    ? "3px 3px"
                    : isMediumLaptop2
                    ? "3px 3px"
                    : "10px 10px",
                }}
              >
                <StyledCheckbox
                  onChange={handleSelectAll}
                  checked={
                    selected?.length === data?.length && selected.length > 0
                  }
                />
              </th>

              {rank && <th style={{ width: "auto" }}>Rank</th>}
              {columns.map((item, index) => {
                const padLeft =
                  item.key === "versionNumber" ||
                  item.key === "params" ||
                  item.key === "version-tag" ||
                  item.key === "params-table-ui" ||
                  item.key === "version-tag-table-ui";

                const padWidth =
                  item.key === "noOfRecords" ||
                  item.key === "noOfRecords-table-ui";

                if (item.hide) return null;
                return (
                  <th
                    key={index}
                    style={{
                      width: item.width || "auto",
                      padding: padLeft
                        ? "5px 0px"
                        : padWidth
                        ? isMediumLaptop
                          ? "5px 0px 5px 50px"
                          : "5px 0px 5px 100px"
                        : "5px 20px",
                    }}
                    onClick={() => item.sortable && handleSort(item.sortKey)}
                  >
                    {item.title}
                    {item.sortable && sortBy === item.sortKey && (
                      <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <SkeletonTable />
            ) : (
              <>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      borderBottom:
                        index === data.length - 1
                          ? "1px solid #1212121A"
                          : isDarkMode
                          ? "1px solid rgba(255, 255, 255, 0.1)"
                          : "1px solid rgba(18, 18, 18, 0.1)",
                      backgroundColor: "transparent",
                    }}
                  >
                    <td
                      style={{
                        width: "50px",
                        padding: isMediumLaptop
                          ? "3px 3px"
                          : isMediumLaptop2
                          ? "3px 3px"
                          : "10px 10px",
                      }}
                    >
                      <StyledCheckbox
                        onChange={(e) => handleSelect(e, item)}
                        checked={
                          selected.findIndex(
                            (selectedItem) => selectedItem?._id === item?._id
                          ) !== -1
                        }
                      />
                    </td>

                    {rank && (
                      <td style={{ width: "50px", textAlign: "center" }}>
                        <Typography
                          variant="h4"
                          sx={{
                            backgroundColor: "rgba(255, 79, 61, 1)",
                            color: "black",
                            textAlign: "center",
                            borderRadius: "50%",
                            width: "25px",
                            opacity:
                              index === 0
                                ? "80%"
                                : index === 1
                                ? "70%"
                                : index === 2
                                ? "60%"
                                : "50%",
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </td>
                    )}
                    {columns.map((col, colIndex) => {
                      if (col.hide) return null;

                      const isViewColumn =
                        col.key === "title" ||
                        col.key === "tag" ||
                        col.key === "useCase" ||
                        col.key === "createdAt" ||
                        col.key === "user.name" ||
                        col.key === "prompt-title" ||
                        col.key === "category" ||
                        col.key === "number" ||
                        col.key === "llm-models" ||
                        col.key === "datasetLength" ||
                        col.key === "responses" ||
                        col.key === "grading";

                      const isIcon =
                        col.key === "datasetTitle" || col.key === "name";

                      const padLeft =
                        col.key === "params" ||
                        col.key === "version-tag" ||
                        col.key === "params-table-ui" ||
                        col.key === "version-tag-table-ui";

                      const padWidth =
                        col.key === "noOfRecords" ||
                        col.key === "noOfRecords-table-ui";

                      const newPad = col.key === "category";

                      return (
                        <td
                          key={colIndex}
                          onClick={
                            onViewClick && isViewColumn
                              ? () => onViewClick(item)
                              : undefined
                          }
                          className={
                            onViewClick && isViewColumn
                              ? "viewColumn"
                              : undefined
                          }
                          style={{
                            padding: padLeft
                              ? "5px 0px 5px 0px"
                              : padWidth
                              ? isMediumLaptop
                                ? "5px 0px 5px 80px"
                                : "5px 0px 5px 120px"
                              : newPad
                              ? "5px 0px 5px 0px"
                              : "5px 20px",
                          }}
                        >
                          {col?.render ? (
                            isIcon ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "7px",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <img
                                  src={Icons.csv}
                                  alt="csv"
                                  width={
                                    isLargeScreen
                                      ? "22px"
                                      : isMediumLaptop
                                      ? "18px"
                                      : "16px"
                                  }
                                  height={
                                    isLargeScreen
                                      ? "22px"
                                      : isMediumLaptop
                                      ? "18px"
                                      : "16px"
                                  }
                                />
                                <Typography
                                  variant="h6"
                                  fontWeight="500"
                                  sx={{
                                    letterSpacing: 0.2,
                                  }}
                                >
                                  {col.render(item)}
                                </Typography>
                              </Box>
                            ) : (
                              <Typography
                                variant="h6"
                                fontWeight="500"
                                sx={{
                                  letterSpacing: 0.2,
                                }}
                              >
                                {col.render(item)}
                              </Typography>
                            )
                          ) : (
                            <Typography
                              variant="h6"
                              fontWeight="500"
                              sx={{
                                letterSpacing: 0.2,
                              }}
                            >
                              {_.get(item, col.key)}
                            </Typography>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </StyledTable>

        {!loading && !data.length ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: "0px 10px",
            }}
          >
            <img
              src={Images.emptyScreen}
              alt="No Data"
              style={{ height: "20vh", marginTop: "2vh" }}
            />
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              No Data
            </Typography>
          </Box>
        ) : null}

        {pagination && (
          <StyledPaginationContainer>
            <Box px={2} mt={1} justifyContent="flex-end" display="flex">
              <TablePagination
                component="div"
                count={pagination.totalCount || 20}
                page={pagination.page || 0}
                onPageChange={handlePageChange}
                rowsPerPageOptions={ITEMS_PER_PAGE_OPTIONS}
                rowsPerPage={pagination.pageCount || 20}
                onRowsPerPageChange={handleRowsPerPageChange}
                sx={{
                  "@media (max-width: 1650px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },
                  "@media (max-width: 1450px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },
                  "@media (max-width: 1280px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },
                  "@media (max-width: 960px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },
                  "@media (max-width: 768px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },
                  "@media (max-width: 480px)": {
                    "& .MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                    },
                  },

                  "& .MuiInputBase-root.MuiTablePagination-input": {
                    width: "auto",
                  },
                }}
              />
            </Box>
          </StyledPaginationContainer>
        )}

        {/* {loading && (
          <StyledTableLoader>
            <CircularProgress color="primary" />
            <Box className="loader"></Box>
          </StyledTableLoader>
        )} */}
      </StyledTableContainer>
    </>
  );
}

export default Table;
