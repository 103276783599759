import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Icons } from "../../../../assets";

const CustomHeader = (
  { column, onLockColumn, onColumnPin, onToggleVisibility, hiddenColumns }: any,
  rowData: any,
  setPresetData: any,
  columnOrder: Record<string, number>
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isFirstColumn = column.getColId() === "DatasetRecord";

  const getPresetData = () =>
    JSON.parse(localStorage.getItem("presetData") || "[]");

  const findSelectedPreset = (presets: any[]) =>
    presets.find((p: any) => p?.columnId === column.getColId());

  const updatePresetData = (presetId: string) => {
    const presets = rowData[0]?.[`${column.getColId()}`]?.response || [];
    const presetIndex = presets.findIndex(
      (preset: any) => preset?.presetId === presetId
    );
    const presetData = presets.find(
      (preset: any) => preset?.presetId === presetId
    );

    setPresetData((prevState: any) => {
      const newState = Array.isArray(prevState) ? [...prevState] : [];
      const existingIndex = newState.findIndex(
        (item) => item.columnId === column.getColId()
      );

      const savedOrder = localStorage.getItem("column-order");
      const currentOrder = savedOrder ? JSON.parse(savedOrder) : columnOrder;

      const newEntry = {
        columnId: column.getColId(),
        presetId,
        presetIndex,
        temperature: presetData?.preset?.temperature,
        order: currentOrder[column.getColId()] ?? existingIndex,
      };

      if (existingIndex !== -1) {
        newState[existingIndex] = newEntry;
      } else {
        newState.push(newEntry);
      }

      newState.sort((a, b) => {
        const orderA = currentOrder[a.columnId] ?? Infinity;
        const orderB = currentOrder[b.columnId] ?? Infinity;
        return orderA - orderB;
      });

      localStorage.setItem("presetData", JSON.stringify(newState));
      return newState;
    });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handlePresetChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updatePresetData(event.target.value);

  const handleColumnAction = (action: () => void) => {
    action();
    handleMenuClose();
  };

  // Action Handlers
  const handleToggleLock = () =>
    handleColumnAction(() =>
      onLockColumn(column.getColId(), !column.getColDef().editable)
    );

  const handlePinLeft = () =>
    handleColumnAction(() => onColumnPin(column.getColId(), "left"));

  const handlePinRight = () =>
    handleColumnAction(() => onColumnPin(column.getColId(), "right"));

  const handleToggleVisibility = () =>
    handleColumnAction(() => onToggleVisibility(column.getColId()));

  const retrievedPresetData = getPresetData();
  const selectedPreset = findSelectedPreset(retrievedPresetData);
  const isHidden = hiddenColumns.includes(column.getColId());

  if (column.getColId() === "Google/gemini-1_5-flash-8b-001") {
    console.log("Data", rowData[0]?.[`${column.getColId()}`]?.response);
  }

  // console.log("apple is good", column?.getColId());

  return (
    <Stack
      direction="row"
      justifyContent={isFirstColumn ? "center" : "space-between"}
      alignItems="center"
      sx={{
        width: "100%",
        cursor: isFirstColumn ? "default" : "pointer",
        border: "2px solid transparent",
        paddingLeft: isFirstColumn ? "0" : "0.4rem",
      }}
    >
      <Stack
        direction="row"
        gap="5px"
        justifyContent="center"
        alignItems="center"
      >
        {!isFirstColumn && column?.getColId()?.endsWith("_v1") && (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              padding: "2px 10px",
              backgroundColor: "#FFF1E7",
              color: "#F97316",
              border: "1px solid #F07946",
              borderRadius: "5px",
              "@media (max-width: 1650px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 1450px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 1280px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 960px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 768px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 480px)": {
                padding: "2px 4px",
              },
            }}
          >
            <Typography variant="body1">V1</Typography>
          </Box>
        )}
        {!isFirstColumn && column?.getColId()?.endsWith("_v2") && (
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              padding: "2px 10px",
              backgroundColor: "#FFF1E7",
              color: "#F07946",
              border: "1px solid #F07946",
              borderRadius: "5px",
              "@media (max-width: 1650px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 1450px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 1280px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 960px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 768px)": {
                padding: "2px 4px",
              },
              "@media (max-width: 480px)": {
                padding: "2px 4px",
              },
            }}
          >
            <Typography variant="body1">V2</Typography>
          </Box>
        )}
        <Typography sx={{ color: "#121212CC" }} variant="body1">
          {isFirstColumn
            ? "Dataset record"
            : column
                ?.getColId()
                ?.replace("_v1", "")
                .replace("_v2", "")
                .replace("/", ": ")}
        </Typography>

        {column?.colDef.lockPosition && !isFirstColumn && (
          <img
            src={Icons.lock}
            width="15px"
            height="15px"
            alt=""
            style={{ marginRight: "15px" }}
          />
        )}
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"5px"}
        ml={0.5}
      >
        {!isFirstColumn && (
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"5px"}
            sx={{
              padding: "5px 10px",
              bgcolor: "#FFF1E0",
              borderRadius: "5px",
              "@media (max-width: 1650px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 1450px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 1280px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 960px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 768px)": {
                padding: "5px 10px",
              },
              "@media (max-width: 480px)": {
                padding: "5px 10px",
              },
            }}
          >
            <Typography variant="body1" color="grey">
              t:
            </Typography>
            <Typography variant="body1" color="grey">
              {selectedPreset?.temperature ??
                rowData[0]?.[`${column.getColId()}`]?.response?.[0]?.preset
                  ?.temperature}
            </Typography>
          </Stack>
        )}

        {!isFirstColumn && (
          <Box>
            <IconButton
              onClick={handleMenuOpen}
              sx={{
                padding: 0,
                mt: -0.7,
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  width: "2.2vw",
                  height: "5.5vh",
                }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Box
                padding={"15px"}
                width={"auto"}
                sx={{
                  color: "#1D1F25",
                }}
              >
                <Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      ml: 1.2,
                    }}
                  >
                    Preset Configurations
                  </Typography>
                  <RadioGroup
                    sx={{ mx: "10px", padding: "8px", gap: "10px" }}
                    value={
                      selectedPreset?.presetId ??
                      rowData[0]?.[`${column.getColId()}`]?.response?.[0]
                        ?.presetId
                    }
                    name="preset-options"
                    onChange={handlePresetChange}
                  >
                    {rowData[0]?.[`${column.getColId()}`]?.response
                      ?.filter(
                        (item: any, index: number, self: any[]) =>
                          self.findIndex(
                            (i) => i.presetId === item.presetId
                          ) === index
                      )
                      ?.map((item: any) => (
                        <FormControlLabel
                          key={item.presetId}
                          value={item.presetId}
                          control={
                            <Radio
                              size="small"
                              sx={{ padding: "0px", marginRight: "6px" }}
                            />
                          }
                          label={`${item.preset.name} : t: ${item.preset.temperature} | max tokens: ${item.preset.maxTokens}`}
                        />
                      ))}
                  </RadioGroup>
                </Stack>
                <Divider sx={{ mx: "2px" }} />
                <MenuItem onClick={handlePinLeft} sx={{ padding: "0px" }}>
                  <Stack direction={"row"} gap={"10px"} padding={"8px"}>
                    <img src={Icons.pin} alt="" width={"15px"} />
                    <Typography variant="body1">Pin to Left</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handlePinRight} sx={{ padding: "0px" }}>
                  <Stack direction={"row"} gap={"10px"} padding={"8px"}>
                    <img
                      src={Icons.pin}
                      alt=""
                      width={"15px"}
                      style={{ transform: "rotate(270deg)" }}
                    />
                    <Typography variant="body1">Pin to Right</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleToggleLock} sx={{ padding: "0px" }}>
                  <Stack
                    gap={"10px"}
                    direction={"row"}
                    width={"100%"}
                    padding={"8px"}
                  >
                    {column?.colDef.lockPosition ? (
                      <>
                        <LockOpenIcon sx={{ fontSize: "18px" }} />
                        <Typography variant="body1">Unlock Column</Typography>
                      </>
                    ) : (
                      <>
                        <LockIcon sx={{ fontSize: "18px" }} />
                        <Typography variant="body1">Lock Column</Typography>
                      </>
                    )}
                  </Stack>
                </MenuItem>
                <MenuItem
                  onClick={handleToggleVisibility}
                  sx={{ padding: "0px" }}
                >
                  <Stack direction={"row"} gap={"10px"} padding={"8px"}>
                    {isHidden ? (
                      <>
                        <VisibilityIcon sx={{ fontStyle: "8px" }} />
                        <Typography variant="body1">Show Column</Typography>
                      </>
                    ) : (
                      <>
                        <img
                          src={Icons.hide}
                          alt=""
                          style={{ fontStyle: "8px" }}
                        />
                        <Typography variant="body1">Hide Column</Typography>
                      </>
                    )}
                  </Stack>
                </MenuItem>
              </Box>
            </Menu>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomHeader;
