import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import { Control } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { UIStore } from "../../../../store/general";

interface DatasetViewTabProps {
  control?: Control;
  noOfVersions: string;
  onClick: (
    clickedStatus: boolean,
    index: number,
    dataset: any,
    id: any
  ) => void;
  index: number;
  dataset: any;
  selectedDatasetId: string | null;
}

export default function DatasetViewTab({
  control,
  noOfVersions,
  onClick,
  index,
  dataset,
  selectedDatasetId,
}: DatasetViewTabProps) {
  // console.log("First:", dataset);

  const navigate = useNavigate();
  const { projectId, datasetId } = useParams();

  const theme = useTheme();
  const isDarkMode = UIStore.useState((s) => s.isDarkMode);
  const currentPath = window.location.pathname;

  const isSelected = dataset?._id === selectedDatasetId;

  const handleClick = () => {
    const clickedStatus = !isSelected;
    onClick(clickedStatus, index, dataset, dataset?._id);

    if (
      currentPath.startsWith("/app/projects/") &&
      currentPath.endsWith("/detailed-view")
    ) {
      navigate(
        `/app/projects/${projectId}/datasets/${dataset?._id}/detailed-view`,
        {
          replace: true,
        }
      );
    } else {
      navigate(`/app/datasets/${dataset?._id}/detailed-view`, {
        replace: true,
      });
    }
  };

  return (
    <ButtonBase
      className="Datasets"
      sx={{
        width: "100%",
        padding: "1vh 1vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: `1px solid ${theme.palette.border.primary}`,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        cursor: "pointer",
        backgroundColor:
          isSelected || dataset?._id === datasetId ? "#F5F5FA" : "transparent",
        "&:hover": {
          bgcolor: isDarkMode ? "rgba(41, 54, 64, 1)" : "#F5F5FA",
        },
        // "@media (max-width: 1450px)": {
        //   padding: "0.5vh 1vw",
        // },
        // "@media (max-width: 1280px)": {
        //   padding: "0.5vh 1vw",
        // },
        // "@media (max-width: 960px)": {
        //   padding: "0.5vh 1vw",
        // },
        // "@media (max-width: 768px)": {
        //   padding: "0.5vh 1vw",
        // },
        // "@media (max-width: 480px)": {
        //   padding: "0.5vh 1vw",
        // },
      }}
      onClick={handleClick}
    >
      {/* <SimpleCheckBox
        control={control}
        isChecked={(clickedStatus: boolean) => {
          // console.log("checkbox");
          onClick(clickedStatus, index, dataset, dataset?._id);
        }}
        text={dataset?.name}
      /> */}

      <Typography
        variant="h5"
        sx={{
          fontWeight: dataset?.checked ? "bold" : "normal", // Visual feedback for selection
          textDecoration: dataset?.checked ? "underline" : "none",
        }}
      >
        {dataset?.name || "Dataset Placeholder"}
      </Typography>

      <Box
        sx={{
          width: "6vw",
          bgcolor: isDarkMode ? "rgba(75, 170, 190, 0.3)" : "#C75737",
          textAlign: "center",
          padding: "8px",
          borderRadius: "5px",
          "@media (max-width: 1650px)": {
            padding: "2px",
          },
          "@media (max-width: 1450px)": {
            padding: "2px",
          },
          "@media (max-width: 1280px)": {
            padding: "2px",
          },
          "@media (max-width: 960px)": {
            padding: "2px",
          },
          "@media (max-width: 768px)": {
            padding: "2px",
          },
          "@media (max-width: 480px)": {
            padding: "2px",
          },
        }}
      >
        <Typography
          color={isDarkMode ? "rgba(75, 170, 190, 1)" : "white"}
          variant="subtitle2"
        >
          {dataset?.dataSetVersions?.length
            ? `${dataset.dataSetVersions.length} ${
                dataset.dataSetVersions.length === 1 ? "Version" : "Versions"
              }`
            : "NA"}
        </Typography>
      </Box>
    </ButtonBase>
  );
}
