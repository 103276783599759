import { Box, Typography, useMediaQuery } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { getAllResponses } from "../../../data-grid/services/DataGridService";
import { getOneProject } from "../../../project/services/ProjectService";
import DynamicText from "../../../prompt/utility";
import Spinner from "../Loaders/Spinner";
import DataGrid from "./DataGrid";
import { transformResponseData } from "./utils/data-transformation-table";

const CompareVersionTable = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { projectId, promptId } = useParams();

  const versionIds = searchParams.get("versionIds")?.split(",") || [];

  const [apiData, setApiData] = useState<any[]>([]);
  const [currentVersionId, setCurrentVersionId] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(5);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const { data: FindResponse, isLoading: responseLoading } = useQuery(
    [
      "prompt-responses",
      {
        projectId,
        promptId,
        versionId: currentVersionId,
        limit: limit,
        offset: offset,
      },
    ],
    getAllResponses,
    {
      enabled: !!currentVersionId,
      onSuccess: (res: any) => {
        // setTotalCount(res?.total || 0);
        setTotalCount(res?.analytics?.recordsCount || 0);
      },
    }
  );

  useEffect(() => {
    if (FindResponse?.responses && currentVersionId) {
      setApiData((prevData) => {
        const existingEntry = prevData.find(
          (entry) => entry.versionId === currentVersionId
        );

        if (existingEntry) {
          return prevData.map((entry) =>
            entry.versionId === currentVersionId
              ? { versionId: currentVersionId, data: FindResponse?.responses }
              : entry
          );
        }

        const updatedData = [
          ...prevData,
          { versionId: currentVersionId, data: FindResponse?.responses },
        ];

        return updatedData.slice(0, versionIds.length);
      });
    }
  }, [FindResponse?.responses, currentVersionId]);

  useEffect(() => {
    if (versionIds.length > 0 && !currentVersionId) {
      setCurrentVersionId(versionIds[0]);
    }
  }, [versionIds, currentVersionId]);

  useEffect(() => {
    if (
      !responseLoading &&
      currentVersionId &&
      apiData.length < versionIds.length
    ) {
      const currentIndex = versionIds.indexOf(currentVersionId);
      if (currentIndex >= 0 && currentIndex < versionIds.length - 1) {
        setCurrentVersionId(versionIds[currentIndex + 1]);
      }
    }
  }, [responseLoading, currentVersionId, apiData.length, versionIds]);

  useEffect(() => {
    if (apiData.length === versionIds.length) {
      setLoading(false);
    }
  }, [apiData, versionIds.length]);

  const prompt = FindOneProject?.prompts.find((p: any) => p?._id === promptId);
  const version1 = prompt?.versions?.find(
    (v: any) => v?._id === apiData?.[0]?.versionId
  );
  const version2 = prompt?.versions?.find(
    (v: any) => v?._id === apiData?.[1]?.versionId
  );

  // const interleavedData: any = [];

  // const baseData = apiData?.[0]?.data || [];

  // baseData.forEach((baseItem: any) => {
  //   // Push the current item from apiData[0]
  //   interleavedData.push({
  //     versionId: apiData[0].versionId,
  //     DatasetRecord: baseItem?.DatasetRecord,
  //     data: baseItem,
  //   });

  //   // Find the corresponding item from apiData[1] based on DatasetRecord
  //   const matchingItem = apiData[1]?.data?.find(
  //     (item: any) => item?.DatasetRecord === baseItem?.DatasetRecord
  //   );

  //   // If a match is found, push it as well
  //   if (matchingItem) {
  //     interleavedData.push({
  //       versionId: apiData[1].versionId,
  //       DatasetRecord: matchingItem?.DatasetRecord,
  //       data: matchingItem,
  //     });
  //   }
  // });

  // const transformedArray = interleavedData.map((item: any) => ({
  //   DatasetRecord: item.DatasetRecord,
  //   models: item.data?.models?.map((m: any) => ({
  //     model: m?.model,
  //     response: m?.responses,
  //   })),
  // }));

  // const outputArray = transformedArray.map((input: any) => {
  //   const output: any = {
  //     DatasetRecord: input.DatasetRecord,
  //   };

  //   input.models.forEach((modelObj: any) => {
  //     const providerName = modelObj.model.providerName;
  //     const versionName = modelObj.model.versionName;
  //     const key = `${providerName}/${versionName}`;
  //     output[key] = { response: modelObj.response };
  //   });

  //   return output;
  // });

  // const modifiedData = outputArray.map((obj: any) => {
  //   const newObj: any = {};
  //   Object.entries(obj).forEach(([key, value]) => {
  //     const newKey = key.replace(/\./g, "_");
  //     newObj[newKey] = value;
  //   });
  //   return newObj;
  // });

  const modifiedData = useMemo(() => {
    return transformResponseData(apiData);
  }, [apiData]);

  // console.log("Main Log:", modifiedData, apiData);

  const handlePaginationChange = useCallback(
    (newLimit: number, newOffset: number) => {
      setLimit(newLimit);
      setOffset(newOffset);
    },
    []
  );

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(min-width: 1550)");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",
              padding: "0 2vw",
              mt: 1,
              "@media (max-width: 1650px)": {
                padding: "0 1vw",
                mt: 0.5,
              },
              "@media (max-width: 1450px)": {
                padding: "0 1vw",
                mt: 0.5,
              },
              "@media (max-width: 1280px)": {
                padding: "0 1vw",

                mt: 0.5,
              },
              "@media (max-width: 960px)": {
                padding: "0 1vw",

                mt: 0.5,
              },
              "@media (max-width: 768px)": {
                padding: "0 1vw",

                mt: 0.5,
              },
              "@media (max-width: 480px)": {
                padding: "0 1vw",

                mt: 0.5,
              },
            }}
          >
            <Box
              sx={{
                flex: 1,
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                border: "1px solid #1212121A",
                padding: "2px 10px 10px 15px",
                position: "relative",
                minHeight: "140px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "@media (max-width: 1440px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 1280px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 960px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 768px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 480px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100px",
                  paddingRight: "8px",
                }}
              >
                <DynamicText
                  text={version1?.datasets?.[0]?.description}
                  // params={apiData?.[0]?.data?.[0]?.data}
                />
              </Box>

              <Box
                sx={{
                  backgroundColor: "#FFF1E7",
                  color: "#F97316",
                  px: 1,
                  py: 0.5,
                  borderRadius: "5px",
                  width: "fit-content",
                  border: "1px solid #F07946",
                }}
              >
                <Typography variant="body1">Version V1</Typography>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 1,
                backgroundColor: "#F8F8F8",
                borderRadius: "10px",
                border: "1px solid #1212121A",
                padding: "2px 10px 10px 15px",
                position: "relative",
                minHeight: "140px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "@media (max-width: 1440px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 1280px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 960px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 768px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
                "@media (max-width: 480px)": {
                  minHeight: "120px",
                  padding: "2px 5px 10px 5px",
                },
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: "auto",
                  maxHeight: "100px",
                  paddingRight: "8px",
                }}
              >
                <DynamicText
                  text={version2?.datasets?.[0]?.description}
                  // params={apiData?.[1]?.data?.[0]?.data}
                />
              </Box>

              <Box
                sx={{
                  backgroundColor: "#FFF1E7",
                  color: "#F97316",
                  borderRadius: "5px",
                  px: 1,
                  py: 0.5,
                  width: "fit-content",
                  border: "1px solid #F07946",
                }}
              >
                <Typography variant="body1">Version V2</Typography>
              </Box>
            </Box>
          </Box>
          <DataGrid
            rowsData={modifiedData}
            loading={loading || responseLoading}
            setLimit={setLimit}
            setOffset={setOffset}
            totalCount={totalCount}
            currentLimit={limit}
            currentOffset={offset}
            onPaginationChange={handlePaginationChange}
            compareTable={true}
          />
        </>
      )}
    </Box>
  );
};

export default CompareVersionTable;
