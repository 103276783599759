import { createBrowserRouter } from "react-router-dom";
import PageNotFound from "../pages/public/pageNotFound";
import privateRoutes from "./PrivateRoute";
import publicRoutes from "./PublicRoute";

const router = createBrowserRouter([
  ...privateRoutes,
  ...publicRoutes,
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;
