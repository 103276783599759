import { styled, Switch } from "@mui/material";

interface GridTheme {
  headerBgColor?: string;
  headerTextColor?: string;
  cellBgColor?: string;
  cellTextColor?: string;
  hoverRowBgColor?: string;
  selectedRowBgColor?: string;
  selectedRowBorderColor?: string;
  borderColor?: string;
  gridBgColor?: string;
}

export const agGridStyles = (theme: GridTheme = {}) => {
  const {
    headerBgColor = "#f6ac23",
    headerTextColor = "rgb(203, 32, 32)",
    cellBgColor = "#aec901",
    cellTextColor = "white",
    hoverRowBgColor = "rgba(238, 74, 63, 0.2)",
    selectedRowBgColor = "rgba(255, 43, 128, 0.3)",
    selectedRowBorderColor = "#e0ddff",
    borderColor = "#0000001A",
    gridBgColor = "#c5d3e9",
  } = theme;

  return {
    gridContainer: {
      backgroundColor: gridBgColor,
      border: `2px solid ${borderColor}`,
      borderRadius: "10px",
      color: cellTextColor,
      height: "50vh",
      // overflow: "hidden",
    },
    header: {
      backgroundColor: headerBgColor,
      color: headerTextColor,
      fontSize: "16px",
      fontFamily: '"Helvetica", sans-serif',
      borderBottom: `2px solid ${borderColor}`,
      textAlign: "center",
      height: "60px", // Increased header height
      lineHeight: "60px", // Ensure text is vertically centered
      // React Data Grid CSS variables
      "--ag-header-background-color": headerBgColor,
      "--ag-header-foreground-color": headerTextColor,
      "--ag-header-height": "60px", // Custom header height
    },
    headerCell: {
      padding: "8px",
      borderRight: `1px solid ${borderColor}`,
      fontWeight: "bold",
      fontSize: "18px",
      // React Data Grid header cell hover
      "--ag-header-cell-hover-background-color": "rgb(80, 40, 140)",
      "--ag-header-cell-moving-background-color": "rgb(80, 40, 140)",
      // React Data Grid column resize handle
      "--ag-header-column-resize-handle-color": "orange",
    },
    row: {
      borderBottom: `1px solid ${borderColor}`,
    },
    rowHover: {
      backgroundColor: hoverRowBgColor,
    },
    rowSelected: {
      backgroundColor: selectedRowBgColor,
      borderLeft: `3px solid ${selectedRowBorderColor}`,
    },
    cell: {
      backgroundColor: cellBgColor,
      color: cellTextColor,
      borderRight: `1px solid ${borderColor}`,
      padding: "6px 10px",
      textAlign: "left",
      whiteSpace: "nowrap",
      "&:first-child": {
        backgroundColor: "#FAFAFA",
      },
    },
    cellWrapText: {
      whiteSpace: "normal",
    },
    // scrollableArea: {
    //   overflowX: "auto",
    //   overflowY: "hidden",
    //   maxWidth: "100%",
    //   whiteSpace: "nowrap",
    // },
    scrollbar: {
      scrollbarWidth: "thin",
      scrollbarColor: `${borderColor} ${gridBgColor}`,
    },
    scrollbarThumb: {
      backgroundColor: "#475569",
      borderRadius: "5px",
    },
    scrollbarTrack: {
      backgroundColor: gridBgColor,
    },
    pagingPanel: {
      backgroundColor: headerBgColor,
      color: headerTextColor,
      fontSize: "14px",
      fontFamily: '"Arial", sans-serif',
      borderTop: `2px solid ${borderColor}`,
    },
    floatingFilter: {
      backgroundColor: "#fef9c3",
      borderBottom: `1px solid ${borderColor}`,
      color: cellTextColor,
    },
    columnResizeHandle: {
      backgroundColor: "#000000",
    },
    selectionCheckbox: {
      backgroundColor: headerBgColor,
      border: `1px solid ${borderColor}`,
    },
    sideBar: {
      backgroundColor: "#c7ecee",
    },
    toolPanel: {
      backgroundColor: "#ecf0f1",
      color: "#2c3e50",
    },
    columnDrop: {
      backgroundColor: headerBgColor,
    },
  };
};

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#F07946",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));
