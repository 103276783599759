import { Box, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import { getAllDatasets } from "../../../dataset/services/DatasetService";
import QuillEditor from "../../../prompt/components/promptcreationComponents/Quill";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import { postPromptNewVersion } from "../../../shared/components/Grid/services";
import { MultiSelect } from "../../../shared/components/Select/MultiSelect";
import { NoSelect } from "../../../shared/components/Select/NoSelect";
import { SingleSelect } from "../../../shared/components/Select/SingleSelect";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";

const EditPromptDialog = ({
  open,
  setOpen,
  prompt,
  version,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  prompt?: any;
  version?: any;
}) => {
  console.log("PromptsAvailable:", prompt, version);
  const navigate = useNavigate();
  const { projectId, promptId, versionId } = useParams();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      promptCategorySingle: "",
      promptCategoryMulti: [],
      promptCategoryParams: [],
    },
  });

  const { data: dataSets, isLoading: dataSetsLoading }: any = useQuery(
    "datasets",
    () => getAllDatasets({ datasetIds: prompt?.datasetIds })
  );

  const promptGen = useRef({
    text: version?.datasets?.[0]?.description || "",
    mentions: [""],
  });

  const { mutate: PostPromptNewVersions, isLoading: PromptVersionLoading } =
    useMutation(postPromptNewVersion, {
      onSuccess: () => {
        toast.success("Prompt new Version Added  Successfully");
        localStorage.clear();
        navigate(
          `/app/projects/${projectId}/prompts/${promptId}/prompt-detailed-view`
        );
      },
      onError: (err) => {
        handleError(err);
      },
    });

  const CurrentDataset =
    dataSets?.data?.map(
      (i: { name: any; _id: any; dataSetVersions?: any }) => ({
        label: i?.name,
        value: i?._id,
        dataSetVersions: i?.dataSetVersions || [],
      })
    ) || [];
  // console.log("third:", CurrentDataset);

  const datasetSingleValue: any = watch("promptCategorySingle") || "";
  // console.log("Fourth", datasetSingleValue);

  const datasetMultiValue: any = watch("promptCategoryMulti") || [];

  const selectedDataset = useMemo(
    () =>
      CurrentDataset.find(
        (dataset: any) => dataset.value === datasetSingleValue
      ) || {},
    [CurrentDataset, datasetSingleValue]
  );

  const datasetForEffect = CurrentDataset.find(
    (dataset: any) => dataset.value === prompt?.datasetIds?.[0]
  );

  // console.log("Fifth:", selectedDataset);

  const datasetVersionOptions = useMemo(
    () =>
      selectedDataset.dataSetVersions?.map(
        (i: { tag: any; versionNumber?: any; _id: any; params?: any }) => ({
          label: `V${i?.versionNumber}: ${i?.tag}`,
          value: i?._id,
          params: i?.params || [],
        })
      ) || [],
    [selectedDataset]
  );

  console.log("Versions length:", datasetVersionOptions);

  const selectedVersions = datasetVersionOptions.filter((version: any) =>
    datasetMultiValue.includes(version.value)
  );

  const paramsOptions = useMemo(
    () =>
      selectedVersions
        .flatMap((version: any) => version.params || [])
        .map((param: any) => ({ label: param, value: param })) || [],
    [selectedVersions]
  );

  const uniqueLabels = useMemo(
    () =>
      paramsOptions
        .map((option: any) => option.label)
        .filter((label: string) => label)
        .reduce((acc: string[], label: string) => {
          if (!acc.includes(label)) {
            acc.push(label);
          }
          return acc;
        }, []),
    [paramsOptions]
  );

  // console.log("Params Options:", uniqueLabels);

  useEffect(() => {
    // if (CurrentDataset?.length > 0) {
    //   setValue("promptCategorySingle", CurrentDataset[0]?.value);
    // }
    if (version?.datasets?.[0]?.datasetId)
      setValue("promptCategorySingle", version?.datasets?.[0]?.datasetId);
    // if (initialData?.params)
    //   setValue("promptCategoryParams", initialData?.params);
  }, [version, setValue]);

  useEffect(() => {
    if (version?.datasets?.[0]?.datasetVersionIds) {
      setValue("promptCategoryMulti", version.datasets[0].datasetVersionIds);
    }
  }, [version, setValue]);

  //   useEffect(() => {
  //     setValue("promptCategorySingle", datasetForEffect?.value);
  //     setValue("promptCategoryMulti", [
  //       datasetForEffect?.dataSetVersions[0]?._id,
  //     ]);
  //   }, []);

  const handleChange = useCallback((quillText: string) => {
    // const rederedText = convert;
    const mentionRegex = /{([^}]+)}/g;
    const mentions =
      quillText.match(mentionRegex)?.map((m) => m.slice(1, -1)) || [];
    promptGen.current = {
      text: quillText,
      mentions: mentions,
    };
    // console.log("Mentions:", promptGen.current);

    localStorage.setItem("Updated Prompt TableUI", quillText);
  }, []);

  //   useEffect(() => {
  //     const selectedDataset: any = CurrentDataset.find(
  //       (cd: any) => cd?.value === watch("promptCategorySingle")
  //     );
  //     if (selectedDataset && selectedDataset?.dataSetVersions?.length > 0) {
  //       setValue("promptCategoryMulti", [
  //         selectedDataset?.dataSetVersions[0]?._id,
  //       ]);
  //     }
  //   }, [watch("promptCategorySingle"), CurrentDataset, setValue, getValues]);

  const RunSubmit = () => {
    const mutationData = {
      projectId,
      promptId,
      prevVersionId: versionId,
      // datasets: {
      //   datasetId: watch("promptCategorySingle"),
      //   datasetVersionIds: watch("promptCategoryMulti"),
      //   params: uniqueLabels,
      // },
      datasetVersionIds: watch("promptCategoryMulti"),
      description: localStorage.getItem("Updated Prompt TableUI"),
    };

    console.log("Final Mutation:", mutationData);

    PostPromptNewVersions(mutationData);
  };

  const isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(max-width: 1290px)");

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={`Edit Prompt`}
      width={isMediumLaptop || isMediumLaptop2 ? "md" : "lg"}
    >
      <Box
        sx={{
          padding: "2.5vw 0vw",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            mb: 2,
          }}
        >
          <Box sx={{ width: "33.3%" }}>
            <SingleSelect
              control={control}
              required
              name="promptCategorySingle"
              label="Select Dataset"
              options={CurrentDataset}
              placeholder="select dataset"
              backgroundColor="#F8F8F8"
              border="#F8F8F8"
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>

          <Box sx={{ width: "33.3%" }}>
            {" "}
            <MultiSelect
              control={control}
              required
              name="promptCategoryMulti"
              label="Select Version"
              options={datasetVersionOptions}
              placeholder="Select Version"
              border="none"
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
          <Box sx={{ width: "33.3%" }}>
            <NoSelect
              name="promptCategoryParams"
              label="Add Params"
              options={uniqueLabels}
              placeholder="Click here for available params"
              border="none"
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        </Box>

        <QuillEditor
          defaultHtml={promptGen.current.text}
          placeholder="Enter message"
          onChange={(quillText: string) => handleChange(quillText)}
          height={
            isLargeScreen
              ? "30vh"
              : isMediumLaptop
              ? "25vh"
              : isMediumLaptop2
              ? "20vh"
              : "25vh"
          }
          mentions={[
            {
              mentionChar: "{",
              values: paramsOptions,
            },
          ]}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 3,
          }}
        >
          <MultiTypeButton
            buttonType={ButtonType.Cancel}
            typeText={"Cancel"}
            actionOnClick={() => setOpen(false)}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Save & Submit"}
            actionOnClick={RunSubmit}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default EditPromptDialog;
