import { Box } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Images } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";
import { inviteSme } from "../../services/LayoutService";
import UserCard from "./SmeCard";
import SmeCreation from "./SmeCreation";
import SmeSearchContainer from "./SmeSearch";

const SmeForm = ({
  open,
  setOpen,
  data,
  sme,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  data?: any;
  sme?: {
    _id: string;
    name: string;
    emailAddress: string;
    status: string;
  }[];
}) => {
  const queryClient = useQueryClient();

  const [search, setSearch] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [createSme, setCreateSme] = useState(false);
  const [smeData, setSmeData] = useState();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { projectId, promptId, versionId } = data;

  const handleSearch = (value: string) => {
    setSearch(value);
    setCreateSme(false);
  };

  const handleUserSelect = (userId: string) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const filteredUsers = (sme || []).filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.emailAddress.toLowerCase().includes(search.toLowerCase())
  );

  const { mutate, isLoading } = useMutation(inviteSme, {
    onSuccess: () => {
      toast.success("Sme Invited  Successfully");

      queryClient.invalidateQueries("sme-invite");
      queryClient.invalidateQueries("users-all");
      queryClient.invalidateQueries("projects");
      setSelectedUsers([]);
      setOpen(false);
    },
    onError: (err) => handleError(err),
  });

  const onSubmit = () => {
    const mutationData = {
      projectId: projectId,
      promptId: promptId,
      versionId: versionId,
      smeIds: selectedUsers,
    };

    mutate(mutationData);
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={"Invite experts."}
      width={isLargeScreen ? "sm" : isMediumScreen ? "xs" : "xs"}
    >
      <Box sx={{ width: "100%" }}>
        <SmeSearchContainer
          onChange={handleSearch}
          value={search}
          filteredUsers={filteredUsers}
        />

        <Box
          sx={{
            mt: 2,
            maxHeight: "35vh",
            overflowY: "auto",
            paddingRight: "4px",
            marginRight: "-4px",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#12121220",
              borderRadius: "4px",
            },
          }}
        >
          {filteredUsers?.length >= 1 ? (
            filteredUsers.map((user) => (
              <UserCard
                key={user._id}
                user={{
                  _id: user._id,
                  name: user.name,
                  email: user.emailAddress,
                  isActive: user.status,
                }}
                selected={selectedUsers.includes(user._id)}
                onSelect={handleUserSelect}
              />
            ))
          ) : createSme ? (
            <SmeCreation
              reset={setCreateSme}
              search={setSearch}
              data={setSmeData}
              setSelectedUsers={setSelectedUsers}
              setOpen={setOpen}
              Ids={data}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                p: "3vh 0",
              }}
            >
              <img src={Images.emptyScreen} alt="emptySme" width={"70%"} />
              {/* <Typography variant="h5" sx={{ fontWeight: 500 }}>
                Sme doesn't exist.
              </Typography> */}
              <MultiTypeButton
                buttonType={ButtonType.Gradient}
                typeText={"Send Invitation"}
                actionOnClick={() => setCreateSme(true)}
                width={
                  isLargeScreen
                    ? "192px"
                    : isMediumScreen
                    ? "115px"
                    : isSmallScreen
                    ? "61px"
                    : "192px"
                }
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </Box>
          )}
        </Box>
        {!createSme && filteredUsers?.length !== 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: 3,
            }}
          >
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Send Invitation"}
              actionOnClick={onSubmit}
              width={
                isLargeScreen
                  ? "195px"
                  : isMediumScreen
                  ? "115px"
                  : isSmallScreen
                  ? "61px"
                  : "192px"
              }
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        ) : null}

        {/* <Button
          onClick={handleSendInvitation}
          sx={{
            mt: 3,
            width: "100%",
            backgroundColor: "#F4B537",
            color: "white",
            textTransform: "none",
            fontSize: "14px",
            fontWeight: 500,
            padding: "10px",
            "&:hover": {
              backgroundColor: "#E5A832",
            },
          }}
        >
          Send Invitation
        </Button> */}
      </Box>
    </DialogWrapper>
  );
};

export default SmeForm;
