import { Box, Stack, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { failed } from "../../../assets/lottie/failed";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { orangeVerified } from "../../../assets/lottie/orangeVerified";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { validateEmail } from "../services/AuthService";

export default function VerifyEmailDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isVerificationFailed, setVerificationFailed] = useState<boolean>(true);
  const [displayMessage, setDisplayMessage] = useState("");
  const { data: verificationData, isLoading }: any = useQuery(
    ["emailVerificationData", { token: params.get("token") }],
    validateEmail,
    {
      onSuccess: (res) => {
        setDisplayMessage(res.message);
        setVerificationFailed(false);
        displaySuccessMessage(res.message);
      },
      onError: (error: any) => {
        displayErrorMessage(error);
      },
    }
  );

  useEffect(() => {
    // console.log(verificationData);
  }, [verificationData]);

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  if (isLoading) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{
            width: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
            height: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
          }} // Fixed size for better presentation
        />
      </Box>
    );
  }
  return (
    <Stack
      spacing={2} // Adds spacing between elements
      alignItems="center" // Centers items horizontally
      justifyContent="center" // Centers items vertically
      sx={{
        padding: 5,
        "@media (max-width: 1440px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 1280px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 960px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 768px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 480px)": {
          padding: "20px 25px",
        },
      }}
    >
      <Lottie
        animationData={isVerificationFailed ? failed : orangeVerified}
        loop={true}
        autoplay={true}
        style={{
          width: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
          height: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
        }}
      />
      <Typography
        variant="h3"
        component="h2"
        align="center"
        sx={{ color: isVerificationFailed ? "red" : "green" }}
      >
        {isVerificationFailed ? (
          <>Email Verification Failed!</>
        ) : (
          <>{displayMessage}</>
        )}
      </Typography>
      <Typography variant="body1" align="center">
        {isVerificationFailed ? (
          <>
            Email verification failed. Please ensure that your verification link
            is correct and hasn't expired. If you continue to experience issues,
            please contact support for assistance.
          </>
        ) : (
          <>
            Thank you for verifying your email. You can now enjoy all the
            features available!.
          </>
        )}
      </Typography>
      <MultiTypeButton
        buttonType={ButtonType.Gradient}
        actionOnClick={() => {
          navigate("/signup", { replace: true });
          if (isVerificationFailed) {
          } else {
            navigate("/register", { replace: true });
          }
        }}
        typeText={"Continue"}
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />
    </Stack>
  );
}
