import { http } from "../../../app/services";

const validateAPI = (data: any) => {
  return http.post("/prompt/validate", data);
};

const createAPIKey = (data: any) => {
  return http.post("/models/api-key", data);
};

const updateAPIKey = (data: any) => {
  return http.put("/models/api-key", data);
};

const postPrompt = (data: any) => {
  return http.post("/prompt", data);
};

const getAllModels = (queryKey: any) => {
  return http
    .get(`/models`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getAllPrompts = (queryKey: any) => {
  return http
    .get(`/prompt`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOnePrompt = ({ queryKey }: any) => {
  return http.get(`/prompt/${queryKey[1]}`).then((res) => res.data);
};

const postGrading = (data: any) => {
  return http.post("/sme/grading", data);
};

const postReRun = (data: any) => {
  return http.post("/prompt-responses/rerun-models", data);
};

const getGradingParams = ({ queryKey }: any) => {
  const [_, projectId, promptId, versionId] = queryKey;

  const queryString = new URLSearchParams({
    projectId,
    promptId,
    versionId,
  }).toString();

  return http
    .get(`/prompt/grading-params?${queryString}`)
    .then((res) => res.data);
};

export {
  createAPIKey,
  getAllModels,
  getAllPrompts,
  getGradingParams,
  getOnePrompt,
  postGrading,
  postPrompt,
  postReRun,
  updateAPIKey,
  validateAPI,
};
