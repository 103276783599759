import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  useTheme,
} from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardIcon from "../../../assets/icons/Dashboard";
import DocumentationIcon from "../../../assets/icons/Documentation";
import HelpIcon from "../../../assets/icons/NeedHelp";
import ProjectIcon from "../../../assets/icons/Project";
import PromptsIcon from "../../../assets/icons/Prompts";
import { UIStore } from "../../../store/general";
import { getOneUser } from "../services/LayoutService";
import ResponsiveAppBar from "./AppBar";
import ResponseTableAppBar from "./TableUIAppBar";

// Define the shape of a sidebar item
interface SidebarItemProps {
  label: string;
  icon: JSX.Element;
  path: string;
}

const SidebarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "bgColor" && prop !== "border",
})<{ open: boolean; bgColor?: string; border?: string }>(
  ({ open, bgColor, border }) => ({
    width: open ? "15vw" : "4vw",
    transition: "width 0.5s ease",
    "& .MuiDrawer-paper": {
      width: open ? "15vw" : "4vw",
      transition: "width 0.5s ease",
      boxSizing: "border-box",
      overflowX: "hidden",
      backgroundColor: bgColor || "#FFFFFF",
      color: "#fff",
      gap: "0.5vw",
      border: border || `1px solid ${bgColor || "#FFFFFF"}`,
      "@media (max-width: 1650px)": {
        width: open ? "17vw" : "4.5vw",
      },
      "@media (max-width: 1450px)": {
        width: open ? "17vw" : "4.5vw",
      },
      "@media (max-width: 1280px)": {
        width: open ? "17vw" : "4vw",
      },
      "@media (max-width: 960px)": {
        width: open ? "16vw" : "4vw",
      },
      "@media (max-width: 768px)": {
        width: open ? "16vw" : "4vw",
      },
      "@media (max-width: 480px)": {
        width: open ? "16vw" : "4vw",
      },
    },
    "@media (max-width: 1650px)": {
      width: open ? "17vw" : "4.5vw",
    },
    "@media (max-width: 1450px)": {
      width: open ? "17vw" : "4vw",
    },
    "@media (max-width: 1280px)": {
      width: open ? "17vw" : "4vw",
    },
    "@media (max-width: 960px)": {
      width: open ? "16vw" : "4vw",
    },
    "@media (max-width: 768px)": {
      width: open ? "15vw" : "3.5vw",
    },
    "@media (max-width: 480px)": {
      width: open ? "15vw" : "3.5vw",
    },
  })
);

const SidebarListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    prop !== "isActive" &&
    prop !== "isSidebarOpen" &&
    prop !== "color" &&
    prop !== "bgColor" &&
    prop !== "isDarkMode",
})<{
  isActive: boolean;
  isSidebarOpen: boolean;
  color?: string;
  bgColor?: string;
  isDarkMode?: boolean;
}>(({ isActive, isSidebarOpen, color, bgColor }) => ({
  width: "3vw",
  borderRadius: isActive ? "12px" : "0",
  transition: "background-color 0.3s, width 0.5s",
  backgroundColor: bgColor, // Dynamic bgColor
  "&:hover": {
    cursor: "pointer",
  },
  color: color || "#fff",
  gap: "15px",
  marginTop: isActive ? "10px" : "0px",
  "@media (max-width: 1650px)": {
    width: "3vw",
  },
  "@media (max-width: 1450px)": {
    width: "3vw",
  },
  "@media (max-width: 1280px)": {
    width: "3vw",
  },
  "@media (max-width: 960px)": {
    width: "2.5vw",
    flexDirection: "row",
    marginTop: "5px",
  },
  "@media (max-width: 768px)": {
    width: "2vw",
  },
  "@media (max-width: 480px)": {
    width: "100%",
    justifyContent: "center",
    marginTop: "5px",
  },
}));

const SidebarItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive: boolean;
  isSidebarOpen?: boolean;
}>(({ isActive, isSidebarOpen }) => ({
  minWidth: 0,
  justifyContent: "center",
  color: isActive ? "#fff" : "#1da1f2",
  backgroundColor: "transparent",
  borderRadius: isActive ? "12px" : "0",
  padding: "8px 0px",
  transition: "color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    color: "#fff",
    transform: "scale(1.1)",
  },
  "@media (max-width: 1650px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 1450px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 1280px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 960px)": {
    width: "22px",
    height: "22px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 768px)": {
    width: "19px",
    height: "19px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 480px)": {
    width: "15px",
    height: "15px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
}));

const SidebarFooter = styled(Box)`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: flex-start;
  color: #000000cc;
  opacity: 0.7;
`;

export default function SmeLayout() {
  const theme = useTheme();
  const Sme = Cookies.get("Sme");
  const { pathname } = useLocation();
  const endpoint = pathname.split("/").pop();
  const isDarkMode = UIStore.useState((s) => {
    return s.isDarkMode;
  });

  const { id, projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  // console.log("User:", FindOneUser?.data);

  // const { data: FindOneProject } = useQuery(
  //   ["projects", projectId],
  //   getOneProject,
  //   {
  //     enabled: !!projectId,
  //   }
  // );

  const isProjectPage = /^\/app\/projects\/(?!all$)[a-zA-Z0-9]+(\/.*)?$/.test(
    location.pathname
  );

  const projectSidebarItems: SidebarItemProps[] = [
    {
      label: "Dashboard",
      icon: <DashboardIcon color={isDarkMode ? "white" : "black"} />,
      path: `/app/sme/projects/${projectId}/dashboard`,
    },
    {
      label: "Prompts",
      icon: <PromptsIcon color={isDarkMode ? "white" : "black"} />,
      path: `/app/sme/projects/${projectId}/prompts`,
    },
  ];

  const sidebarItems: SidebarItemProps[] = [
    {
      label: "Dashboard",
      icon: <DashboardIcon color={isDarkMode ? "white" : "black"} />,
      path: "dashboard",
    },
    {
      label: "Projects",
      icon: <ProjectIcon color={isDarkMode ? "white" : "black"} />,
      path: "projects",
    },
  ];

  const sidebarFooterItems: SidebarItemProps[] = [
    {
      label: "Documentation",
      icon: <DocumentationIcon color={isDarkMode ? "#FFF" : "black"} />,
      path: "configuration",
    },
    {
      label: "Need help?",
      icon: <HelpIcon color={isDarkMode ? "white" : "black"} />,
      path: "configuration",
    },
  ];

  const currentSidebarItems =
    isProjectPage && Sme === "false" ? projectSidebarItems : sidebarItems;

  const SidebarItemComponent: React.FC<SidebarItemProps> = ({
    label,
    icon,
    path,
  }) => {
    const isActive = location.pathname.includes(path);
    return (
      <SidebarListItem
        onClick={() => navigate(path)}
        isActive={isActive}
        isSidebarOpen={false}
        color={isDarkMode ? "white" : "black"}
        bgColor={
          isActive && isDarkMode
            ? "rgba(50, 65, 85, 1)"
            : isActive && !isDarkMode
            ? "#FFE0D1"
            : "transparent"
        }
      >
        <SidebarItemIcon isActive={isActive}>{icon}</SidebarItemIcon>
      </SidebarListItem>
    );
  };

  const currentPath = window.location.pathname;

  return (
    <Box display="flex" height="100vh">
      {/* Sidebar */}
      <SidebarDrawer
        variant="permanent"
        open={false}
        bgColor={theme.palette.surface.layoutBackground}
        border={`2px solid ${theme.palette.border}`}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* <IconButton
            onClick={() => console.log("clicked")}
            sx={{ color: "black", cursor: "auto" }}
            aria-label="Toggle Sidebar"
          >
            <ArrowForwardIosRoundedIcon
              htmlColor={isDarkMode ? "white" : "black"}
              sx={{
                "@media (max-width: 1450px)": {
                  marginLeft: -2,
                },
                "@media (max-width: 1280px)": {
                  marginLeft: -2,
                },
                "@media (max-width: 960px)": {
                  marginLeft: -2,
                },
                "@media (max-width: 768px)": {
                  marginLeft: -2,
                },
                "@media (max-width: 480px)": {
                  marginLeft: -2,
                },
              }}
            />
          </IconButton> */}
        </Toolbar>

        <Divider sx={{ backgroundColor: theme.palette.border }} />
        <List
          sx={{
            display: "block",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            left: 10,
          }}
        >
          {currentSidebarItems.map((item) => (
            <SidebarItemComponent
              key={item.label}
              label={item.label}
              icon={item.icon}
              path={item.path}
            />
          ))}
        </List>

        {/* <SidebarFooter>
          <List
            sx={{
              display: isSidebarOpen ? "flex" : "block",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isSidebarOpen ? "0px 30px" : "0px",
              height: "150px",
            }}
          >
            {sidebarFooterItems.map((item) => (
              <SidebarItemComponent
                key={item.label}
                label={item.label}
                icon={item.icon}
                path={item.path}
              />
            ))}
          </List>
        </SidebarFooter> */}
      </SidebarDrawer>

      {/* Main Content */}
      <Box flexGrow={1}>
        {/* AppBar */}
        {endpoint === "responses" ? (
          <>
            <ResponseTableAppBar isSidebarOpen={false} sme={true} />
          </>
        ) : (
          <>
            <ResponsiveAppBar isSidebarOpen={false} sme={true} />
          </>
        )}
        <Box
          sx={{
            mt: "75px",
            zIndex: -2,
            bgcolor: theme.palette.surface.outletBackground,
            // width: "95.6vw",
            // minHeight: "92vh",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
