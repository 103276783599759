import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Box,
  LinearProgress,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useScreenSizes from "../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../shared/components/Button/MultiTypeButton";
import { handleError } from "../shared/components/Errors/HandleError";
import GradingParams from "./components/gradingParameters";
import ModelSelection from "./components/modelSelection";
import PromptCreation from "./components/promptCreation";
import PromptDataSets from "./components/promptDataSets";
import PromptDetails from "./components/promptDetails";
import { postPrompt, validateAPI } from "./services";
import { StickyFooter } from "./styles";

const steps = [
  "Prompt Details",
  "Add Dataset",
  "Prompt Creation",
  "Model Selection",
  "Grading Parameters",
];

const CustomStepIcon = (props: any) => {
  const { active, completed, className, isMediumLaptop, isMediumLaptop2 } =
    props;
  return (
    <div
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: isMediumLaptop || isMediumLaptop2 ? 30 : 40,
        height: isMediumLaptop || isMediumLaptop2 ? 30 : 40,
        borderRadius: "50%",
        backgroundColor: completed ? "#4caf50" : active ? "#FFFFFF" : "#FFFFFF",
        border: active ? "1px solid #F07946" : "1px solid #1212124D",
        color: active ? "#F07946" : "#1212124D",
        fontWeight: "bold",
      }}
    >
      {completed ? <CheckOutlinedIcon sx={{ color: "#FFFFFF" }} /> : props.icon}
    </div>
  );
};

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses?.line}`]: {
    borderColor: theme.palette.divider,
    borderTopWidth: 2,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const Prompts = () => {
  const prevActiveTab = localStorage.getItem("activeTab");
  const queryClient = useQueryClient();
  const { projectId, promptId } = useParams();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  let isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  let isMediumLaptop2 = useMediaQuery("(max-width: 1290px)");

  const [isPreviousStepSkipped, setIsPreviousStepSkipped] = useState(false);
  const [skipped, setSkipped] = useState(new Set<number>());

  const [isValidPromptDetails, setIsValidPromptDetails] = useState(false);
  const [isValidDataset, setIsValidDataset] = useState(false);
  const [isValidPromptCreation, setIsValidPromptcreation] = useState(false);
  const [isValidModelSelection, setIsValidModelSelection] = useState(false);
  const [isValidGradingParams, setIsValidGradingParams] = useState(false);
  const [validButton, setIsValidButton] = useState<any>();
  const [detailsData, setDetailsData] = useState<any>();
  const [datasetData, setDatasetData] = useState([]);
  const [promptcreationData, setPromptcreationData] = useState<any>();
  const [modelSelectionData, setModelSelectionData] = useState<any>();
  const [gradingParamsData, setGradingParamsData] = useState();

  const [mutationSuccess, setMutationSuccess] = useState(false);

  // const stepRoutes = [
  //   "prompt-details",
  //   "/data-sets",
  //   "/prompt-create",
  //   "/model-select",
  //   "/grading",
  // ];

  // useEffect(() => {
  //   navigate(stepRoutes[activeStep]);
  // }, [activeStep, navigate, stepRoutes]);

  const Parameters = [
    {
      name: "Overall Quality",
      description: "General assessment of response quality",
      type: "Default",
    },
  ];

  const { mutate, isLoading } = useMutation(validateAPI, {
    onSuccess: () => {
      setMutationSuccess(true);
      toast.success("Prompt Details Added  Successfully");
      if (activeStep === 3) {
        const existingGradingParams = localStorage.getItem("GradingParams");
        if (!existingGradingParams) {
          localStorage.setItem("GradingParams", JSON.stringify(Parameters));
        }
      }
      queryClient.invalidateQueries("validate");
    },
    onError: (err) => {
      setMutationSuccess(false);
      if (activeStep === 3) {
        toast.error("Please add a preset before proceeding");
      } else {
        handleError(err);
      }
    },
  });

  const { mutate: PostPrompt, isLoading: PromptLoading } = useMutation(
    postPrompt,
    {
      onSuccess: () => {
        toast.success("Prompt Added  Successfully");
        localStorage.clear();
        navigate(`/app/projects/${projectId}/prompts/all`);
        // navigate(
        //   `/app/projects/${projectId}/prompts/${promptId}/prompt-detailed-view`
        // );
      },
      onError: (err) => {
        handleError(err);
      },
    }
  );

  useEffect(() => {
    const determineActiveStep = () => {
      if (localStorage.getItem("reloadTab")) {
        setActiveStep(1);
        return;
      }
      if (localStorage.getItem("PromptDetails")) {
        setActiveStep(0);
      }
      if (localStorage.getItem("PromptDataset")) {
        setActiveStep(1);
      }
      if (localStorage.getItem("PromptCreation")) {
        setActiveStep(2);
      }
      if (localStorage.getItem("ModelSelection")) {
        setActiveStep(3);
      }
      if (localStorage.getItem("GradingParams")) {
        setActiveStep(4);
      }
    };

    determineActiveStep();
  }, []);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = async () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    localStorage.setItem("currentActiveState", (activeStep + 1).toString());

    const handleMutationCallback = (onSuccessCallback: () => void) => {
      mutate(
        {
          // Pass mutation data based on the step
          ...(activeStep === 0 && {
            type: "Details",
            details: {
              title: detailsData?.name,
              category: detailsData?.promptCategory,
              tag: detailsData?.tag,
            },
          }),
          ...(activeStep === 1 && {
            type: "Dataset",
            datasetIds: datasetData?.map((i: any) => i?._id),
          }),
          ...(activeStep === 2 && {
            type: "Creation",
            datasets: [
              {
                datasetId: promptcreationData[0]?.promptCategorySingle || "",
                datasetVersionIds:
                  promptcreationData[0]?.promptCategoryMulti || [],
                params: promptcreationData[0]?.promptCategoryParams || [],
                description: localStorage.getItem("Updated Prompt") || "",
              },
            ],
          }),
          ...(activeStep === 3 && {
            type: "Model",
            models: modelSelectionData?.map((model: any) => ({
              modelId: model.modelId,
              presets: model.presets,
            })),
          }),
        },
        {
          onSuccess: () => {
            onSuccessCallback();
          },
          // onError: () => {
          //   toast.error("Please resolve errors before proceeding.");
          // },
        }
      );
    };

    if (activeStep === 0 && isValidPromptDetails) {
      handleMutationCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      });
      localStorage.setItem("PromptDetails", JSON.stringify(detailsData));
    }

    if (activeStep === 1) {
      handleMutationCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      });
      localStorage.setItem(
        "PromptDataset",
        JSON.stringify(datasetData?.map((i: any) => i?._id))
      );
    }

    if (activeStep === 2 && isValidPromptCreation) {
      handleMutationCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      });
      const storedDescription = localStorage.getItem("Updated Prompt");
      const datasetConfig = {
        datasetId: promptcreationData[0]?.promptCategorySingle || "",
        datasetVersionIds: promptcreationData[0]?.promptCategoryMulti || [],
        params: promptcreationData[0]?.promptCategoryParams || [],
        description: storedDescription || "",
      };
      localStorage.setItem("PromptCreation", JSON.stringify(datasetConfig));
    }

    if (activeStep === 3) {
      const storedPresets = JSON.parse(localStorage.getItem("presets") || "{}");

      if (
        !modelSelectionData ||
        !Array.isArray(modelSelectionData) ||
        modelSelectionData?.length === 0
      ) {
        toast.error("Please select at least one model before proceeding.");
        return;
      }

      handleMutationCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      });

      localStorage.setItem(
        "ModelSelection",
        JSON.stringify(
          modelSelectionData.map((model: any) => ({
            modelId: model.modelId,
            versionId: model.versionId,
            presets: model.presets,
          }))
        )
      );
    }

    if (activeStep === 4 && isValidGradingParams) {
      const promptDetails = JSON.parse(
        localStorage.getItem("PromptDetails") || "{}"
      );
      const promptDataset = JSON.parse(
        localStorage.getItem("PromptDataset") || "{}"
      );
      const promptCreation = JSON.parse(
        localStorage.getItem("PromptCreation") || "{}"
      );
      const modelSelection = JSON.parse(
        localStorage.getItem("ModelSelection") || "[]"
      );
      const gradingParams = JSON.parse(
        localStorage.getItem("GradingParams") || "[]"
      );

      PostPrompt({
        projectId: projectId,
        details: {
          title: promptDetails?.name,
          category: promptDetails?.promptCategory,
          tag: promptDetails?.tag,
        },
        datasetIds: promptDataset || [],
        dataset: promptCreation,
        models: modelSelection.map((model: any) => ({
          modelId: model.versionId,
          presets: model.presets,
        })),
        gradingParams: gradingParamsData || gradingParams,
      });

      localStorage.setItem("GradingParams", JSON.stringify(gradingParamsData));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setIsPreviousStepSkipped(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setIsPreviousStepSkipped(true);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleStepClick = (stepIndex: number) => {
    const completedSteps = [
      "PromptDetails",
      "PromptDataset",
      "PromptCreation",
      "ModelSelection",
      "GradingParams",
    ];

    const preActiveState = parseInt(
      localStorage.getItem("currentActiveState") || "0"
    );

    if (
      stepIndex <= activeStep ||
      stepIndex === preActiveState ||
      completedSteps
        .slice(0, Math.max(activeStep + 1, preActiveState + 1))
        .some(
          (step, index) => stepIndex === index && localStorage.getItem(step)
        )
    ) {
      setActiveStep(stepIndex);
    }
  };

  // const handleStepClick = (stepIndex: number) => {
  //   setActiveStep(stepIndex);
  // };

  if (PromptLoading) {
    return <LinearProgress />;
  }

  return (
    <Box
      sx={{
        padding: "2.2vh 2vw 1.3vh 1.5vw",
        "@media (max-width: 1650px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
        "@media (max-width: 1450px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
        "@media (max-width: 1280px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
        "@media (max-width: 960px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
        "@media (max-width: 768px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
        "@media (max-width: 480px)": {
          padding: "2vh 2.5vw 1.3vh 1.5vw",
        },
      }}
    >
      <Stepper
        activeStep={activeStep}
        connector={<CustomConnector />}
        sx={{ cursor: "pointer" }}
      >
        {steps?.map((label, index) => {
          // const stepProps: { completed?: boolean } = {};
          // const labelProps: {
          //   optional?: React.ReactNode;
          // } = {};
          // if (isStepOptional(index)) {
          //   labelProps.optional = (
          //     <Typography variant="caption">Optional</Typography>
          //   );
          // }
          // if (isStepSkipped(index)) {
          //   stepProps.completed = false;
          // }
          return (
            <Step
              key={label}
              // {...stepProps}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel
                StepIconComponent={(stepIconProps) => (
                  <CustomStepIcon
                    {...stepIconProps}
                    isMediumLaptop={isMediumLaptop}
                    isMediumLaptop2={isMediumLaptop2}
                  />
                )}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    opacity: activeStep === index ? 1 : 0.5,
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        sx={{
          borderBottom: "1px solid #1212121A",
          mt: 1.5,
          mx: 1,
        }}
      />

      {activeStep === 0 && (
        <PromptDetails
          finalData={setDetailsData}
          onValid={setIsValidPromptDetails}
        />
      )}
      {activeStep === 1 && (
        <PromptDataSets
          onValid={setIsValidDataset}
          skip={handleSkip}
          finalData={setDatasetData}
        />
      )}
      {activeStep === 2 && (
        <PromptCreation
          finalData={setPromptcreationData}
          onValid={setIsValidPromptcreation}
          isPreviousStepSkipped={isPreviousStepSkipped}
        />
      )}
      {activeStep === 3 && (
        <ModelSelection
          finalData={setModelSelectionData}
          onValid={setIsValidModelSelection}
          buttonData={setIsValidButton}
        />
      )}
      {activeStep === 4 && (
        <GradingParams
          finalData={setGradingParamsData}
          onValid={setIsValidGradingParams}
        />
      )}

      {/* {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )} */}

      <StickyFooter
        sx={{
          padding:
            activeStep === 0 ? "1.39vh 9.9vw" : "1.39vh 9.9vw 1.39vh 17.63vw",

          justifyContent: activeStep === 0 ? "flex-end" : "space-between",
          "@media (max-width: 1650px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
          },
          "@media (max-width: 1450px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
            // mb: 2,
          },
          "@media (max-width: 1280px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
            mb: 2,
          },
          "@media (max-width: 960px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
            mb: 2,
          },
          "@media (max-width: 768px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
            mb: 2,
          },
          "@media (max-width: 480px)": {
            padding: activeStep === 0 ? "1vh 9vw" : "1vh 9vw 1vh 21vw",
            mb: 2,
          },
        }}
      >
        {/* <Button onClick={handleNext}>
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button> */}

        {activeStep !== 0 && (
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
            typeText={"Previous"}
            actionOnClick={handleBack}
          />
        )}

        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          // isLoading={isLoading}
          disabled={
            (activeStep === 0 && !isValidPromptDetails) ||
            (activeStep === 1 && !isValidDataset) ||
            (activeStep === 2 && !isValidPromptCreation) ||
            // (activeStep === 3 && !validFinalButton) ||
            (activeStep === 4 && !isValidGradingParams)
          }
          width={
            isLargeScreen
              ? "192px"
              : isMediumScreen
              ? "115px"
              : isSmallScreen
              ? "61px"
              : "192px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
          typeText={activeStep === steps.length - 1 ? "Run Prompt" : "Next"}
          actionOnClick={handleNext}
        />
      </StickyFooter>
    </Box>
  );
};

export default Prompts;
