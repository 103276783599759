import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo, useState } from "react";

const StyledSelect = styled(Select)({
  backgroundColor: "#F8F8F8",
  borderRadius: "8px",
  width: "300px",
  "@media (max-width: 1440px)": {
    width: "200px",
    height: "35px",
  },
  "@media (max-width: 1280px)": {
    width: "200px",
    height: "35px",
  },
  "@media (max-width: 960px)": {
    width: "200px",
    height: "35px",
  },
  "@media (max-width: 768px)": {
    width: "200px",
    height: "35px",
  },
  "@media (max-width: 480px)": {
    width: "200px",
    height: "35px",
  },
});

const menuProps = {
  PaperProps: {
    style: {
      marginTop: "8px",
      width: "300px",
      "@media (max-width: 1440px)": {
        width: "200px",
      },
      "@media (max-width: 1280px)": {
        width: "200px",
      },
      "@media (max-width: 960px)": {
        width: "200px",
      },
      "@media (max-width: 768px)": {
        width: "200px",
      },
      "@media (max-width: 480px)": {
        width: "200px",
      },
    },
  },
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
  transformOrigin: {
    vertical: "top" as const,
    horizontal: "left" as const,
  },
};

interface ModelFilterProps {
  rowsData: any[];
  onFilterChange: (selectedModels: string[]) => void;
}

export default function ModelFilter({
  rowsData,
  onFilterChange,
}: ModelFilterProps) {
  const [selectedModels, setSelectedModels] = useState<string[]>([]);

  const modelOptions = useMemo(() => {
    if (!rowsData?.length) return [];

    const allModels = Object.keys(rowsData[0])
      .filter((key) => key !== "DatasetRecord" && key !== "+AddModel")
      .map((key) => key.replace(/_v[12]$/, ""));

    return [...new Set(allModels)];
  }, [rowsData]);

  const handleChange = (event: any) => {
    const value = event.target.value;
    const selectedValues = typeof value === "string" ? value.split(",") : value;
    setSelectedModels(selectedValues);
    onFilterChange(selectedValues);
  };

  return (
    <StyledSelect
      multiple
      displayEmpty
      value={selectedModels}
      onChange={handleChange}
      IconComponent={KeyboardArrowDownIcon}
      MenuProps={menuProps}
      renderValue={(selected) => {
        if (!selected || (Array.isArray(selected) && selected.length >= 0)) {
          return (
            <Typography
              variant="h6"
              sx={{
                opacity: 0.5,
              }}
            >
              Select LLM Models
            </Typography>
          );
        }
        // return (Array.isArray(selected) ? selected : []).join(", ");
      }}
    >
      {modelOptions.map((model) => (
        <MenuItem
          key={model}
          value={model}
          sx={{
            color: "#6B7280",
            fontSize: "14px",
            padding: "5px",
            "@media (max-width: 1440px)": {
              fontSize: "12px",
              padding: "5px",
            },
            "@media (max-width: 1280px)": {
              fontSize: "12px",
              padding: "5px",
            },
            "@media (max-width: 960px)": {
              fontSize: "12px",
              padding: "5px",
            },
            "@media (max-width: 768px)": {
              fontSize: "12px",
              padding: "5px",
            },
            "@media (max-width: 480px)": {
              fontSize: "12px",
              padding: "5px",
            },
          }}
        >
          <Checkbox
            checked={selectedModels.indexOf(model) > -1}
            size="small"
            sx={{
              padding: "5px",
              "&.Mui-checked": {
                color: "#F87320",
              },
            }}
          />
          <ListItemText
            primary={model}
            sx={{
              margin: 0,
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: "#6B7280",
              },
              "@media (max-width: 1440px)": {
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
              "@media (max-width: 1280px)": {
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
              "@media (max-width: 960px)": {
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
              "@media (max-width: 768px)": {
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
              "@media (max-width: 480px)": {
                "& .MuiTypography-root": {
                  fontSize: "12px",
                  color: "#6B7280",
                },
              },
            }}
          />
        </MenuItem>
      ))}
    </StyledSelect>
  );
}
