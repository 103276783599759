import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  FormControl,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  StackProps,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

export type SelectProps = {
  containerProps?: StackProps;
  description?: string;
  control: any;
  options: any;
  defaultData?: string;
  backgroundColor?: string;
  border?: string;
  width?: string;
  height?: string;
  textColor?: string;
  borderRadius?: any;
  padding?: any;
  onChange?: (value: any) => void;
  disabled?: boolean;
} & MuiSelectProps;

export const SingleSelect = ({
  name = "",
  label,
  control,
  required = false,
  containerProps,
  description,
  options,
  placeholder,
  height,
  defaultData,
  backgroundColor,
  border,
  width,
  onChange,
  padding,
  borderRadius,
  textColor,
  disabled = false,
}: SelectProps) => {
  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      containerProps={containerProps}
    >
      <FormControl fullWidth required={required}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultData}
          render={({ field: { onChange: fieldOnChange, ...field } }) => (
            <MuiSelect
              {...field}
              // displayEmpty
              value={field.value || ""}
              disabled={disabled}
              onChange={(event) => {
                const value = event.target.value;
                fieldOnChange(value);
                if (onChange) {
                  onChange(value);
                }
              }}
              IconComponent={ExpandMoreIcon}
              // placeholder={
              //   name === "promptCategorySingle" ? "Select dataset" : placeholder
              // }
              sx={{
                height: height,
                width: width,
                backgroundColor: backgroundColor,
                border: border,
                borderRadius: borderRadius,
                color: textColor,
                padding: padding,
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    mt: 1,
                    borderRadius: 2,
                    width: width,
                    boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                  },
                },
              }}
            >
              {/* {name === "promptCategorySingle" && (
                <MenuItem value="" disabled>
                  {placeholder || "Select an option"}
                </MenuItem>
              )} */}

              {options?.map((option: any, index: any) => (
                <MenuItem
                  key={option?.value ? option?.value : option}
                  value={option?.value ? option?.value : option}
                  sx={{
                    width: width,
                    borderBottom:
                      index !== options?.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                    fontWeight: 400,
                    fontSize: "16px",
                    "@media (min-width:600px)": {
                      fontSize: "16px",
                    },
                    "@media (min-width:900px)": {
                      fontSize: "16px",
                    },
                    "@media (min-width:1200px)": {
                      fontSize: "16px",
                    },
                    "@media (min-width:1536px)": {
                      fontSize: "18px",
                    },
                  }}
                >
                  {option?.label ? option?.label : option}
                </MenuItem>
              ))}
            </MuiSelect>
          )}
        />
      </FormControl>
      {description && <Typography variant="caption">{description}</Typography>}
    </LabelWrapper>
  );
};
