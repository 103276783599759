import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Images } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import { getOneProject } from "../../../project/services/ProjectService";
import LLMCard from "../../../prompt/components/modelSelectionComponents/LLMCard";
import PresetsSection from "../../../prompt/components/modelSelectionComponents/PresetsSection";
import SavePresentDialogCard from "../../../prompt/components/modelSelectionComponents/savePresentDialogCard";
import SliderSection from "../../../prompt/components/modelSelectionComponents/SliderSection";
import { getAllModels, postReRun } from "../../../prompt/services";
import { AntSwitch } from "../../../prompt/styles";
import { extractMaxValue, extractMinValue } from "../../../prompt/utility";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";
import { handleError } from "../Errors/HandleError";
import Spinner from "../Loaders/Spinner";
import SearchContainer from "../SearchContainer";
import DialogWrapper from "../Wrapper/DialogWrapper";

const AddModelDialog = ({
  open,
  setOpen,
  setSelectedCell,
  data,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSelectedCell: any;
  data: any;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  const queryClient = useQueryClient();
  const { projectId, promptId, versionId } = useParams();

  const [cardSelected, setCardSelected] = useState<any[]>([]);

  const [openPresent, setOpenPresent] = useState(false);

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [parent, setParent] = useState<any>();
  const [child, setChild] = useState<any>();

  const [presetsVersion, setPresetsVersion] = useState(0);

  const [sliderMaxTokens, setSliderMaxTokens] = useState(1647);
  const [maxTokens, setMaxTokens] = useState(1647);

  const [sliderTopPValue, setSliderTopPValue] = useState(0.5);

  const [sliderTempValue, setSliderTempValue] = useState<any>(0);
  const [tempValue, setTempValue] = useState("0-1");

  const [textForNext, setTextForNext] = useState<any>();

  const [showSelectedOnly, setShowSelectedOnly] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [onePreset, setOnePreset] = useState<any>();

  const [presetCreation, setPresetCreation] = useState<any>();

  const [localPreset, setLocalPreset] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem("presets") || "{}");
    } catch (error) {
      console.error("Error parsing presets from localStorage:", error);
      return {};
    }
  });

  const { data: GetModels, isLoading: modelsLoading } = useQuery(
    ["all-models", search, parent, child],
    () =>
      getAllModels({
        search,
        providerId: parent,
        modelId: child,
      })
  );

  const filteredModels = useMemo(() => {
    const excludedModelIds = data?.models?.flatMap((m: any) => m?.modelId);

    // console.log("Data:", data, GetModels?.models, excludedModelIds);

    return GetModels?.models?.filter(
      (model: any) =>
        !excludedModelIds.some((key: any) => key === model?.versionId)
    );
  }, [GetModels?.models, data]);

  // console.log("MainFilter:", filteredModels);

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || {};

  const version = segregatedData?.versions?.find(
    (v: any) => v?._id == versionId
  );

  const { mutate, isLoading: isSubmitting } = useMutation(postReRun, {
    onSuccess: () => {
      toast.success("Models added successfully");
      queryClient.invalidateQueries("prompt-responses");
      queryClient.invalidateQueries("one-responses");
      queryClient.invalidateQueries("grading-params");
      setOpen(false);
      window.location.reload();
    },
    onError: (err) => handleError(err),
  });

  const handleCardSelection = useCallback((data: any, checked: boolean) => {
    setCardSelected((prevSelected) => {
      if (checked) {
        return [...prevSelected, { ...data, checked }];
      } else {
        return prevSelected?.filter(
          (selectedCard) => selectedCard?.versionName !== data?.versionName
        );
      }
    });
  }, []);

  const presets = useMemo(() => {
    const local = JSON.parse(localStorage.getItem("presets") || "{}");
    const selectedversionNames = cardSelected.map((card) => card?.versionName);
    return Object.values(local)
      ?.flat()
      ?.filter((preset: any) =>
        preset.selectedCards.some((card: any) =>
          selectedversionNames.includes(card?.versionName)
        )
      );
  }, [cardSelected, presetsVersion]);

  const finalPresetData = useMemo(() => {
    const uniqueModels = new Map();

    cardSelected.forEach((selectedCard) => {
      const applicablePresets = presets
        ?.filter((preset: any) =>
          preset.applicableKeys?.includes(selectedCard.versionName)
        )
        .map((preset: any) => ({
          name: preset.name,
          maxTokens: preset.maxTokens,
          temperature: preset.temperature,
          topP: preset.topP,
        }));

      // Construct the model object
      const model = {
        providerId: selectedCard.providerId,
        providerName: selectedCard.providerName,
        modelId: selectedCard.modelId,
        modelName: selectedCard.modelName,
        versionId: selectedCard.versionId,
        versionName: selectedCard.versionName,
        presets: applicablePresets,
      };

      // Use a Map to ensure uniqueness based on versionId
      if (!uniqueModels.has(selectedCard.versionId)) {
        uniqueModels.set(selectedCard.versionId, model);
      }
    });

    // Convert the Map back to an array
    return Array.from(uniqueModels.values());
  }, [cardSelected, presets]);

  // console.log("Final Final:", finalPresetData);

  const handlePresetsUpdate = useCallback(() => {
    setPresetsVersion((prevVersion) => prevVersion + 1);
  }, []);

  const handleDeletePreset = () => {
    if (!onePreset) return;

    const updatedPresets = JSON.parse(localStorage.getItem("presets") || "{}");
    // console.log("Delete2:", updatedPresets);

    const presetKey = onePreset.selectedCards[0].versionName;

    if (updatedPresets[presetKey]) {
      updatedPresets[presetKey] = updatedPresets[presetKey]?.filter(
        (preset: any) => preset.name !== onePreset.name
      );

      if (updatedPresets[presetKey].length === 0) {
        delete updatedPresets[presetKey];
      }

      localStorage.setItem("presets", JSON.stringify(updatedPresets));
      setLocalPreset(updatedPresets);
      setOnePreset(null);
      setShowDelete(false);
      handlePresetsUpdate();
    }
  };

  const handleToggleChange = () => {
    setShowSelectedOnly((prevState: any) => !prevState);
  };

  const handleSaveChanges = () => {
    if (!onePreset) return;

    const updatedPresets = JSON.parse(localStorage.getItem("presets") || "{}");
    const presetKey = onePreset.selectedCards[0].versionName;

    if (updatedPresets[presetKey]) {
      updatedPresets[presetKey] = updatedPresets[presetKey].map(
        (preset: any) => {
          if (preset.name === onePreset.name) {
            return {
              ...preset,
              temperature: sliderTempValue,
              topP: sliderTopPValue,
              maxTokens: sliderMaxTokens,
            };
          }
          return preset;
        }
      );

      localStorage.setItem("presets", JSON.stringify(updatedPresets));
      setLocalPreset(updatedPresets);
      setOnePreset(null);
      setShowDelete(false);
      handlePresetsUpdate();
    }
  };

  const handleSubmit = () => {
    const postData = {
      projectId,
      promptId,
      versionId,
      datasetConfigId: version?.datasets?.[0]?._id,
      models: finalPresetData?.map((model: any) => ({
        modelId: model?.versionId,
        presets: model?.presets,
      })),
    };

    // console.log("Submitting Data:", postData);
    mutate(postData);
  };

  if (modelsLoading) {
    <Spinner />;
  }

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
        setSelectedCell(null);
      }}
      title="Add Model"
      // width={isLargeScreen ? "lg" : isMediumScreen ? "md" : "md"}
      width="lg"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={{
            width: "65%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              mb: 1,
              gap: "10px",
            }}
          >
            <Box
              sx={{
                mt: -1,
              }}
            >
              <SearchContainer
                debounced={true}
                onChange={setSearch}
                value={search}
              />
            </Box>
            {/* <Box
              sx={{
                mt: -0.5,
                position: "relative",
              }}
            >
              <CustomDropdown
                options={Options}
                onChange={(
                  parentIds: any,
                  childrenIds: any,
                  versionIds: any
                ) => {
                  setParent(parentIds);
                  setChild(childrenIds);
                  setVersion(versionIds);
                }}
              />
            </Box> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 0.5,
                gap: "0.5vw",
              }}
            >
              <AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                onChange={handleToggleChange}
              />
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                }}
              >
                {useMediaQuery("(max-width: 1700px)")
                  ? "Selected Models"
                  : "Show only Selected Models"}
              </Typography>
            </Box>
          </Box>

          <Box>
            <LLMCard
              load={presetsVersion}
              data={filteredModels}
              loading={modelsLoading}
              onCardSelection={handleCardSelection}
              selectedCard={cardSelected}
              showSelectedOnly={showSelectedOnly}
              nextButton={setTextForNext}
              // sliderTemp={setSliderTempValue}
              maxTemp={setTempValue}
              sliderMaxTokens={setSliderMaxTokens}
              maxTokens={setMaxTokens}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "35%",
            p: "5px 10px 0px 10px",
            borderLeft: "1px solid #1212121A",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              paddingBottom: "2vh",
              paddingLeft: "0.4vw",
              borderBottom: "1px solid #1212121A",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
              }}
            >
              Preset Configuration
            </Typography>
          </Box>

          {cardSelected?.length > 0 ? (
            <Box
              sx={{
                p: "5px 10px 0px 10px",
                maxHeight: "60vh",
                overflowY: "auto",
                overflowX: "hidden",
                scrollbarWidth: "thin",
                scrollbarColor: "#F07946 #E8E8E8",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#F07946",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#E8E8E8",
                  margin: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <PresetsSection
                presets={presets}
                cardSelected={cardSelected}
                preSetDelete={setShowDelete}
                includedPreset={setOnePreset}
                presetCreation={setPresetCreation}
                clearSelection={onePreset}
                dialog={true}
              />

              <Divider
                sx={{
                  mt: 1,
                  mb: 1,
                  borderWidth: 1,
                  mx: "-2vw",
                }}
              />
              <SliderSection
                label="Max tokens"
                value={Number(sliderMaxTokens)}
                onChange={(event: any, newValue: any) =>
                  setSliderMaxTokens(newValue)
                }
                color="#F57C00"
                step={1}
                min={0}
                max={maxTokens}
              />
              <SliderSection
                label="Top P"
                value={Number(sliderTopPValue)}
                onChange={(event: any, newValue: any) =>
                  setSliderTopPValue(newValue)
                }
                color="#F57C00"
                step={0.1}
                min={0}
                max={1}
              />
              <SliderSection
                label="Temperature"
                value={sliderTempValue}
                onChange={(event: any, newValue: any) =>
                  setSliderTempValue(newValue)
                }
                color="#F57C00"
                step={0.1}
                min={extractMinValue(tempValue)}
                max={extractMaxValue(tempValue)}
              />

              {showDelete ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  <IconButton
                    onClick={handleDeletePreset}
                    sx={{
                      bgcolor: "#F2F2F2",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>

                  <MultiTypeButton
                    buttonType={ButtonType.Gradient}
                    // isLoading={isLoading}
                    typeText={"Save Changes"}
                    actionOnClick={handleSaveChanges}
                    height={
                      isLargeScreen
                        ? "42px"
                        : isMediumScreen
                        ? "40px"
                        : isSmallScreen
                        ? "38px"
                        : "35px"
                    }
                  />
                </Box>
              ) : (
                (() => {
                  // const selectedKeys = cardSelected.map(
                  //   (card: any) => card?.versionName
                  // );
                  const selectedKeys = [textForNext?.versionName];
                  const maxPresetsReached = selectedKeys.some((key: string) => {
                    const presetsForKey = presets?.filter((preset: any) =>
                      preset.selectedCards.some(
                        (card: any) => card?.versionName === key
                      )
                    );
                    return presetsForKey.length >= 3;
                  });

                  return maxPresetsReached ? null : (
                    <MultiTypeButton
                      buttonType={ButtonType.Gradient}
                      // isLoading={isLoading}
                      typeText={"Add Preset"}
                      // actionOnClick={handleAddPreset}
                      actionOnClick={() => {
                        // if (cardSelected.length >= 2) {
                        //   toast.error(
                        //     "Presets can only be added to one model at a time."
                        //   );
                        // } else {
                        setOpenPresent(true);
                        // }
                      }}
                      height={
                        isLargeScreen
                          ? "42px"
                          : isMediumScreen
                          ? "40px"
                          : isSmallScreen
                          ? "38px"
                          : "35px"
                      }
                    />
                  );
                })()
              )}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                  fontStyle: "italic",
                  mt: 1,
                }}
              >
                Note: Upon selecting a model, you may configure up to three
                presets.
              </Typography>

              <SavePresentDialogCard
                open={openPresent}
                setOpen={setOpenPresent}
                data={{
                  selectedCards: presetCreation || cardSelected,
                  // selectedCards: cardSelected,
                  maxTokens: sliderMaxTokens,
                  temperature: sliderTempValue,
                  topP: sliderTopPValue,
                }}
                onPresetsUpdate={handlePresetsUpdate}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                p: 2,
                marginTop: "15vh",
              }}
            >
              <img
                src={Images.emptyScreen}
                alt="Empty"
                style={{
                  width: "60%",
                  height: "auto",
                  // marginBottom: "20px",
                }}
              />
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                Create your first preset
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                You can save your preset configuration by selecting one of the
                model
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: 2,
          pr: 2,
        }}
      >
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          isLoading={isSubmitting}
          disabled={
            finalPresetData?.length === 0 ||
            finalPresetData.some(
              (model) => !model.presets || model.presets.length === 0
            )
          }
          width={
            isLargeScreen
              ? "192px"
              : isMediumScreen
              ? "115px"
              : isSmallScreen
              ? "61px"
              : "192px"
          }
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
          typeText={"Add Model"}
          actionOnClick={handleSubmit}
        />
      </Box>
    </DialogWrapper>
  );
};

export default AddModelDialog;
