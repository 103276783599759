import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { addUser, updateUser } from "../../layout/services/LayoutService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../shared/components/Errors/HandleError";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import { MultiTypeTextField } from "../../shared/components/TextField/multiTypeTextField";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import DialogWrapper from "../../shared/components/Wrapper/DialogWrapper";
import { getAllRoles } from "../services/roleService";

const usersSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup.string().required("Email is required"),
  type: yup.string().required("Type is required"),
  expertise: yup.array().when("type", {
    is: "SME",
    then: (schema) => schema.min(1, "At least one expertise is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const UsersForm = ({ open, setOpen, data }: any) => {
  const queryClient = useQueryClient();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(usersSchema),
    defaultValues: {
      name: "",
      email: "",
      type: "",
      expertise: [],
    },
  });

  const { data: rolesData, isLoading: rolesLoading }: any = useQuery(
    "users",
    getAllRoles
  );

  const { mutate, isLoading } = useMutation(data ? updateUser : addUser, {
    onSuccess: (res) => {
      {
        data
          ? toast.success("User Updated  Successfully")
          : toast.success("User Added  Successfully");
      }
      console.log("Last:", res);

      queryClient.invalidateQueries("users");
      setOpen(false);
      reset();
    },
    onError: (err) => handleError(err),
  });

  const roleOptions =
    rolesData?.data?.roles?.map((i: { name: any; _id: any }) => ({
      label: i?.name,
      value: i?._id,
    })) || [];

  const typeRefetch = roleOptions?.find(
    (f: any) => f?.value === data?.roleId
  )?.value;

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || "",
        email: data.emailAddress || "",
        type: typeRefetch,
        expertise: data.expertise || [],
      });
    }
  }, [data, reset]);

  const SMEValue = roleOptions?.find((f: any) => f?.label === "Sme");
  const IsSme = SMEValue?.value === watch("type");

  const onSubmit = (value: any) => {
    const mutationData = {
      name: value.name,
      email: value.email,
      roleId: value.type,
      ...(IsSme && { expertise: value.expertise }),
    };

    if (data) {
      mutate({
        data: mutationData,
        id: data._id,
      });
    } else {
      mutate(mutationData);
    }
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
        reset();
      }}
      title={data ? "Update user" : "Create user"}
      width={isLargeScreen ? "sm" : isMediumScreen ? "xs" : "xs"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Name"
          name="name"
          required={data ? false : true}
          placeholder="Enter name"
          backgroundColor="#FFFFFF"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <TextField
          formateType={Formate.EmailAddress}
          control={control}
          label="Email"
          name="email"
          required={data ? false : true}
          placeholder="Enter email"
          backgroundColor="#FFFFFF"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <SingleSelect
          name="type"
          control={control}
          options={roleOptions}
          label="Type"
          required={data ? false : true}
          placeholder="Select type"
          backgroundColor="#FFFFFF"
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        {IsSme && (
          <MultiTypeTextField
            control={control}
            name="expertise"
            label="Areas of Expertise"
            required={true}
            placeholder="Enter your expertise and press enter"
            backgroundColor="#FFFFFF"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        )}

        <Box sx={{ display: "flex", textAlign: "center", mt: 2, gap: "15px" }}>
          <MultiTypeButton
            buttonType={ButtonType.Cancel}
            typeText={"Cancel"}
            actionOnClick={() => setOpen(false)}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />

          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            isLoading={isLoading}
            typeText={data ? "Update" : "Submit"}
            actionOnClick={() => {
              handleSubmit(onSubmit)();
            }}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default UsersForm;
