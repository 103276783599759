import {
  Box,
  Chip,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  StackProps,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ColorPalette } from "../../../../themes/colorTokens/colorPalette";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

export type MultiTypeTextFieldProps = {
  containerProps?: StackProps;
  description?: string;
  control: any;
  backgroundColor?: string;
  height?: string;
  width?: string;
  defaultValue?: string[];
  disabled?: boolean;
} & MuiTextFieldProps;

export const MultiTypeTextField = ({
  name = "",
  label,
  required = false,
  containerProps,
  description,
  control,
  placeholder,
  backgroundColor,
  height,
  width,
  defaultValue = [],
  disabled = false,
}: MultiTypeTextFieldProps) => {
  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      containerProps={containerProps}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({
          field: { value = [], onChange },
          fieldState: { error },
        }) => (
          <>
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "4px",
                padding: "8px",
                backgroundColor,
                minHeight: height,
                width,
                "&:focus-within": {
                  border: "1px solid #12121226",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  mb: 1,
                  maxHeight: "10vh",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#f1f1f1",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor: "#666",
                    },
                  },
                }}
              >
                {value.map((expertise: string, index: number) => (
                  <Chip
                    key={index}
                    label={expertise}
                    onDelete={() => {
                      const newValue = value.filter(
                        (_: any, i: any) => i !== index
                      );
                      onChange(newValue);
                    }}
                    disabled={disabled}
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#666666",
                      fontSize: "0.8vw",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#F5F5F5",
                      },
                      "& .MuiChip-deleteIcon": {
                        color: "#666666",
                        "&:hover": {
                          color: "#666666",
                        },
                      },

                      borderRadius: "4px",
                    }}
                  />
                ))}
              </Box>
              <MuiTextField
                fullWidth
                placeholder={placeholder}
                disabled={disabled}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    fontSize: "1.11vw",
                    fontWeight: 300,
                    padding: "0 0.5vw",
                    border: "none",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  },
                }}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    (e.target as HTMLInputElement).value
                  ) {
                    e.preventDefault();
                    const newValue = (
                      e.target as HTMLInputElement
                    ).value.trim();
                    if (newValue && !value.includes(newValue)) {
                      onChange([...value, newValue]);
                      (e.target as HTMLInputElement).value = "";
                    }
                  }
                }}
              />
            </Box>
            {error && (
              <Typography
                variant="caption"
                color={ColorPalette.UtilityColors.Red}
                sx={{ mt: 0, fontWeight: "bold" }}
              >
                {error.message}
              </Typography>
            )}
            {description && (
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                {description}
              </Typography>
            )}
          </>
        )}
      />
    </LabelWrapper>
  );
};
