import { Stack, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { displaySuccessMessage } from "../../../app/services";
import { Icons } from "../../../assets";
import { AuthStore } from "../../../store/general";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import { emailVerificationStatus } from "../services/AuthService";

export default function EmailVerificationDetails() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const EmailAddress = AuthStore.useState((s) => s.signupEmailAddress);
  const { data: emailVerificationData }: any = useQuery(
    ["emailVerificationData", EmailAddress],
    emailVerificationStatus,
    {
      refetchInterval: 5000,
      enabled: !!EmailAddress,
      onSuccess: (res) => {
        // console.log("Email Verified:", res);

        if (res.isEmailVerified) {
          displaySuccessMessage(res.message);
          navigate("/register");
        }
      },
    }
  );

  return (
    <Stack
      alignItems={"center"}
      gap={"12px"}
      padding={5}
      sx={{
        "@media (max-width: 1440px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 1280px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 960px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 768px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 480px)": {
          padding: "20px 25px",
        },
      }}
    >
      <img src={Icons.email} alt="loading..." width={"20%"} />
      <Typography variant="h3">Verify your Email</Typography>
      <Typography variant="subtitle2">
        Please check your email for a confirmation
      </Typography>
      <MultiTypeButton
        isLoading={false}
        buttonType={ButtonType.Gradient}
        actionOnClick={() => {
          queryClient.invalidateQueries("emailVerificationData");
        }}
        typeText="Resend Verification Link"
        key={"email-verification"}
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />
    </Stack>
  );
}
