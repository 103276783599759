import { Box, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import { Control, Controller } from "react-hook-form";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

interface OptionType {
  label: string;
  value: string;
  iconUrl?: string;
  backgroundColor?: any;
}

interface AutocompleteGenericProps {
  name: string;
  control: Control<any>;
  options: OptionType[];
  label: string;
  placeholder?: string;
  disabled?: boolean;
  errors?: any;
  sx?: any;
  size?: "small" | "medium";
  required: boolean;
  defaultValue?: string;
  backgroundColor?: any;
  width?: string | number;
  height?: string | number;
}

const AutocompleteGeneric: React.FC<AutocompleteGenericProps> = ({
  name,
  control,
  options,
  label,
  required,
  disabled = false,
  size = "small",
  placeholder = "Select an option",
  defaultValue = "",
  backgroundColor,
  height,
  width,
}) => {
  return (
    <LabelWrapper label={label} required={required} name={name}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            disabled={disabled}
            size={size}
            {...field}
            options={options}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            renderOption={(props, option) => (
              <li {...props}>
                {option.iconUrl && (
                  <img
                    src={option.iconUrl}
                    alt=""
                    width="20"
                    style={{ marginRight: 8 }}
                  />
                )}
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment:
                    field.value && false ? (
                      <InputAdornment position="start">
                        <Box sx={{ marginLeft: "10px" }}>
                          {options.find(
                            (option) => option.value === field.value
                          )?.iconUrl && (
                            <img
                              src={
                                options.find(
                                  (option) => option.value === field.value
                                )?.iconUrl
                              }
                              alt=""
                              width="20"
                            />
                          )}
                        </Box>
                      </InputAdornment>
                    ) : null,
                }}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                }}
                sx={{
                  height: height,
                }}
              />
            )}
            value={
              options.find((option) => option.value === field.value) || null
            }
            onChange={(e, newValue) => {
              field.onChange(newValue ? newValue.value : null); // Register value
            }}
            sx={{
              backgroundColor: backgroundColor,
              height: height,
              width: width,
              "& .MuiInputBase-root": {
                backgroundColor: backgroundColor,
                height: height,
              },
            }}
          />
        )}
      />
    </LabelWrapper>
  );
};

export default AutocompleteGeneric;
