import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import VersionParamsCard from "../../../dataset/components/UploadedCards/VersionParamsCard";
import { getOneDataset } from "../../../dataset/services/DatasetService";
import { useConfirm } from "../../../project/components/confirmDialog";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import { handleError } from "../../../shared/components/Errors/HandleError";
import { postPromptReRun } from "../../../shared/components/Grid/services";
import SearchContainer from "../../../shared/components/SearchContainer";
import EditPromptTable from "../../../shared/components/Table/EditPromptTable";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";

const DatasetsDialog = ({
  open,
  setOpen,
  datasetId,
  datasetVersionIds,
  mainId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  datasetId?: any;
  datasetVersionIds?: any;
  mainId?: any;
}) => {
  const { projectId, promptId, versionId } = useParams();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [preselectedRows, setPreselectedRows] = useState<any[]>([]);
  const [newlySelectedRows, setNewlySelectedRows] = useState<any[]>([]);

  const { data: GetOneDatasets, isLoading } = useQuery(
    ["datasets", datasetId, search],
    getOneDataset,
    {
      enabled: !!datasetId,
    }
  );
  console.log(
    "Selected IDs",
    GetOneDatasets?.data?.dataSetVersions,
    preselectedRows
  );

  const { mutate: PostPromptReRun, isLoading: PromptReRunLoading } =
    useMutation(postPromptReRun, {
      onSuccess: () => {
        toast.success("Prompt re-runned successfully");
        queryClient.invalidateQueries("prompt-responses");
        localStorage.clear();
        window.location.reload();
      },
      onError: (err) => {
        handleError(err);
      },
    });

  useEffect(() => {
    if (!GetOneDatasets?.data?.dataSetVersions) return;

    const datasetVersions = GetOneDatasets?.data?.dataSetVersions;

    const matchedRows = datasetVersions.filter((version: any) =>
      datasetVersionIds.includes(version._id)
    );

    if (matchedRows.length > 0) {
      setPreselectedRows(matchedRows);
    }
  }, [GetOneDatasets, datasetVersionIds]);

  const handleRowSelectionChange = (selected: any[]) => {
    const newSelections = selected.filter(
      (row) => !datasetVersionIds.includes(row._id)
    );
    setNewlySelectedRows(newSelections);
  };

  const handleSubmit = () => {
    const preselectedIds = datasetVersionIds || [];

    // const newlySelectedIds = preselectedRows
    //   .filter((row: any) => !datasetVersionIds.includes(row._id))
    //   .map((row: any) => row._id);

    const newlySelectedId = newlySelectedRows.map((row) => row._id);

    // const allSelectedIds = [...preselectedIds, ...newlySelectedIds];

    const mutationData = {
      projectId,
      promptId,
      versionId,
      datasetConfigId: mainId,
      datasetVersionIds: newlySelectedId,
    };

    console.log("Final Mutation:", mutationData);

    PostPromptReRun(mutationData);
  };
  const isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(max-width: 1290px)");

  return (
    <DialogWrapper
      open={open}
      setOpen={() => {
        setOpen(false);
      }}
      title={`${GetOneDatasets?.data?.name} Dataset`}
      width={isMediumLaptop || isMediumLaptop2 ? "md" : "lg"}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "30vw",
            }}
          >
            <SearchContainer
              debounced={true}
              onChange={setSearch}
              value={search}
            />
          </Box>

          <Box
            sx={{
              mt: 1,
            }}
          >
            {/* <Button
              sx={{ fontSize: "1rem" }}
              // onClick={() => {
              //   navigate(
              //     `/app/projects/${projectId}/datasets/${datasetId}/add-dataset-version`
              //   );
              // }}
            >
              + Add New Version
            </Button> */}
          </Box>
        </Box>

        {/* <Box
          sx={{
            mt: 3,
            maxHeight: "35vh",
            overflowY: "auto",
            paddingRight: "4px",
            marginRight: "-4px",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#12121220",
              borderRadius: "4px",
            },
          }}
        >
          
        </Box> */}
        <Box
          sx={{
            mt: 2,
          }}
        >
          <EditPromptTable
            data={GetOneDatasets?.data?.dataSetVersions || []}
            columns={columns}
            loading={isLoading}
            is_Id={true}
            // renderButtons={renderButtons}
            preSelectedRows={preselectedRows}
            // onRowSelectionChange={(selected: any) => {
            //   console.log("tested:", selected);
            //   setPreselectedRows(selected || null);
            // }}
            onRowSelectionChange={handleRowSelectionChange}
            onViewClick={(item: any) => {}}
            pagination={{
              totalCount: GetOneDatasets?.data?.dataSetVersions?.length,
              pageCount: limit,
              setPageCount: setLimit,
              page: offset / limit,
              setPage: (page) => {
                const newOffset = page * limit;
                setOffset(newOffset);
              },
            }}
            onPageChangeCallback={(paginationData) => {
              setOffset(paginationData.offset);
              setLimit(paginationData.limit);
            }}
          />
        </Box>

        {/* {GetOneDatasets?.data?.dataSetVersions?.length ===
        selectedRow?.length ? null : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: 3,
            }}
          >
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Re-Run"}
              actionOnClick={handleSubmit}
              width={"15vw"}
            />
          </Box>
        )} */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: 3,
          }}
        >
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Re-Run"}
            actionOnClick={handleSubmit}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default DatasetsDialog;

const columns = [
  {
    key: "versionNumber-TableUI",
    title: "Version",
    render: (e: any) => {
      return e?.versionNumber;
    },
  },
  {
    key: "version-tag-table-ui",
    title: "Tag",
    render: (e: any) => {
      return e?.tag;
    },
  },
  {
    key: "params-table-ui",
    title: "Parameters",
    render: (e: any) => {
      return <VersionParamsCard key={"params-card"} params={e?.params} />;
    },
  },
  {
    key: "noOfRecords-table-ui",
    title: "No of records",
    render: (e: any) => {
      return e?.noOfRecords;
    },
  },
];
