import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  StackProps,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { ColorPalette } from "../../../../themes/colorTokens/colorPalette";
import {
  alphabetsOnly,
  alphanumeric,
  formatAadharNumber,
  formatAPIKey,
  formatEmail,
  formatMobileNumber,
  formatOtp,
  formatTag,
  lowerCaseValue,
  onlyNumbers,
  upperCaseValue,
} from "../../utils/InputDataFormate";
import { LabelWrapper } from "../LabelWrapper/LabelWrapper";

export type TextFieldProps = {
  containerProps?: StackProps;
  description?: string;
  control: any;
  formateType: Formate;
  backgroundColor?: string;
  height?: string;
  width?: string;
  padding?: string;
  defaultValue?: any;
  disabled?: boolean;
} & MuiTextFieldProps;

export enum Formate {
  StrictUpperCase,
  StrictLowerCase,
  OnlyNumbers,
  Alphanumeric,
  AlphabetsOnly,
  AadharNumber,
  Otp,
  MobileNumber,
  EmailAddress,
  Tag,
  APIKey,
  AllowCapsSmallSpaces,
  Anything,
}

export const TextField = ({
  name = "",
  label,
  value,
  required = false,
  containerProps,
  description,
  control,
  placeholder,
  formateType,
  backgroundColor,
  height,
  width,
  padding,
  defaultValue,
  multiline,
  disabled = false,
}: TextFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedValue = e.target.value;

    switch (formateType) {
      case Formate.StrictUpperCase:
        formattedValue = upperCaseValue(formattedValue);
        break;

      case Formate.StrictLowerCase:
        formattedValue = lowerCaseValue(formattedValue);
        break;

      case Formate.OnlyNumbers:
        formattedValue = onlyNumbers(formattedValue);
        break;

      case Formate.Alphanumeric:
        formattedValue = alphanumeric(formattedValue);
        break;

      case Formate.AlphabetsOnly:
        formattedValue = alphabetsOnly(formattedValue);
        break;

      case Formate.AadharNumber:
        formattedValue = formatAadharNumber(formattedValue);
        break;

      case Formate.Otp:
        formattedValue = formatOtp(formattedValue);
        break;

      case Formate.MobileNumber:
        formattedValue = formatMobileNumber(formattedValue);
        break;

      case Formate.EmailAddress:
        formattedValue = formatEmail(formattedValue);
        break;

      case Formate.Tag:
        formattedValue = formatTag(formattedValue);
        break;

      case Formate.APIKey:
        formattedValue = formatAPIKey(formattedValue);
        break;

      case Formate.AllowCapsSmallSpaces:
        formattedValue = formattedValue.replace(/[^a-zA-Z ]/g, "");
        break;

      case Formate.Anything:
        formattedValue = formattedValue;
        break;

      default:
        break;
    }

    return formattedValue;
  };

  return (
    <LabelWrapper
      label={label}
      required={required}
      name={name}
      containerProps={containerProps}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ""}
        render={({ field, fieldState: { error } }) => (
          <>
            <MuiTextField
              {...field}
              onChange={(e: any) => field.onChange(handleChange(e))}
              required={required}
              disabled={disabled}
              id={name}
              placeholder={placeholder}
              rows={multiline ? 3 : undefined}
              InputProps={{
                sx: {
                  fontSize: "1vw",
                  fontWeight: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                },
              }}
              sx={{
                backgroundColor: backgroundColor,
                height: height,
                width: width,
                // width: {
                //   xs: "60%",
                //   sm: "70%",
                //   md: "75%",
                //   lg: "80%",
                //   xl: "100%",
                // },
                "& .MuiInputBase-input": {
                  // padding: "5px",
                },
                "&::placeholder": {
                  fontSize: "1vw",
                  fontWeight: 300,
                },
              }}
            />

            {error && (
              <Typography
                variant="caption"
                color={ColorPalette.UtilityColors.Red}
                sx={{ mt: 0, fontWeight: "bold" }}
              >
                {error.message}
              </Typography>
            )}

            {description && (
              <Typography variant="caption" sx={{ mt: 0.5 }}>
                {description}
              </Typography>
            )}
          </>
        )}
      />
    </LabelWrapper>
  );
};
