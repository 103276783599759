import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Images } from "../../../../../assets";

interface ResponseCardProps {
  response: any;
  model: any;
  preset: any;
  isSelected: boolean;
  onClick: () => void;
  isSubmitted: boolean;
}

export default function ResponseCard({
  response,
  model,
  preset,
  isSelected,
  onClick,
  isSubmitted,
}: ResponseCardProps) {
  const [userId, setUserId] = useState("");

  // console.log("Checks Final:", response, model, preset);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);
      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      }
    }
  }, []);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "white",
      color: "rgba(0, 0, 0, 0.87)",
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  return (
    <Box
      onClick={onClick}
      sx={{
        padding: "1rem",
        border: isSelected ? "1px solid #F07946" : "1px solid #1212121A",
        cursor: "pointer",
        borderRadius: "5px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontWeight: 500, mb: 1 }}>
          {response?.data
            ?.flatMap((g: any) => g?.value)
            ?.slice(0, 3)
            .join(", ")}
          {response?.data?.flatMap((g: any) => g?.value)?.length > 3 && (
            <>
              ,{" "}
              <HtmlTooltip
                title={
                  <Typography
                    variant="subtitle1"
                    sx={{ whiteSpace: "pre-line" }}
                  >
                    {response?.data?.flatMap((g: any) => g?.value)?.join(", ")}
                  </Typography>
                }
              >
                <Box
                  component="span"
                  sx={{
                    cursor: "pointer",
                    color: "#F07946",
                  }}
                >
                  ...
                </Box>
              </HtmlTooltip>
            </>
          )}
        </Typography>

        <img
          alt="sme"
          src={
            preset.grading
              ?.filter((grade: any) => grade.type === "Sme")
              ?.some((grade: any) => grade.userId === userId)
              ? Images.correctSme
              : Images.warningSme
          }
          style={{ width: "24px", height: "24px" }}
        />
      </Box>

      {/* <Box
        sx={{
          backgroundColor: "#FBE9D7",
          border: "1px solid #736AEC1A",
          padding: "0.25rem 0.5rem",
          width: "fit-content",
          mb: 1,
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 300, color: "#F4B537" }}
        >
          {preset.preset.temperature} temperature
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "#121212CC",
        }}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
            Response Time
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
            {response?.responseTime}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
            Output Tokens
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
            {response?.models?.[0]?.responses?.[0]?.preset?.maxTokens}{" "}
          </Typography>
        </Box>
      </Box> */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            color: "#121212CC",
            alignItems: "center",
            gap: "0.5vw",
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
            t: {preset.preset.temperature}
          </Typography>

          <Box
            sx={{
              width: "1px",
              height: "2vh",
              bgcolor: "#121212CC",
              my: 1,
            }}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
            output tokens:{" "}
            {response?.models?.[0]?.responses?.[0]?.preset?.maxTokens}{" "}
          </Typography>
          <Box
            sx={{
              width: "1px",
              height: "2vh",
              bgcolor: "#121212CC",
              my: 1,
            }}
          />
          <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
            res time: {response?.responseTime}
          </Typography>
        </Box>

        <HtmlTooltip
          title={
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {model?.model?.providerName}: {model?.model?.modelName}(
              {model?.model?.versionName})
            </Typography>
          }
        >
          <IconButton
            sx={{
              width: "24px",
              height: "24px",
              marginTop: "0.5vh",
            }}
          >
            <InfoIcon />
          </IconButton>
        </HtmlTooltip>
      </Box>
    </Box>
  );
}
