export const transformResponseData = (apiData: any[]): any[] => {
  if (!apiData?.[0]?.data) return [];

  const baseData = apiData[0].data;
  const result: any[] = [];

  const v2ResponseMap = new Map();
  apiData[1]?.data.forEach((item: any) => {
    v2ResponseMap.set(item.DatasetRecord, item.models);
  });

  baseData.forEach((baseItem: any) => {
    const newEntry: any = {
      DatasetRecord: baseItem.DatasetRecord,
    };

    baseItem.models?.forEach((model: any) => {
      const modelKey = `${model.model.providerName}/${model.model.versionName}_v1`;
      newEntry[modelKey] = {
        response: model.responses,
      };
    });

    const v2Models = v2ResponseMap.get(baseItem.DatasetRecord);
    if (v2Models) {
      v2Models.forEach((model: any) => {
        const modelKey = `${model.model.providerName}/${model.model.versionName}_v2`;
        newEntry[modelKey] = {
          response: model.responses,
        };
      });
    }

    result.push(newEntry);
  });

  return result;
};

export const sortColumnKeys = (keys: string[]): string[] => {
  const modelKeys = keys.filter((key) => key !== "DatasetRecord");

  const groupedKeys: { [key: string]: string[] } = {};
  modelKeys.forEach((key) => {
    const baseKey = key.replace(/_v[12]$/, "");
    if (!groupedKeys[baseKey]) {
      groupedKeys[baseKey] = [];
    }
    groupedKeys[baseKey].push(key);
  });

  const sortedKeys: string[] = [];
  Object.values(groupedKeys).forEach((group) => {
    group.sort((a, b) => {
      const aVersion = a.endsWith("_v1") ? 1 : 2;
      const bVersion = b.endsWith("_v1") ? 1 : 2;
      return aVersion - bVersion;
    });
    sortedKeys.push(...group);
  });

  return ["DatasetRecord", ...sortedKeys];
};
