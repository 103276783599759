import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../../assets";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { getOneProject } from "../../project/services/ProjectService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";

export default function EmptyPromptPage() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(20);
  const [offset, setOffset] = useState<number>(0);
  localStorage.clear();

  const { data: FindOneProject, isLoading } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
      onSuccess: (res: any) => {
        // console.log("Res:", res);
        if (res?.prompts?.length > 0) {
          navigate(`/app/projects/${projectId}/prompts/all`, { replace: true });
        }
      },
    }
  );

  const handleClickAdd = () => {
    localStorage.clear();
    navigate(`/app/projects/${projectId}/prompts/create`);
  };

  const GetAllPrompts = FindOneProject?.prompts;

  if (isLoading) {
    return (
      <>
        <LinearProgress></LinearProgress>
      </>
    );
  }

  return (
    <Box>
      {GetAllPrompts?.length === 0 ? (
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
            gap: "20px",
          }}
        >
          <img src={Images.emptyScreen} alt="" width={"32%"} />
          <Typography variant="h1" sx={{ fontWeight: 600 }}>
            {"Create your first prompt"}
          </Typography>
          <Box width={"15%"}>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={"Create prompt"}
              actionOnClick={handleClickAdd}
              key={"empty-prompt"}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
}
