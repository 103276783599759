import { Box, LinearProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { getAllDatasets } from "../../dataset/services/DatasetService";
import { MultiSelect } from "../../shared/components/Select/MultiSelect";
import { NoSelect } from "../../shared/components/Select/NoSelect";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import { StyledContainer } from "../styles";
import QuillEditor from "./promptcreationComponents/Quill";

const PromptCreation = ({
  onValid,
  finalData,
  isPreviousStepSkipped,
}: {
  onValid: (isValid: boolean) => void;
  finalData: any;
  isPreviousStepSkipped?: any;
}) => {
  const initialData = JSON.parse(
    localStorage.getItem("PromptCreation") || "{}"
  );

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const {
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      promptCategorySingle: initialData?.datasetId || "",
      promptCategoryMulti: initialData?.datasetVersionIds || [],
      promptCategoryParams: initialData?.params || [],
    },
  });

  const promptGen = useRef({
    text: initialData?.description || "",
    mentions: [""],
  });

  const promptDataset = localStorage.getItem("PromptDataset");
  const datasetIds = promptDataset ? JSON.parse(promptDataset) : [];

  // console.log("First:", promptDataset);
  // console.log("Second:", datasetIds);

  const { data: dataSets, isLoading }: any = useQuery("datasets", () =>
    getAllDatasets({ datasetIds: datasetIds })
  );

  const CurrentDataset =
    dataSets?.data?.map(
      (i: { name: any; _id: any; dataSetVersions?: any }) => ({
        label: i?.name,
        value: i?._id,
        dataSetVersions: i?.dataSetVersions || [],
      })
    ) || [];
  // console.log("third:", CurrentDataset);

  const datasetSingleValue = watch("promptCategorySingle");
  // console.log("Fourth", datasetSingleValue);

  const datasetMultiValue = watch("promptCategoryMulti");

  const selectedDataset =
    CurrentDataset.find(
      (dataset: any) => dataset.value === datasetSingleValue
    ) || {};

  const datasetForEffect = CurrentDataset.find(
    (dataset: any) => dataset.value === datasetIds?.[0]
  );
  // console.log("Fifth:", datasetForEffect);

  const datasetVersionOptions =
    selectedDataset.dataSetVersions?.map(
      (i: { tag: any; versionNumber?: any; _id: any; params?: any }) => ({
        label: `V${i?.versionNumber}: ${i?.tag}`,
        value: i?._id,
        params: i?.params || [],
      })
    ) || [];

  // console.log("Versions length:", datasetVersionOptions);

  const selectedVersions = datasetVersionOptions.filter((version: any) =>
    datasetMultiValue.includes(version.value)
  );

  const paramsOptions =
    selectedVersions
      .flatMap((version: any) => version.params || [])
      .map((param: any) => ({ label: param, value: param })) || [];

  const uniqueLabels = paramsOptions
    .map((option: any) => option.label)
    .filter((label: string) => label)
    .reduce((acc: string[], label: string) => {
      if (!acc.includes(label)) {
        acc.push(label);
      }
      return acc;
    }, []);

  // console.log("Params Options:", uniqueLabels);

  const handleValidation = useCallback(() => {
    const isFormFilled = datasetSingleValue && datasetMultiValue.length > 0;

    if (isPreviousStepSkipped) {
      onValid(true);
      finalData(localStorage.getItem("Updated Prompt"));
    } else {
      if (isFormFilled) {
        onValid(true);
        finalData([
          {
            promptCategorySingle: datasetSingleValue,
            promptCategoryMulti: datasetMultiValue,
            promptCategoryParams: uniqueLabels,
            // promptCategoryParams: promptGen.current.mentions,
          },
          localStorage.getItem("Updated Prompt"),
        ]);
      } else {
        onValid(false);
        // console.log("Form is not completely filled.");
      }
    }
  }, [
    datasetSingleValue,
    datasetMultiValue,
    promptGen.current,
    isPreviousStepSkipped,
    onValid,
    finalData,
  ]);

  const handleChange = useCallback((quillText: string) => {
    // const rederedText = convert;
    const mentionRegex = /{([^}]+)}/g;
    const mentions =
      quillText.match(mentionRegex)?.map((m) => m.slice(1, -1)) || [];
    promptGen.current = {
      text: quillText,
      mentions: mentions,
    };
    // console.log("Mentions:", promptGen.current);

    localStorage.setItem("Updated Prompt", quillText);
  }, []);

  useEffect(() => {
    handleValidation();
  }, [datasetSingleValue, datasetMultiValue, handleValidation]);

  useEffect(() => {
    // if (CurrentDataset?.length > 0) {
    //   setValue("promptCategorySingle", CurrentDataset[0]?.value);
    // }
    if (initialData?.datasetId)
      setValue("promptCategorySingle", initialData?.datasetId);
    // if (initialData?.params)
    //   setValue("promptCategoryParams", initialData?.params);
  }, [initialData?.datasetId, initialData?.params, setValue]);

  useEffect(() => {
    setValue("promptCategorySingle", datasetForEffect?.value);
    setValue("promptCategoryMulti", [
      datasetForEffect?.dataSetVersions[0]?._id,
    ]);
  }, []);

  useEffect(() => {
    const fetch1 = CurrentDataset.find(
      (cd: any) => cd?.value === getValues("promptCategorySingle")
    );
    setValue("promptCategoryMulti", [fetch1?.dataSetVersions[0]?._id]);
  }, [watch("promptCategorySingle")]);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Typography
        variant="h3"
        fontWeight="600"
        sx={{
          width: "60vw",
          textAlign: "left",
          mb: 2,
        }}
      >
        Prompt Creation
      </Typography>
      <StyledContainer>
        {!isPreviousStepSkipped && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            {/* {CurrentDataset?.[0]?.value && ( */}
            <Box sx={{ width: "33.3%" }}>
              <SingleSelect
                control={control}
                required
                name="promptCategorySingle"
                label="Select Dataset"
                options={CurrentDataset}
                defaultData={CurrentDataset?.[0]?.value}
                placeholder="select dataset"
                backgroundColor="#F8F8F8"
                border="#F8F8F8"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </Box>
            {/* )} */}

            <Box sx={{ width: "33.3%" }}>
              {" "}
              <MultiSelect
                control={control}
                required
                name="promptCategoryMulti"
                label="Dataset Version"
                options={datasetVersionOptions}
                defaultValue={datasetVersionOptions?.[0]?.value || []}
                placeholder="Select Version"
                border="none"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </Box>
            <Box sx={{ width: "33.3%" }}>
              <NoSelect
                name="promptCategoryParams"
                label="Params"
                options={uniqueLabels}
                placeholder="Click here for available params"
                border="none"
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </Box>
          </Box>
        )}

        <QuillEditor
          defaultHtml={promptGen.current.text}
          placeholder="Enter user message"
          onChange={(quillText: string) => handleChange(quillText)}
          height={isLargeScreen ? "40vh" : isMediumScreen ? "30vh" : "25vh"}
          mentions={[
            {
              mentionChar: "{",
              values: paramsOptions,
            },
          ]}
        />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 300,
            color: "#000000",
            opacity: 0.5,
            fontStyle: "italic",
            justifyContent: "flex-start",
          }}
        >
          {
            "Note: You can include parameters from your selected dataset in the prompt by typing {{ and selecting the desired parameter to insert it into the prompt."
          }
        </Typography>
      </StyledContainer>
    </Box>
  );
};

export default PromptCreation;
