import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../../app/services";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import SingleFileUploadedCard from "../../../dataset/components/UploadedCards/SingleFileUploadedCard";
import UploadedDataDisplay from "../../../dataset/components/UploadedCards/UploadedDataDisplay";
import {
  addDataset,
  CreateDataSetVersion,
} from "../../../dataset/services/DatasetService";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import FileUploadField from "../../../shared/components/FileUpload/FileUploadField";
import { SingleSelect } from "../../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";

export enum mediaSubType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  CSV = "CSV",
  EXCEL = "EXCEL",
  PDF = "PDF",
}

const fileTypesMap = {
  IMAGE: ".png, .jpeg, .jpg",
  VIDEO: ".mp4, .avi",
  AUDIO: ".mp3, .wav",
  CSV: ".csv",
  EXCEL: ".xlsx, .xls",
  PDF: ".pdf",
};

const validationSchema = Yup.object().shape({
  DatasetName: Yup.string()
    .required("Dataset Name is required")
    .max(100, "Dataset Name cannot exceed 100 characters"),
  DatasetTag: Yup.string()
    .required("Dataset Tag is required")
    .max(50, "Dataset Tag cannot exceed 50 characters"),
  UploadFileType: Yup.string().required("Upload File Type is required"),
});

export default function PromptDatasetNew({
  skip,
  setView,
}: {
  skip?: any;
  setView?: any;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { datasetId } = useParams();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectId = params.get("projectId");
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [isNewTab, setIsNewTab] = useState(false);

  useEffect(() => {
    setIsNewTab(window.location.href.includes("/add-new-dataset"));
  }, []);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const handleUploadComplete = (data: any) => {
    setUploadedFiles((prevFiles) => [...prevFiles, data]);
  };

  const DeleteFilesHandler = (fileIndex: number) => {
    setUploadedFiles((prevKeys) =>
      prevKeys?.filter((_, index) => index !== fileIndex)
    );
  };

  const { mutate: CreateDataSet, isLoading: uploadLoader } = useMutation(
    addDataset,
    {
      onSuccess: (res: any) => {
        localStorage.setItem("reloadTab", "1");
        // setView("selectDataset");
        if (isNewTab && window.opener) {
          window.opener.location.reload();
          window.close();
        } else {
          setView("selectDataset");
        }
      },
      onError: (error) => {
        displayErrorMessage(error);
      },
    }
  );

  const { mutate: CreateDataVersion, isLoading: uploadLoader2 } = useMutation(
    CreateDataSetVersion,
    {
      onSuccess: (res: any) => {
        displaySuccessMessage(res.message);

        if (isNewTab && window.opener) {
          window.opener.location.reload();
          window.close();
        } else {
          setView("selectDataset");
        }
      },
      onError: (error) => {
        displayErrorMessage(error);
      },
    }
  );

  const uploadKeys = uploadedFiles?.map((item: any) => item?.key);

  const uploadFileType = ["IMAGE", "VIDEO", "AUDIO", "CSV", "EXCEL", "PDF"];
  const selectedFileType: mediaSubType = watch(
    "UploadFileType"
  ) as mediaSubType;

  useEffect(() => {
    setValue("UploadFileType", "CSV");
  }, [setValue]);

  const onSubmit = (data: any) => {
    if (datasetId) {
      CreateDataVersion({
        body: {
          tags: [data.DatasetTag],
          uploadType: data.UploadFileType,
          fileKeys: uploadKeys,
          generatedFileName: data.DatasetName,
          projectId: projectId,
        },
        datasetId: datasetId,
      });
    } else {
      CreateDataSet({
        name: data.DatasetName,
        tags: [data.DatasetTag],
        uploadType: data.UploadFileType,
        fileKeys: uploadKeys,
        generatedFileName: data.DatasetName,
        projectId: projectId,
      });
    }
  };

  const isMultipleViewAllowed = ["IMAGE", "VIDEO", "AUDIO"].includes(
    selectedFileType
  );

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        mt: 2,
      }}
    >
      <Stack
        width={"73%"}
        sx={{
          gap: "20px",
        }}
      >
        {datasetId ? (
          <>
            {" "}
            <Typography variant="h3">Add Dataset Version</Typography>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: 600 }}>
              Add Dataset
            </Typography>
            {!isNewTab && (
              <Button onClick={skip} sx={{ fontSize: "20px" }}>
                Skip
              </Button>
            )}
            {/* <Typography variant="h3">Skip</Typography> */}
          </Box>
        )}
        <Stack
          border={`2px solid ${theme.palette.border.primary}`}
          sx={{
            width: "100%",
            p: "20px",

            // height:
            //   isMultipleViewAllowed && uploadedFiles?.length > 0
            //     ? "80vh"
            //     : "70vh",
            minHeight: "80vh",
            // justifyContent: "space-between",
          }}
          borderRadius="10px"
        >
          <Stack sx={{ width: "50%", gap: "10px" }}>
            <TextField
              formateType={Formate.Alphanumeric}
              control={control}
              name="DatasetName"
              label="Dataset Name"
              required
              placeholder="Enter your Dataset Name"
            />

            <TextField
              formateType={Formate.EmailAddress}
              control={control}
              name="DatasetTag"
              label="Dataset Tag"
              required
              placeholder="Enter your Dataset Tag"
            />
            <Typography
              sx={{ fontStyle: "italic", color: "grey", marginBottom: "10px" }}
            >
              {" "}
              Ex - beta, test, staging, prod
            </Typography>

            <SingleSelect
              defaultData="CSV"
              control={control}
              required
              name="UploadFileType"
              label="Upload File Type"
              options={uploadFileType}
              placeholder="Select File Type"
            />

            <FileUploadField
              label="Upload Dataset"
              required={true}
              name="datasetFile"
              uploadFileExtension={fileTypesMap[selectedFileType]}
              uploadedFiles={uploadedFiles}
              uploadFileType={watch("UploadFileType")}
              containerProps={{ my: 2 }}
              type={"DATASET"}
              onUploadComplete={handleUploadComplete}
            />
          </Stack>
          {isMultipleViewAllowed && uploadedFiles?.length > 0 ? (
            <Box
              sx={{
                mt: 2,
              }}
            >
              <UploadedDataDisplay
                label="Upload Dataset"
                required={true}
                name="datasetFile"
                uploadFileType={selectedFileType}
                uploadedFiles={uploadedFiles}
                DeleteFilesHandler={DeleteFilesHandler}
              />
            </Box>
          ) : (
            !isMultipleViewAllowed &&
            uploadedFiles?.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <SingleFileUploadedCard
                  label="Upload Dataset"
                  required={true}
                  name="datasetFile"
                  uploadFileType={selectedFileType}
                  uploadedFiles={uploadedFiles}
                  DeleteFilesHandler={DeleteFilesHandler}
                />
              </Box>
            )
          )}

          <Box sx={{ mt: 2 }}>
            <MultiTypeButton
              actionOnClick={handleSubmit(onSubmit)}
              isLoading={uploadLoader || uploadLoader2}
              typeText="Submit"
              buttonType={ButtonType.Gradient}
              key={"prompt-dataset-new"}
              disabled={uploadedFiles?.length === 0}
              width={
                isLargeScreen
                  ? "192px"
                  : isMediumScreen
                  ? "115px"
                  : isSmallScreen
                  ? "61px"
                  : "192px"
              }
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
