// utilities/formatting.ts

export const upperCaseValue = (value: string): string => {
  return value.toUpperCase();
};

export const lowerCaseValue = (value: string): string => {
  return value.toLowerCase();
};

export const onlyNumbers = (value: string): string => {
  return value.replace(/[^0-9]/g, "");
};

export const alphanumeric = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9\s]/g, "");
};

export const alphabetsOnly = (value: string): string => {
  return value.replace(/[^a-zA-Z\s]/g, ""); // \s allows spaces
};

export const formatAadharNumber = (value: string): string => {
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  const formattedValue = onlyNumbers.replace(/(\d{4})(?=\d)/g, "$1 ");

  return formattedValue.slice(0, 14);
};

export const formatOtp = (value: string): string => {
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  const formattedValue = onlyNumbers.replace(/(\d{1})(?=\d)/g, "$1 ");

  return formattedValue.slice(0, 11); // 6 digits + 5 spaces
};

export const formatMobileNumber = (value: string): string => {
  const onlyNumbers = value.replace(/[^0-9]/g, "");

  const formattedValue = onlyNumbers.slice(0, 10);

  return formattedValue;
};

export const formatEmail = (value: string): string => {
  const trimmedValue = value.trim();

  const formattedValue = trimmedValue.toLowerCase();

  return formattedValue;
};

export const formatTag = (value: string): string => {
  const trimmedValue = value.trim();

  const formattedValue = trimmedValue.replace(/[^a-z-]/gi, "").toLowerCase();
  return formattedValue;
};

export const formatAPIKey = (value: string): string => {
  const trimmedValue = value.trim();
  return trimmedValue;
};
