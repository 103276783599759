import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Icons } from "../../../assets";
import ConfigurationIcon from "../../../assets/icons/Configuration";
import DashboardIcon from "../../../assets/icons/Dashboard";
import Datasets from "../../../assets/icons/Datasets";
import DocumentationIcon from "../../../assets/icons/Documentation";
import HelpIcon from "../../../assets/icons/NeedHelp";
import ProjectIcon from "../../../assets/icons/Project";
import PromptsIcon from "../../../assets/icons/Prompts";
import { UIStore } from "../../../store/general";
import { getOneProject } from "../../project/services/ProjectService";
import { getOneUser } from "../services/LayoutService";
import { capitalizeFirstLetter } from "../utils/LayoutUtils";
import ResponsiveAppBar from "./AppBar";
import ResponseTableAppBar from "./TableUIAppBar";

interface SidebarItemProps {
  label: string;
  icon: JSX.Element;
  path: string;
}

const SidebarDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "bgColor" && prop !== "border",
})<{ open: boolean; bgColor?: string; border?: string }>(
  ({ open, bgColor, border }) => ({
    width: open ? "15vw" : "4vw",
    transition: "width 0.5s ease",
    "& .MuiDrawer-paper": {
      width: open ? "15vw" : "4vw",
      transition: "width 0.5s ease",
      boxSizing: "border-box",
      overflowX: "hidden",
      backgroundColor: bgColor || "#FFFFFF",
      color: "#fff",
      gap: "0.5vw",
      border: border || `1px solid ${bgColor || "#FFFFFF"}`,
      "@media (max-width: 1650px)": {
        width: open ? "17vw" : "4vw",
      },
      "@media (max-width: 1450px)": {
        width: open ? "17vw" : "4vw",
      },
      "@media (max-width: 1280px)": {
        width: open ? "17vw" : "4vw",
      },
      "@media (max-width: 960px)": {
        width: open ? "17vw" : "4vw",
      },
      "@media (max-width: 768px)": {
        width: open ? "16vw" : "4vw",
      },
      "@media (max-width: 480px)": {
        width: open ? "16vw" : "4vw",
      },
    },
    "@media (max-width: 1650px)": {
      width: open ? "17vw" : "4vw",
    },
    "@media (max-width: 1450px)": {
      width: open ? "17vw" : "4vw",
    },
    "@media (max-width: 1280px)": {
      width: open ? "17vw" : "4vw",
    },
    "@media (max-width: 960px)": {
      width: open ? "10vw" : "3vw",
    },
    "@media (max-width: 768px)": {
      width: open ? "8vw" : "2.5vw",
    },
    "@media (max-width: 480px)": {
      width: open ? "6vw" : "2vw",
    },
  })
);

const SidebarListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    prop !== "isActive" &&
    prop !== "isSidebarOpen" &&
    prop !== "color" &&
    prop !== "bgColor" &&
    prop !== "isDarkMode",
})<{
  isActive: boolean;
  isSidebarOpen: boolean;
  color?: string;
  bgColor?: string;
  isDarkMode?: boolean;
}>(({ isActive, isSidebarOpen, color, bgColor }) => ({
  width: isSidebarOpen ? "11.5vw" : "3vw",
  borderRadius: isActive ? "12px" : "0",
  transition: "background-color 0.3s, width 0.5s",
  backgroundColor: bgColor, // Dynamic bgColor
  "&:hover": {
    cursor: "pointer",
  },
  color: color || "#fff",
  gap: "15px",
  marginTop: isActive ? "10px" : "0px",
  "@media (max-width: 1650px)": {
    width: isSidebarOpen ? "13vw" : "3vw",
  },
  "@media (max-width: 1450px)": {
    width: isSidebarOpen ? "13vw" : "3vw",
  },
  "@media (max-width: 1280px)": {
    width: isSidebarOpen ? "13.8vw" : "3vw",
  },
  "@media (max-width: 960px)": {
    width: isSidebarOpen ? "8vw" : "2.5vw",
    flexDirection: "row",
    marginTop: "5px",
  },
  "@media (max-width: 768px)": {
    width: isSidebarOpen ? "7vw" : "2vw",
  },
  "@media (max-width: 480px)": {
    width: "100%",
    justifyContent: "center",
    marginTop: "5px",
  },
}));

const SidebarItemIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{
  isActive: boolean;
  isSidebarOpen?: boolean;
}>(({ isActive, isSidebarOpen }) => ({
  minWidth: 0,
  justifyContent: "center",
  color: isActive ? "#fff" : "#1da1f2",
  backgroundColor: "transparent",
  borderRadius: isActive ? "12px" : "0",
  padding: "8px 0px",
  transition: "color 0.3s ease, transform 0.2s ease",
  "&:hover": {
    color: "#fff",
    transform: "scale(1.1)",
  },
  "@media (max-width: 1650px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 1450px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 1280px)": {
    width: "25px",
    height: "25px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 960px)": {
    width: "22px",
    height: "22px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 768px)": {
    width: "19px",
    height: "19px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
  "@media (max-width: 480px)": {
    width: "15px",
    height: "15px",
    padding: "0px",
    marginLeft: isSidebarOpen ? 0 : -6,
    marginTop: isSidebarOpen ? -1 : -4,
  },
}));

const SidebarFooter = styled(Box)`
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: flex-start;
  color: #000000cc;
  opacity: 0.7;
`;

export default function HomeLayout() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const endpoint = pathname.split("/").pop();
  // console.log(endpoint);
  const isDarkMode = UIStore.useState((s) => {
    return s.isDarkMode;
  });

  const { id, projectId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const Sme = Cookies.get("Sme");

  const isLargeScreen = useMediaQuery("(min-width: 1750px)");
  const isMediumScreen = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const toggleSidebar = () => setIsSidebarOpen((prev) => !prev);

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);

      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      } else {
        // console.error("Failed to decode user id from token");
      }
    } else {
      // console.error("No token found in localStorage");
    }
  }, []);

  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });

  // console.log("User:", FindOneUser?.data);

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: Sme === "false" && !!projectId,
    }
  );

  useEffect(() => {
    const isResponsesPage = window.location.href.endsWith("responses");
    const isVersionsComparisonPage = window.location.href.includes(
      "versions-comparison"
    );

    if (isResponsesPage || isVersionsComparisonPage) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }
  }, [location]);

  const isProjectPage = /^\/app\/projects\/(?!all$)[a-zA-Z0-9]+(\/.*)?$/.test(
    location.pathname
  );

  const projectSidebarItems: SidebarItemProps[] = [
    {
      label: "Dashboard",
      icon: <DashboardIcon color={isDarkMode ? "white" : "black"} />,
      path: `/app/projects/${projectId}/dashboard`,
    },
    {
      label: "Prompts",
      icon: <PromptsIcon color={isDarkMode ? "white" : "black"} />,
      path: `/app/projects/${projectId}/prompts`,
    },
    {
      label: "Datasets",
      icon: <Datasets color={isDarkMode ? "white" : "black"} />,
      path: `/app/projects/${projectId}/datasets`,
    },
    {
      label: "Configuration",
      icon: <ConfigurationIcon color={isDarkMode ? "white" : "black"} />,
      path: `/app/projects/${projectId}/configuration`,
    },
  ];

  const sidebarItems: SidebarItemProps[] = [
    {
      label: "Dashboard",
      icon: <DashboardIcon color={isDarkMode ? "white" : "black"} />,
      path: "dashboard",
    },
    {
      label: "Projects",
      icon: <ProjectIcon color={isDarkMode ? "white" : "black"} />,
      path: "projects",
    },
    {
      label: "Datasets",
      icon: <Datasets color={isDarkMode ? "white" : "black"} />,
      path: "datasets",
    },
    {
      label: "Configuration",
      icon: <ConfigurationIcon color={isDarkMode ? "white" : "black"} />,
      path: "configuration",
    },
  ];

  const sidebarFooterItems: SidebarItemProps[] = [
    {
      label: "Documentation",
      icon: <DocumentationIcon color={isDarkMode ? "#FFF" : "black"} />,
      path: "configuration",
    },
    {
      label: "Need help?",
      icon: <HelpIcon color={isDarkMode ? "white" : "black"} />,
      path: "configuration",
    },
  ];

  const currentSidebarItems = isProjectPage
    ? projectSidebarItems
    : sidebarItems;

  const SidebarItemComponent: React.FC<SidebarItemProps> = ({
    label,
    icon,
    path,
  }) => {
    const isActive = location.pathname.includes(path);
    return (
      <SidebarListItem
        onClick={() => navigate(path)}
        isActive={isActive}
        isSidebarOpen={isSidebarOpen}
        color={isDarkMode ? "white" : "black"}
        bgColor={
          isActive && isDarkMode
            ? "rgba(50, 65, 85, 1)"
            : isActive && !isDarkMode
            ? "#FFE0D1"
            : "transparent"
        }
      >
        <SidebarItemIcon isActive={isActive} isSidebarOpen={isSidebarOpen}>
          {icon}
        </SidebarItemIcon>
        {isSidebarOpen && (
          <Typography sx={{ fontWeight: 500, mt: 0.5 }} variant="h4">
            {label}
          </Typography>
        )}
      </SidebarListItem>
    );
  };

  const currentPath = window.location.pathname;

  return (
    <Box display="flex" height="100vh">
      {/* Sidebar */}
      <SidebarDrawer
        variant="permanent"
        open={isSidebarOpen}
        bgColor={theme.palette.surface.layoutBackground}
        border={`1px solid ${theme.palette.border}`}
      >
        {/* Sidebar toggle button */}
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {isSidebarOpen ? (
            <img
              src={Icons.namedLogo}
              alt="loading....."
              width={isLargeScreen ? "80%" : isMediumScreen ? "70%" : "58%"}
              onClick={() => navigate("/app/dashboard")}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <></>
          )}
          <IconButton
            onClick={toggleSidebar}
            sx={{ color: "black" }}
            aria-label="Toggle Sidebar"
          >
            {isSidebarOpen ? (
              <>
                {" "}
                <ArrowBackIosNewRoundedIcon
                  htmlColor={isDarkMode ? "white" : "black"}
                  sx={{
                    marginLeft: !isSidebarOpen ? 0 : 2.2,
                    width: "27px",
                    height: "27px",
                    "@media (max-width: 1650px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1450px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1280px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 960px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "15px",
                      height: "15px",
                    },
                    "@media (max-width: 768px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "15px",
                      height: "15px",
                    },
                    "@media (max-width: 480px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              </>
            ) : (
              <>
                <ArrowForwardIosRoundedIcon
                  htmlColor={isDarkMode ? "white" : "black"}
                  sx={{
                    width: "27px",
                    height: "27px",
                    "@media (max-width: 1650px)": {
                      marginLeft: !isSidebarOpen ? 0 : 2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1450px)": {
                      marginLeft: isSidebarOpen ? 0 : -2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 1280px)": {
                      marginLeft: isSidebarOpen ? 0 : -2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 960px)": {
                      marginLeft: isSidebarOpen ? 0 : -2,
                      width: "17px",
                      height: "17px",
                    },
                    "@media (max-width: 768px)": {
                      marginLeft: isSidebarOpen ? 0 : -2,
                      width: "15px",
                      height: "15px",
                    },
                    "@media (max-width: 480px)": {
                      marginLeft: isSidebarOpen ? 0 : -2,
                      width: "15px",
                      height: "15px",
                    },
                  }}
                />
              </>
            )}
          </IconButton>
        </Toolbar>

        <Divider sx={{ backgroundColor: theme.palette.border }} />

        <List
          sx={{
            display: isSidebarOpen ? "flex" : "block",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            left: 6,
          }}
        >
          {isSidebarOpen && (
            <Box
              sx={{
                bgcolor: "#F07946",
                color: "#FFFFFF",
                padding: "1vw",
                mb: 4,
                borderRadius: "10px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "11.5vw",
                height: "6vh",
                "@media (max-width: 1650px)": {
                  width: "13vw",
                  height: "5vh",
                },

                "@media (max-width: 1450px)": {
                  width: "13vw",
                  height: "5vh",
                  // mb: 2,
                },
                "@media (max-width: 1280px)": {
                  width: "12.5vw",
                  height: "4vh",
                },
                "@media (max-width: 960px)": {
                  width: "12vw",
                  height: "4vh",
                },
                "@media (max-width: 768px)": {
                  width: "12vw",
                  height: "4vh",
                },
                "@media (max-width: 480px)": {
                  width: "12vw",
                  height: "4vh",
                },
              }}
              onClick={() => navigate("/app/dashboard")}
            >
              <Typography
                variant="h1"
                sx={{
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  // maxWidth: "200px",
                }}
              >
                {currentPath === "/app/projects/all"
                  ? capitalizeFirstLetter(FindOneUser?.data?.organization?.name)
                  : currentPath.startsWith("/app/projects/")
                  ? capitalizeFirstLetter(FindOneProject?.title)
                  : capitalizeFirstLetter(
                      FindOneUser?.data?.organization?.name
                    )}
              </Typography>
            </Box>
          )}

          {currentSidebarItems.map((item) => (
            <SidebarItemComponent
              key={item.label}
              label={item.label}
              icon={item.icon}
              path={item.path}
            />
          ))}
        </List>

        {/* <SidebarFooter>
          <List
            sx={{
              display: isSidebarOpen ? "flex" : "block",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              padding: isSidebarOpen ? "0px 30px" : "0px",
              height: "150px",
            }}
          >
            {sidebarFooterItems.map((item) => (
              <SidebarItemComponent
                key={item.label}
                label={item.label}
                icon={item.icon}
                path={item.path}
              />
            ))}
          </List>
        </SidebarFooter> */}
      </SidebarDrawer>

      {/* Main Content */}
      <Box flexGrow={1}>
        {/* AppBar */}
        {endpoint === "responses" ? (
          <>
            <ResponseTableAppBar isSidebarOpen={isSidebarOpen} sme={false} />
          </>
        ) : (
          <>
            <ResponsiveAppBar isSidebarOpen={isSidebarOpen} sme={false} />
          </>
        )}
        <Box
          sx={{
            mt: "75px", // Offset by AppBar height (adjust if AppBar height differs)
            zIndex: -2,
            bgcolor: theme.palette.surface.outletBackground,
            // width: isSidebarOpen ? "84.8vw" : "95.6vw",
            // minHeight: "92vh",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
