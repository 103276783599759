import { yupResolver } from "@hookform/resolvers/yup";
import { Box, styled, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";
import useScreenSizes from "../../../utils/CustomSizesHook";
import { getOneUser } from "../../layout/services/LayoutService";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";

const projectSchema = yup.object({
  name: yup
    .string()
    .matches(/^[A-Za-z0-9\s]+$/, "Only alphanumeric characters are allowed")
    .required("Full name is required"),
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  orgName: yup.string().required("Organization name is required"),
});

const StyledContainer = styled(Box)(() => ({
  border: "1px solid #1212121A",
  backgroundColor: "#FFFFFF",
  borderRadius: "15px",
  width: "90%",
  padding: "35px",
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  minHeight: "65vh",
}));

const Profile = () => {
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(projectSchema),
    mode: "onChange",
    defaultValues: {
      name: "",
      email: "",
      orgName: "",
    },
  });

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);
      const userId = decoded?.userId;
      if (userId) {
        setUserId(userId);
      }
    }
  }, []);

  const { data: FindOneUser } = useQuery(["users", userId], getOneUser, {
    enabled: !!userId,
  });

  useEffect(() => {
    if (FindOneUser?.data) {
      setValue("name", FindOneUser?.data?.name || "");
      setValue("email", FindOneUser?.data?.emailAddress || "");
      setValue("orgName", FindOneUser?.data?.organization?.name || "");
    }
  }, [FindOneUser, setValue]);

  const onSubmit = (value: any) => {
    // const mutationData = {
    //   name: value.name,
    //   email: value.email,
    //   orgName: value.orgName,
    // };
    //   mutate(mutationData);
    //
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 5.5,
        "@media (max-width: 1650px)": {
          mt: "4.2vh",
        },
        "@media (max-width: 1450px)": {
          mt: "4.2vh",
        },
        "@media (max-width: 1280px)": {
          mt: "4.2vh",
        },
        "@media (max-width: 960px)": {
          mt: "4.2vh",
        },
        "@media (max-width: 768px)": {
          mt: "4.2vh",
        },
        "@media (max-width: 480px)": {
          mt: "4.2vh",
        },
      }}
    >
      <Typography
        variant="h3"
        fontWeight="600"
        sx={{ width: "90%", textAlign: "left", mb: 2 }}
      >
        Personal Information
      </Typography>
      <StyledContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            width: "65%",
          }}
        >
          <TextField
            formateType={Formate.AllowCapsSmallSpaces}
            control={control}
            label="Full Name"
            name="name"
            required
            placeholder="Enter full name..."
            backgroundColor="#F8F8F8"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <TextField
            formateType={Formate.AllowCapsSmallSpaces}
            control={control}
            label="Email Address"
            name="email"
            required
            placeholder="Enter email address..."
            backgroundColor="#F8F8F8"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <TextField
            formateType={Formate.EmailAddress}
            control={control}
            label="Organization Name"
            name="orgName"
            required
            placeholder="Enter organization name..."
            backgroundColor="#F8F8F8"
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <MultiTypeButton
            buttonType={ButtonType.Gradient}
            typeText={"Save Changes"}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
            disabled
            actionOnClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default Profile;
