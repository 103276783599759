import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Icons } from "../../../../../assets";
import {
  getAllSmeProjects,
  getOneResponse,
} from "../../../../project/services/ProjectService";
import Spinner from "../../../../shared/components/Loaders/Spinner";
import { getGradingParams } from "../../../services";
import DynamicText from "../../../utility";
import { GradingParameters } from "../../types/sme-grade";
import GradingSection from "./GradingSection";

interface RightPanelProps {
  response: any | null;
  currentPage: number;
  totalResponses: number;
  onPrevious: () => void;
  onNext: () => void;
  onPageChange: (newPage: number) => void;
  onSubmit: any;
  submittedGrades: Record<string, GradingParameters>;
  comments: Record<string, string>;
  analytics?: any;
  main?: any;
}

export default function RightPanel({
  response,
  currentPage,
  totalResponses,
  onPrevious,
  onNext,
  onPageChange,
  onSubmit,
  submittedGrades,
  comments,
  analytics,
  main,
}: RightPanelProps) {
  const { projectId, promptId, versionId } = useParams();

  const { data: FindOneResponses, isLoading }: any = useQuery(
    [
      "one-responses",
      response?._id,
      response?.models[response?.selectedModelIndex]?.modelId,
      response?.models[response?.selectedModelIndex]?.responses[
        response?.selectedPresetIndex
      ]?.presetId,
    ],
    getOneResponse,

    {
      enabled: !!response?._id,
    }
  );
  console.log("FindOne Response:", FindOneResponses);

  const ButtonInvalidate = main?.find(
    (f: any) => f?._id === FindOneResponses?.data?.[0]?._id
  );

  const { data: FindOneProject, isLoading: FindOneSmeLoading }: any = useQuery(
    ["sme-projects", projectId],
    () => getAllSmeProjects({ projectId }),
    {
      enabled: !!projectId,
    }
  );

  const { data: FindGradingParams, isLoading: gradingLoading } = useQuery(
    ["grading-params", projectId, promptId, versionId],
    getGradingParams,
    {
      enabled: !!versionId,
    }
  );
  // console.log("FindGradings:", FindGradingParams);

  const FilteredProjectPrompt = FindOneProject?.data.find(
    (p: any) => p?.prompts?._id === promptId
  );
  // console.log("FindOneProjectChanged:", FilteredProjectPrompt, versionId);

  const FilteredProjectVersion =
    FilteredProjectPrompt?.prompts?.versions?._id === versionId;

  const FilteredDataset =
    FilteredProjectPrompt?.prompts?.versions?.datasets?.[0]?.description;

  // console.log("Filtered Project Version:", FilteredDataset);

  if (!response) {
    return (
      <Box sx={{ p: 3, height: "100%", textAlign: "center" }}>
        <Spinner />
      </Box>
    );
  }

  const progress =
    (analytics?.gradedResponsesCount / analytics?.totalResponsesCount) * 100;

  const handleSubmit = (
    grading: any,
    comment: string,
    responseId: any,
    modelId: any,
    presetId: any
  ) => {
    onSubmit(grading, comment, responseId, modelId, presetId);
  };

  const pageNumbers = [];
  const totalPages = 3;
  const halfTotalPages = Math.floor(totalPages / 2);

  for (let i = 0; i < totalPages; i++) {
    let pageNumber;
    if (currentPage <= halfTotalPages) {
      pageNumber = i + 1;
    } else if (currentPage > totalResponses - halfTotalPages) {
      pageNumber = totalResponses - totalPages + i + 1;
    } else {
      pageNumber = currentPage - halfTotalPages + i;
    }

    if (pageNumber > 0 && pageNumber <= totalResponses) {
      pageNumbers.push(pageNumber);
    }
  }

  const selectedModel = response.models[response.selectedModelIndex];
  const selectedPreset = selectedModel.responses[response.selectedPresetIndex];

  console.log("selectedPreset", selectedPreset?.responseId);
  // console.log("selectedModel", selectedModel);

  // const Resp = FindOneResponses?.data[0];
  // console.log("Super one:", Resp);

  if (isLoading || FindOneSmeLoading) {
    return (
      <Box
        sx={{
          p: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Box sx={{ mb: 2, mt: 0.5 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: "0.8rem",
            backgroundColor: "#F2F2F2",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#F07946",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 0.5,
            mb: 0,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            Total Responses: {analytics?.totalResponsesCount}
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 400 }}>
            {analytics?.gradedResponsesCount} of{" "}
            {analytics?.totalResponsesCount} Responses Graded
          </Typography>
        </Box>
      </Box>
      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0vh 1vw 0vh 0vw",
          mb: 3,
          bgcolor: "#FFFFFF",
          // border: "1px solid #6D4C410D",
          borderRadius: "5px",
          maxHeight: "25vh",
          overflowY: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar": {
            width: "0.5rem",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "0.25rem",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#555",
          },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mt: 2,
          }}
        >
          Prompt
        </Typography>
        {/* <DynamicText text="Tell me a joke about {name}" /> */}

        <DynamicText text={FilteredDataset} params={response?.data} />
        {/* <Box
          sx={{
            backgroundColor: "#F8F8F8",
            border: "1px solid #1212121A",
            color: "#121212B2",
            mt: 4,
            px: 1,
            py: 0.5,
            borderRadius: "4px",
            width: "fit-content",
          }}
        >
          <Typography variant="h5">User Prompt</Typography>
        </Box> */}
      </Box>

      {selectedPreset?.datasetsDetails?.[0]?.dataSetVersions?.[0]
        ?.storageDetails?.url && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            p: 2,
            backgroundColor: "#FFFFFF",
            border: "1px solid #1212121A",
            borderRadius: "0.5rem",
            mb: 3,
            cursor: "pointer",
            width: "39.5vw",
          }}
          onClick={() =>
            window.open(
              selectedPreset?.datasetsDetails?.[0]?.dataSetVersions?.[0]
                ?.storageDetails?.url,
              "_blank"
            )
          }
        >
          <img alt="csv-panel" src={Icons.csv} height={"35vh"} width={"35vw"} />
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {selectedPreset?.datasetsDetails?.[0]?.dataSetVersions?.[0]?.tag}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            borderTop: "1px solid #E0E0E0",
            padding: "0.8vw 1vw 0.8vw 0vw",
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#12121220",
              borderRadius: "4px",
            },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              mb: 2,
              fontWeight: 500,
            }}
          >
            {selectedPreset?.response === null ? "Failure" : "Response"}
          </Typography>
          {selectedPreset?.response || selectedPreset?.failure ? (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 400,
                opacity: 0.6,
                textAlign: "justify",
                color: selectedPreset?.response === null ? "red" : null,
              }}
            >
              {selectedPreset?.response === null
                ? selectedPreset?.failure
                : selectedPreset?.response}
            </Typography>
          ) : (
            <Spinner />
          )}
        </Box>
        {gradingLoading ? (
          <Spinner />
        ) : (
          <Box
            sx={{
              width: "50%",
              padding: "0.8vw 0vw",
              borderLeft: "1px solid #E0E0E0",
              borderTop: "1px solid #E0E0E0",
              pl: 2,
            }}
          >
            <GradingSection
              onGradingChange={console.log}
              onCommentChange={console.log}
              data={FindGradingParams?.gradingParams}
              buttonLogic={selectedPreset}
              showButton={ButtonInvalidate}
              onSubmit={(grades: any) =>
                handleSubmit(
                  grades,
                  comments[response._id],
                  selectedPreset?.responseId,
                  selectedModel.modelId,
                  selectedPreset?.presetId
                )
              }
              // initialGrading={submittedGrades[response._id]}
              // initialComment={comments[response._id]}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{ margin: "0 -1.2vw", mb: 2, borderTop: "1px solid #12121210" }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={onPrevious}
          disabled={currentPage === 1}
          sx={{
            color: "#F4B537",
            minWidth: "auto",
            p: 0,
            "&.Mui-disabled": {
              color: "#E0E0E0",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
            }}
          >
            Previous
          </Typography>
        </Button>
        <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
          {pageNumbers.map((pageNumber) => (
            <Box
              key={pageNumber}
              onClick={() => onPageChange(pageNumber)}
              sx={{
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                border:
                  pageNumber === currentPage
                    ? "2px solid #F4B537"
                    : "1px solid #E0E0E0",
                backgroundColor: "transparent",
                color: pageNumber === currentPage ? "#F07946" : "#666",
                fontWeight: pageNumber === currentPage ? 600 : 400,
                mx: 0.5,
                cursor: "pointer",
              }}
            >
              {pageNumber}
            </Box>
          ))}
        </Box>

        <Button
          onClick={onNext}
          disabled={currentPage === totalResponses}
          sx={{
            color: "#F4B537",
            minWidth: "auto",
            p: 0,
            "&.Mui-disabled": {
              color: "#E0E0E0",
            },
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
            }}
          >
            Next
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
