import Quill from "quill";
import { Mention, MentionBlot } from "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.snow.css";
import { useEffect, useRef } from "react";
// import "../../styles/quill.css";

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });
interface QuillMentionModule {
  openMenu?: any;
  mentionDenotationChars: string[];
  source: (searchTerm: string, renderList: any, mentionChar: string) => void;
}
export interface ValueObject {
  label: string;
  value: string;
}
export interface MentionObject {
  mentionChar: string;
  values: ValueObject[];
}
export interface QuillEditorWithMentionsProps {
  defaultHtml: any;
  placeholder: any;
  onChange: (e: string) => void;
  mentions: MentionObject[];
  loading?: any;
  height?: string;
  border?: boolean;
}

const QuillEditor = ({
  defaultHtml,
  placeholder,
  onChange,
  mentions,
  loading,
  height,
  border = true,
}: QuillEditorWithMentionsProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const mentionModuleRef = useRef<QuillMentionModule | null>(null);

  useEffect(() => {
    if (!editorRef.current) return;

    const quill = new Quill(editorRef.current, {
      modules: {
        toolbar: null,
        mention: {
          allowedChars: /^[A-Za-z\s]*$/,
          mentionDenotationChars: mentions.map(
            (mention) => mention.mentionChar
          ),
          source: function (
            searchTerm: string,
            renderList: any,
            mentionChar: string
          ) {
            const mentionConfig = mentions.find(
              (mention) => mention.mentionChar === mentionChar
            );
            const values = mentionConfig ? mentionConfig.values : [];

            if (!searchTerm || searchTerm.length === 0) {
              renderList(
                values.map((item) => ({ id: item.value, value: item.label })),
                searchTerm
              );
            } else {
              const matches = values
                .filter((item) =>
                  item.label.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((item) => ({ id: item.value, value: item.label }));
              renderList(matches, searchTerm);
            }
          },
          onSelect: function (item: any, insertItem: Function) {
            const selectedValue = item.value;

            const mentionText = ` ${selectedValue} }}`;

            insertItem({
              id: item.id,
              value: mentionText,
              denotationChar: "{{", // Use "{" as the mention character
            });
          },
          listContainerClass: "ql-mention-list-container",
        },
      },
      placeholder,
      theme: "snow",
    });

    quillRef.current = quill;
    mentionModuleRef.current = quill.getModule("mention") as QuillMentionModule;

    quill.clipboard.dangerouslyPasteHTML(0, defaultHtml);

    quill.on("text-change", (delta, oldDelta, source) => {
      onChange(quill.root.innerHTML);
    });

    const copyButton = document.getElementById("copyButton");
    copyButton?.addEventListener("click", () => {
      const htmlContent = quill.root.innerHTML;
      navigator.clipboard.writeText(htmlContent).then(
        () => {
          alert("Content copied to clipboard!");
        },
        () => {
          alert("Failed to copy content.");
        }
      );
    });

    return () => {
      quill.disable();
      quill.container.innerHTML = "";
    };
  }, [mentions, onChange, placeholder]);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}
      {/* <button
        id="userButton"
        style={{
          position: "absolute",
          bottom: "15px",
          left: "15px",
          zIndex: 10,
          backgroundColor: "#F2F2F2",
          color: "#121212B2",
          border: "1px solid #1212121A",
          borderRadius: "5px",
          padding: "8px 20px",
        }}
        // onClick={() => alert("User button clicked!")}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 400,
          }}
        >
          User
        </Typography>
      </button> */}
      <div
        ref={editorRef}
        style={{ height: height, border: border ? "auto" : "none" }}
      ></div>
    </div>
  );
};

export default QuillEditor;
