import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../../shared/components/TextField/TextField";
import DialogWrapper from "../../../shared/components/Wrapper/DialogWrapper";
import { addGradingParameter } from "../../services/utility";

const gradingSchema = yup.object({
  name: yup.string().required("Parameter Name is required"),
  description: yup.string().required("Description is required"),
});

const GradingDialogCard = ({ open, setOpen, data }: any) => {
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(gradingSchema),
  });

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();
  const onSubmit = (formData: any) => {
    addGradingParameter(formData);
    setOpen(false);
    reset();
  };

  return (
    <DialogWrapper
      open={open}
      setOpen={setOpen}
      title={"Add Grading"}
      width={isLargeScreen ? "sm" : isMediumScreen ? "xs" : "xs"}
    >
      <Box
        sx={{
          padding: "6px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Parameter Name"
          name="name"
          required
          placeholder="Enter Parameter Name"
          backgroundColor="#F8F8F8"
          error={!!errors.name}
        />
        <TextField
          formateType={Formate.Alphanumeric}
          control={control}
          label="Description"
          name="description"
          required
          placeholder="Enter Description"
          backgroundColor="#F8F8F8"
          error={!!errors.description}
        />
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
            fontStyle: "italic",
            opacity: 0.7,
          }}
        >
          Note : With respect to parameter add the specific point you want the
          LM response to be graded on.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "10px",
            mt: 2,
          }}
        >
          <MultiTypeButton
            actionOnClick={() => setOpen(false)}
            //   isLoading={uploadLoader || uploadLoader2}
            typeText="Cancel"
            buttonType={ButtonType.Cancel}
            key={"grading-cancel"}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
          <MultiTypeButton
            actionOnClick={handleSubmit(onSubmit)}
            //   isLoading={uploadLoader || uploadLoader2}
            typeText="Submit"
            buttonType={ButtonType.Gradient}
            key={"grading-submit"}
            // disabled={uploadedFiles?.length === 0}
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        </Box>
      </Box>
    </DialogWrapper>
  );
};

export default GradingDialogCard;
