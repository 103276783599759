import { Box, Checkbox, Typography } from "@mui/material";

interface UserCardProps {
  user: {
    _id: string;
    name: string;
    email: string;
    isActive?: any;
  };
  selected: boolean;
  onSelect: (id: string) => void;
}

export default function UserCard({ user, selected, onSelect }: UserCardProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        padding: "12px 0",
        borderBottom: "1px solid #12121210",
        "&:last-child": {
          borderBottom: "none",
        },
      }}
    >
      <Checkbox
        checked={selected}
        onChange={() => onSelect(user._id)}
        sx={{
          padding: "0 12px 0 0",
        }}
      />
      <Box
        sx={{
          color: "#121212",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 500,
            mb: 0.5,
          }}
        >
          {user.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 300,
            opacity: 0.7,
          }}
        >
          {user.email}
        </Typography>
      </Box>
      <Box
        sx={{
          ml: "auto",
          backgroundColor: "#88B05333",
          padding: "4px 12px",
          borderRadius: "10px",
          color: "#88B053",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: 400,
          }}
        >
          {user?.isActive}
        </Typography>
      </Box>
    </Box>
  );
}
