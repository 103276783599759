import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import { Icons } from "../../../assets";
import { orangeLoader } from "../../../assets/lottie/orangeLoader";
import { AuthStore } from "../../../store/general";
import { ColorPalette } from "../../../themes/colorTokens/colorPalette";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import { googleLogin, requestOtp } from "../services/AuthService";

// Define the validation schema
const validationSchema = yup.object({
  EmailAddress: yup
    .string()
    .email("Enter a valid email address")
    .required("Email Address is required"),
});

type GoogleLoginProps = {
  postGoogleLoginDetails: (data: any) => void;
  responseToken: any;
  setFetchingDetails: any;
};

// Helper function for Google login
const handleGoogleLogin = async ({
  responseToken,
  postGoogleLoginDetails,
  setFetchingDetails,
}: GoogleLoginProps) => {
  try {
    setFetchingDetails(true);
    const res = await axios.get(
      "https://www.googleapis.com/oauth2/v2/userinfo",
      {
        headers: {
          Authorization: `Bearer ${responseToken.access_token}`,
        },
      }
    );
    AuthStore.update((s) => {
      s.signupEmailAddress = res.data.email;
      s.userName = res.data.name;
    });
    const data = { token: responseToken.access_token };
    setFetchingDetails(false);
    postGoogleLoginDetails(data);
  } catch (error) {
    setFetchingDetails(false);
    displayErrorMessage("Failed to retrieve user info from Google.");
  }
};

const generateRandomString = (length = 16) => {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
    ""
  );
};

const clientID = process.env.REACT_APP_GITHUB_CLIENT_ID;
const redirectURI = `${process.env.REACT_APP_GITHUB_REDIRECT_URL}/auth/github`;
const state = generateRandomString();

const loginWithGitHub = () => {
  window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientID}&response_type=code&scope=repo&redirect_uri=${redirectURI}&state=${state}&scope=user:email`;
};

export default function LoginDetails() {
  const navigate = useNavigate();
  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [fetchingDetails, setFetchingDetails] = useState(false);

  AuthStore.update((s) => {
    s.signupEmailAddress = null;
  });

  const {
    mutate: postGoogleLoginDetails,
    isLoading: googleLoader,
    isSuccess: isGoogleLoginSuccess,
  } = useMutation(googleLogin, {
    onSuccess: (res) => {
      console.log("GLogin:", res);

      displaySuccessMessage(res.message);
      if (res.isAccountVerified) {
        Cookies.set("access_token", res.access_token);
        Cookies.set("Sme", res?.sme);
        // navigate("/app");
      } else {
        Cookies.set("refresh_Token", res.access_token);
        navigate("/register");
        displayErrorMessage("Please register into platform");
      }
    },
    onError: (error) => {
      displayErrorMessage("Google Login Failed");
      navigate("/signup");
    },
  });

  useEffect(() => {
    if (isGoogleLoginSuccess) {
      const checkSmeAndNavigate = () => {
        const smeValue = Cookies.get("Sme");
        if (smeValue !== undefined) {
          if (smeValue === "true") {
            navigate("/app/sme", { replace: true });
          } else {
            navigate("/app", { replace: true });
          }
        } else {
          // If cookie is not set yet, try again after a short delay
          setTimeout(checkSmeAndNavigate, 100);
        }
      };

      checkSmeAndNavigate();
    }
  }, [isGoogleLoginSuccess, navigate]);

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (responseToken) => {
      handleGoogleLogin({
        responseToken,
        postGoogleLoginDetails,
        setFetchingDetails,
      });
    },
    onError: () => {
      displayErrorMessage("Google Login Failed");
    },
  });

  const { mutate: GetOTP, isLoading: loginLoader } = useMutation(requestOtp, {
    onSuccess: (res: any) => {
      AuthStore.update((s) => {
        s.signupEmailAddress = getValues("EmailAddress");
      });
      Cookies.set("refresh_Token", res.access_token);
      navigate("/otp-verification");
      displaySuccessMessage(res.message);
    },
    onError: (error) => {
      displayErrorMessage(error);
      navigate("/signup");
    },
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: any) => {
    GetOTP({
      type: "LOGIN",
      value: data.EmailAddress,
    });
  };

  if (googleLoader || fetchingDetails) {
    return (
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">Please Wait....</Typography>
        <Lottie
          animationData={orangeLoader}
          loop={true}
          autoplay={true}
          style={{
            width: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
            height: isLargeScreen ? "150px" : isMediumScreen ? "100px" : "80px",
          }} // Fixed size for better presentation
        />
      </Box>
    );
  }

  return (
    <Box
      padding={5}
      sx={{
        "@media (max-width: 1440px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 1280px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 960px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 768px)": {
          padding: "20px 25px",
        },
        "@media (max-width: 480px)": {
          padding: "20px 25px",
        },
      }}
    >
      <img
        src={Icons.namedLogo}
        alt="loading..."
        width={isLargeScreen ? "60%" : isMediumScreen ? "50%" : "30%"}
      />
      <Stack
        my={"3vh"}
        sx={{
          "@media (max-width: 1440px)": {
            my: "1vh",
          },
          "@media (max-width: 1280px)": {
            my: "1vh",
          },
          "@media (max-width: 960px)": {
            my: "1vh",
          },
          "@media (max-width: 768px)": {
            my: "1vh",
          },
          "@media (max-width: 480px)": {
            my: "1vh",
          },
        }}
      >
        <Typography variant="h3">Welcome back!</Typography>
        <Typography variant="subtitle2">
          Log in to continue using your account.
        </Typography>
      </Stack>

      <TextField
        formateType={Formate.EmailAddress}
        control={control}
        name="EmailAddress"
        label="Email Address"
        required
        placeholder="Enter your email address"
        height={
          isLargeScreen
            ? "42px"
            : isMediumScreen
            ? "40px"
            : isSmallScreen
            ? "38px"
            : "35px"
        }
      />

      <Stack
        gap={"1vh"}
        marginTop={"1vh"}
        sx={{
          "@media (max-width: 1440px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 1280px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 960px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 768px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 480px)": {
            marginTop: "1vh",
          },
        }}
      >
        <MultiTypeButton
          buttonType={ButtonType.Gradient}
          actionOnClick={handleSubmit(onSubmit)}
          typeText="Login"
          isLoading={loginLoader}
          key={"log-in"}
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <MultiTypeButton
          buttonType={ButtonType.Google}
          actionOnClick={loginWithGoogle}
          typeText="Login with Google"
          icon={Icons.googleLogo}
          key={"log-in-google"}
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />

        <MultiTypeButton
          buttonType={ButtonType.GitHub}
          actionOnClick={() => {
            loginWithGitHub();
          }}
          typeText="Login with Github"
          icon={Icons.githubLogo}
          key={"log-in-github"}
          height={
            isLargeScreen
              ? "42px"
              : isMediumScreen
              ? "40px"
              : isSmallScreen
              ? "38px"
              : "35px"
          }
        />
      </Stack>

      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2vh",
          "@media (max-width: 1440px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 1280px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 960px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 768px)": {
            marginTop: "1vh",
          },
          "@media (max-width: 480px)": {
            marginTop: "1vh",
          },
        }}
      >
        <Typography variant="subtitle2">New User?</Typography>
        <Button
          variant="text"
          onClick={() => {
            navigate("/signup");
          }}
        >
          <Typography
            variant="subtitle2"
            color={ColorPalette.BrandColors.OrangeLight}
            fontWeight="bold"
          >
            Sign Up
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
}
