import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../../../app/services";
import useScreenSizes from "../../../utils/CustomSizesHook";
import MultiTypeButton, {
  ButtonType,
} from "../../shared/components/Button/MultiTypeButton";
import FileUploadField from "../../shared/components/FileUpload/FileUploadField";
import { SingleSelect } from "../../shared/components/Select/SingleSelect";
import {
  Formate,
  TextField,
} from "../../shared/components/TextField/TextField";
import {
  addDataset,
  CreateDataSetVersion,
  getOneDataset,
} from "../services/DatasetService";
import SingleFileUploadedCard from "./UploadedCards/SingleFileUploadedCard";
import UploadedDataDisplay from "./UploadedCards/UploadedDataDisplay";

export enum mediaSubType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  CSV = "CSV",
  EXCEL = "EXCEL",
  PDF = "PDF",
}

const fileTypesMap = {
  IMAGE: ".png, .jpeg, .jpg",
  VIDEO: ".mp4, .avi",
  AUDIO: ".mp3, .wav",
  CSV: ".csv",
  EXCEL: ".xlsx, .xls",
  PDF: ".pdf",
};

// Define the validation schema
const validationSchema = Yup.object().shape({
  DatasetName: Yup.string().notRequired(),
  DatasetTag: Yup.string()
    .required("Dataset Tag is required")
    .max(50, "Dataset Tag cannot exceed 50 characters"),
  UploadFileType: Yup.string().required("Upload File Type is required"),
});

export default function CreateDataset() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { datasetId, projectId } = useParams();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);

  const currentPath = window.location.pathname;

  const { data: GetOneDatasets } = useQuery(
    ["datasets", datasetId],
    getOneDataset,
    {
      enabled: !!datasetId,
    }
  );
  // console.log("One Dataset:", GetOneDatasets);

  const handleUploadComplete = (data: any) => {
    setUploadedFiles((prevFiles) => [...prevFiles, data]);
  };

  const DeleteFilesHandler = (fileIndex: number) => {
    setUploadedFiles((prevKeys) =>
      prevKeys?.filter((_, index) => index !== fileIndex)
    );
  };

  const { mutate: CreateDataSet, isLoading: uploadLoader } = useMutation(
    addDataset,
    {
      onSuccess: (res: any) => {
        if (projectId) {
          // console.log("1");
          navigate(`/app/projects/${projectId}/datasets/all`);
        } else {
          // console.log("2");
          navigate("/app/datasets/all");
        }
      },
      onError: (error) => {
        displayErrorMessage(error);
      },
    }
  );

  const { mutate: CreateDataVersion, isLoading: uploadLoader2 } = useMutation(
    CreateDataSetVersion,
    {
      onSuccess: (res: any) => {
        if (projectId) {
          navigate(
            `/app/projects/${projectId}/datasets/${datasetId}/detailed-view`
          );
        } else {
          navigate(`/app/datasets/${datasetId}/detailed-view`);
        }
        displaySuccessMessage(res.message);
      },
      onError: (error) => {
        displayErrorMessage(error);
      },
    }
  );

  const uploadKeys = uploadedFiles.map((item: any) => item.key);

  const uploadFileType = ["IMAGE", "VIDEO", "AUDIO", "CSV", "EXCEL", "PDF"];
  const selectedFileType: mediaSubType = watch(
    "UploadFileType"
  ) as mediaSubType;

  useEffect(() => {
    setValue("UploadFileType", "CSV");
  }, [setValue]);

  const onSubmit = (data: any) => {
    if (datasetId) {
      CreateDataVersion({
        body: {
          tags: [data.DatasetTag],
          uploadType: data.UploadFileType,
          fileKeys: uploadKeys,
          generatedFileName: data.DatasetName,
          projectId: projectId,
        },
        datasetId: datasetId,
      });
    } else {
      CreateDataSet({
        name: data.DatasetName,
        tags: [data.DatasetTag],
        uploadType: data.UploadFileType,
        fileKeys: uploadKeys,
        generatedFileName: data.DatasetName,
        projectId: projectId,
      });
    }
  };

  const isMultipleViewAllowed = ["IMAGE", "VIDEO", "AUDIO"].includes(
    selectedFileType
  );

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        sx={{
          p: "20px",
          gap: "20px",
          width: "80%",
          "@media (max-width: 1650px)": {
            width: "70%",
          },
          "@media (max-width: 1450px)": {
            width: "70%",
          },
          "@media (max-width: 1280px)": {
            width: "60%",
          },
          "@media (max-width: 960px)": {
            width: "60%",
          },
          "@media (max-width: 768px)": {
            width: "60%",
          },
          "@media (max-width: 480px)": {
            width: "60%",
          },
        }}
      >
        {datasetId ? (
          <>
            {" "}
            <Typography variant="h3">
              Add Dataset Version for {GetOneDatasets?.data?.name}
              {/* dataSetVersions?.length + 1} */}
            </Typography>
          </>
        ) : (
          <>
            {" "}
            <Typography variant="h3">Add Dataset</Typography>
          </>
        )}
        <Stack
          border={`2px solid ${theme.palette.border.primary}`}
          sx={{
            width: "100%",
            p: "20px",
            // height:
            //   isMultipleViewAllowed && uploadedFiles?.length > 0
            //     ? "80vh"
            //     : "70vh",
            justifyContent: "space-between",
          }}
          borderRadius="10px"
        >
          <Stack sx={{ width: "50%", gap: "10px" }}>
            {datasetId ? (
              <>
                <Typography variant="h4">
                  Version {GetOneDatasets?.data?.dataSetVersions?.length + 1}
                </Typography>
              </>
            ) : null}
            {(!currentPath.startsWith("/app/datasets/") ||
              currentPath === "/app/datasets/create") &&
              !currentPath.endsWith("/add-dataset-version") && (
                <TextField
                  formateType={Formate.Alphanumeric}
                  control={control}
                  name="DatasetName"
                  label="Dataset Name"
                  required
                  placeholder="Enter your Dataset Name"
                  height={
                    isLargeScreen
                      ? "42px"
                      : isMediumScreen
                      ? "40px"
                      : isSmallScreen
                      ? "38px"
                      : "35px"
                  }
                />
              )}

            <TextField
              formateType={Formate.EmailAddress}
              control={control}
              name="DatasetTag"
              label="Dataset Tag"
              required
              placeholder="Enter your Dataset Tag"
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
            <Typography
              variant="body1"
              sx={{ fontStyle: "italic", color: "grey", marginBottom: "10px" }}
            >
              {" "}
              Ex - beta, test, staging, prod
            </Typography>

            <SingleSelect
              defaultData="CSV"
              control={control}
              required
              name="UploadFileType"
              label="Upload File Type"
              options={uploadFileType}
              placeholder="Select File Type"
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />

            <FileUploadField
              label="Upload Dataset"
              required={true}
              name="datasetFile"
              uploadFileExtension={fileTypesMap[selectedFileType]}
              uploadedFiles={uploadedFiles}
              uploadFileType={watch("UploadFileType")}
              containerProps={{ my: 2 }}
              type={"DATASET"}
              onUploadComplete={handleUploadComplete}
            />
          </Stack>
          {isMultipleViewAllowed && uploadedFiles?.length > 0 ? (
            <Box
              sx={{
                mt: 2,
                mb: 1,
              }}
            >
              <UploadedDataDisplay
                label="Upload Dataset"
                required={true}
                name="datasetFile"
                uploadFileType={selectedFileType}
                uploadedFiles={uploadedFiles}
                DeleteFilesHandler={DeleteFilesHandler}
              />
            </Box>
          ) : (
            !isMultipleViewAllowed &&
            uploadedFiles?.length > 0 && (
              <Box
                sx={{
                  mt: 2,
                  mb: 1,
                }}
              >
                <SingleFileUploadedCard
                  label="Upload Dataset"
                  required={true}
                  name="datasetFile"
                  uploadFileType={selectedFileType}
                  uploadedFiles={uploadedFiles}
                  DeleteFilesHandler={DeleteFilesHandler}
                />
              </Box>
            )
          )}

          <Box sx={{ width: "150px", mt: 1 }}>
            <MultiTypeButton
              actionOnClick={handleSubmit(onSubmit)}
              isLoading={uploadLoader || uploadLoader2}
              typeText="Submit"
              buttonType={ButtonType.Gradient}
              key={"create-dataset"}
              disabled={uploadedFiles?.length === 0}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
