import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ColDef, GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import DatasetsDialog from "../../../layout/components/TableUIComponents/DatasetsDialog";
import { getOneProject } from "../../../project/services/ProjectService";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";
import { handleError } from "../Errors/HandleError";
import AddModelDialog from "./addModelDialog";
import CompareModelDialog from "./compareModelDialog";
import CustomHeader from "./CustomHeader";
import GradientDialog from "./gradientDialog";
import "./grid.css";
import { AntSwitch } from "./grid.styles";
import ModelHeader from "./modelHeader";
import { postPromptNewVersion } from "./services";
import ModelFilter from "./utils/model-filter";

interface GridExampleProps {
  rowsData: any[];
  loading?: boolean;
  setLimit?: any;
  setOffset?: any;
  totalCount: number;
  currentLimit: number;
  currentOffset: number;
  onPaginationChange: (limit: number, offset: number) => void;
  compareTable?: boolean;
}
interface ColumnOrderState {
  [key: string]: number;
}

const GradientStrip = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90deg, #F17B46 0%, #F3A33C 100%)",
  padding: "0.5rem 1rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const IconWrapper = styled(IconButton)(({ theme }) => ({
  color: "#FFFFFF",
  padding: "0.25rem",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const ResponseMetrics = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  display: "flex",
  alignItems: "center",
  gap: "0.25rem",
  fontSize: "clamp(0.75rem, 2vw, 1rem)",
  fontWeight: 300,
}));

const DataGrid = ({
  rowsData = [],
  loading = false,
  totalCount,
  setLimit,
  setOffset,
  currentLimit,
  currentOffset,
  onPaginationChange,
  compareTable,
}: GridExampleProps) => {
  const navigate = useNavigate();
  const { projectId, promptId, versionId } = useParams();
  const [rowData, setRowData] = useState<any[]>(rowsData || []);
  const [lockedColumns, setLockedColumns] = useState<string[]>([]);
  // const [isAllLocked, setIsAllLocked] = useState(false);
  const [selectedCell, setSelectedCell] = useState<
    { rowIndex: any; colId: any; colIndex: any; data?: any } | any
  >(null);
  const [openHideFieldsModel, setHideFieldsModel] =
    useState<null | HTMLElement>(null);
  const [openLockFieldsModel, setLockFieldsModel] =
    useState<null | HTMLElement>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | any>();
  const [isAllLocked, setIsAllLocked] = useState(false);
  const [RowHeight, setRowHeight] = useState<any>("100");
  const [presetData, setPresetData] = useState<any[]>([]);

  const [pageSize, setPageSize] = useState<number>(currentLimit);
  const [currentPage, setCurrentPage] = useState(
    Math.floor(currentOffset / currentLimit) + 1
  );

  const [openStrip, setOpenStrip] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const [openCompare, setOpenCompare] = useState(false);
  const [openRun, setOpenRun] = useState(false);

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>({});
  const columnOrderRef = useRef<ColumnOrderState>({});

  const totalPages = Math.ceil(totalCount / pageSize);

  const rowHeight = Number(RowHeight);

  const isMediumLaptop = useMediaQuery(
    "(min-width: 1320px) and (max-width: 1450px)"
  );
  const isMediumLaptop2 = useMediaQuery("(min-width: 1550)");

  const { data: FindOneProject } = useQuery(
    ["projects", projectId],
    getOneProject,
    {
      enabled: !!projectId,
    }
  );

  const GetAllPrompts = FindOneProject?.prompts;

  const segregatedData =
    GetAllPrompts?.find((prompt: any) => prompt?._id === promptId) || {};

  const version = segregatedData?.versions?.find(
    (v: any) => v?._id == versionId
  );
  const { mutate: PostPromptNewVersions, isLoading: PromptVersionLoading } =
    useMutation(postPromptNewVersion, {
      onSuccess: () => {
        toast.success("Prompt new Version Added  Successfully");
        localStorage.clear();
        navigate(
          `/app/projects/${projectId}/prompts/${promptId}/prompt-detailed-view`
        );
      },
      onError: (err) => {
        handleError(err);
      },
    });

  const presetDataRef = useRef(presetData);
  useEffect(() => {
    presetDataRef.current = presetData;
  }, [presetData]);

  const handleGridReady = (params: any) => {
    setGridApi(params.api);
  };

  useEffect(() => {
    const savedOrder = localStorage.getItem("column-order");
    const savedPresetData = localStorage.getItem("presetData");

    if (savedOrder) {
      const parsedOrder = JSON.parse(savedOrder);
      setColumnOrder(parsedOrder);
      columnOrderRef.current = parsedOrder;
    }

    if (savedPresetData) {
      const parsedPresetData = JSON.parse(savedPresetData);
      setPresetData(parsedPresetData);
    }
  }, []);

  useEffect(() => {
    if (rowsData?.length > 0) {
      setRowData(rowsData);

      const initialOrder = columnOrderRef.current;
      const hasStoredOrder = Object.keys(initialOrder).length > 0;

      // console.log("rowsData1:", rowsData);

      let regularColumns: any = Object.keys(rowsData[0])
        .filter((key) => key !== "DatasetRecord" && key !== "+AddModel")
        .sort((a, b) => {
          if (compareTable) {
            const baseA = a.replace(/_v[0-9]+$/, "");
            const baseB = b.replace(/_v[0-9]+$/, "");

            if (baseA === baseB) {
              const versionA = parseInt(a.match(/_v(\d+)$/)?.[1] || "0");
              const versionB = parseInt(b.match(/_v(\d+)$/)?.[1] || "0");
              return versionA - versionB;
            }

            return baseA.localeCompare(baseB);
          }

          if (!hasStoredOrder) return 0;
          const orderA = initialOrder[a] ?? Infinity;
          const orderB = initialOrder[b] ?? Infinity;
          return orderA - orderB;
        });

      // console.log("Sorted column keys:", regularColumns);

      regularColumns = regularColumns.map((key: any) => {
        // console.log("Mapped key:", key);

        return {
          field: key,
          width: 400,
          minWidth: 350,
          // maxWidth: 400,
          editable: false,
          suppressSizeToFit: true,
          headerComponent: (props: any) =>
            CustomHeader(
              props,
              rowsData,
              setPresetData,
              columnOrderRef.current
            ),
          cellRenderer: (cellData: any) => renderCell(cellData, key),
        };
      });

      const datasetRecordColumn = {
        field: "DatasetRecord",
        width: 150,
        minWidth: 150,
        // maxWidth: 150,
        editable: false,
        pinned: "left",
        lockPosition: true,
        suppressMovable: true,
        suppressSizeToFit: true,
        headerComponent: (props: any) =>
          CustomHeader(props, rowsData, setPresetData, columnOrderRef.current),
        cellRenderer: (cellData: any) => renderCell(cellData, "DatasetRecord"),
      };

      const addModelColumn = !compareTable
        ? {
            field: "+AddModel",
            headerName: "+ Add Model",
            width: 100,
            minWidth: 100,
            // maxWidth: 100,
            pinned: "right",
            editable: false,
            lockPosition: true,
            suppressMovable: true,
            suppressSizeToFit: true,
            headerComponent: (props: any) => (
              <ModelHeader setOpenAddModel={setOpenAddModel} />
            ),
            cellRenderer: (params: any) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  height: "100%",
                }}
                onClick={() => setOpenAddModel(true)}
              />
            ),
          }
        : null;

      const allColumnDefs = [datasetRecordColumn, ...regularColumns];
      if (addModelColumn) allColumnDefs.push(addModelColumn);

      // console.log("All Column Definitions:", allColumnDefs);

      setColumnDefs(allColumnDefs);
    }
  }, [rowsData, presetData, compareTable, CustomHeader, selectedCell]);

  // const dynamicColumnDefs: ColDef[] = useMemo(() => {
  //   const columns: ColDef[] =
  //     rowsData?.length > 0
  //       ? Object.keys(rowsData[0]).map((key, index) => ({
  //           field: key,
  //           flex: index === 0 ? 0 : 1,
  //           editable: false,
  //           pinned: index === 0 ? "left" : undefined,
  //           lockPosition: index === 0,
  //           suppressMovable: index === 0,
  //           suppressSizeToFit: index === 0,
  //           headerComponent: (props: any) =>
  //             CustomHeader(
  //               props,
  //               rowsData,
  //               setPresetData,
  //               columnOrderRef.current
  //             ),
  //           cellRenderer: (params: any) => renderCell(params, key),
  //         }))
  //       : [];

  //   const addModelColumn: ColDef = {
  //     colId: "+AddModel",
  //     field: "+AddModel",
  //     headerName: "+ Add Model",
  //     pinned: "right",
  //     editable: false,
  //     lockPosition: true,
  //     suppressMovable: true,
  //     suppressSizeToFit: true,
  //     resizable: false,
  //     sortable: false,
  //     filter: false,
  //     width: 100,
  //     headerComponent: (props: any) => (
  //       <ModelHeader setOpenAddModel={setOpenAddModel} />
  //     ),
  //     cellRenderer: (params: any) => {
  //       return (
  //         <>
  //           <Box
  //             sx={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItems: "center",
  //               cursor: "pointer",
  //             }}
  //             onClick={() => setOpenAddModel(true)}
  //           ></Box>
  //         </>
  //       );
  //     },
  //   };
  //   if (!compareTable) {
  //     columns.push(addModelColumn);
  //   }
  //   return columns;
  // }, [rowsData, presetData, CustomHeader, selectedCell]);

  useEffect(() => {
    // setColumnDefs(dynamicColumnDefs);
    localStorage.setItem("presetData", JSON.stringify(presetData));
  }, [presetData]);

  const renderCell = useCallback(
    (cellData: any, key: string) => {
      const isSelected =
        selectedCell?.rowIndex === cellData.node.rowIndex &&
        selectedCell?.colId === key;

      const selectedPreset: any = presetData?.find(
        (p: any) => p?.columnId === key
      );

      if (key === "DatasetRecord") {
        return (
          <>
            {/* {
            isSelected ?
             (
              <>
                <button
                  onClick={() => alert(`Cell Text: ${cellData.value}`)}
                  style={{
                    background: "#e3f2fd",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  {cellData.value}
                </button>
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  mx={"5px"}
                  variant="body1"
                  sx={{ textDecoration: "underline" }}
                >
                  {cellData.value}
                </Typography>
              </Box>
            )} */}
            <Box
              sx={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                mx={"5px"}
                variant="body1"
                sx={{ textDecoration: "underline" }}
              >
                {cellData.value}
              </Typography>
            </Box>
          </>
        );
      } else {
        const responseText =
          cellData?.value?.response?.[selectedPreset?.presetIndex || 0]
            ?.response !== null
            ? cellData?.value?.response?.[selectedPreset?.presetIndex || 0]
                ?.response
            : cellData?.value?.response?.[selectedPreset?.presetIndex || 0]
                ?.failure;
        const responseTime = "0.4 sec";
        const tokenCount =
          cellData?.value?.response?.[selectedPreset?.presetIndex || 0]?.preset
            ?.maxTokens;

        return (
          <Box
            key={`${cellData.node.rowIndex}-${key}-${isSelected}`}
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.05)",
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                flex: 1,
                padding: "1rem",
                overflowY: "auto",
              }}
            >
              <Typography
                variant="body1"
                color={
                  cellData?.value?.response?.[selectedPreset?.presetIndex || 0]
                    ?.response !== null
                    ? "text.secondary"
                    : "red"
                }
                sx={{
                  fontSize: "clamp(0.75rem, 1.5vw, 0.875rem)",
                }}
              >
                {responseText}
              </Typography>
            </Box>
            {isSelected && (
              <GradientStrip onClick={() => setOpenStrip(true)}>
                <ResponseMetrics>
                  response time - {responseTime} | max tokens - {tokenCount}
                </ResponseMetrics>

                <Stack direction="row" spacing={0.5}>
                  <img src={Icons.expandArrow} alt="" width={"18px"} />
                </Stack>
              </GradientStrip>
            )}
          </Box>
        );
      }
    },
    [selectedCell, presetData, setOpenStrip]
  );

  useEffect(() => {
    return () => {
      if (Object.keys(columnOrderRef.current).length > 0) {
        localStorage.setItem(
          "column-order",
          JSON.stringify(columnOrderRef.current)
        );
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      // Clean up Ag-Grid state
      if (gridApi) {
        gridApi.destroy();
      }
    };
  }, [gridApi]);

  const handleColumnMoved = useCallback((event: any) => {
    if (!event.finished) return;

    const newOrder: ColumnOrderState = {};
    event.api.getAllGridColumns().forEach((column: any, index: number) => {
      newOrder[column.colId] = index;
    });

    setColumnOrder(newOrder);
    columnOrderRef.current = newOrder;
    localStorage.setItem("column-order", JSON.stringify(newOrder));
  }, []);

  const handleCellClick = useCallback(
    (event: any) => {
      if (!event.colDef || event.rowIndex === undefined || !gridApi) return;
      setSelectedCell(null);
      const colId = event.colDef.field;

      const columnIds = event.api
        .getColumnDefs()
        .filter(
          (col: any) =>
            col.field !== "DatasetRecord" && col.field !== "+AddModel"
        )
        .map((col: any) => col.field);

      const colIndex = columnIds.indexOf(colId);
      const newSelection = {
        rowIndex: event.rowIndex,
        colId: event.colDef.field || "",
        colIndex: colIndex,
        data: event.data,
      };

      setSelectedCell(newSelection);

      if (event?.colDef?.field === "+AddModel") {
        setOpenAddModel(true);
      }

      gridApi.refreshCells({
        force: true,
        columns: [event.colDef.field],
      });
    },
    [gridApi]
  );

  const handleCloseStrip = useCallback(() => {
    setOpenStrip(false);
    setSelectedCell(null);
    localStorage.clear();

    if (selectedCell && gridApi) {
      gridApi.refreshCells({
        force: true,
        columns: [selectedCell.colId],
      });
    }
  }, [selectedCell, gridApi]);

  const defaultColDef: ColDef = {
    cellStyle: (params) => {
      const isSelected = selectedColumns.includes(params.colDef.field || "");
      const isDatasetRecord = params.colDef.field === "DatasetRecord";

      return {
        fontWeight: isSelected ? "bold" : "normal",
        // backgroundColor: isSelected ? "rgba(25, 118, 210, 0.2)" : "",
        // borderLeft: "0.2px solid lightGrey",
        borderRight: "0.2px solid lightGrey",
        whiteSpace: "normal",
        textAlign: isDatasetRecord ? "center" : "left",
        // justifyContent: isDatasetRecord ? "center" : "flex-start",
        padding: "0",
        // borderAll: "2px solid #1976d2",
      };
    },
  };

  const handleLockColumn = (colId: string) => {
    const newColumnDefs = columnDefs.map((col) => {
      if (col.field === colId) {
        const lockPosition = !col.lockPosition;

        return {
          ...col,
          suppressMovable: lockPosition,
          suppressDrag: lockPosition,
          lockPosition: lockPosition,
        };
      }
      return col;
    });
    setLockedColumns((prevLocked) =>
      prevLocked.includes(colId)
        ? prevLocked.filter((col) => col !== colId)
        : [...prevLocked, colId]
    );
    setColumnDefs(newColumnDefs);
  };

  const handleUnlockAllColumns = () => {
    const newColumnDefs = columnDefs.map((col) => {
      const lockPosition = false;

      return {
        ...col,
        suppressMovable: lockPosition,
        suppressDrag: lockPosition,
        lockPosition: lockPosition,
      };
    });

    setLockedColumns([]);
    setColumnDefs(newColumnDefs);
  };

  const handleToggleVisibility = (colId: string) => {
    setHiddenColumns((prevHidden) =>
      prevHidden.includes(colId)
        ? prevHidden.filter((col) => col !== colId)
        : [...prevHidden, colId]
    );
  };

  const handleShowAllColumns = () => {
    setHiddenColumns([]);
  };

  const handleColumnSelect = (
    colId: string,
    isCtrlPressed: boolean,
    isShiftPressed: boolean
  ) => {
    if (isShiftPressed && selectedColumns.length > 0) {
      const colIndex = columnDefs.findIndex((col) => col.field === colId);
      const lastSelectedIndex = columnDefs.findIndex(
        (col) => col.field === selectedColumns[selectedColumns.length - 1]
      );
      const newSelectedColumns = columnDefs
        .slice(
          Math.min(colIndex, lastSelectedIndex),
          Math.max(colIndex, lastSelectedIndex) + 1
        )
        .map((col) => col.field || "");
      setSelectedColumns(newSelectedColumns);
    } else if (isCtrlPressed) {
      setSelectedColumns((prev) =>
        prev.includes(colId)
          ? prev.filter((col) => col !== colId)
          : [...prev, colId]
      );
    } else {
      setSelectedColumns([colId]);
    }
  };

  const handleExportSelectedColumns = () => {
    if (!gridApi) return;

    gridApi.exportDataAsCsv({
      onlySelected: false,
      columnKeys: selectedColumns.length ? selectedColumns : undefined,
    });
  };

  const handleGlobalKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setSelectedColumns([]);
    }
  };

  const handleClickOutsideGrid = () => {
    setSelectedColumns([]);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleGlobalKeyPress);
    document.addEventListener("click", handleClickOutsideGrid);

    return () => {
      document.removeEventListener("keydown", handleGlobalKeyPress);
      document.removeEventListener("click", handleClickOutsideGrid);
    };
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (height: number) => {
    setRowHeight(height);
    setAnchorEl(null);
  };

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
      const newOffset = (page - 1) * pageSize;
      onPaginationChange(pageSize, newOffset);
    },
    [pageSize, onPaginationChange]
  );

  useEffect(() => {
    const newPage = Math.floor(currentOffset / currentLimit) + 1;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [currentOffset, currentLimit]);

  const convertToCSV = (data: any) => {
    const headers = Object.keys(data[0]).join(",") + "\n";

    // Map data to CSV rows
    const rows = data
      .map((row: any) => {
        return Object.values(row)
          .map((value) => {
            if (typeof value === "object" && value !== null) {
              return `"${JSON.stringify(value)}"`;
            }
            return `"${value}"`;
          })
          .join(",");
      })
      .join("\n");

    return headers + rows;
  };

  const downloadCSV = () => {
    if (!rowsData || rowsData.length === 0) {
      alert("No data to export!");
      return;
    }

    const csvData = convertToCSV(rowsData);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `responses.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHideFieldsModel(event.currentTarget);
  };

  const handleMenuClose = () => {
    setHideFieldsModel(null);
  };

  const handleHideAllColumns = () => {
    setHiddenColumns(
      columnDefs
        .filter((col) => col.field !== "DatasetRecord")
        .map((col) => col.field || "")
    );
  };

  const handleLockMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLockFieldsModel(event.currentTarget);
  };

  const handleLockMenuClose = () => {
    setLockFieldsModel(null);
  };

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1vw",
          justifyContent: compareTable ? "flex-end" : "space-between",
          padding: "1vw 2.5vw",
        }}
      >
        {compareTable ? null : (
          <MultiTypeButton
            actionOnClick={() => setOpenRun(true)}
            typeText={"Re-Run"}
            buttonType={ButtonType.Gradient}
            width={
              isLargeScreen
                ? "192px"
                : isMediumScreen
                ? "115px"
                : isSmallScreen
                ? "61px"
                : "192px"
            }
            height={
              isLargeScreen
                ? "42px"
                : isMediumScreen
                ? "40px"
                : isSmallScreen
                ? "38px"
                : "35px"
            }
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.5vw",
          }}
        >
          {compareTable && (
            <ModelFilter
              rowsData={rowsData}
              onFilterChange={(selectedModels) => {
                const newHiddenColumns = columnDefs
                  .filter(
                    (col) =>
                      col.field !== "DatasetRecord" && col.field !== "+AddModel"
                  )
                  .map((col) => col.field || "")
                  .filter((field) => {
                    const baseModelName = field.replace(/_v[12]$/, "");
                    return (
                      selectedModels.length > 0 &&
                      !selectedModels.includes(baseModelName)
                    );
                  });

                setHiddenColumns(newHiddenColumns);
              }}
            />
          )}
          <FormControl
            sx={{
              marginTop: 0,
            }}
          >
            <IconButton onClick={handleMenuOpen}>
              <img
                src={Icons.hide}
                style={{ width: "16px", marginRight: "5px" }}
                alt="hide"
              />
              <Typography variant="h5">
                Hide fields{" "}
                {hiddenColumns.length > 0 && `(${hiddenColumns.length})`}
              </Typography>
            </IconButton>

            <Menu
              anchorEl={openHideFieldsModel}
              open={Boolean(openHideFieldsModel)}
              onClose={handleMenuClose}
              PaperProps={{ style: { maxHeight: "auto", width: "auto" } }}
            >
              <Box
                padding={"10px 18px"}
                sx={{
                  "@media (max-width: 1650px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 1450px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 1280px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 960px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 480px)": {
                    padding: "10px 18px",
                  },
                }}
              >
                {columnDefs
                  .filter(
                    (col) =>
                      col.field !== "DatasetRecord" && col.field !== "+AddModel"
                  )
                  .map((col) => (
                    <MenuItem
                      key={col.field}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "10px",
                        padding: "4px",
                      }}
                    >
                      <AntSwitch
                        size="small"
                        checked={!hiddenColumns.includes(col.field || "")}
                        onChange={() => handleToggleVisibility(col.field || "")}
                        sx={{ transform: "scale(0.8)" }}
                        inputProps={{ "aria-label": "ant design" }}
                      />
                      <Typography variant="body1" style={{ flexGrow: 1 }}>
                        {col.field}
                      </Typography>
                    </MenuItem>
                  ))}
              </Box>

              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                padding={"5px 16px"}
                marginBottom={"5px"}
              >
                <MenuItem
                  onClick={handleShowAllColumns}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 8px",
                    backgroundColor: "#0000000D",
                  }}
                >
                  <Typography
                    textAlign="center"
                    variant="body1"
                    sx={{ flexGrow: 1 }}
                  >
                    Show All
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={handleHideAllColumns}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 8px",
                    backgroundColor: "#0000000D",
                  }}
                >
                  <Typography
                    textAlign="center"
                    variant="body1"
                    sx={{ flexGrow: 1 }}
                  >
                    Hide All
                  </Typography>
                </MenuItem>
              </Stack>
            </Menu>
          </FormControl>

          <FormControl
            sx={{
              marginTop: 0,
            }}
          >
            <IconButton onClick={handleLockMenuOpen}>
              {isAllLocked ? (
                <>
                  <LockOpenIcon
                    sx={{
                      width: "1vw",
                      height: "1.3vh",
                      marginRight: "0.2rem",
                    }}
                  />
                  <Typography variant="h5">
                    Unlock fields{" "}
                    {lockedColumns.length > 0 && `(${lockedColumns.length})`}
                  </Typography>
                </>
              ) : (
                <>
                  <img
                    src={Icons.lock}
                    style={{ width: "13px", marginRight: "5px" }}
                    alt="lock"
                  />
                  <Typography variant="h5">
                    Lock fields{" "}
                    {lockedColumns.length > 0 && `(${lockedColumns.length})`}
                  </Typography>
                </>
              )}
            </IconButton>

            {/* Dropdown Menu */}
            <Menu
              anchorEl={openLockFieldsModel}
              open={Boolean(openLockFieldsModel)}
              onClose={handleLockMenuClose}
              // PaperProps={{ style: { maxHeight: 400, w } }}
            >
              <Box
                padding={"10px 18px"}
                sx={{
                  "@media (max-width: 1650px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 1450px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 1280px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 960px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 768px)": {
                    padding: "10px 18px",
                  },
                  "@media (max-width: 480px)": {
                    padding: "10px 18px",
                  },
                }}
              >
                {columnDefs
                  .filter(
                    (col) =>
                      col.field !== "DatasetRecord" && col.field !== "+AddModel"
                  )
                  .map((col) => (
                    <MenuItem
                      key={col.field}
                      sx={{
                        alignItems: "center",
                        gap: "10px",
                        padding: "4px",
                      }}
                      onClick={() => handleLockColumn(col.field || "")}
                    >
                      {lockedColumns.includes(col.field || "") ? (
                        <>
                          {" "}
                          <img
                            src={Icons.lock}
                            style={{
                              width: "17px",
                            }}
                            alt="lock"
                          />{" "}
                        </>
                      ) : (
                        <LockOpenIcon sx={{ fontSize: "20px" }} />
                      )}

                      <Typography variant="body1">{col.field}</Typography>
                    </MenuItem>
                  ))}
              </Box>

              <MenuItem
                onClick={() => {
                  if (lockedColumns.length === 0) {
                    const allFields = columnDefs
                      .filter(
                        (col) =>
                          col.field !== "DatasetRecord" &&
                          col.field !== "+AddModel"
                      )
                      .map((col) => col.field);

                    allFields.forEach((field) => handleLockColumn(field || ""));
                  } else if (lockedColumns.length === 1) {
                    handleLockColumn(lockedColumns[0]);
                  } else {
                    handleUnlockAllColumns();
                  }
                }}
                sx={{
                  width: "auto",
                  display: "flex",
                  justifyContent: "center",
                  padding: "0px 8px",
                  backgroundColor: "#0000000D",
                  "&:hover": {
                    backgroundColor: "#0000000D",
                  },
                  "&:active": {
                    backgroundColor: "#0000000D",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "#0000000D",
                  },
                }}
              >
                <Typography variant="body1">
                  {lockedColumns.length === 0
                    ? "Lock All"
                    : lockedColumns.length === 1
                    ? `Unlock ${
                        lockedColumns[0].length > 10
                          ? lockedColumns[0].slice(0, 10) + "..."
                          : lockedColumns[0]
                      }`
                    : "Unlock All"}
                </Typography>
              </MenuItem>
            </Menu>
          </FormControl>

          <FormControl
            sx={{
              marginTop: 0,
              paddingRight: "10px",
            }}
          >
            <IconButton onClick={handleClick} id="row-height-button">
              <Stack direction={"row"}>
                <img
                  src={Icons.upDownArrow}
                  style={{
                    width: isMediumLaptop2 ? "13.5px" : "15px",
                    height: isMediumLaptop2 ? "13.5px" : "13px",
                    marginTop: 5,
                  }}
                  alt="height"
                />
                <Typography variant="h5">Row height</Typography>
              </Stack>
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "row-height-button",
              }}
              sx={{
                "& .MuiPaper-root": {
                  width: anchorEl ? anchorEl.offsetWidth : "fit-content", // Match width to IconButton
                },
              }}
            >
              <Box sx={{ padding: "8px" }}>
                {[100, 150, 200, 250].map((value) => (
                  <MenuItem
                    key={value}
                    onClick={() => handleSelect(value)}
                    sx={{
                      padding: "4px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        color:
                          Number(RowHeight) === value ? "orange" : "inherit",
                      }}
                    >
                      {value === 100 && "Short"}
                      {value === 150 && "Medium"}
                      {value === 200 && "Tall"}
                      {value === 250 && "Extra tall"}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </FormControl>
          {!compareTable && (
            <>
              <MultiTypeButton
                actionOnClick={downloadCSV}
                typeText="Export"
                buttonType={ButtonType.Gradient}
                icon={Icons.DownloadIcon}
                width={
                  isLargeScreen
                    ? "192px"
                    : isMediumScreen
                    ? "115px"
                    : isSmallScreen
                    ? "61px"
                    : "192px"
                }
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
              <MultiTypeButton
                actionOnClick={() => setOpenCompare(true)}
                typeText="Compare"
                buttonType={ButtonType.Gradient}
                width={
                  isLargeScreen
                    ? "192px"
                    : isMediumScreen
                    ? "115px"
                    : isSmallScreen
                    ? "61px"
                    : "192px"
                }
                height={
                  isLargeScreen
                    ? "42px"
                    : isMediumScreen
                    ? "40px"
                    : isSmallScreen
                    ? "38px"
                    : "35px"
                }
              />
            </>
          )}
        </Box>
      </Box>

      <Box
        onClick={(e) => e.stopPropagation()}
        sx={{
          height: "100%",
          width: "100%",
          overflow: "hidden",
          margin: 0,
          padding: 0,
        }}
      >
        <AgGridReact
          className="ag-theme-alpine"
          loading={loading}
          pagination={false}
          // paginationPageSize={pageSize}
          rowData={rowData}
          suppressPaginationPanel={true}
          suppressHorizontalScroll={false}
          onPaginationChanged={(e) => {}}
          columnDefs={columnDefs
            .filter((col) => !hiddenColumns.includes(col.field || ""))
            .map((col, index: any) => {
              return {
                ...col,
                minWidth:
                  col.field === "DatasetRecord"
                    ? 10
                    : col.field === "+AddModel"
                    ? 5
                    : 350,
                headerComponentParams: {
                  onLockColumn: handleLockColumn,
                  onColumnSelect: handleColumnSelect,
                  onToggleVisibility: handleToggleVisibility,
                  onColumnPin: (colId: string, pin: any) => {
                    if (gridApi) gridApi.setColumnPinned(colId, pin);
                  },
                  selectedColumns,
                  hiddenColumns,
                  lockedColumns,
                },
              };
            })}
          defaultColDef={defaultColDef}
          rowHeight={rowHeight || 400}
          onColumnMoved={handleColumnMoved}
          maintainColumnOrder={true}
          onGridReady={handleGridReady}
          domLayout="autoHeight"
          onCellClicked={handleCellClick}
          onCellFocused={(event) => {
            if (!event.rowIndex && !event.column) {
              // Deselect cell when focus is lost
              setSelectedCell(null);
            }
          }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // marginTop: 2,
            padding: "0.5vw 2vw",
          }}
        >
          {/* <Typography variant="body1">
            Showing {pageSize} of {rowData?.length} entries{" "}
          </Typography> */}
          <Typography variant="body1">
            Showing {Math.min(currentOffset + 1, totalCount)} to{" "}
            {Math.min(currentOffset + currentLimit, totalCount)} of {totalCount}{" "}
            entries
          </Typography>

          <Typography variant="body1">
            Note: LLM may display inaccurate info, including about people, so
            double-check its responses.
          </Typography>

          <Pagination
            count={totalPages}
            variant="outlined"
            page={currentPage}
            shape="rounded"
            siblingCount={1}
            boundaryCount={1}
            color="primary"
            sx={{ color: "#F87320" }}
            onChange={handlePageChange}
            renderItem={(item: any) => (
              <PaginationItem
                {...item}
                sx={{ color: "#F87320" }}
                page={item.page < 10 ? `0${item.page}` : item.page}
              />
            )}
          />
        </Box>
      </Box>
      <GradientDialog
        open={openStrip}
        setOpen={setOpenStrip}
        data={selectedCell}
        onClose={handleCloseStrip}
      />
      <AddModelDialog
        open={openAddModel}
        setOpen={setOpenAddModel}
        setSelectedCell={setSelectedCell}
        data={version}
      />
      <DatasetsDialog
        open={openRun}
        setOpen={setOpenRun}
        datasetId={version?.datasets?.[0]?.datasetId}
        mainId={version?.datasets?.[0]?._id}
        datasetVersionIds={version?.datasets?.[0]?.datasetVersionIds}
      />
      <CompareModelDialog
        open={openCompare}
        setOpen={setOpenCompare}
        data={version}
        rowsData={rowsData}
        prompt={segregatedData}
      />
    </Box>
  );
};

export default DataGrid;
