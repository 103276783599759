import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";

const CardSelector = ({
  names,
  onNameSelect,
  cards,
  setDelete,
  emptyPreset,
}: {
  names: string[];
  onNameSelect: (selectedName: string) => void;
  cards?: any;
  setDelete?: any;
  emptyPreset?: any;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (names.length === 0) {
      setSelectedIndex(0);
      onNameSelect("");
      return;
    }

    if (selectedIndex >= names.length) {
      setSelectedIndex(names.length - 1);
    }

    const selectedName = names[selectedIndex];
    if (selectedName) {
      const selectedCard = cards.find(
        (card: any) =>
          `${card?.providerName} ${card?.versionName}` === selectedName
      );
      onNameSelect(selectedCard || null);
    }
  }, [selectedIndex, names, cards, onNameSelect]);

  const handleUp = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === 0 ? names.length - 1 : prevIndex - 1
    );
    setDelete(false);
    emptyPreset(null);
  };

  const handleDown = () => {
    setSelectedIndex((prevIndex) =>
      prevIndex === names.length - 1 ? 0 : prevIndex + 1
    );
    setDelete(false);
    emptyPreset(null);
  };

  useEffect(() => {
    if (!names[selectedIndex]) {
      const newIndex = selectedIndex === 0 ? 0 : selectedIndex - 1;
      setSelectedIndex(newIndex);
    }
  }, [names, selectedIndex]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    setDelete(false);
    emptyPreset(null);
    handleClose();
  };

  const selectedCard =
    cards?.find(
      (card: any) =>
        `${card.providerName} ${card.versionName}` === names[selectedIndex]
    ) || {};

  return (
    <Box
      sx={{
        // display: "flex",
        // flexDirection: "row",
        // gap: "0.5vw",
        position: "relative",
        bgcolor: "#FDF7F4",
        border: "1px solid #1212121A",
        // borderBottom: "none",
        borderRadius: "8px",
        // alignItems: "center",
        // justifyContent: "space-between",
        // padding: "0 0.5vw",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5vw 0.8vw",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
          }}
        >
          {selectedCard?.providerName && selectedCard?.versionName
            ? `${selectedCard?.providerName}: ${selectedCard?.versionName}`
            : names[selectedIndex]}
        </Typography>
        <IconButton
          size="small"
          sx={{
            p: 0,
            ml: 1,
          }}
        >
          <KeyboardArrowDown
            sx={{
              transition: "transform 0.2s",
              transform: open ? "rotate(180deg)" : "rotate(0)",
            }}
          />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            minWidth: anchorEl?.offsetWidth,
            "&:hover": {},
            "& .MuiList-root": {
              padding: "0.5vw 0",
            },
          },
        }}
      >
        {names.map((name, index) => {
          const matchedCard = cards?.find(
            (card: any) => `${card.providerName} ${card.versionName}` === name
          );
          console.log("Mains:", matchedCard);

          return (
            <MenuItem
              key={name}
              onClick={() => handleSelect(index)}
              selected={index === selectedIndex}
              sx={{
                padding: "0.5vw 0.8vw",
                minHeight: "auto",
                "&.Mui-selected": {
                  backgroundColor: "rgba(240, 121, 70, 0.08)",
                  color: "#F07946",
                },
                "&:hover": {
                  backgroundColor: "rgba(240, 121, 70, 0.04)",
                },
              }}
            >
              <Typography variant="subtitle1">
                {`${matchedCard.providerName}: ${matchedCard.versionName}`}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <IconButton
          size="small"
          onClick={handleUp}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <ExpandLessIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={handleDown}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <ExpandMoreIcon fontSize="small" />
        </IconButton>
      </Box> */}
    </Box>
  );
};

export default CardSelector;
