import { Box, Card } from "@mui/material";
import { ReactNode } from "react";
import { Images } from "../../../../assets"; // Ensure this path is correct
import { UIStore } from "../../../../store/general";

interface PageWrapperProps {
  children: ReactNode;
}

export default function OnboardingPageWrapper({ children }: PageWrapperProps) {
  const isDarkTheme = UIStore.useState((s) => s.isDarkMode);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        background: `linear-gradient(to bottom, #FFFFFF, #F1F4FE)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background Image Layer with Reduced Opacity */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          backgroundImage: isDarkTheme
            ? `url(${Images.wrapperPatternDark})`
            : `url(${Images.wrapperPattern})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          opacity: isDarkTheme ? 1 : 0.5, // Reduced opacity for background only
          zIndex: 1, // Behind the content
        }}
      />

      {/* Content Layer */}
      <Card
        sx={{
          boxShadow: "0px 1px 5px 5px #F079461A",
          maxHeight: "900px",
          width: "500px",
          zIndex: 2,
          "@media (max-width: 1440px)": {
            width: "380px",
          },
          "@media (max-width: 1280px)": {
            width: "360px",
          },
          "@media (max-width: 960px)": {
            width: "350px",
          },
          "@media (max-width: 768px)": {
            width: "300px",
          },
          "@media (max-width: 480px)": {
            width: "300px",
          },
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
