import { Edit } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import Quill from "quill";
import { Mention, MentionBlot } from "quill-mention";
import "quill-mention/dist/quill.mention.css";
import "quill/dist/quill.snow.css";
import { useEffect, useRef } from "react";
import "../../styles/quill.css";

Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });

export interface ValueObject {
  label: string;
  value: string;
}
export interface MentionObject {
  mentionChar: string;
  values: ValueObject[];
}
export interface QuillEditorEditWithMentionsProps {
  defaultHtml: any;
  placeholder: any;
  onChange: (e: string) => void;
  mentions: MentionObject[];
  loading?: any;
  height?: string;
  border?: boolean;
  setIsEditable: (value: boolean) => void;
}

const QuillEditorEdit = ({
  defaultHtml,
  placeholder,
  onChange,
  mentions,
  loading,
  height = "40vh",
  border = true,
  setIsEditable,
}: QuillEditorEditWithMentionsProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const isEditableRef = useRef<boolean>(false); // Tracks editor's editability state

  useEffect(() => {
    if (!editorRef.current) return;

    const quill = new Quill(editorRef.current, {
      modules: {
        toolbar: null,
        mention: {
          allowedChars: /^[A-Za-z\s]*$/,
          mentionDenotationChars: mentions.map(
            (mention) => mention.mentionChar
          ),
          source: function (
            searchTerm: string,
            renderList: any,
            mentionChar: string
          ) {
            const mentionConfig = mentions.find(
              (mention) => mention.mentionChar === mentionChar
            );
            const values = mentionConfig ? mentionConfig.values : [];

            if (!searchTerm || searchTerm.length === 0) {
              renderList(
                values.map((item) => ({ id: item.value, value: item.label })),
                searchTerm
              );
            } else {
              const matches = values
                .filter((item) =>
                  item.label.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((item) => ({ id: item.value, value: item.label }));
              renderList(matches, searchTerm);
            }
          },
          onSelect: function (item: any, insertItem: Function) {
            const selectedValue = item.value;

            const mentionText = ` ${selectedValue} }}`;

            insertItem({
              id: item.id,
              value: mentionText,
              denotationChar: "{{",
            });
          },
        },
      },
      placeholder,
      theme: "snow",
    });

    quillRef.current = quill;

    // Disable editor initially
    quill.disable();

    quill.clipboard.dangerouslyPasteHTML(0, defaultHtml);

    quill.on("text-change", () => {
      onChange(quill.root.innerHTML);
    });

    return () => {
      quill.disable();
      quill.container.innerHTML = "";
    };
  }, [mentions, onChange, placeholder, defaultHtml]);

  const toggleEditable = () => {
    if (quillRef.current) {
      const newEditableState = !isEditableRef.current;
      isEditableRef.current = newEditableState;
      setIsEditable(newEditableState); // Trigger re-render for icon color
      if (newEditableState) {
        quillRef.current.enable(); // Enable editing
      } else {
        quillRef.current.disable(); // Disable editing
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
        </div>
      )}

      <IconButton
        onClick={toggleEditable}
        sx={{
          position: "absolute",
          top: "1vh",
          right: "3vw",
          zIndex: 10,
          padding: "4px",
          // color: isEditable ? "primary.main" : "text.secondary", // Use state for color
        }}
      >
        <Edit
          fontSize="small"
          sx={{
            width: "18px",
            height: "18px",
            "@media (max-width: 1650px)": {
              width: "20px",
              height: "16px",
            },
            "@media (max-width: 1450px)": {
              width: "20px",
              height: "16px",
            },
            "@media (max-width: 1280px)": {
              width: "20px",
              height: "16px",
            },
            "@media (max-width: 960px)": {
              width: "20px",
              height: "16px",
            },
            "@media (max-width: 768px)": {
              width: "20px",
              height: "16px",
            },
            "@media (max-width: 480px)": {
              width: "20px",
              height: "16px",
            },
          }}
        />
        <Typography variant="h5">Edit prompt</Typography>
      </IconButton>

      <div
        ref={editorRef}
        style={{
          height: height,
          border: border ? "1px solid #ccc" : "none",
          paddingRight: "clamp(120px, 15vw, 180px)",
        }}
      ></div>
    </div>
  );
};

export default QuillEditorEdit;
