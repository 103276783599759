import { Stack, StackProps, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";

export const LabelWrapper = ({
  required,
  name,
  children,
  containerProps,
  label,
}: {
  required: boolean;
  name?: string;
  label?: ReactNode | string;
  children: ReactNode;
  containerProps?: StackProps;
}) => {
  const theme = useTheme();
  return (
    <Stack {...containerProps}>
      {typeof label === "string" ? (
        <Typography
          htmlFor={name}
          component="label"
          variant="h6"
          sx={{
            fontWeight: 400,
          }}
        >
          {label}
          {required && <span style={{ color: `red` }}>{" *"}</span>}
        </Typography>
      ) : (
        label
      )}
      {children}
    </Stack>
  );
};
