import { Box, ButtonBase, Typography } from "@mui/material";

export default function ModelHeader({
  setOpenAddModel,
}: {
  setOpenAddModel: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClick = () => {
    setOpenAddModel(true);
  };

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        padding: "10px",
      }}
    >
      <Box>
        <Typography variant="body1">+ Add Model</Typography>
      </Box>
    </ButtonBase>
  );
}
