import { Box, InputBase } from "@mui/material";
import { Search } from "lucide-react";

interface SearchContainerProps {
  placeholder?: string;
  onChange: (value: string) => void;
  value?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  filteredUsers?: any;
}

function SmeSearchContainer({
  placeholder = "Search by Email/Name",
  onChange,
  value,
  defaultValue = "",
  autoFocus = false,
  filteredUsers,
}: SearchContainerProps) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "44px",
        backgroundColor: "#F8F8F8",
        border: "1px solid #12121226",
        borderRadius: "6px",
        overflow: "hidden",
        pr: 1,
      }}
    >
      <InputBase
        sx={{
          flex: 1,
          pl: 2,
          fontSize: "14px",
          color: "#121212",
          "&::placeholder": {
            color: "#121212",
            opacity: 0.5,
          },
        }}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
        autoFocus={autoFocus}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#F4B537",
          width: "32px",
          height: "32px",
          borderRadius: "4px",
          margin: "6px",
        }}
      >
        <Search size={16} color="#FFF" />
      </Box>
    </Box>
  );
}

export default SmeSearchContainer;
