import { Typography } from "@mui/material";

export const tier = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Tier 1",
    value: "tier_1",
  },
  {
    label: "Tier 2",
    value: "tier_2",
  },
  {
    label: "Tier 3",
    value: "tier_3",
  },
  {
    label: "Tier 4",
    value: "tier_4",
  },
  {
    label: "Tier 5",
    value: "tier_5",
  },
];

export function extractMinValue(range: any) {
  // console.log("Function:Range", range);

  const minPart = range?.split("-");

  // console.log("Function:Parts:Min", minPart);
  // console.log("Function:Return", Number(minPart[0]));

  return minPart?.length === 2 ? Number(minPart[0]) : NaN;
}

export function extractMaxValue(range: any) {
  const maxPart = range?.split("-");
  // console.log("Function:Parts:Max", maxPart);

  return maxPart?.length === 2 ? Number(maxPart[1]) : NaN;
}

export default function DynamicText({
  text,
  params,
}: {
  text: string;
  params?: any;
}) {
  const regex = /{{([^{}]+)}}/g;

  const paramMap = params
    ? params.reduce((acc: any, { param, value }: any) => {
        acc[param.trim()] = value;
        return acc;
      }, {} as Record<string, any>)
    : {};

  const processMatch = (match: string) => {
    const cleanedMatch = match
      .replace(/^{{[">﻿\s]+/, "{{")
      .replace(/\s+}}$/, "}}");
    const content = cleanedMatch.slice(2, -2).trim();

    const truncatedContent =
      content.length > 60 ? content.slice(0, 60) + "..." : content;

    const replacementValue = paramMap?.[content];

    if (replacementValue !== undefined) {
      return `<span style="color: #9C27B0;">${replacementValue}</span>`;
    }

    return `<span style="color: #9C27B0;">{{ ${truncatedContent} }}</span>`;
  };

  const htmlContent = text.replace(regex, processMatch);

  return (
    <Typography
      variant="h6"
      sx={{ fontWeight: 400 }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}
