import { Box, IconButton, MenuItem, Select, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { ChevronLeftIcon } from "lucide-react";
import { useEffect, useState } from "react";
import Spinner from "../../../../shared/components/Loaders/Spinner";
import { GradingParameters } from "../../types/sme-grade";
import ResponseCard from "./ResponseCard";

interface LeftPanelProps {
  search?: any;
  responses: any;
  selectedResponse: any | null;
  onSelectResponse: (
    response: any,
    modelIndex: number,
    presetIndex: number
  ) => void;
  submittedGrades: Record<string, GradingParameters>;
}

export default function LeftPanel({
  search,
  responses,
  selectedResponse,
  onSelectResponse,
  submittedGrades,
}: LeftPanelProps) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [filter, setFilter] = useState("All");
  const [userId, setUserId] = useState("");
  const [isContentReady, setIsContentReady] = useState(false);

  useEffect(() => {
    if (responses?.length) {
      setIsContentReady(true);
    }
  }, [responses]);

  useEffect(() => {
    const token = Cookies.get("access_token");
    if (token) {
      const decoded: any = jwtDecode(token);
      setUserId(decoded?.userId || "");
    }
  }, []);

  const filterResponses = (responses: any[], filter: string) => {
    if (filter === "All") return responses;

    const isCompleted = (preset: any) =>
      preset.grading
        ?.filter((grade: any) => grade.type === "Sme")
        ?.some((grade: any) => grade.userId === userId);

    return responses
      .map((response) => ({
        ...response,
        models: response.models.map((model: any) => ({
          ...model,
          responses: model.responses.filter((preset: any) =>
            filter === "Completed" ? isCompleted(preset) : !isCompleted(preset)
          ),
        })),
      }))
      .filter((response) =>
        response.models.some((model: any) => model.responses.length > 0)
      );
  };

  return (
    <Box
      sx={{
        width: isCollapsed ? "4rem" : "25rem",
        borderRight: "1px solid #12121210",
        height: "100%",
        // transition: "width 0.3s ease",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            mt: 2,
            "@media (max-width: 1650px)": {
              mt: 0,
            },
            "@media (max-width: 1450px)": {
              mt: 0,
            },
            "@media (max-width: 1280px)": {
              mt: 0,
            },
            "@media (max-width: 960px)": {
              mt: 0,
            },
            "@media (max-width: 768px)": {
              mt: 0,
            },
            "@media (max-width: 480px)": {
              mt: 0,
            },
          }}
        >
          {!isCollapsed && <Typography variant="h4">All Responses</Typography>}
          <IconButton
            onClick={() => setIsCollapsed(!isCollapsed)}
            sx={{
              ml: isCollapsed ? -1 : 0,
              mr: isCollapsed ? 0 : -1,
              "@media (max-width: 1650px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
              "@media (max-width: 1450px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
              "@media (max-width: 1280px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
              "@media (max-width: 960px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
              "@media (max-width: 768px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
              "@media (max-width: 480px)": {
                ml: isCollapsed ? -1.5 : 0,
                mr: isCollapsed ? 0 : -1.5,
              },
            }}
          >
            <ChevronLeftIcon
              style={{
                transform: isCollapsed ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease",
              }}
            />
          </IconButton>
        </Box>
        {!isCollapsed && (
          <>
            <Box
              sx={{ margin: "0 -1vw", mb: 2, borderTop: "1px solid #12121210" }}
            />
            <Select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              displayEmpty
              sx={{
                width: "100%",
                marginBottom: "1rem",
              }}
            >
              <MenuItem value="All">All Responses</MenuItem>
              <MenuItem value="Pending">Pending Responses</MenuItem>
              <MenuItem value="Completed">Completed Responses</MenuItem>
            </Select>
          </>
        )}
      </Box>

      {!isCollapsed && (
        <Box
          sx={{
            height: "calc(100% - 8rem)",
            padding: "0vh 0.9vw",
            mt: -2,
            display: "flex",
            flexDirection: "column",
            gap: "1vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#12121220",
              borderRadius: "4px",
              cursor: "pointer",
            },
          }}
        >
          {responses ? (
            // responses.flatMap((response: any, responseIndex: number) =>
            filterResponses(responses, filter).flatMap(
              (response: any, responseIndex: number) =>
                response.models.flatMap((model: any, modelIndex: number) =>
                  model.responses.map((preset: any, presetIndex: number) => (
                    <ResponseCard
                      key={`${response._id}-${model.modelId}-${preset.presetId}`}
                      response={response}
                      model={model}
                      preset={preset}
                      isSelected={
                        selectedResponse?._id === response._id &&
                        selectedResponse?.selectedModelIndex === modelIndex &&
                        selectedResponse?.selectedPresetIndex === presetIndex
                      }
                      onClick={() =>
                        onSelectResponse(response, modelIndex, presetIndex)
                      }
                      isSubmitted={
                        !!submittedGrades[
                          `${response._id}-${model.modelId}-${preset.presetId}`
                        ]
                      }
                    />
                  ))
                )
            )
          ) : (
            <Spinner />
          )}
        </Box>
      )}
    </Box>
  );
}
