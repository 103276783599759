import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Images } from "../../../../assets";
import useScreenSizes from "../../../../utils/CustomSizesHook";
import AddProjectForm from "../../../project/components/AddProjectForm";
import MultiTypeButton, { ButtonType } from "../Button/MultiTypeButton";

interface EmptyPageProps {
  buttonTitle: string;
  tagLine?: string;
  Sme?: string;
}

export default function EmptyPage({
  buttonTitle,
  tagLine,
  Sme,
}: EmptyPageProps) {
  const [open, setOpen] = useState(false);
  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (open && buttonTitle === "Create a Dataset") {
      if (projectId) {
        navigate(`/app/projects/${projectId}/datasets/create`);
      } else {
        navigate("/app/datasets/create");
      }
    }
  }, [open, buttonTitle, navigate]);

  const handleClickAdd = () => {
    setOpen(true);
  };

  const { isLargeScreen, isMediumScreen, isSmallScreen, isExtraSmallScreen } =
    useScreenSizes();

  const renderDialogCard = () => {
    if (buttonTitle === "Add Project") {
      return <AddProjectForm open={open} setOpen={setOpen} data={null} />;
    }

    return null;
  };

  return (
    <Stack
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        gap: "20px",
      }}
    >
      {buttonTitle === "This Page not build Yet!" ? (
        <img src={Images.PageUnderCon} alt="" width={"32%"} />
      ) : buttonTitle === "Page Not Found" ? (
        <img src={Images.PageNotF} alt="" width={"32%"} />
      ) : (
        <img src={Images.emptyScreen} alt="" width={"32%"} />
      )}
      <Typography variant="h1" sx={{ fontWeight: 600 }}>
        {tagLine}
      </Typography>
      {buttonTitle !== "This Page not build Yet!" &&
        buttonTitle !== "Page Not Found" &&
        Sme === "false" && (
          <Box width={"15%"}>
            <MultiTypeButton
              buttonType={ButtonType.Gradient}
              typeText={buttonTitle}
              actionOnClick={handleClickAdd}
              key={"empty-page-button"}
              height={
                isLargeScreen
                  ? "42px"
                  : isMediumScreen
                  ? "40px"
                  : isSmallScreen
                  ? "38px"
                  : "35px"
              }
            />
          </Box>
        )}

      {open && renderDialogCard()}
    </Stack>
  );
}
