import { http } from "../../../app/services";

const addRole = (data: any) => {
  return http.post("/role", data);
};

const getAllRoles = (queryKey: any) => {
  return http
    .get(`/role`, {
      params: { ...queryKey },
    })
    .then((res) => res.data);
};

const getOneRole = ({ queryKey }: any) => {
  return http.get(`/role/${queryKey[1]}`).then((res) => res.data);
};

const updateRole = ({ data, id }: any) => {
  return http.put(`/role/${id}`, data).then((res) => res.data);
};

const deleteRole = ({ ids }: any) => {
  return http.delete(`/role`, { data: { ids } });
};

export { addRole, deleteRole, getAllRoles, getOneRole, updateRole };
